/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  LockOutlined,
} from '@ant-design/icons';

import Foliadores from 'views/Retys/Foliador';
// import MotivosDeSolicitudes from 'views/Solicitudes/MotivosDeSolicitudes';
import Usuarios from 'views/Administracion/Usuarios';
import Roles from 'views/Administracion/Roles';
import Horarios from 'views/Administracion/Horarios';
import Funcionarios from 'views/Administracion/Funcionarios';
import EntidadMunicipal from 'views/Administracion/EntidadMunicipal';
import Puestos from 'views/Administracion/Puestos';
import UnidadesRecaudadoras from 'views/Administracion/UnidadesRecaudadoras';
import blank from './blank';

export default {
  title: 'Administración',
  icon: () => <LockOutlined />,
  showInSidebar: true,
  component: blank,
  type: 'private',
  children: [
    {
      title: 'Unidades de Recaudación',
      icon: null,
      type: 'private',
      showInSidebar: true,
      component: UnidadesRecaudadoras,
    },
    {
      title: 'Formato De Folios',
      icon: null,
      component: Foliadores,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Usuarios',
      icon: null,
      component: Usuarios,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Entidad Municipal',
      icon: null,
      component: EntidadMunicipal,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Roles',
      icon: null,
      component: Roles,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Permisos',
      icon: null,
      component: blank,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Horarios de Trabajo',
      icon: null,
      component: Horarios,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Funcionarios',
      icon: null,
      component: Funcionarios,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Puestos',
      icon: null,
      component: Puestos,
      type: 'private',
      showInSidebar: true,
    },

  ],
};
