/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Row,
  Col,
  Input,
} from 'antd';
import NumericInput from 'components/NumericInput';
import makeMessager from 'utils/complexFormsMessages';
import { onError } from 'utils/handlers';
import { getUnidadDeRecaudacion, postConfiguracionDeRetiro } from 'api/configuracion/unidadesDeRecaudacion';

const Configuration = ({
  setLoading,
  callback,
  setCurrentTabKey,
  setForm,
  disabled,
  selectedRowKey,
}) => {
  const [form] = Form.useForm();

  const fetchData = async () => {
    try {
      setLoading(true);
      if (selectedRowKey) {
        const unidad = await getUnidadDeRecaudacion(selectedRowKey);
        if (unidad?.configuracion_de_retiro) {
          form.setFieldsValue(unidad.configuracion_de_retiro);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const showMsg = makeMessager(setCurrentTabKey, fetchData);

  const onFinish = async (_continue = true) => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      values.unidad_de_recaudacion = selectedRowKey;
      const data = await postConfiguracionDeRetiro(selectedRowKey, values);
      if (data) {
        setTimeout(async () => {
          callback(data, _continue);
          if (_continue) {
            await fetchData();
          }
        });
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onFinishHandler = async (tabKey = null, _continue = true) => {
    try {
      if (tabKey) {
        showMsg(tabKey);
      } else {
        await onFinish(_continue);
      }
    } catch (err) {
      onError(err);
    }
  };

  useEffect(() => {
    form.onFinishHandler = onFinishHandler;
    let mounted = true;
    const fetchAll = async () => {
      setLoading(true);
      if (selectedRowKey) {
        const unidad = await getUnidadDeRecaudacion(selectedRowKey);
        if (unidad?.configuracion_de_retiro) {
          form.setFieldsValue(unidad.configuracion_de_retiro);
        }
      }
      if (mounted) {
        setLoading(false);
        setTimeout(() => setForm(form));
      }
    };
    fetchAll();
    return () => { mounted = false; };
  }, []);

  return (
    <Col span={24}>
      <Form
        form={form}
        name="configuration"
        layout="vertical"
        onFinish={() => onFinish()}
      >
        <Row gutter={10}>
          <Col xs={24} sm={24} md={8}>
            <NumericInput
              name="importe_maximo"
              label="Importe máximo"
              decimal
              required
              disabled={disabled}
            />
          </Col>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Row>
      </Form>
    </Col>
  );
};

Configuration.propTypes = {
  callback: PropTypes.func,
  setLoading: PropTypes.func.isRequired,
  setForm: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  setCurrentTabKey: PropTypes.func.isRequired,
  selectedRowKey: PropTypes.number,
};

Configuration.defaultProps = {
  callback: () => {},
  disabled: false,
  selectedRowKey: null,
};

export default Configuration;
