import { store } from 'store';

export const ActionsNames = {
  view: 'view',
  add: 'add',
  change: 'change',
  delete: 'delete',
  authorize: 'autorizar',
  cancel: 'delete',
  export: 'exportar',
  import: 'importar',
  sync: 'sincronizar',
};

// Rutas accesibles para todos los usuarios
const alwaysAllowedRoutes = [
  '/dashboard',
  '/configuracion/entidad',
  '/configuracion/cuenta-de-usuario',
];

// Rutas que aun no se encuentran disponibles
const currentExceptions = [];

const allExceptions = [...alwaysAllowedRoutes, ...currentExceptions];

/**
  * Función para identificar si el usuario es un administrador
  * @returns {boolean}
 */
export const isAdmin = () => {
  const entidad = store.getState()?.auth?.entidad;
  const user = store.getState()?.auth?.user;
  return entidad?.administradores?.includes(user.id);
};

/**
  * Función para identificar si el usuario tiene el permiso para una acción específica
  * @param {string} model - Modelo
  * @param {string} type - Tipo de accion
  * @returns {boolean}
 */
export const hasActionPermission = (model, type) => {
  const user = store.getState()?.auth?.user;
  // eslint-disable-next-line prefer-template
  return user?.permisos?.includes((type + '_' + model).toLowerCase()) || false;
};

/**
  * Función para obtener los permisos de un usuario para un modelo específico
  * @param {string} model - Modelo
  * @param {Array<string>} catalogs - Catálogos del Modelo
  * @param {boolean} affectView - Afectar vista si no tiene permisos de catálogos
  * @returns {object}
 */
export const getModelPermission = (model, catalogs = [], affectView = false) => {
  const keys = Object.keys(ActionsNames);
  const entries = keys.map((key) => [key, hasActionPermission(model, ActionsNames[key])]);
  const permission = Object.fromEntries(entries);
  permission.model = model;
  permission.hasAllViewPermission = permission.view && catalogs
    .map((m) => hasActionPermission(m, ActionsNames.view)).every((e) => e);
  permission.view = affectView ? permission.hasAllViewPermission : permission.view;
  return permission;
};

/**
  * Función para identificar si el usuario tiene el permiso de vista para una ruta específica
  * @param {string} path - Path de la ruta
  * @param {string} permissionModel - Nombre del modelo base de la ruta
  * @param {Array<string>} catalogsModels - Nombre de los modelos de las
  * dependencias que tiene la ruta
  * @returns {boolean}
 */
export const routePermission = (path, permissionModel, catalogsModels = []) => {
  const permission = getModelPermission(permissionModel, catalogsModels);
  return allExceptions.some((e) => e?.includes(path))
    || (permission.view && permission.hasAllViewPermission);
};
