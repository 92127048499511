export const defaultSeparators = [
  {
    value: '/',
    label: '/',
  },
  {
    value: '-',
    label: '-',
  },
  {
    value: '.',
    label: '.',
  },
  {
    value: '_',
    label: '_',
  },
  {
    value: '*',
    label: '*',
  },
  {
    value: '\\',
    label: '\\',
  },
  {
    value: '~',
    label: '~',
  },
  {
    value: '|',
    label: '|',
  },
  {
    value: ' ',
    label: 'Espacio',
  },
];

export default {
  defaultSeparators,
};
