/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  UnorderedListOutlined,
} from '@ant-design/icons';

import BenefiaciosDelTramite from 'views/Retys/BeneficiariosDelTramite';
import CanalesDePresentacion from 'views/Retys/CanalDePresentacion';
import CategoriasDelTramite from 'views/Retys/CategoriasDelTramite';
import CatRequisitos from 'views/Retys/CatRequisitos';
import EmisorDeDocumentos from 'views/Retys/EmisorDeDocumentos';
import FamiliaDelTramite from 'views/Retys/FamiliaDeTramite';
import FundamentosLegales from 'views/Retys/FundamentosLegales';
import ProcesoEconomico from 'views/Retys/ProcesoEconomico';
import Requisitos from 'views/Retys/Requisitos';
import SubCategoriaDeFamilias from 'views/Retys/SubCategoriaDeFamiliaDeTramite';
import SubSectoresEconomicos from 'views/Retys/SubSectoresEconomicos';
import TecnologiasDeTramite from 'views/Retys/TecnologiasDeLosTramites';
import TipoDeAcceso from 'views/Retys/TiposDeAcceso';
import TipoDeDocumento from 'views/Retys/TipoDeDocumento';
import TipoDeOficinaDeAtencion from 'views/Retys/TiposDeOficinaDeAtencion';
import TipoDePersonaQueCumple from 'views/Retys/TipoDePersonaQueCumple';
// import TipoDePersonaQuePuede from 'views/Retys/TipoDePersonaQuePuede';
import TipoDeResolucion from 'views/Retys/TipoDeResolucion';
import TiposDeTramite from 'views/Retys/TiposDeTramite';
import UnidadesResponsable from 'views/CatalogoCompartido/UnidadesResponsables';
import ProgramaRelacionado from 'views/Retys/ProgramaRelacionado';
// import SubsectorEconomicoPlantillas from 'views/Retys/SubSectorEconomicoPlantillas';
import NombresDeOrdenamiento from 'views/Retys/NombresDeOrdenamiento';
import InstitucionDeOrigen from 'views/Retys/InstitucionDeOrigen';
import CatalogoDeFichasDeTramites from 'views/Retys/CatalogoDeFichasDeTramites';
import TipoDePersonaInteresada from 'views/Retys/tipoDePersonasInteresadas';
import blank from './blank';
// import solicitudes from './solicitudes';

export default {
  title: 'Catálogos de Trámites',
  icon: () => <UnorderedListOutlined />,
  showInSidebar: true,
  type: 'private',
  children: [
    {
      title: 'Básica I',
      icon: null,
      type: 'private',
      showInSidebar: true,
      children: [
        {
          title: 'Dependencias',
          icon: null,
          component: UnidadesResponsable,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Departamentos',
          icon: null,
          component: UnidadesResponsable,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Programa Relacionado',
          icon: null,
          component: ProgramaRelacionado,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Catálogo de Folios',
          icon: null,
          component: blank,
          type: 'private',
          showInSidebar: true,
        },
        // {
        //   title: 'Ámbito del Tramite',
        //   icon: null,
        //   component: Ambito,
        //   type: 'private',
        //   showInSidebar: true,
        // },
        {
          title: 'Tipos de Trámites',
          icon: null,
          component: TiposDeTramite,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Tipos De Personas Interesadas',
          icon: null,
          component: TipoDePersonaInteresada,
          type: 'private',
          showInSidebar: true,
        },
      ],
    },
    {
      title: 'Básica II',
      icon: null,
      type: 'private',
      showInSidebar: true,
      children: [
        {
          title: 'Canal de Presentación',
          icon: null,
          component: CanalesDePresentacion,
          type: 'private',
          showInSidebar: true,
        },
      ],

    },
    {
      title: 'Básica III',
      icon: null,
      type: 'private',
      showInSidebar: true,
      children: [
        {
          title: 'Tipo de Resolución',
          icon: null,
          component: TipoDeResolucion,
          type: 'private',
          showInSidebar: true,
        },
      ],

    },
    {
      title: 'Complementaria I',
      icon: null,
      type: 'private',
      showInSidebar: true,
      children: [
        {
          title: 'Proceso Económico',
          icon: null,
          component: ProcesoEconomico,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Subsector Económico',
          icon: null,
          component: SubSectoresEconomicos,
          type: 'private',
          showInSidebar: true,
        },
        // {
        //   title: 'Subsector Económico Plantillas',
        //   icon: null,
        //   component: SubsectorEconomicoPlantillas,
        //   type: 'private',
        //   showInSidebar: true,
        // },
        {
          title: 'Tecnología del Trámite',
          icon: null,
          component: TecnologiasDeTramite,
          type: 'private',
          showInSidebar: true,
        },
      ],
    },
    {
      title: 'Complementaria II',
      icon: null,
      type: 'private',
      showInSidebar: true,
      children: [
        {
          title: 'Beneficiarios del Trámite',
          icon: null,
          component: BenefiaciosDelTramite,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Categoría de Trámites',
          icon: null,
          component: CategoriasDelTramite,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Familias del Trámite',
          icon: null,
          component: blank,
          type: 'private',
          showInSidebar: true,
          children: [
            {
              title: 'Familia de Trámite',
              icon: null,
              component: FamiliaDelTramite,
              type: 'private',
              showInSidebar: true,
            },
            {
              title: 'Subcategoría de Familias de Trámite',
              icon: null,
              component: SubCategoriaDeFamilias,
              type: 'private',
              showInSidebar: true,
            },
          ],
        },
      ],
    },
    {
      title: 'Interoperabilidad',
      icon: null,
      type: 'private',
      showInSidebar: true,
      children: [
        {
          title: 'Configuración de Requisitos',
          icon: null,
          component: Requisitos,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Catálogo de Requisitos',
          icon: null,
          component: CatRequisitos,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Emisor de Documentos',
          icon: null,
          component: EmisorDeDocumentos,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Tipos de Documento',
          icon: null,
          component: TipoDeDocumento,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Tipos de Acceso',
          icon: null,
          component: TipoDeAcceso,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Institución de Origen',
          icon: null,
          component: InstitucionDeOrigen,
          type: 'private',
          showInSidebar: true,
        },
      ],
    },
    {
      title: 'Oficinas de Atención',
      icon: null,
      type: 'private',
      showInSidebar: true,
      children: [
        {
          title: 'Oficina de Atención',
          icon: null,
          component: UnidadesResponsable,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Tipos de Oficinas de Atención',
          icon: null,
          component: TipoDeOficinaDeAtencion,
          type: 'private',
          showInSidebar: true,
        },
      ],
    },
    {
      title: 'Costeo Estándar',
      icon: null,
      type: 'private',
      showInSidebar: true,
      children: [
        {
          title: 'Tipos de Persona que Cumple ',
          icon: null,
          component: TipoDePersonaQueCumple,
          type: 'private',
          showInSidebar: true,
        },
      ],

    },
    {
      title: 'Catálogo Compartido',
      showInSidebar: true,
      type: 'private',
      children: [
        {
          title: 'Fundamentos Legales',
          icon: null,
          component: FundamentosLegales,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Regulaciones',
          icon: null,
          component: NombresDeOrdenamiento,
          type: 'private',
          showInSidebar: true,
        },

      ],
    },
    {
      title: 'Catálogo De Fichas de Trámites',
      showInSidebar: true,
      type: 'private',
      component: CatalogoDeFichasDeTramites,
    },
  ],
};
