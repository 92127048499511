import Moment from 'moment';
import { store } from 'store';
import { DATE_FRONTEND_FORMAT } from 'utils/formatValues';

Moment.defaultFormat = DATE_FRONTEND_FORMAT;

export const DECIMAL_PATTERN = /^(\d)*(\.\d{1,2})?$/;
export const INTEGER_PATTERN = /[0-9]/g;
export const INTEGER_PATTERN_REPLACE = /[^0-9]+/g;
export const toUpper = (val = '') => val?.toUpperCase();

export const toInteger = (val) => val.replace(INTEGER_PATTERN_REPLACE, '') || null;

/**
 * Format to Upper
 * @param {string|Date} val
 * @returns {Moment}
 */
export const toMoment = (val = '') => new Moment(val);

/**
 * Format to Integer
 * @param {string} val
 * @returns number|null
 */
export const decimalNormalizer = (_val = '') => {
  if (DECIMAL_PATTERN.test(_val)) {
    return _val;
  }
  const val = _val.toString();
  const finishWithDot = val.at(-1) === '.';
  const valWithoutDot = finishWithDot ? val.substring(0, val.length - 1) : val;
  const otherDotIdx = valWithoutDot.indexOf('.');
  const hasTwoNonConsecitiveDots = otherDotIdx !== -1
    && (finishWithDot && otherDotIdx !== valWithoutDot.length - 1);
  if ((!finishWithDot || hasTwoNonConsecitiveDots) && parseFloat(valWithoutDot)) {
    return parseFloat(valWithoutDot);
  }
  const normalized = valWithoutDot.replace(INTEGER_PATTERN, '');
  return normalized.concat(finishWithDot ? '.' : '');
};

export const capitalize = (sentence) => {
  const { solo_mayusculas } = store.getState()?.auth?.entidad || false;
  return solo_mayusculas && sentence ? sentence.toUpperCase() : sentence;
};

/**
 * List Formatter
 * @param {string[]} list
 * @param {'conjunction'|'disjunction'} type
 * @returns
 */
export const listToString = (
  list,
  type = 'conjunction',
) => new Intl.ListFormat('es-MX', { type }).format(list);
