import React from 'react';
import PropTypes from 'prop-types';
import '../index.scss';
import styled from 'styled-components';

const ENV_NAME = process.env.REACT_APP_ENV_NAME || 'migob';
// eslint-disable-next-line import/no-dynamic-require
const bg = require(`../../../assets/${ENV_NAME}/bg.png`);

const styles = {
  migob: {
    bgColor: '#eef3f7',
    btnColor: '#184759',
    linkBtnColor: '#D2B25B',
    linkBtnActiveColor: '#184759',
    backgroundSize: 'cover',
  },
  nayarit: {
    bgColor: '#4F494C',
    fiColor: '#FFFFFF',
    btnColor: '#6C3440',
    linkBtnColor: '#FFFFFF',
    linkBtnActiveColor: '#4F494C',
    // backgroundSize: '1000px',
  },
  repuve: {
    bgColor: '#4F494C',
    fiColor: '#FFFFFF',
    btnColor: '#6C3440',
    linkBtnColor: '#FFFFFF',
    linkBtnActiveColor: '#4F494C',
    // backgroundSize: '1000px',
  },
  juarez: {
    bgColor: '#4F494C',
    fiColor: '#FFFFFF',
    footerBg: '#4F494C',
    btnColor: '#55283e',
    linkBtnColor: '#FFFFFF',
    linkBtnActiveColor: '#55283e', // no lika
  },
}[ENV_NAME];

const Card = ({ children }) => (
  <Container align="center">
    <Wrapper>
      <InnerContent>
        {children}
      </InnerContent>
    </Wrapper>
  </Container>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-image: url(${bg});
  background-position: center 0;
  @media only screen and (max-width: 768px) {
    /* background-size: ${styles.backgroundSize}; */
  }
  .auth-button:not(.ant-btn-link) {
    background-color: ${styles.btnColor} !important;
    color: #FFFFFF !important;
    &:hover {
      border: none !important;
    }
  }
  .auth-button.ant-btn-link * {
    color: ${styles.linkBtnColor} !important;
  }
  .auth-button.ant-btn-link:hover * {
    color: ${styles.linkBtnActiveColor} !important;
  }
`;

const Wrapper = styled.div`
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const InnerContent = styled.div`
  padding: 20px 30px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${styles.bgColor};
  border-radius: 12px;
  .ant-typography, .ant-form-item-label > label {
    color: ${styles.fiColor} !important;
  }
  .ant-form {
    width: 100%;
  }
`;

export default Card;
