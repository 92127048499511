import API from 'utils/api';
import { onSuccess, onError } from 'utils/handlers';

export const baseURI = 'configuracion/unidades-de-recaudacion/';

/**
 * GET Unidades de Recaudación
 * @returns {Promise<Array<object>>}
 */
export const getUnidadesDeRecaudacion = async () => {
  try {
    const response = await API.get(baseURI);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Unidad de Recaudación
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getUnidadDeRecaudacion = async (id) => {
  try {
    const response = await API.get(`${baseURI}${id}`);
    return response.data;
  } catch (err) {
    onError(err);
    return null;
  }
};

/**
 * POST Unidad de Recaudación
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const postUnidadDeRecaudacion = async (values) => {
  try {
    const response = await API.post(baseURI, values);
    onSuccess(response, 'Agregado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * POST Configuracion de retiro
 * @param {number} id
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const postConfiguracionDeRetiro = async (id, values) => {
  try {
    const response = await API.post(`${baseURI}${id}/configuracion-de-retiro`, values);
    onSuccess(response, `${response.status === 201 ? 'Agregado' : 'Editado'} correctamente`);
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * PATCH Unidad de Recaudación
 * @param {number} id
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const patchUnidadDeRecaudacion = async (id, values) => {
  try {
    const response = await API.patch(`${baseURI}${id}`, values);
    onSuccess(response, 'Editado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * DELETE Unidad de Recaudación
 * @param {number} id
 * @returns {Promise<object|boolean>}
 */
export const deleteUnidadDeRecaudacion = async (id) => {
  try {
    const response = await API.delete(`${baseURI}${id}`);
    onSuccess(response, 'Eliminado correctamente');
    return true;
  } catch (err) {
    onError(err);
    return false;
  }
};
