import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Col,
  Row,
  Typography,
} from 'antd';
import themes from 'themes';
import API from 'utils/api';
import { onError } from 'utils/handlers';

const { Title } = Typography;

const ThemeConfig = () => {
  const dispatch = useDispatch();
  const theme = useSelector(({ app }) => app.theme);
  const user = useSelector(({ auth }) => auth.user);
  const themeKeys = Object.keys(themes);
  const colors = [];
  themeKeys.forEach((key) => {
    const bg = themes[key].backgroundColor.replace('!important', '');
    const color = {
      theme: key,
      bg,
    };
    if (!colors.some((c) => c.bg === bg)) {
      colors.push(color);
    }
  });
  return (
    <Col style={{ padding: '30px 20px' }}>
      <Title level={3}>
        Layout
      </Title>
      <Row
        align="center"
        justify="middle"
        style={{
          padding: '0 15px',
        }}
      >
        {colors.map((color) => (
          <Col
            key={color.bg}
            style={{
              width: 100,
              height: 50,
              backgroundColor: color.bg,
              margin: '10px',
              boxShadow: '1px 1px 1px 0px rgba(0,0,0,0.75)',
              borderRadius: 5,
              cursor: 'pointer',
            }}
            onClick={async () => {
              try {
                const selected = themes[color.theme];
                if (selected.name !== theme.name) {
                  const res = await API.patch(`usuarios/usuarios/${user.id}/`, {
                    tema: selected.name,
                  });
                  if (res?.status === 200) {
                    dispatch({
                      type: 'SET_THEME',
                      payload: selected,
                    });
                  }
                } else {
                  dispatch({
                    type: 'SET_THEME',
                    payload: selected,
                  });
                }
              } catch (err) {
                onError(err);
              }
            }}
          >
            <p style={{
              color: themes[color.theme].primaryText !== themes[color.theme].backgroundColor
                ? themes[color.theme].primaryText : '#FFFFFF',
              lineHeight: '50px',
              width: '100%',
              textAlign: 'center',
              zIndex: 2,
              fontWeight: 'bold',
            }}
            >
              Apariencia
            </p>
          </Col>
        ))}
      </Row>
    </Col>
  );
};

ThemeConfig.propTypes = {

};

export default ThemeConfig;
