import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Input,
  Card,

} from 'antd';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import { onSuccess, onError } from 'utils/handlers';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import NumericInput from 'components/NumericInput';
import FundamentosTransfer from './FundamentosTransfer';

const baseURI = 'tramites/plazos-prevenciones/';

const TablePlazoPrev = ({
  selectedRowKey,
  callback,
}) => {
  const [formTable] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [unidadPlazo, setUnidadPlazo] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [aplicaPara, setAplicaPara] = useState(true);
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI, {
        params: {
          plantilla_de_tramites: selectedRowKey,
        },
      });

      if (response?.status === 200) {
        setData(response.data);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resPlazo = await API.get('/catalogos/unidades-de-plazo');
      setUnidadPlazo(resPlazo.data || []);

      if (selectedRowKey) {
        await fetchData();
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();

    return () => API.source.cancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    formTable.resetFields();
    setVisibleAlert(false);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await formTable.validateFields();
      const values = formTable.getFieldsValue();
      const [key] = selectedRowKeys;
      if (selectedRowKey) {
        values.plantilla_de_tramites = selectedRowKey;
        if (!key) {
          const response = await API.post(baseURI, values);
          if (response?.status === 201) {
            onSuccess(response, 'Agregado correctamente');
            onCancel();
            await fetchData();
          }
        } else {
          const response = await API.put(`${baseURI}${key}/`, values);
          if (response?.status === 200) {
            onSuccess(response, 'Actualizado correctamente');
            onCancel();
            await fetchData();
          }
        }
      } else {
        const clone = [...data];
        if (!key) {
          clone.push({
            ...values,
            key: clone.length + 1,
          });
          setData(clone);
          callback(clone);
          onCancel();
        } else {
          const res = clone.map((item) => (item.key === key ? { ...values, key } : item));
          setData(res);
          callback(res);
          onCancel();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      if (selectedRowKey) {
        if (key) {
          const response = await API.delete(`${baseURI}${key}/`);
          if (response?.status === 204) {
            onSuccess(response, 'Eliminado correctamente');
            onCancel();
            await fetchData();
          }
        }
      } else {
        const clone = data.filter((item) => item.key !== key);
        const normalized = clone.map((item, idx) => ({ ...item, key: idx }));
        setData(normalized);
        callback(normalized);
        onCancel();
        setVisibleAlert(false);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickAdd = () => {
    setVisible(true);
    setSelectedRowKeys([]);
    formTable.resetFields();
    setAplicaPara();
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      if (selectedRowKey) {
        const [key] = selectedRowKeys;
        const res = await API.get(`${baseURI}${key}`);
        const { fundamento_legal, ...values } = res.data;
        if (fundamento_legal?.length) {
          const promises = [];
          fundamento_legal.forEach((id) => {
            const response = API.get(`/configuracion/fundamentos-legales/${id}`);
            promises.push(response);
          });
          const responses = promises.length ? await Promise.all(promises) : [];
          const fundamentos = responses.map((fundamento) => fundamento.data);
          formTable.setFieldsValue({
            fundamento_legal,
            fundamento_legal_objects: fundamentos,
          });
        }
        formTable.setFieldsValue(values);
      } else {
        if (data?.fundamento_legal?.length) {
          const promises = [];
          data.fundamento_legal.forEach((id) => {
            const response = API.get(`/configuracion/fundamentos-legales/${id}`);
            promises.push(response);
          });
          const responses = promises.length ? await Promise.all(promises) : [];
          const fundamentos = responses.map((fundamento) => fundamento.data);
          formTable.setFieldsValue({
            fundamento_legal: data?.fundamento_legal,
            fundamento_legal_objects: fundamentos,
          });
        }
        formTable.setFieldsValue(data);
      }

      setVisible(true);
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    const _key = selectedRowKey ? record.id : record.key;
    setSelectedRowKeys([_key]);
    formTable.setFieldsValue(record);
    setAplicaPara(record?.es_usuario);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const isUser = [
    {
      label: 'Usuario',
      value: true,
    },
    {
      label: 'Dependencia',
      value: false,
    },
  ];

  const columns = [
    {
      titleText: 'Unidad de Plazo Real',
      dataIndex: 'unidad_de_plazo_real',
      key: 'unidad_de_plazo_real',
      width: 300,
      render: (val) => (unidadPlazo.find((item) => item.id === val)?.descripcion),
    },
    {
      titleText: 'Plazo de Prevención',
      dataIndex: 'plazo_prevencion',
      key: 'plazo_prevencion',
      width: 300,
    },
    {
      titleText: 'Observaciones',
      dataIndex: 'observaciones',
      key: 'observaciones',
      width: 300,
    },
    {
      titleText: 'Aplica a',
      dataIndex: 'es_usuario',
      key: 'es_usuario',
      width: 300,
      render: (val) => (isUser.find((item) => item.value === val)?.label),
    },

  ];

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            mobileColIndex={0}
            rowKey={selectedRowKey ? 'id' : 'key'}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            allowImport
            baseURI={baseURI}
          />
        ) : (
          <Card
            bordered={false}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
              />
             )}
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Plazo de Prevención`}
          >
            <Form
              layout="vertical"
              form={formTable}
              onFinish={onFinish}
              scrollToFirstError
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="unidad_de_plazo_real"
                    label="Unidad del Plazo de Prevención"
                  >
                    <Select dataSource={unidadPlazo} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>

                  <NumericInput
                    name="plazo_prevencion"
                    label="Plazo de Prevención"
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="es_usuario"
                    label="Aplica a :"
                    tooltip="Indica que el plazo de prevención aplica para usuario o dependecia"
                  >
                    <Select
                      dataSource={isUser}
                      labelProp="label"
                      valueProp="value"
                      onChange={setAplicaPara}
                    />
                  </Form.Item>
                </Col>
                {!aplicaPara && (
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="respuesta_de_dependencia"
                    label="Respuesta de la Dependencia"
                    hasFeedback
                    tooltip="Respuesta de la dependencia en caso de no responder en el tiempo establecido"
                  >
                    <Input.TextArea allowClear autoSize={{ minRows: 2, maxRows: 4 }} />
                  </Form.Item>
                </Col>
                )}

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="consideraciones_necesarias"
                    label="Consideraciones Necesarias"
                    hasFeedback
                  >
                    <Input.TextArea allowClear autoSize={{ minRows: 2, maxRows: 4 }} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="observaciones"
                    label="Observaciones"
                    hasFeedback
                  >
                    <Input.TextArea allowClear autoSize={{ minRows: 2, maxRows: 4 }} />
                  </Form.Item>
                </Col>
                <FundamentosTransfer formFundamento={formTable} />
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content="El Plazo De Prevención seleccionado"
        />
      </Spin>
    </Row>
  );
};
TablePlazoPrev.propTypes = {

  selectedRowKey: PropTypes.number,
  callback: PropTypes.func.isRequired,

};
TablePlazoPrev.defaultProps = {
  selectedRowKey: null,
};

export default TablePlazoPrev;
