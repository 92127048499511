import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Modal,
  DatePicker,
} from 'antd';

import Moment from 'moment';
import API from 'utils/api';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import { onError, onSuccess } from 'utils/handlers';
import Select from 'components/Select';

const baseURI = '/configuracion/horarios/';

const Horarios = () => {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [dias, setDias] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(res.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    const fetchAll = async () => {
      try {
        setLoading(true);
        const resDias = await API.get('/catalogos/catalogos-de-dias/');
        setDias(resDias.data || []);
        await fetchData();
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      values.entrada = values.entrada.format('HH:mm:ss');
      values.salida = values.salida.format('HH:mm:ss');
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        await form.validateFields();
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`/configuracion/horarios/${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue({
      ...record,
      entrada: new Moment(record.entrada, 'hh:mm:ss'),
      salida: new Moment(record.salida, 'hh:mm:ss'),
    });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    setVisible(true);
    setSelectedRowKeys([]);
    form.resetFields();
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
    dias_laborales: [
      requiredRule,
      {
        validator: async (rule, val) => {
          if (val.length) {
            const values = form.getFieldsValue();
            const allValues = val.concat(values?.dias_de_descanso);
            const repetidos = [];
            let temporal = [];
            allValues.forEach((e, idx) => {
              temporal = Object.assign([], allValues);
              temporal.splice(idx, 1);
              if (temporal.indexOf(e) !== -1
              && repetidos.indexOf(e) === -1) repetidos.push(e);
            });
            if (repetidos.length) {
              throw new Error('No se puede seleccionar el mismo día como laboral y de descanso');
            }
          }
        },
      },
    ],
    dias_de_descanso: [
      requiredRule,
      {
        validator: async (rule, val) => {
          if (val.length) {
            const values = form.getFieldsValue();
            const allValues = val.concat(values?.dias_laborales);
            const repetidos = [];
            let temporal = [];
            allValues.forEach((e, idx) => {
              temporal = Object.assign([], allValues);
              temporal.splice(idx, 1);
              if (temporal.indexOf(e) !== -1
              && repetidos.indexOf(e) === -1) repetidos.push(e);
            });
            if (repetidos.length) {
              throw new Error('No se puede seleccionar el mismo día como laboral y de descanso');
            }
          }
        },
      },
    ],
  };

  const columns = [
    {
      titleText: 'Entrada',
      dataIndex: 'entrada',
      key: 'entrada',
      width: 250,
      render: (val) => (new Moment(val, 'hh:mm:ss').format('LT')),
    },
    {
      titleText: 'Salida',
      dataIndex: 'salida',
      key: 'salida',
      width: 250,
      render: (val) => (new Moment(val, 'hh:mm:ss').format('LT')),
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 300,
      render: (val) => (estadosGlobales.find((item) => item.id === val).descripcion),
    },
  ];

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          mobileCols={[columns[0], columns[1]]}
          data={data}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
          loading={loading}
          rowKey="id"
        />
        <Modal
          open={visible}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Horario`}
              loading={loading}
              onFinish={onFinish}
              onCancel={onCancel}
            />
            )}
          onCancel={onCancel}
          footer={null}
          closable={false}
          forceRender
          keyboard={!loading}
          maskClosable={!loading}
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            scrollToFirstError
            initialValues={{ estados_globales: 1 }}
          >
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  name="entrada"
                  label="Hora de entrada"
                  rules={rules.required}
                  hasFeedback
                >
                  <DatePicker
                    placeholder=""
                    suffixIcon=""
                    mode="time"
                    picker="time"
                    format="h:mm a"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="salida"
                  label="Hora de salida"
                  rules={rules.required}
                  hasFeedback
                >
                  <DatePicker
                    suffixIcon=""
                    placeholder=""
                    mode="time"
                    picker="time"
                    format="h:mm a"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="dias_laborales"
                  label="Días laborales"
                  rules={rules.dias_laborales}
                  hasFeedback
                >
                  <Select mode="multiple" dataSource={dias} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="dias_de_descanso"
                  rules={rules.dias_de_descanso}
                  label="Días de descanso"
                >
                  <Select mode="multiple" dataSource={dias} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  rules={rules.required}
                  name="estados_globales"
                  label="Estado"
                  hasFeedback
                >
                  <Select
                    disabled={!selectedRowKeys.length}
                    dataSource={estadosGlobales}
                  />
                </Form.Item>
              </Col>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Row>
          </Form>
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            onCancel();
            setVisibleAlert(false);
          }}
          visible={visibleAlert}
          content="Horario de Trabajo seleccionado"
        />
      </Spin>
    </Row>
  );
};

export default Horarios;
