/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import {
  Row,
  Col,
  Steps,
  Form,
  Input,
  Button,
  Typography,
  Grid,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { FULL_DATE_FRONTEND_FORMAT, getFullName } from 'utils/formatValues';
import Select from 'components/Select';
import { onError, onSuccess } from 'utils/handlers';
import API from 'utils/api';
import Table from 'components/Table';
import { sortItems } from 'utils/sorters';

export const getFlujoActual = (_historial = [], configFlujos) => {
  const historial = [..._historial].sort(sortItems('id'));
  if (historial.length) {
    let posicionActual;
    historial.forEach((h, idx) => {
      // Paso inicial BORRADOR
      if (idx === 0) {
        posicionActual = 1;
        return;
      }
      const prev = historial[idx - 1].estado?.id || historial[idx - 1].estado;
      const curr = h.estado?.id || h.estado;
      // Si estado actual es mayor a estado anterior el flujo avanzó y posición aumenta
      if (curr > prev) {
        posicionActual += 1;
        return;
      }
      // Si estado actual es menor a estado anterior el flujo regresó a BORRADOR o PRIMER VALIDADOR
      if (curr < prev) {
        posicionActual = 1;
        return;
      }
      if (curr === 2 && prev === 2) {
        const pasoActual = configFlujos.flujos[posicionActual - 1];
        // Si el paso actual podia publicar regresó a primer validador,
        // sino avanzó a siguiente validador
        if (pasoActual.se_puede_publicar) {
          posicionActual = 2;
        } else {
          posicionActual += 1;
        }
      }
      // Último escenario, estado se mantuvo en BORRADOR, no se cambia el paso
    });
    return configFlujos.flujos[posicionActual - 1];
  }
  return null;
};

const Editar = ({
  plantilla,
  configFlujos,
  setLoading,
  callback,
}) => {
  const plantilla_de_tramites = plantilla?.plantilla_de_tramites;
  const historial = plantilla.historial
    .filter((e) => e.plantilla_de_tramites === plantilla_de_tramites?.id) || [];
  const [form] = Form.useForm();
  const estado = Form.useWatch('estado', form);
  const screens = Grid.useBreakpoint();
  const flujoActual = useMemo(
    () => plantilla?.flujoactual || getFlujoActual(historial, configFlujos),
    [plantilla],
  );

  const optionsEstados = useMemo(
    () => {
      if (plantilla) {
        const _options = [];
        if (flujoActual?.se_puede_pasar_a_borrador) {
          _options.push({
            id: 1,
            descripcion: 'Borrador',
          });
        }
        if (flujoActual?.se_puede_validar) {
          // flujos en borrador necesarios para validar
          const needed = configFlujos.requiere_validar ? 3 : 2;
          if (flujoActual?.posicion !== 1 || (historial.length >= needed
            && historial.slice(0, needed - 1).every((h) => h.estado.id === 1))) {
            _options.push({
              id: 2,
              descripcion: 'Validación',
            });
          }
        }
        if (flujoActual?.se_puede_publicar) {
          _options.push({
            id: 3,
            descripcion: 'Publicado',
          });
        }
        return _options;
      }
      return [];
    },
    [plantilla],
  );

  const columnsHistorial = [
    {
      titleText: 'id',
      dataIndex: 'id',
      key: 'id',
      width: 200,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      width: 200,
      render: (e) => e.descripcion,
    },
    {
      titleText: 'Usuario',
      dataIndex: 'usuario_creo',
      key: 'usuario_creo',
      width: 300,
      render: (e) => getFullName(e, 'numero_de_empleado'),
    },
    {
      titleText: 'Fecha de modificación',
      dataIndex: 'fecha_de_creacion',
      key: 'fecha_de_creacion',
      width: 200,
      render: (val) => val?.format(FULL_DATE_FRONTEND_FORMAT),
    },
    {
      titleText: 'Movimiento',
      dataIndex: 'movimiento',
      key: 'movimiento',
      width: 400,
      ellipsis: true,
    },
    {
      titleText: 'Comentario',
      dataIndex: 'comentario',
      key: 'comentario',
      width: 400,
      ellipsis: true,
    },
  ];

  const rules = { required: [{ required: true }] };

  const requiereMovimiento = estado === 1
    && plantilla_de_tramites?.estado_de_ficha.id === 1;
  const requiereComentario = estado < plantilla_de_tramites?.estado_de_ficha.id;

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      values.configuracion_de_flujo_de_retys = configFlujos.id;
      values.plantilla_de_tramites = plantilla.plantilla_de_tramites.id;
      const res = await API.post('tramites/historiales-de-retys/', values);
      if (res.status === 201) {
        const valuesPatch = { estado_de_ficha: values.estado };
        const resPatch = await API.patch(`tramites/plantillas-de-tramites/${plantilla_de_tramites.id}/`, valuesPatch);
        if (resPatch.status === 200) {
          onSuccess({}, 'Estado de  ficha de tramite actualizado', 5);
          await callback();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  return (
    <>
      <Steps
        status={plantilla.progreso === 100 ? 'finish' : 'process'}
        current={flujoActual?.posicion - 1}
        style={{ padding: '20px 30px' }}
      >
        {configFlujos.flujos.map((e) => (
          <Steps.Step key={e.id} title={e.roles.map((r) => r.name)} />
        ))}
      </Steps>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Row gutter={10}>
          <Col xs={24} sm={24} md={10} lg={6}>
            <Form.Item label="Marcar como" name="estado" rules={rules.required}>
              <Select
                dataSource={optionsEstados}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={4}>
            <Form.Item noStyle>
              <Button
                htmlType="submit"
                style={{ marginTop: 23, height: 32 }}
                block
              >
                <SaveOutlined />
                Guardar
              </Button>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={14}>
            <Form.Item
              label={requiereMovimiento ? 'Movimiento' : 'Comentario'}
              name={requiereMovimiento ? 'movimiento' : 'comentario'}
              rules={requiereMovimiento || requiereComentario ? rules.required : null}
            >
              <Input.TextArea autoSize={{ minRows: screens.lg ? 1 : 3 }} allowClear />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <br />
      <Typography.Title level={3}>
        Historial
      </Typography.Title>
      <Table
        cols={columnsHistorial}
        data={historial || []}
        mobileColIndex={0}
        rowKey="id"
        allowSearch={false}
        allowSort={false}
      />
    </>
  );
};

export default Editar;
