import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Spin,
  Col,
  Modal,
  DatePicker,
  Row,
  Card,
  Divider,
  Typography,
  message,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Moment from 'moment';
import fetchSome from 'utils/fetchSome';
import { SET_DIAS } from 'store/reducers/catalogs';
import ModalDelete from 'components/ModalDelete';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import Select from 'components/Select';

const { Text } = Typography;

const HorariosUR = ({ selectedRowKey, mutable, completeURI }) => {
  const dias = useSelector(({ catalogs }) => catalogs.dias);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowKeysH, setSelectedRowKeysH] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [dataHorarios, setDataHorarios] = useState([]);

  const fetchData = async () => {
    if (selectedRowKey.length) {
      const resHorarios = await API.get('configuracion/horarios');
      setDataHorarios(resHorarios.data);
      const [key] = selectedRowKey;
      const response = await API.get(`${completeURI}/${key}/horarios`);
      setData(response.data);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);

      if (!dias.length) {
        fetchSome('/catalogos/catalogos-de-dias/', SET_DIAS);
      }

      await fetchData();

      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
    setSelectedRowKeys([]);
    setSelectedRowKeysH([]);
  };

  const patchURS = async (idHorario) => {
    try {
      const [key] = selectedRowKey;
      const horarios = [...data];
      horarios.push(idHorario);
      const response = await API.patch(`${completeURI}/${key}`, {
        horarios,
      });
      if (response?.status === 200) {
        onSuccess(response, 'Se ha agregado correctamente');
        onCancel();
        await fetchData();
      }
    } catch (err) {
      onError(err);
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      values.entrada = values.entrada.format('HH:mm:ss');
      values.salida = values.salida.format('HH:mm:ss');
      const response = await API.post('/configuracion/horarios/', {
        ...values,
      });
      if (response?.status === 201) {
        await patchURS(response.data.id);
      }

      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue({
      ...record,
      entrada: new Moment(record.entrada, 'HH:mm:ss'),
      salida: new Moment(record.salida, 'HH:mm:ss'),
    });
  };

  const handleOnRowClickHorarios = (record) => {
    setSelectedRowKeysH([record.id]);
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const rowSelectionHorarios = {
    selectedRowKeys: selectedRowKeysH,
    type: 'radio',
  };

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
    dias_laborales: [
      requiredRule,
      {
        validator: async (rule, val) => {
          if (val.length) {
            const values = form.getFieldsValue();
            const allValues = val.concat(values?.dias_de_descanso);
            const repetidos = [];
            let temporal = [];
            allValues.forEach((e, idx) => {
              temporal = Object.assign([], allValues);
              temporal.splice(idx, 1);
              if (temporal.indexOf(e) !== -1
                && repetidos.indexOf(e) === -1) repetidos.push(e);
            });
            if (repetidos.length) {
              throw new Error('Y');
            }
          }
        },
      },
    ],
    dias_de_descanso: [
      requiredRule,
      {
        validator: async (rule, val) => {
          if (val.length) {
            const values = form.getFieldsValue();
            const allValues = val.concat(values?.dias_laborales);
            const repetidos = [];
            let temporal = [];
            allValues.forEach((e, idx) => {
              temporal = Object.assign([], allValues);
              temporal.splice(idx, 1);
              if (temporal.indexOf(e) !== -1
                && repetidos.indexOf(e) === -1) repetidos.push(e);
            });
            if (repetidos.length) {
              throw new Error('Días');
            }
          }
        },
      },
    ],
  };

  const columns = [
    {
      titleText: 'Entrada',
      dataIndex: 'entrada',
      key: 'entrada',
      render: (val) => (new Moment(val, 'hh:mm:ss').format('LT')),
    },
    {
      titleText: 'Salida',
      dataIndex: 'salida',
      key: 'salida',
      render: (val) => (new Moment(val, 'hh:mm:ss').format('LT')),
    },
    {
      titleText: 'Días Laborales',
      dataIndex: 'dias_laborales',
      key: 'dias_laborales',
      render: (val) => {
        const days = val?.map((e) => dias.find((i) => i.id === e)?.descripcion);
        return days.map((e) => ` ${e}`).toString();
      },
    },
    {
      titleText: 'Días de Descanso',
      dataIndex: 'dias_de_descanso',
      key: 'dias_de_descanso',
      render: (val) => {
        const days = val.map((e) => dias.find((i) => i.id === e)?.descripcion);
        return days.map((e) => ` ${e}`).toString();
      },
    },
  ];

  const asignarHorario = async () => {
    try {
      setLoading(true);
      const clone = [...data].map((e) => e.id);
      const [key] = selectedRowKeysH;
      const [keyUR] = selectedRowKey;
      if (!clone.includes(key)) {
        clone.push(key);
        const response = await API.patch(`${completeURI}/${keyUR}`, {
          horarios: clone,
        });
        if (response?.status === 200) {
          onSuccess(null, 'Horario Asignado Correctamente');
          onCancel();
          await fetchAll();
        }
      } else {
        message.warn('Esta UR ya tiene asignado este Horario');
        setSelectedRowKeysH([]);
      }

      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      const clone = [...data];
      const [key] = selectedRowKeys;
      const [id] = selectedRowKey;
      const horarios = clone.filter((e) => e.id !== key).map((i) => i.id);
      const response = await API.patch(`${completeURI}/${id}`, {
        horarios,
      });
      if (response?.status === 200) {
        onCancel();
        setVisibleAlert(false);
        await fetchAll();
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Card
          className="form-container no-padding"
          bordered={false}
        >
          <Divider orientation="left"><Text>Horarios de la UR</Text></Divider>
          <Table
            cols={columns}
            mobileCols={[columns[1]]}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickDelete,
            }}
            noControls={!mutable}
            allowSort={false}
            allowSearch={false}
            rowKey="id"

          />
          <Divider orientation="left"><Text>Horarios</Text></Divider>
          <Table
            cols={columns}
            mobileCols={[columns[1]]}
            data={dataHorarios}
            rowSelection={rowSelectionHorarios}
            handleOnRowClick={handleOnRowClickHorarios}
            customActions={[
              {
                onClick: asignarHorario,
                icon: PlusOutlined,
                text: 'Asignar Horario',
                disabled: selectedRowKeysH.length,
              },
            ]}
            noControls={!mutable}
            allowSort={false}
            allowSearch={false}
            rowKey="id"

          />
          <Modal
            open={visible}
            closable={false}
            footer={null}
            title={(
              <FormSubmitControls
                label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Horario`}
                onFinish={onFinish}
                onCancel={onCancel}
                loading={loading}
              />
            )}
            keyboard={!loading}
            maskClosable={!loading}
            onCancel={onCancel}
            forceRender
          >

            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
            >
              <Row gutter={10}>
                <Col span={12}>
                  <Form.Item
                    name="entrada"
                    label="Hora de entrada"
                    rules={rules.required}
                    hasFeedback
                  >
                    <DatePicker
                      placeholder=""
                      suffixIcon=""
                      mode="time"
                      picker="time"
                      format="h:mm a"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="salida"
                    label="Hora de salida"
                    rules={rules.required}
                    hasFeedback
                  >
                    <DatePicker
                      suffixIcon=""
                      placeholder=""
                      mode="time"
                      picker="time"
                      format="h:mm a"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="dias_laborales"
                    label="Días Laborales"
                    rules={rules.dias_laborales}
                    hasFeedback
                  >
                    <Select mode="multiple" dataSource={dias} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="dias_de_descanso"
                    rules={rules.dias_de_descanso}
                    label="Días de Descanso"
                  >
                    <Select mode="multiple" dataSource={dias} />
                  </Form.Item>
                </Col>

                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          </Modal>
          <ModalDelete
            onDelete={deleteItem}
            onCancel={() => setVisibleAlert(false)}
            visible={visibleAlert}
            content="el Horario de la UR"
          />
        </Card>
      </Spin>
    </Row>
  );
};

HorariosUR.propTypes = {
  completeURI: PropTypes.string.isRequired,
  selectedRowKey: PropTypes.arrayOf(PropTypes.number),
  mutable: PropTypes.bool.isRequired,

};
HorariosUR.defaultProps = {
  selectedRowKey: [],
};

export default HorariosUR;
