import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Result,
} from 'antd';

const NotFound = () => {
  const history = useHistory();

  return (
    <div style={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }}
    >
      <Result
        status="404"
        title="404"
        subTitle="Lo sentimos, la página a la que esta intentando acceder no existe."
      />
      <Button type="link" onClick={() => history.push('/dashboard')}>
        Volver
      </Button>
    </div>
  );
};

export default NotFound;
