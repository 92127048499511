import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Row,
  Typography,
  Button,
  Spin,
  Form,
  message,
} from 'antd';

import {
  LeftOutlined,
} from '@ant-design/icons';
import API from 'utils/api';
import getQueryParams from 'utils/getQueryParams';
import './index.scss';
import {
  defaultOptions,
  allRequirementsPassed,
  validatePasswordRequirements,
} from 'utils/passwordRequirements';
import Password from 'components/Password';
import Card from './components/Card';

const { Title } = Typography;

const RestablecerContrasena = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const codigo = getQueryParams('codigo', window.location.href);
  const [options, setOptions] = useState(defaultOptions);

  let timeout = null;
  const onFormValuesChange = (changedValues, allValues) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      const { password } = changedValues;
      if (password) {
        const newOps = validatePasswordRequirements(password);
        setOptions(newOps);
      } else if (!allValues.password) {
        setOptions(defaultOptions);
      }
      form.validateFields(['password', 'confirmPassword']);
    }, 500);
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const response = await API.post(`usuarios/restablecer-contrasena?codigo=${codigo}`, {
        contrasena: values.password,
        contrasena_confirmacion: values.passwordConfirm,
      });
      if (response && response.status === 200) {
        message.info(response.data.detail);
        history.push('/inicio-de-sesion');
      } else {
        message.warn(response.data.detail);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      message.warn('Ha ocurrido un error');
    }
  };

  const rules = {
    password: [
      {
        validator: async () => {
          const password = form.getFieldValue('password');
          if (!allRequirementsPassed(password)) {
            throw new Error('La contraseña no cumple con los requisitos');
          }
        },
      },
    ],
    confirmPassword: [
      {
        validator: async (rule, value) => {
          const password = form.getFieldValue('password');
          if (value && value !== password) {
            throw new Error('Las contraseñas no coinciden.');
          }
        },
      },
    ],
    currentPassword: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  return (
    <Card>
      <Spin tip="Loading..." spinning={loading} style={{ justifyContent: 'center' }}>
        <Row style={{ width: '100%' }}>
          <Button
            type="link"
            className="auth"
            style={{ textAlign: 'left', padding: 0 }}
            onClick={() => history.push('/inicio-de-sesion')}
          >
            <LeftOutlined />
            {' '}
            Volver
          </Button>
        </Row>
        <Title style={{ fontWeight: 'bold' }} level={3}>
          Restablecer contraseña
        </Title>
        <Form
          name="forgot-pwd-form"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onValuesChange={onFormValuesChange}
        >
          <Row gutter={10}>
            <Password rules={rules} options={options} />
          </Row>
          <Form.Item style={{ marginBottom: 0, marginTop: 15 }}>
            <Button
              className="auth-submit-button"
              shape="round"
              block
            >
              Restablecer
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Card>
  );
};

export default RestablecerContrasena;
