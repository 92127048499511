// Constants
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SET_THEME = 'SET_THEME';
export const SET_ESTRUCTURA_OPERATIVA = 'SET_ESTRUCTURA_OPERATIVA';
export const SET_RECIBOS = 'APP__SET_RECIBOS';
export const CLEAN = 'CLEAN';

// Initial state
const initialState = {
  collapsedSidebar: false,
  theme: {},
  estructuraOperativa: {},
  recibos: [],
};

// Action handlers
const toggleSidebar = (state, { payload }) => ({ ...state, collapsedSidebar: payload });
const setTheme = (state, { payload }) => ({ ...state, theme: { ...payload } });
const setRecibos = (state, { payload }) => ({ ...state, recibos: payload });
const setEO = (state, { payload }) => ({ ...state, estructuraOperativa: payload });
const clear = () => ({ ...initialState });

const ACTION_HANDLER = {
  [`${SET_THEME}`]: setTheme,
  [`${TOGGLE_SIDEBAR}`]: toggleSidebar,
  [`${SET_RECIBOS}`]: setRecibos,
  [`${SET_ESTRUCTURA_OPERATIVA}`]: setEO,
  [`${CLEAN}`]: clear,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLER[action.type];
  return handler ? handler(state, action) : state;
};
