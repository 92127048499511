import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Spin,
  Col,
  Modal,
  Typography,
} from 'antd';

import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import FormSubmitControls from '../FormSubmitControls';
import CustomTable from '../Table';
import ModalDelete from '../ModalDelete';

const { Text } = Typography;

const Administrators = ({
  disabled,
}) => {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get('/usuarios/administradores-de-la-entidad/');
      setData(res.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (!data.find((d) => d.email === values.email)) {
        const response = await API.post('/usuarios/administradores-de-la-entidad/', values);
        if (response?.status === 201) {
          onSuccess(response, 'Se ha agregado correctamente', 2.5);
          onCancel();
          await fetchData();
        }
      } else {
        onSuccess(null, 'El Usuario ya es administrador de la entidad');
        onCancel();
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`/usuarios/administradores-de-la-entidad/${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente', 2.5);
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Correo',
      key: 'email',
      dataIndex: 'email',
      width: 300,
    },
    {
      titleText: 'Nombre',
      key: 'first_name',
      dataIndex: 'first_name',
      width: 150,
    },
    {
      titleText: 'Apellido',
      key: 'last_name',
      dataIndex: 'last_name',
      width: 150,
    },
  ];

  return (
    <>
      <Spin tip="Cargando..." spinning={loading}>
        <CustomTable
          cols={columns}
          mobileCols={[columns[1]]}
          data={data}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          controls={{
            onClickAdd,
            onClickDelete,
          }}
          allowSort={false}
          allowSearch={false}
          disabled={disabled}
          rowKey="id"
          pageSize={5}
        />
        <Modal
          open={visible}
          closable={false}
          footer={null}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Administrador`}
              onFinish={onFinish}
              onCancel={onCancel}
              loading={loading}
            />
          )}
          keyboard={!loading}
          maskClosable={!loading}
          onCancel={onCancel}
          forceRender
        >
          <Col span={24}>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              name="administrators"
            >
              <Col span={24}>
                <Form.Item
                  name="email"
                  label="Correo"
                  rules={rules.required}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Form>
          </Col>
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content={(
            <Text style={{ fontSize: 16 }}>
              {`¿Deseas que ${form.getFieldValue('email')}
               deje de ser administrador de la entidad ?`}
            </Text>
          )}
          loading={loading}
        />
      </Spin>
    </>
  );
};

Administrators.propTypes = {
  disabled: PropTypes.bool,
};

Administrators.defaultProps = {
  disabled: false,
};

export default Administrators;
