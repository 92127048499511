/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Spin,
  Card,
  Button,
  Typography,
  Steps,
  Grid,
  DatePicker,
  Divider,
  message,
  Empty,
} from 'antd';
import {
  FilePdfOutlined,
  FileOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  SaveOutlined,
  EditOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import API from 'utils/api';
import Select from 'components/Select';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import Previewer from 'components/Previewer';
import Mapa from 'components/Mapa';
import { formatReceived, hasFiles } from 'utils/formatValues';
import { onError, onSuccess } from 'utils/handlers';
import fetchSome from 'utils/fetchSome';
import {
  SET_CATEGORIAS_SOLICITUDES,
  SET_MOTIVOS_SOLICITUD,
  SET_SUBCATEGORIAS_SOLICITUDES,
  SET_ESTADOS_SOLICITUD,
} from 'store/reducers/catalogs';
import Dragger from 'components/Dragger';
import { useLocation } from 'react-router-dom';

const baseURI = 'solicitudes/solicitudes/';
const { Text } = Typography;
const useQuery = () => new URLSearchParams(useLocation().search);

const SeguimientoDeSolicitud = () => {
  const query = useQuery();
  const {
    categoriasSolicitudes,
    motivosSolicitudes,
    subcategoriasSolicitudes,
    estadosSolicitud,
  } = useSelector((state) => state.catalogs);
  const theme = useSelector(({ app }) => app.theme);
  const screens = Grid.useBreakpoint();
  const [form] = Form.useForm();
  const [formTable] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const [steps, setSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentFile, setCurrentFile] = useState();
  const [disabled, setDisabled] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [funcionarios, setFuncionarios] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const InfoNoRequired = ({ text }) => (
    <Col span={24}>
      {text && <Text>{text}</Text>}
      <Empty description="Información no requerida" />
    </Col>
  );

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    const estadosEntidad = estadosSolicitud.sort((a, b) => (a.prioridad < b.prioridad ? -1 : 1))
      .map((estado) => ({
        ...estado,
        disabled: !record.seguimientos
          .some((seguimiento) => seguimiento.estado_de_solicitud === estado.id),
      }));
    form.setFieldsValue({
      ...record,
      motivo_de_la_solicitud: record.motivo_de_la_solicitud.descripcion,
    });
    setSteps(estadosEntidad);
  };

  const onClickExpand = () => { setVisible(true); };

  const fetchData = async (page = 1, q) => {
    try {
      setLoading(true);
      const { estado_de_la_solicitud } = formTable.getFieldsValue();
      if (estado_de_la_solicitud) {
        const response = await API.get(baseURI, {
          params: {
            ...q,
            page,
            estado_de_la_solicitud,
          },
        });
        setCurrentPage(page);
        const formatted = { ...response.data, results: formatReceived(response.data.results) };
        setData(formatted);
        setTimeout(() => {
          const id = parseInt(query.get('id'), 10);
          const match = formatted.results.find((e) => e.id === id);
          if (match) {
            handleOnRowClick(match);
            onClickExpand();
          }
        });
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);

      if (!categoriasSolicitudes?.length) {
        await fetchSome('/solicitudes/categoria-de-solicitudes/', SET_CATEGORIAS_SOLICITUDES);
      }
      if (!motivosSolicitudes?.length) {
        await fetchSome('/solicitudes/motivos-de-las-solicitudes/', SET_MOTIVOS_SOLICITUD);
      }
      if (!motivosSolicitudes?.length) {
        await fetchSome('/solicitudes/estado-de-solicitudes/', SET_MOTIVOS_SOLICITUD);
      }
      if (!subcategoriasSolicitudes?.length) {
        await fetchSome('/solicitudes/subcategorias-de-las-solicitudes/', SET_SUBCATEGORIAS_SOLICITUDES);
      }
      if (!estadosSolicitud?.length) {
        await fetchSome('/solicitudes/estado-de-solicitudes/', SET_ESTADOS_SOLICITUD);
      }

      const resUsuarios = await API.get('/usuarios/usuarios/');
      setUsuarios(resUsuarios.data.map((e) => ({
        ...e,
        nombre_completo: `${e.first_name || ''} ${e.last_name || ''}
      ${e.second_last_name || ''} - ${e.email}`,
      })) || []);

      const resFuncionarios = await API.get('/usuarios/funcionario/');
      setFuncionarios(resFuncionarios.data.map((e) => ({
        ...e,
        nombre_completo: `${e.nombre || ''} ${e.apellido_paterno || ''}
       ${e.apellido_materno || ''}`,
      })) || []);

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
    setCurrentStep(0);
  };

  const columns = [
    {
      titleText: 'Motivo',
      dataIndex: 'motivo_de_la_solicitud',
      key: 'motivo_de_la_solicitud',
      width: 200,
      render: (val) => val?.descripcion,
    },
    {
      titleText: 'Fecha',
      dataIndex: 'fecha_de_la_solicitud',
      key: 'fecha_de_la_solicitud',
      width: 100,
      render: (val) => val?.format('DD/MM/YYYY'),
    },
    {
      titleText: 'Estado',
      dataIndex: 'estado_de_la_solicitud',
      key: 'estado_de_la_solicitud',
      render: (e) => estadosSolicitud.find((i) => i.id === e)?.nombre,
      width: 100,
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const isImageExt = (ext) => ext === 'jpeg' || ext === 'jpg' || ext === 'png';

  const cardStyle = {
    width: '100%',
    height: '100%',
    borderRadius: 10,
    border: '1px solid #CCCCCC',
    cursor: 'pointer',
  };

  const flexStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 36,
  };

  const Archivos = () => (
    <Dragger
      form={form}
      label="Archivos"
      limitMB={8}
      name="archivos"
      allowedExtensions={['png', 'jpeg', 'jpg', 'pdf', 'docx']}
      multiple
    />
  );

  const Gallery = ({ files }) => (
    <>
      <br />
      <Text>Archivos</Text>
      <br />
      <Row gutter={[10, 10]}>
        {files.length ? (
          files.map((file) => (
            <Col
              xs={24}
              sm={12}
              lg={8}
              xxl={6}
              key={file.id}
              style={{
                height: 150,
                ...flexStyle,
              }}
            >
              {isImageExt(file.extension_del_documento) && (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <img
                  src={file.archivo}
                  alt="archivo"
                  style={{
                    objectFit: 'cover',
                    ...cardStyle,
                  }}
                  onClick={() => {
                    setCurrentFile(file);
                    setVisiblePreview(true);
                  }}
                />
              )}
              {(file.extension_del_documento === 'pdf' || file.extension_del_documento === 'docx') && (
                <Button
                  type="link"
                  onClick={() => {
                    setCurrentFile(file);
                    setVisiblePreview(true);
                  }}
                  style={{ ...cardStyle, ...flexStyle }}
                >
                  {file.extension_del_documento === 'pdf' ? (
                    <FilePdfOutlined />
                  ) : (
                    <FileOutlined />
                  )}
                </Button>
              )}
            </Col>
          ))
        ) : (
          <InfoNoRequired />
        )}
      </Row>
    </>
  );

  const filterFiles = (seguimientoID) => {
    const [key] = selectedRowKeys;
    const match = [...data.results].find((e) => e.id === key);
    if (seguimientoID) {
      const seguimiento = match.seguimientos.find((e) => e.id === seguimientoID);
      return seguimiento.archivos || [];
    }
    const estadoInicial = steps.find((e) => e.prioridad === 0);
    const seguimiento = match.seguimientos.find((e) => e.estado_de_solicitud === estadoInicial.id);
    return seguimiento?.archivos || [];
  };

  const Summary = () => (
    <Form
      layout="vertical"
      form={form}
    >
      <Row gutter={10}>
        <Col xs={24} sm={24} md={16} lg={8}>
          <Form.Item
            name="motivo_de_la_solicitud"
            label="Motivo"
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={6}>
          <Form.Item
            name="estado_de_la_solicitud"
            label="Estado Actual"
          >
            <Select
              disabled={!disabled}
              dataSource={estadosSolicitud.sort((a, b) => (a.prioridad < b.prioridad ? -1 : 1))}
              render={(e) => `${e.nombre}`}
              onChange={() => {
                form.resetFields(['comentario']);
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={4}>
          <Form.Item
            name="fecha_de_la_solicitud"
            label="Fecha"
          >
            <DatePicker suffixIcon={null} format="DD/MM/YYYY" disabled />
          </Form.Item>
        </Col>
        {disabled && (
          <>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Usuario que está Atendiendo"
                name="usuario_que_esta_atendiendo"
              >
                <Select
                  dataSource={usuarios}
                  render={(val) => `${val.nombre_completo}`}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Funcionario que está Atendiendo"
                name="funcionario_que_esta_atendiendo"
              >
                <Select
                  dataSource={funcionarios}
                  render={(val) => `${val.nombre_completo}`}
                />
              </Form.Item>
            </Col>
          </>
        )}
        <Col span={24}>
          <Form.Item
            name="comentario"
            label="Comentario"
          >
            <Input.TextArea autoSize={{ minRows: 5, maxRows: 5 }} disabled={!disabled} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
          {form.getFieldValue('latitud') ? (
            <Mapa
              form={form}
              interactive={false}
              showAddress
            />
          ) : (
            <InfoNoRequired text="Ubicación" />
          )}
        </Col>
        <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
          {!disabled ? (
            <Gallery
              files={filterFiles()}
            />
          ) : (
            <Archivos />
          )}
        </Col>
      </Row>
    </Form>
  );

  const StatusContent = ({ statusID }) => {
    const [key] = selectedRowKeys;
    const match = [...data.results].find((e) => e.id === key);
    const seguimientos = match.seguimientos
      .filter((e) => e.estado_de_solicitud === statusID)
      .map((e) => formatReceived(e));
    return (
      <Form layout="vertical" className="without-feedback">
        {seguimientos.map((seguimiento, idx) => (
          <React.Fragment key={seguimiento.id}>
            <Form.Item label="Comentario">
              <Input.TextArea
                autoSize={{ minRows: 5, maxRows: 5 }}
                value={seguimiento.comentario || 'Comentario default'}
                disabled
              />
            </Form.Item>
            <Form.Item label="Fecha">
              <DatePicker
                suffixIcon={null}
                value={seguimiento.fecha_de_modificacion || seguimiento.fecha_de_creacion}
                format="DD/MM/YYYY"
                placeholder=""
                disabled
              />
            </Form.Item>
            <Gallery
              files={seguimiento.archivos}
            />
            <br />
            {idx < seguimientos.length * 2 && <Divider />}
            <br />
          </React.Fragment>
        ))}
      </Form>
    );
  };

  const prevIsDisabled = steps.find((e) => e.prioridad === currentStep - 1)?.disabled || true;
  const nextIsDisabled = steps.find((e) => e.prioridad === currentStep + 1)?.disabled || true;

  const stickyStyles = {
    position: 'sticky',
    zIndex: 2,
    backgroundColor: theme.bodyBackgroundColor || '#FFFFFF',
    top: 1,
  };

  const baseControlStyles = {
    width: '100%', padding: '10px 0',
  };

  const postFiles = async (idSeguimiento) => {
    try {
      const files = form.getFieldsValue().archivos.fileList.map((file) => {
        const [key] = selectedRowKeys;
        const item = {};
        item.extension_del_documento = file.name.split('.').pop().toLowerCase();
        item.seguimiento = idSeguimiento;
        item.solicitud = key;
        item.archivo = file.originFileObj;
        return item;
      });
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const requests = files.map((file) => {
        const formData = new FormData();
        const valuesKeys = Object.keys(file);
        valuesKeys.forEach((key) => {
          formData.append(`${key}`, file[key]);
        });
        return API.post('solicitudes/archivos-de-solicitudes/', formData, config);
      });
      await Promise.all(requests);
      return true;
    } catch {
      message.warn('Ha ocurrido un error al enviar la solicitud, por favor intentelo de nuevo.');
      return false;
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const [key] = selectedRowKeys;
      const values = form.getFieldsValue();
      values.solicitud = key;
      values.estado_de_solicitud = values.estado_de_la_solicitud;
      const response = await API.post(
        '/solicitudes/seguimiento-de-solicitudes/',
        values,
      );
      if (response?.status === 201) {
        if (hasFiles(values, ['archivos'])) {
          await postFiles(response.data.id);
        }
        const res = await API.patch(`/solicitudes/solicitudes/${key}`, {
          estado_de_la_solicitud: values.estado_de_solicitud,
        });
        setCurrentStep(res.data.estado_de_la_solicitud);
        onSuccess(null, 'Solicitud actualizada correctamente');
        setDisabled(false);
        onCancel();
        await fetchAll();
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const isEditable = () => {
    if (selectedRowKeys.length) {
      const editable = [12, 1, 2, 3, 4];
      const [key] = selectedRowKeys;
      const match = data.results.find((e) => e.id === key);
      return editable.includes(match.estado_de_la_solicitud);
    }
    return false;
  };

  const Controls = ({ sticky, showButons }) => (
    <Row
      justify="space-between"
      style={sticky ? { ...stickyStyles, ...baseControlStyles } : baseControlStyles}
    >
      <Button
        type="link"
        onClick={() => setCurrentStep(currentStep - 1)}
        disabled={prevIsDisabled}
      >
        <ArrowLeftOutlined />
        Anterior
      </Button>
      {(disabled && showButons) && (
        <Button
          type="link"
          icon={<CloseOutlined />}
          onClick={() => {
            setDisabled(false);
            const clone = [...data.results];
            const [key] = selectedRowKeys;
            const match = clone.find((e) => e.id === key);
            form.setFieldsValue({
              ...match,
              motivo_de_la_solicitud: match.motivo_de_la_solicitud.descripcion,
            });
          }}
        >
          Cancelar
        </Button>
      )}
      {showButons && (
        <Button
          type="link"
          onClick={() => {
            if (disabled) {
              onFinish();
            } else {
              setCurrentStep(0);
              setDisabled(!disabled);
              const values = form.getFieldsValue();
              if (values.estado_de_la_solicitud !== 5) {
                const { prioridad } = estadosSolicitud
                  .find((e) => e.id === values.estado_de_la_solicitud);
                const next = estadosSolicitud.find((e) => e.prioridad === prioridad + 1);
                form.setFieldsValue({ estado_de_la_solicitud: next?.id });
              }
            }
          }}
        >
          {disabled ? (
            <span>
              <SaveOutlined />
              Guardar
              {' '}
            </span>
          ) : (
            <span>
              <EditOutlined />
              {' '}
              Dar Seguimiento
            </span>
          )}
        </Button>
      )}
      <Button
        type="link"
        onClick={() => setCurrentStep(currentStep + 1)}
        disabled={nextIsDisabled}
      >
        Siguiente
        <ArrowRightOutlined />
      </Button>
    </Row>
  );

  const TableSteps = () => (
    <>
      {!visible ? (
        <>
          <Col span={24}>
            <Form
              layout="vertical"
              name="form-item-grid"
              initialValues={{ estado_de_la_solicitud: 12 }}
              form={formTable}
            >
              <Row>
                <Col xs={24} sm={24} md={10}>
                  <Form.Item
                    label="Estado de la Solicitud"
                    name="estado_de_la_solicitud"
                  >
                    <Select
                      dataSource={estadosSolicitud
                        .sort((a, b) => (a.prioridad < b.prioridad ? -1 : 1))}
                      render={(e) => `${e.nombre}`}
                      onChange={() => fetchData()}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          <Table
            cols={columns}
            data={data.results}
            rowSelection={rowSelection}
            rowKey="id"
            mobileColIndex={0}
            handleOnRowClick={handleOnRowClick}
            allowAdd={false}
            allowEdit={false}
            allowDelete={false}
            pagination={false}
            currentPage={currentPage}
            paginationCallback={fetchData}
            count={data.count}
            controls={{
              onClickExpand: () => setVisible(true),
            }}
            allowExpand
          />
        </>
      ) : (
        <Card
          title="Seguimiento de Solicitud"
          extra={(
            <FormSubmitControls
              onCancel={onCancel}
            />
          )}
          bordered={false}
          bodyStyle={{ padding: '5px 10px' }}
        >
          <Row style={{ height: '100%' }} gutter={10}>
            <Col xs={24} sm={8} md={8} lg={24} style={screens.lg && stickyStyles}>
              {((screens.xs || screens.sm || screens.md) && !screens.lg)
                && (<Controls showButons={isEditable()} sticky={!screens.xs} />)}
              <Steps
                style={(!screens.xs && !screens.lg)
                  ? { ...stickyStyles, top: !screens.lg ? 75 : 0 } : {}}
                direction={screens.lg ? 'horizontal' : 'vertical'}
                current={currentStep}
                onChange={setCurrentStep}
                size="small"
                progressDot
              >
                {steps.map((step) => (
                  <Steps.Step
                    title={step.nombre}
                    key={step.prioridad}
                    disabled={step.disabled}
                    style={{ backgroundColor: stickyStyles.backgroundColor }}
                  />
                ))}
              </Steps>
              {(screens.lg) && (<Controls showButons={isEditable()} />)}
            </Col>
            <Col
              xs={24}
              sm={16}
              md={16}
              lg={24}
            >
              {steps.map((step) => (
                <Col span={currentStep === step.prioridad ? 24 : 0} key={step.prioridad}>
                  {step.prioridad === 0 ? <Summary statusID={step.id} />
                    : <StatusContent statusID={step.id} />}
                </Col>
              ))}
            </Col>
          </Row>
        </Card>
      )}
    </>
  );

  return (
    <Row align="center" justify="center" className="container">
      <Col xs={24} sm={24} md={24}>
        <Spin tip="Cargando..." spinning={loading}>
          <TableSteps />
        </Spin>
      </Col>
      <Previewer
        visible={visiblePreview}
        setVisible={setVisiblePreview}
        iFrameUrl={currentFile?.archivo}
        isImage={isImageExt(currentFile?.extension_del_documento)}
      />
    </Row>
  );
};

export default SeguimientoDeSolicitud;
