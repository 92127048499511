import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Button,
  Grid,
  Col,
} from 'antd';

const FormSubmitCustomControls = ({ controls, loading, label }) => {
  const screens = Grid.useBreakpoint();
  return (
    <Row justify="space-between">
      <Col>
        {label}
      </Col>
      <Col>
        {controls.filter((e) => e).map(({
          icon,
          key,
          onClick,
          disabled,
          text,
          ...btnProps
        }, idx) => (
          btnProps && (
            <Button
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...btnProps}
              type="link"
              onClick={onClick}
              key={key || idx}
              disabled={loading || disabled}
            >
              {icon}
              {screens.md && (text)}
            </Button>
          )
        ))}
      </Col>
    </Row>
  );
};

FormSubmitCustomControls.propTypes = {
  controls: PropTypes.arrayOf(PropTypes.exact({
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
  })).isRequired,
  loading: PropTypes.bool,
  label: PropTypes.string,
};

FormSubmitCustomControls.defaultProps = {
  loading: false,
  label: null,
};

export default FormSubmitCustomControls;
