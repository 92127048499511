/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Card,
  message,
  Steps,
  Modal,
  Grid,
} from 'antd';
import {
  InfoCircleOutlined,
  PictureOutlined,
  SnippetsOutlined,
  EnvironmentOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import API from 'utils/api';
import Select from 'components/Select';
import Dragger from 'components/Dragger';
import Mapa from 'components/Mapa';
import { onError } from 'utils/handlers';
import { findItemNested } from 'utils/filters';
import { SET_GENEROS } from 'store/reducers/catalogs';
import fetchSome from 'utils/fetchSome';
import { getCatalogoDePaises } from 'api/catalogos';

const baseURI = '/solicitudes/solicitudes/';
const { Step } = Steps;

const onClickTitle = (e) => {
  const element = e.target;
  if (element) {
    let parent = element.parentElement;
    parent = parent ? parent.parentElement : null;
    if (parent) {
      const clickableSpan = parent.previousSibling;
      if (clickableSpan) {
        clickableSpan.click();
      }
    }
  }
};

const renderTitle = (title, bold) => (
  // eslint-disable-next-line
  <span onClick={onClickTitle} className={bold ? "bold" : ""}>
    {title}
  </span>
);

export const normalizeMotivos = (_data = []) => {
  const output = _data.map((item) => {
    const clone = { ...item };
    const children = normalizeMotivos(clone.children);
    const label = clone.descripcion;
    const prefix = '';
    /*
    if (clone.categoria_id) {
      prefix = 'subcategoria_id__';
    } else if (!clone.subcategoria_id) {
      prefix = 'categoria_id__';
    }
    */
    const value = prefix ? `${prefix}${clone.id}` : clone.id;
    const title = renderTitle(label, !prefix);
    if (!children.length) {
      delete clone.children;
      return {
        ...clone,
        title,
        value,
        selectable: !prefix,
      };
    }
    return {
      ...clone,
      title,
      value,
      children,
      selectable: false,
    };
  });
  return output;
};

const NuevaSolicitud = () => {
  const screens = Grid.useBreakpoint();
  const entidad = useSelector(({ auth }) => auth.entidad);
  const user = useSelector(({ auth }) => auth.user);
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [filesRequired, setFilesRequired] = useState(false);
  const [locationRequired, setLocationRequired] = useState(false);
  const [motivos, setMotivos] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [solicitudPendiente, setSolicitudPendiente] = useState();
  const [tipoDeSolicitante, setTipoDeSolicitante] = useState([]);
  const [tipoDeAtencion, setTipoDeAtencion] = useState([]);
  const [tipoDePersona, setTipoDePersona] = useState(0);
  const generos = useSelector(({ catalogs }) => catalogs.generos);
  const [paisesTemp, setPaisesTemp] = useState({ results: [] });
  const paises = useRef({ results: [] });
  const [fullAddress, setFullAddress] = useState('');

  const fetchAll = async () => {
    try {
      setLoading(true);
      const res = await API.get('/solicitudes/motivos-de-las-solicitudes/', {
        params: { entidad: entidad.id },
      });
      const resTipos = await API.get(
        '/solicitudes/tipos-de-usuario-de-solicitud/',
      );
      const resTipoDeSolicitud = await API.get(
        '/solicitudes/tipos-de-atencion-de-solicitud/',
      );
      setTipoDeAtencion(resTipoDeSolicitud.data || []);
      setTipoDeSolicitante(resTipos.data);
      if (!generos.length) {
        fetchSome('usuarios/generos/', SET_GENEROS);
      }
      const resPaises = await getCatalogoDePaises();
      setPaisesTemp(resPaises);
      setMotivos(res.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    if (!user?.id) {
      Modal.warning({
        title: 'Alerta de seguimiento',
        content: (
          <>
            Los usuarios invitados no podrán realizar el seguimiento a sus
            solicitudes, le recomendamos
            {' '}
            <Button type="link">Iniciar sesión</Button>
            {' '}
            con su cuenta o
            {' '}
            <Button type="link">Registrarse</Button>
          </>
        ),
        width: 600,
        okText: 'Continuar como invitado',
      });
    }
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setCurrentStep(0);
    form.resetFields();
  };

  const postFiles = async (id) => {
    try {
      const archivos = form.getFieldValue('archivos');
      const files = archivos?.fileList.map((file) => {
        const item = {};
        item.extension_del_documento = file.name.split('.').pop().toLowerCase();
        item.seguimiento = id;
        item.archivo = file.originFileObj;
        return item;
      });
      if (files?.length) {
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
        const requests = files.map((file) => {
          const formData = new FormData();
          const valuesKeys = Object.keys(file);
          valuesKeys.forEach((key) => {
            formData.append(`${key}`, file[key]);
          });
          return API.post(
            'solicitudes/archivos-de-solicitudes/',
            formData,
            config,
          );
        });
        await Promise.all(requests);
      }
      return true;
    } catch {
      message.warn(
        'Ha ocurrido un error al enviar la evidencia, por favor inténtelo de nuevo.',
      );
      return false;
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      let postedFiles = false;
      let response;
      if (!solicitudPendiente) {
        await form.validateFields();
        const values = form.getFieldsValue();
        delete values.archivos;
        values.estado_de_la_solicitud = 1;
        values.entidad = entidad.id;
        response = await API.post(baseURI, values);
        if (response?.status === 201) {
          setSolicitudPendiente(response.data.seguimientos[0].id);
          postedFiles = await postFiles(response.data.seguimientos[0].id);
        }
      } else {
        postedFiles = await postFiles(solicitudPendiente);
      }
      if (postedFiles) {
        // onSuccess(null, 'Tu Solicitud fue registrada correctamente');
        setTimeout(() => {
          Modal.success({
            title: 'Tu Solicitud fue registrada con éxito',
            content: (
              <Row>
                {`Numero de folio para seguimiento: ${response.data.folio}`}
              </Row>

            ),
          });
          onCancel();
          history.push(
            `/mi-buzon/mis-incidentes/seguimiento?id=${response.data.id}`,
          );
        });
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
    conditionalRequired: [
      {
        required: tipoDePersona !== 1,
        message: 'El campo es requerido',
      },
    ],
    email: [
      {
        required: tipoDePersona !== 1,
        message: 'El campo es requerido',
      },
      {
        type: 'email',
        message: 'Introduzca un correo valido.',
      },
    ],
    numero_de_celular: [
      {
        pattern: /^[0-9]{10}$/,
        message: 'Ingrese un número teléfonico válido',
      },
    ],
  };

  const onChangeMotivo = (val) => {
    if (val) {
      // console.log(val, motivos);
      const motivo = findItemNested(motivos, val, 'children', 'id');
      setFilesRequired(motivo.requiere_archivos);
      setLocationRequired(motivo.requiere_ubicacion);
    }
  };

  const confirm = () => {
    const {
      motivo_de_la_solicitud,
      comentario,
      archivos,
    } = form.getFieldValue();
    const motivo = findItemNested(
      motivos,
      motivo_de_la_solicitud,
      'children',
      'id',
    );

    Modal.confirm({
      title: 'Confirmación',
      content: (
        <>
          <b>Motivo:</b>
          {' '}
          {motivo.descripcion}
          <br />
          <b>Comentario:</b>
          {' '}
          {comentario}
          <br />
          <b>Evidencias:</b>
          {' '}
          {archivos?.fileList.length}
          <br />
          <b>Ubicación:</b>
          {' '}
          {fullAddress || ''}
        </>
      ),
      width: 600,
      okText: 'Confirmar',
      onOk: onFinish,
    });
  };

  const TipoDeRegistro = () => (
    <>
      <Row gutter={10}>
        <Col xs={24} sm={24} md={24}>
          <Form.Item
            name="tipo_de_usuario_solicitante"
            rules={rules.required}
            label="Tipo de Usuario Solicitante"
          >
            <Select
              dataSource={tipoDeSolicitante}
              onChange={setTipoDePersona}
            />
          </Form.Item>
        </Col>
        {tipoDePersona === 3 && ( // 1 = anonimo
          <>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="nombre"
                rules={rules.required}
                label="Nombre"
                hasFeedback
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="apellido paterno"
                rules={rules.required}
                label="Apellido Paterno"
                hasFeedback
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="apellido materno"
                rules={rules.required}
                label="Apellido Materno"
                hasFeedback
              >
                <Input allowClear />
              </Form.Item>
            </Col>
          </>
        )}
        <Col xs={24} sm={24} md={8}>
          <Form.Item
            name="correo electronico"
            rules={rules.email}
            label="Correo Electrónico"
            hasFeedback
          >
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Form.Item
            name="telefono"
            rules={rules.numero_de_celular}
            label="Teléfono"
            hasFeedback
          >
            <Input allowClear />
          </Form.Item>
        </Col>
        {tipoDePersona !== 1 && (
          <>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="nacionalidad"
                rules={rules.required}
                label="Nacionalidad"
                hasFeedback
              >
                <Select
                  // labelProp="nacionalidad"
                  pagination={{
                    getter: getCatalogoDePaises,
                    config: { auto: true },
                    setter: (dataSource) => {
                      paises.current = dataSource;
                    },
                  }}
                  dataSource={paisesTemp.results.length
                    ? paisesTemp : paises.current}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="genero_solicitante_de_queja"
                rules={rules.required}
                label="Genero"
                hasFeedback
              >
                <Select dataSource={generos} allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="direccion"
                rules={rules.required}
                label="Dirección"
                hasFeedback
              >
                <Input allowClear />
              </Form.Item>
            </Col>
          </>
        )}
        <Col xs={24} sm={24} md={8}>
          <Form.Item
            name="tipo_de_atencion_de_solicitud"
            rules={rules.required}
            label="Tipo de Atención"
          >
            <Select dataSource={tipoDeAtencion} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );

  const Informacion = () => (
    <>
      <Form.Item
        name="motivo_de_la_solicitud"
        rules={rules.required}
        label="Motivo de la solicitud"
        hasFeedback
      >
        <Select onChange={onChangeMotivo} dataSource={motivos} />
      </Form.Item>
      <Form.Item
        name="comentario"
        rules={rules.required}
        label="Comentarios"
        hasFeedback
      >
        <Input.TextArea
          autoSize={{ minRows: 5, maxRows: 5 }}
          maxLength={250}
          allowClear
        />
      </Form.Item>
    </>
  );

  const Archivos = ({ required }) => (
    <Dragger
      form={form}
      label="Evidencias"
      limitMB={30}
      rules={required ? rules.required : null}
      name="archivos"
      allowedExtensions={['png', 'jpeg', 'jpg', 'pdf', 'docx']}
      multiple
    />
  );

  const steps = [
    {
      key: 0,
      title: 'Tipo De Registro',
      icon: <InfoCircleOutlined />,
    },
    {
      key: 1,
      title: 'Información',
      icon: <SnippetsOutlined />,
    },
    {
      key: 2,
      title: 'Archivos',
      icon: <PictureOutlined />,
    },
    {
      key: 3,
      title: 'Ubicación',
      icon: <EnvironmentOutlined />,
    },
  ];

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const next = async () => {
    try {
      const fieldsToValidate = {
        1: ['correo electronico', 'telefono', 'tipo_de_atencion_de_solicitud'], // Anonimo
        2: [
          'correo electronico',
          'telefono',
          'nacionalidad',
          'genero_solicitante_de_queja',
          'direccion',
          'tipo_de_atencion_de_solicitud',
        ], // cuenta unica
        3: [
          'tipo_de_usuario_solicitante',
          'nombre',
          'apellido paterno',
          'apellido materno',
          'correo electronico',
          'telefono',
          'nacionalidad',
          'genero_solicitante_de_queja',
          'direccion',
          'tipo_de_atencion_de_solicitud',
        ], // sin registro
      };
      await form.validateFields(fieldsToValidate[tipoDePersona]);
      switch (currentStep) {
        case 1:
          await form.validateFields(['motivo_de_la_solicitud', 'comentario']);
          break;
        case 2:
          await form.validateFields(['archivos']);
          break;
        case 3:
          if (locationRequired && !form.getFieldValue('latitud')) {
            message.warn('Seleccione la ubicación en el mapa');
            throw new Error('seleccione_ubicacion');
          } else {
            confirm();
          }
          break;
        default:
          break;
      }
      if (currentStep !== 3) {
        setCurrentStep(currentStep + 1);
      }
    } catch (err) {
      if (err.message !== 'seleccione_ubicacion') {
        onError(err);
      }
    }
  };

  const styles = {
    show: {
      right: 0,
      opacity: 1,
      zIndex: 3,
      // height: `calc(100vh - ${screens.md ? '350' : '300'}px)`,
    },
    hide: {
      right: '100%',
      opacity: 0,
      zIndex: 0,
    },
    base: {
      position: 'absolute',
      top: 0,
      left: 0,
    },
  };

  return (
    <>
      <Row align="center" justify="center" className="container">
        <Col xs={24} sm={24} md={24}>
          <Spin
            tip="Cargando..."
            spinning={loading}
            style={{ width: '100%', height: '100%' }}
          >
            <Card
              className="form-container no-padding"
              title="Agregar Solicitud"
              bordered={false}
              bodyStyle={{ overflow: 'hidden' }}
            >
              <Col>
                <Steps current={currentStep}>
                  {steps.map((step) => (
                    <Step title={step.title} icon={step.icon} key={step.key} />
                  ))}
                </Steps>
              </Col>
              <Col
                span={24}
                style={{
                  height: `calc(100vh - ${screens.md ? '300' : '250'}px)`,
                }}
              >
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                  scrollToFirstError
                >
                  <Row gutter={10} style={{ height: '100%' }}>
                    <Col
                      span={24}
                      style={{
                        ...styles.base,
                        ...styles[currentStep === 0 ? 'show' : 'hide'],
                      }}
                    >
                      <TipoDeRegistro />
                    </Col>
                    <Col
                      span={24}
                      style={{
                        ...styles.base,
                        ...styles[currentStep === 1 ? 'show' : 'hide'],
                      }}
                    >
                      <Informacion />
                    </Col>
                    <Col
                      span={24}
                      style={{
                        ...styles.base,
                        ...styles[currentStep === 2 ? 'show' : 'hide'],
                      }}
                    >
                      <Archivos required={filesRequired} />
                    </Col>
                    <Col
                      span={24}
                      style={{
                        ...styles.base,
                        ...styles[currentStep === 3 ? 'show' : 'hide'],
                        height: 'calc(100% - 30px)',
                        overflowY: 'scroll',
                        paddingBottom: 10,
                      }}
                    >
                      Colocar el marcador en el lugar preciso del percance.
                      <Mapa
                        callbackAddress={(address) => {
                          setFullAddress(address);
                        }}
                        showAddress
                        form={form}
                        currentStep={currentStep}
                        height={`calc(100vh - ${screens.md ? '360' : '330'}px)`}
                        hiddenInputs={false}
                      />
                    </Col>
                    <Row
                      justify="space-between"
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        height: 30,
                      }}
                    >
                      <Button
                        type="link"
                        onClick={prev}
                        disabled={currentStep === 0}
                      >
                        <ArrowLeftOutlined />
                        Anterior
                      </Button>
                      <Button type="link" onClick={next}>
                        {currentStep === steps.length - 1 ? (
                          <>
                            <span>Finalizar</span>
                            <SendOutlined />
                          </>
                        ) : (
                          <>
                            <span>Siguiente</span>
                            <ArrowRightOutlined />
                          </>
                        )}
                      </Button>
                    </Row>
                  </Row>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </Col>
            </Card>
          </Spin>
        </Col>
      </Row>
    </>
  );
};

export default NuevaSolicitud;
