/* eslint-disable import/prefer-default-export */
import API from 'utils/api';
import { store } from 'store';
import {
  SET_LADAS,
} from 'store/reducers/catalogs';
import { onError } from 'utils/handlers';

export const baseURI = 'usuarios/';

/**
 * GET Ladas
 * @returns {Promise<Array<object>>}
 */
export const getLadas = async () => {
  try {
    if (!store.getState().catalogs.ladas?.length) {
      const response = await API.get(`${baseURI}lada`);
      store.dispatch({
        type: SET_LADAS,
        payload: response.data,
      });
      return response.data;
    }
    return store.getState().catalogs.ladas;
  } catch (err) {
    onError(err);
    return [];
  }
};
