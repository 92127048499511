/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  EnvironmentOutlined,
} from '@ant-design/icons';

import Mapa from 'views/Mapas/Mapa';
import blank from './blank';

export default {
  title: 'Mapas',
  icon: () => <EnvironmentOutlined />,
  showInSidebar: true,
  component: blank,
  type: 'private',
  children: [
    {
      title: 'Mapa',
      icon: null,
      component: Mapa,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Solicitantes Registrados',
      icon: null,
      component: blank,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Empresas Registradas',
      icon: null,
      component: blank,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Apoyos Sociales Otorgados',
      icon: null,
      component: blank,
      type: 'private',
      showInSidebar: true,
    },
  ],
};
