import React, { useEffect, useState, useMemo } from 'react';
import {
  Row,
  Spin,
  Col,
  Card,
  Segmented,
  Progress as AntProgress,
} from 'antd';
import Table from 'components/Table';
import { onError } from 'utils/handlers';
import API from 'utils/api';
import {
  EditOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { FULL_DATE_FRONTEND_FORMAT, getFullName, toMoment } from 'utils/formatValues';
import { sortItems } from 'utils/sorters';
import FormSubmitControls from 'components/FormSubmitControls';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Vista from 'views/MiBuzon/MisFichasEnProceso/Vista';
import Editar, { getFlujoActual } from 'views/MiBuzon/MisFichasEnProceso/Editar';
import { isAdmin } from 'utils/permission';

const baseURI = '/tramites/plantillas-de-retys/';

const MisFichasEnProceso = () => {
  const user = useSelector(({ auth }) => auth.user);
  const roles = useSelector(({ auth }) => auth.roles);
  const [currentTabKey, setCurrentTabKey] = useState('editar');
  const [data, setData] = useState([]);
  const [configFlujos, setConfigFlujos] = useState(null);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const plantilla = useMemo(() => data.find((e) => e.id === selectedRowKeys[0]), [selectedRowKeys]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [_confFlujosRetys] = (await API.get('/tramites/configuraciones-de-flujos-retys/',
        { params: { proceso_de_retys: 1 } })).data;
      if (_confFlujosRetys) {
        const _roles = (await API.get('catalogos/grupos/'))
          .data.map((e) => ({ ...e, name: e.name.split(' - ')[0] }));
        const flujos = (await API.get('/tramites/flujos-de-retys/',
          { params: { configuracion_de_flujo_de_retys: _confFlujosRetys.id } }))
          .data.sort(sortItems('posicion'));
        _confFlujosRetys.flujos = flujos.map((f) => ({
          ...f,
          roles: f.roles.map((r) => _roles.find((_r) => _r.id === r)),
        }));
        setConfigFlujos(_confFlujosRetys);
        const params = {};
        if (!isAdmin()) {
          if (user.unidad_responsable) {
            params.plantilla_de_tramites__departamentos = user.unidad_responsable.id;
          } else if (user.unidad_operativa) {
            params.dependencia = user.unidad_operativa.id;
          }
        }
        const _plantillasRetys = (await API.get(
          baseURI,
          {
            params: {
              ...params,
              configuracion_de_flujo_de_retys: _confFlujosRetys.id,
              estado_de_validacion_de_ficha: 2,
            },
          },
        )).data;
        const _estados = (await API.get('/tramites/estados-de-fichas/')).data;
        const _historiales = (await API.get('/tramites/historiales-de-retys/',
          { configuracion_de_flujo_de_retys: _confFlujosRetys.id })).data;

        const procesos = (await API.get('/tramites/procesos-de-tipo-de-tramite/')).data;
        const subProcesos = (await API.get('/tramites/subprocesos-de-tipo-de-tramite/')).data;
        const opciones = (await API.get('/tramites/opciones-de-tipo-de-tramite/')).data;
        const respuestas = (await API.get('/tramites/respuestas-de-tipo-de-tramite/')).data;
        const subOpciones = (await API.get('/tramites/subopciones-de-tipo-de-tramite/')).data;
        const subRespuestas = (await API.get('/tramites/subrespuestas-de-tipo-de-tramite/')).data;
        const contenTypesTramites = (await API.get('/catalogos/content-types-tramites/')).data;

        const _data = _plantillasRetys
          .map(({
            cadena,
            canal_de_presentacion_plantilla,
            casos_de_los_requisitos,
            comentario_general,
            costeo_estandar,
            citerio_de_resolucion_de_tramite,
            documento_de_salida_de_tramite,
            familia_del_tramite_plantilla,
            formato_requerido,
            fundamento_legal,
            fundamento_legal_monto,
            oficina_de_atencion,
            plazo_maximo,
            plazo_prevencion,
            sinonimo,
            subsector_economico_de_plantilla,
            tipo_de_resolucion_plantilla,
            ..._plantilla
          }) => {
            const { plantilla_de_tramites } = _plantilla;
            plantilla_de_tramites.unidad_operativa = plantilla_de_tramites
              .departamentos[0].unidad_operativa;
            const _historial = _historiales
              .filter((e) => e.plantilla_de_tramites === plantilla_de_tramites.id);
            const flujoActual = getFlujoActual(_historial, _confFlujosRetys);
            const progreso = Math.round((100 / flujos.length) * (flujoActual?.posicion - 1) || 0);
            const historial = _historial.map((h) => ({
              ...h,
              fecha_de_creacion: toMoment(h.fecha_de_creacion),
              estado: _estados.find((e) => e.id === h.estado),
            })).reverse();

            const valuesProceso = {};
            if (plantilla_de_tramites.content_type_tramite) {
              const keysByLvl = {
                1: 'tipo_de_tramite_proceso',
                2: 'tipo_de_tramite_sub_proceso',
                3: 'tipo_de_tramite_opciones',
                4: 'tipo_de_tramite_respuesta',
                5: 'tipo_de_tramite_sub_opciones',
                6: 'tipo_de_tramite_sub_respuesta',
              };
              const sourcesByLvl = {
                1: procesos,
                2: subProcesos,
                3: opciones,
                4: respuestas,
                5: subOpciones,
                6: subRespuestas,
              };
              let lvl = contenTypesTramites[contenTypesTramites
                .findIndex((e) => e.id === plantilla_de_tramites.content_type_tramite)].nivel;
              let value = plantilla_de_tramites.object_id_tramite;
              while (lvl) {
                // eslint-disable-next-line no-loop-func
                const match = sourcesByLvl[lvl].find((e) => e.id === value);
                valuesProceso[keysByLvl[lvl]] = match;
                if (lvl !== 1) {
                  value = sourcesByLvl[lvl - 1]
                    // eslint-disable-next-line no-loop-func
                    .find((e) => match[keysByLvl[lvl - 1]]?.includes?.(e.id)
                      || match[keysByLvl[lvl - 1]] === e.id).id;
                }
                lvl -= 1;
              }
            }

            return {
              id: _plantilla.id,
              folio: _plantilla.folio || plantilla_de_tramites.homoclave,
              plantilla_de_tramites: {
                ...plantilla_de_tramites,
                ...valuesProceso,
                cadena,
                canal_de_presentacion_plantilla,
                casos_de_los_requisitos,
                comentario_general,
                costeo_estandar,
                citerio_de_resolucion_de_tramite,
                documento_de_salida_de_tramite,
                familia_del_tramite_plantilla,
                formato_requerido,
                fundamento_legal,
                fundamento_legal_monto,
                oficina_de_atencion,
                plazo_maximo,
                plazo_prevencion,
                sinonimo,
                subsector_economico_de_plantilla,
                tipo_de_resolucion_plantilla,
              },
              usuario_modifico: historial[0].usuario_creo,
              historial,
              progreso,
              flujoActual,
            };
          });
        const filtered = _data.filter((_plantilla) => _plantilla.flujoActual?.roles
          .some((r) => roles.some((_r) => _r.id === r.id)));
        setData(filtered);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // eslint-disable-next-line react/prop-types
  const LabelInfo = ({ label, info }) => (
    <>
      <Col xs={12} sm={12} md={4}>
        {label}
        :
      </Col>
      <Col xs={12} sm={12} md={8}>
        {info}
      </Col>
    </>
  );

  const columns = [
    {
      titleText: 'Ficha de Retys',
      dataIndex: 'id',
      key: 'id',
      width: '100%',
      render: (_, record) => (
        <Row style={{ userSelect: 'none' }}>
          <LabelInfo
            label="Nombre"
            info={record.plantilla_de_tramites.nombre}
          />
          <LabelInfo
            label="Progreso"
            info={(
              <Progress
                percent={record.progreso}
                steps={configFlujos.flujos.length}
                size="large"
                trailColor="#ccc"
                showInfo={record.flujoActual?.posicion === configFlujos.flujos.length
                  && record.plantilla_de_tramites.estado_de_ficha.id === 3}
              />
            )}
          />
          <LabelInfo
            label="Fecha"
            info={record.historial[0].fecha_de_creacion?.format(FULL_DATE_FRONTEND_FORMAT)}
          />
          <LabelInfo
            label="Folio"
            info={record.folio}
          />
          <LabelInfo
            label="Dependencia"
            info={record.plantilla_de_tramites.departamentos[0]?.unidad_operativa.descripcion}
          />
          <LabelInfo
            label="Estado"
            info={record.plantilla_de_tramites.estado_de_ficha.descripcion}
          />
          <LabelInfo
            label="Rol a seguir"
            info={record.flujoActual?.roles.map((e) => e.name)}
          />
          <LabelInfo
            label="Usuario"
            info={getFullName(record.historial[0].usuario_creo, 'numero_de_empleado')}
          />
        </Row>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
  };

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    setCurrentTabKey('editar');
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            mobileColIndex={0}
            rowKey="id"
            handleOnRowClick={handleOnRowClick}
            allowSearch={false}
            customActions={[
              {
                onClick: () => {
                  setCurrentTabKey('vista');
                  setVisible(true);
                },
                icon: EyeOutlined,
                text: 'Vista',
              },
              {
                onClick: () => {
                  setCurrentTabKey('editar');
                  setVisible(true);
                },
                icon: EditOutlined,
                text: 'Editar',
              },
            ]}
            expandable={false}
          />
        ) : (
          <Card
            className="form-container"
            title="Ficha de Retys"
            extra={(
              <FormSubmitControls
                onCancel={onCancel}
                allowSaveAndContinue
                onCancelText="Cancelar"
              />
            )}
            bordered={false}
          >
            <Segmented
              size="large"
              value={currentTabKey}
              onChange={setCurrentTabKey}
              options={[
                {
                  icon: <EyeOutlined />,
                  label: 'Vista',
                  value: 'vista',
                },
                {
                  icon: <EditOutlined />,
                  label: 'Editar',
                  value: 'editar',
                },
              ]}
            />
            <Col span={24}>
              {currentTabKey === 'vista' ? (
                <Vista plantilla={plantilla.plantilla_de_tramites} />
              ) : (
                <Editar
                  callback={async () => {
                    onCancel();
                    await fetchData();
                  }}
                  plantilla={plantilla}
                  configFlujos={configFlujos}
                  setLoading={setLoading}
                />
              )}
            </Col>
          </Card>
        )}
      </Spin>
    </Row>
  );
};

const Progress = styled(AntProgress)`
  /* width: calc(100% - 50px); */
  width: 100%;
  .ant-progress-steps-outer {
    width: 100%;
  }
  .ant-progress-steps-item {
    width: calc((100% - 50px) / ${(props) => props.steps}) !important;
  }
`;

export default MisFichasEnProceso;
