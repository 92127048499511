/* eslint-disable no-unused-expressions */
import { useState, useEffect, useRef } from 'react';

const initialData = {
  q: null,
  page: 1,
  count: 0,
  next: true,
  prev: false,
  params: {},
  results: [],
};

const _getter = () => new Error('Getter is required');

/**
 * @param {func} getter Function to get data
 * @param {object} config
 * @param {boolean} config.auto Auto fetch first page
 * @param {string|number} config.selfValue
 * @param {boolean} config.useLoadingState
 * @param {boolean} config.onlyOnePage
 * @returns [] [data, fetchData, loading]
 */
const usePaginatedFetcher = (getter = _getter, config = {}) => {
  const [data, setData] = useState(initialData);
  const loadingRef = useRef(config.auto || false);
  const [loading, setLoading] = useState(config.auto || false);

  const getDistinctResults = (_results) => [...new Set(_results.map(({ id }) => id))]
    .map((id) => _results.find((e) => e.id === id));

  const changeLoading = (state) => {
    if (config.useLoadingState) {
      setLoading(state);
    } else {
      loadingRef.current = state;
    }
  };

  const fetchData = async (page = 1, q = null, _params = null) => {
    const params = { ..._params };
    params.page = page;
    if (q) {
      params[config?.qName || 'q'] = q;
    }
    const samePage = page === data.page && !(page === 1 && data.page === 1);
    const sameQ = q === data.q;
    if (!(samePage && sameQ)) {
      changeLoading(true);
      const _data = await getter(params);
      changeLoading(false);
      if (page !== 1 && !config.onlyOnePage) {
        setData((prev) => ({
          q,
          page,
          count: _data.count,
          params: _params,
          next: Boolean(_data.next),
          prev: Boolean(_data.next),
          results: getDistinctResults([...prev.results, ..._data.results]),
        }));
        return _data;
      }
      setData({
        q: _data.q || null,
        page,
        count: _data.count,
        params: _params,
        next: Boolean(_data.next),
        prev: Boolean(_data.prev),
        results: _data.results,
      });
      return _data;
    }
    return data;
  };

  useEffect(() => {
    if (config.auto && !config.selfValue) {
      fetchData();
    }
    return () => {
      loadingRef.current = false;
    };
  }, []);

  return [data, fetchData, config.useLoadingState ? loading : loadingRef.current];
};

export default usePaginatedFetcher;
