import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Card,
  DatePicker,
  Tabs,
} from 'antd';
import {
  EnvironmentOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import Direccion from 'components/Direccion';
import getFormattedValues, { hasFiles, formatReceived } from 'utils/formatValues';
import Table from 'components/Table';
import API from 'utils/api';
import {
  CURP_PATTERN,
  digitoVerificador,
  RFC_PATTERN,
  INE_PATTERN,
} from 'utils/patterns';
import fetchSome from 'utils/fetchSome';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import LadaNumero from 'components/LadaNumero';
import { SET_GENEROS, SET_ESTADOS_CIVILES } from 'store/reducers/catalogs/index';
import AvatarUploader from 'components/AvatarUploader';
import Select from 'components/Select';
import { onError, onSuccess } from 'utils/handlers';
import { isMutable } from 'utils/estadosGlobales';
import makeMessager from 'utils/complexFormsMessages';

const { TabPane } = Tabs;
const baseURI = 'cuentaunicasir/ciudadanos/';

const Ciudadano = () => {
  const [form] = Form.useForm();
  const [formInfo] = Form.useForm();
  const generos = useSelector(({ catalogs }) => catalogs.generos);
  const estadosCiviles = useSelector(({ catalogs }) => catalogs.estadosCiviles);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTabKey, setCurrentTabKey] = useState('general');
  const [formAddress, setFormAddress] = useState();
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState({ results: [] });
  const [mutable, setMutable] = useState(true);

  const basicKeys = ['general', 'info'];
  const complexForms = [{ key: 'direccion', formInstance: formAddress }];

  const fetchData = async (page = 1, q = null) => {
    try {
      setLoading(true);
      setCurrentPage(page);
      const response = await API.get(baseURI, { params: { page, ...q } });
      if (response?.status === 200) {
        setData({ ...response.data, results: formatReceived(response.data.results) });
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };
  const showMsg = makeMessager(setCurrentTabKey, fetchData);

  const fetchAll = async () => {
    try {
      setLoading(true);
      if (!generos.length) {
        await fetchSome('usuarios/generos/', SET_GENEROS);
      }
      if (!estadosCiviles.length) {
        await fetchSome('usuarios/estados-civiles/', SET_ESTADOS_CIVILES);
      }
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    formInfo.resetFields();
    setSelectedRowKeys([]);
    setCurrentTabKey('general');
    setMutable(true);
  };

  const onChangeTabKey = (key) => {
    const fromBasic = basicKeys.includes(currentTabKey);
    const toBasic = basicKeys.includes(key);
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (!match) {
      if (fromBasic && toBasic) {
        setCurrentTabKey(key);
      } else if (fromBasic) {
        showMsg(key);
      } else {
        setCurrentTabKey(key);
      }
    } else {
      match.formInstance.onFinishHandler(key, true);
    }
  };

  const onClickEdit = async (responseData = null) => {
    const [key] = selectedRowKeys;
    const match = responseData || data.results.find((item) => item.id === key);
    if (match) {
      setVisible(true);
      form.setFieldsValue(match);
      formInfo.setFieldsValue(match);
    }
  };

  const onFinish = async (_values, _continue = false) => {
    try {
      setLoading(true);
      await form.validateFields();
      await formInfo.validateFields();
      const values = {
        ...form.getFieldsValue(),
        ...formInfo.getFieldsValue(),
      };
      const files = ['foto'];
      const config = hasFiles(values, files) ? {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      } : {};
      const formattedValues = getFormattedValues(values, {
        formData: config.headers,
        files,
        clean: false,
      });
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, formattedValues, config);
        if (response?.status === 201) {
          if (_continue) {
            onSuccess(response, 'Agregado correctamente');
            await fetchData();
            setSelectedRowKeys([response.data.id]);
            onClickEdit(response.data);
          } else {
            onSuccess(response.data, 'Agregado correctamente');
            onCancel();
            await fetchData();
          }
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.patch(`${baseURI}${key}/`, formattedValues, config);
        if (response?.status === 200) {
          if (_continue) {
            await fetchData();
            setTimeout(() => {
              onClickEdit();
            });
          } else {
            onSuccess(response, 'Actualizado Correctamente');
            onCancel();
            await fetchData();
          }
        }
      }
      setLoading(false);
      return true;
    } catch (err) {
      onError(err, setLoading, [form, formInfo], setCurrentTabKey);
      return false;
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
    formInfo.setFieldsValue(record);
    setMutable(isMutable(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Clave',
      dataIndex: 'clave_ciudadana',
      key: 'clave_ciudadana',
      width: 100,
    },
    {
      titleText: 'Correo Electrónico',
      dataIndex: 'email',
      key: 'email',
      width: 250,
    },
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 200,
    },
    {
      titleText: 'Apellido Paterno',
      dataIndex: 'apellido_paterno',
      key: 'apellido_paterno',
      width: 200,
    },
    {
      titleText: 'Apellido Materno',
      dataIndex: 'apellido_materno',
      key: 'apellido_materno',
      width: 200,
    },
  ];

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
    RFC: [
      {
        validator: async (r, val) => {
          if (val) {
            if (!RFC_PATTERN.test(val) || val.length !== 13) {
              throw new Error('Ingrese un RFC válido');
            }
          }
        },
      },
    ],
    INE: [
      {
        pattern: INE_PATTERN,
        message: 'Ingrese un INE válido',
      },
    ],
    curp: [
      {
        validator: async (rule, value) => {
          if (value) {
            const validado = value.toUpperCase().match(CURP_PATTERN);
            if (!validado || validado[2] !== digitoVerificador(validado[1])) {
              throw new Error('Ingrese una CURP válida');
            }
          }
        },
      },
    ],
    email: [
      {
        required: true,
        message: 'El campo es requerido',
      },
      {
        type: 'email',
        message: 'Ingrese un correo electrónico válido',
      },
    ],
    email_alternativo: [
      {
        type: 'email',
        message: 'Ingrese un correo electrónico válido',
      },
    ],
  };
  const handleOnFinish = (vals, _continue = false) => {
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (match) {
      match.formInstance.onFinishHandler(null, _continue);
    } else {
      onFinish(null, _continue);
    }
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible
          ? (
            <Table
              cols={columns}
              data={data.results}
              rowSelection={rowSelection}
              mobileColIndex={0}
              rowKey="id"
              handleOnRowClick={handleOnRowClick}
              controls={{
                onClickAdd,
                onClickEdit: () => onClickEdit(),
                onClickDelete,
              }}
              allowImport
              baseURI={baseURI}
              pagination={false}
              paginationCallback={fetchData}
              count={data.count}
              currentPage={currentPage}
            />
          ) : (
            <Col span={24} style={{ height: '100%' }}>
              <Card
                className="form-container"
                title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Ciudadano`}
                extra={(
                  <FormSubmitControls
                    onFinish={basicKeys
                      .concat(complexForms.map((e) => e.key))
                      .includes(currentTabKey)
                      ? handleOnFinish : null}
                    onCancel={onCancel}
                    mutable={mutable}
                    allowSaveAndContinue
                    onCancelText="Cancelar"
                  />
                  )}
                bordered={false}
              >
                <Tabs
                  onChange={mutable ? onChangeTabKey : setCurrentTabKey}
                  activeKey={currentTabKey}
                  type="card"
                >
                  <TabPane
                    tab={(
                      <span>
                        <InfoCircleOutlined />
                        Información General
                      </span>

                      )}
                    key="general"
                    forceRender
                  >
                    <Form
                      name="general"
                      layout="vertical"
                      form={form}
                      onFinish={onFinish}
                      scrollToFirstError
                    >
                      <Row gutter={10}>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="clave_ciudadana"
                            rules={rules.required}
                            label="Clave ciudadana"
                            hasFeedback
                          >
                            <Input disabled={selectedRowKeys.length} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="nombre"
                            rules={rules.required}
                            label="Nombre"
                            hasFeedback
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="apellido_paterno"
                            rules={rules.required}
                            label="Apellido paterno"
                            hasFeedback
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="apellido_materno"
                            rules={rules.apellido_materno}
                            label="Apellido materno"
                            hasFeedback
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={24} xs={24}>
                          <Form.Item
                            label="Género"
                            name="genero"
                          >
                            <Select dataSource={generos} />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={24} xs={24}>
                          <Form.Item
                            label="Estado civil"
                            name="estado_civil"
                          >
                            <Select dataSource={estadosCiviles} />
                          </Form.Item>
                        </Col>
                        <LadaNumero
                          form={form}
                          rules={rules}
                          fullSize
                          names={{
                            lada: 'lada',
                            numero_de_telefono: 'numero_de_celular',
                          }}
                          celular
                          breakPoints={{
                            md: 24,
                            lg: 12,
                            xl: 8,
                            xxl: 8,
                          }}
                        />
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="email"
                            rules={rules.email}
                            label="Correo electrónico"
                            hasFeedback
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="email_alternativo"
                            rules={rules.email_alternativo}
                            label="Correo alternativo"
                            hasFeedback
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item hidden>
                        <Button htmlType="submit" />
                      </Form.Item>
                    </Form>
                  </TabPane>
                  <TabPane
                    tab={(
                      <>
                        <InfoCircleOutlined />
                        Información del Ciudadano
                      </>
                      )}
                    key="info"
                    forceRender
                  >
                    <Form
                      name="info"
                      layout="vertical"
                      form={formInfo}
                      onFinish={onFinish}
                      scrollToFirstError
                    >
                      <Row gutter={10} style={{ width: '100%' }}>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="RFC"
                            rules={rules.RFC}
                            label="RFC"
                            hasFeedback
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="CURP"
                            label="CURP"
                            hasFeedback
                            rules={rules.curp}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="INE"
                            rules={rules.INE}
                            label="INE"
                            hasFeedback
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="licencia_de_conducir"
                            label="Licencia de Conducir"
                            hasFeedback
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="fecha_nacimiento"
                            label="Fecha de nacimiento"
                            hasFeedback
                          >
                            <DatePicker
                              placeholder=""
                              format="DD/MM/YYYY"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <AvatarUploader
                            form={form}
                            imageURL={form.getFieldValue('foto')}
                          />
                        </Col>
                      </Row>
                      <Form.Item hidden>
                        <Button htmlType="submit" />
                      </Form.Item>
                    </Form>
                  </TabPane>
                  <TabPane
                    tab={(
                      <>
                        <EnvironmentOutlined />
                        Dirección
                      </>
                    )}
                    key="direccion"
                    forceRender
                    disabled={!selectedRowKeys.length}
                  >
                    <Direccion
                      currentURI={baseURI}
                      parentID={selectedRowKeys[0]}
                      setLoading={setLoading}
                      onError={onError}
                      setFormAddress={setFormAddress}
                      setCurrentTabKey={setCurrentTabKey}
                      addressPropName="direccion"
                      callback={(values, _continue) => {
                        if (!_continue) {
                          onCancel();
                        } else {
                          const [key] = selectedRowKeys;
                          const clone = [...data];
                          const mappedData = clone.map((e) => (e.id === key
                            ? ({ ...e, direccion: values?.id }) : e));
                          setData(mappedData);
                        }
                      }}
                    />
                  </TabPane>
                </Tabs>
              </Card>
            </Col>
          )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`Ciudadano ${form.getFieldValue('email')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
};

export default Ciudadano;
