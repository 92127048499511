import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import socketio from 'socket.io-client';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
// import socketEvents from 'utils//sockets';
import API from 'utils/api';
import {
  UPDATE_ENTIDAD_INFO,
  UPDATE_USER_INFO,
  SET_PERIODO_FISCAL_SELECTED,
} from 'store/reducers/auth';

import AuthRoute from 'components/AuthRoute';
import { routes } from 'routes/index';
// import Landing from 'views/Landing/index';
import Login from 'views/Auth/Login/index';
import { onSuccess, onError } from 'utils/handlers';
import ForgotPassword from 'views/Auth/ForgotPassword';
import RecuperacionDeContrasena from 'views/Auth/RecuperacionDeContrasena';
import ActivarCuenta from 'views/Auth/ActivarCuenta';
import RestablecerContrasena from 'views/Auth/RestablecerContrasena';
import CancelarRestablecerContraseña from 'views/Auth/CancelarRestablecerContrasena';
import NotFound from './NotFound';
import Layout from './Layout';

const AppRouter = () => {
  const dispatch = useDispatch();
  const { periodoFiscalSelected, token } = useSelector(({ auth }) => auth);
  const { isAuthenticated } = useSelector(({ auth }) => auth);
  // const io = useRef();

  // useEffect(() => {
  //   if (token) {
  //     if (!io.current) {
  //       io.current = socketio('https://websockets.migob.mx');
  //     }
  //     socketEvents(io.current, token);
  //   } else {
  //     io.current = null;
  //   }
  // }, [token]);

  useEffect(() => {
    const updateInfo = async () => {
      try {
        const response = await API.get('usuarios/id/');
        const { data } = response;
        const { entidad, roles } = data;
        delete data.roles;
        delete data.corte;
        delete data.entidad;
        if (response?.status === 200) {
          dispatch({
            type: UPDATE_USER_INFO,
            payload: {
              user: data,
              roles,
            },
          });
          dispatch({
            type: UPDATE_ENTIDAD_INFO,
            entidad,
          });
          const selected = { ...periodoFiscalSelected };
          const { periodo_fiscal_vigente } = entidad;
          if (selected?.vigente && selected?.id !== periodo_fiscal_vigente?.id) {
            selected.vigente = false;
          } else if (!selected?.vigente && selected?.id === periodo_fiscal_vigente?.id) {
            selected.vigente = true;
          }
          if (selected?.vigente !== periodoFiscalSelected?.vigente) {
            dispatch({
              type: SET_PERIODO_FISCAL_SELECTED,
              payload: selected,
            });
          }
        }
      } catch (err) {
        onError(err);
      }
    };
    if (token) {
      updateInfo();
    }
    // eslint-disable-next-line
  }, [token]);
  const getRoutes = (_routes, parentPath = '') => _routes.map((r) => {
    if (r.children) {
      return getRoutes(r.children, parentPath + r.path);
    }
    if (r.withoutLayout) {
      return (
        <AuthRoute path={parentPath + r.path} type={r.type} key={parentPath + r.path} exact>
          <r.component onSuccess={onSuccess} onError={onError} />
        </AuthRoute>
      );
    }
    return (
      <AuthRoute path={parentPath + r.path} type={r.type} key={parentPath + r.path} exact>
        <Layout onSuccess={onSuccess} onError={onError}>
          <r.component onSuccess={onSuccess} onError={onError} />
        </Layout>
      </AuthRoute>
    );
  });

  const layoutRoutes = getRoutes(routes.filter((e, idx) => idx !== routes.length - 1));
  const restRoutes = getRoutes([routes[routes.length - 1]]);

  return (
    <Router>
      <div className="app-container">
        <Switch>
          {layoutRoutes}
          <Redirect exact from="/" to={isAuthenticated ? '/dashboard' : '/inicio-de-sesion'} />
          {/* <Route path="/" exact>
            <Redirect to={{ pathname: '/inicio-de-sesion' }} />
          </Route> */}
          <AuthRoute path="/inicio-de-sesion">
            <Login />
          </AuthRoute>
          <AuthRoute path="/recuperar-contrasena">
            <ForgotPassword />
          </AuthRoute>
          <AuthRoute path="/recuperacion-de-contrasena">
            <RecuperacionDeContrasena />
          </AuthRoute>
          <AuthRoute path="/activar-cuenta">
            <ActivarCuenta />
          </AuthRoute>
          <AuthRoute path="/restablecer-contrasena">
            <RestablecerContrasena />
          </AuthRoute>
          <AuthRoute path="/cancelar-restablecer-contrasena">
            <CancelarRestablecerContraseña />
          </AuthRoute>
          {restRoutes}
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default AppRouter;
