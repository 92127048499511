/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  TeamOutlined,
} from '@ant-design/icons';

// import blank from './blank';
import Contribuyente from 'views/Ciudadano/Contribuyente';

export default {
  title: 'Ciudadanos',
  icon: () => <TeamOutlined />,
  showInSidebar: true,
  component: Contribuyente,
  type: 'private',

};
