import React, { useState, useEffect, useRef } from 'react';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Input,
  Card,
} from 'antd';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import { onSuccess, onError } from 'utils/handlers';
import ModalDelete from 'components/ModalDelete';
import getFormatedValues, { formatReceived, hasFiles } from 'utils/formatValues';
import Select from 'components/Select';
import NumericInput from 'components/NumericInput';
import Uploader from 'components/Uploader';
import FundamentosTransfer from 'components/Catalogos/FundamentosTransfer';
import { getRequisito } from 'api/catalogos/requisitos';

const baseURI = '/tramites/requisitos/';

const Requisitos = () => {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [hasVigencia, setHasVigencia] = useState(false);
  const [allowsEdoc, setAllowsEdoc] = useState(false);
  const [requisitosTemp, setRequisitosTemp] = useState({ results: [] });
  const requisitos = useRef({ results: [] });
  const [emisorDocumento, setEmisorDocumento] = useState([]);
  const [tipoDeRequisito, setTipoDeRequisito] = useState([]);
  const [listaDeAcceso, setListaDeAcceso] = useState([]);
  const [docParaTramite, setDocParaTramite] = useState([]);
  const [institucionDeOrigen, setInsitucionDeOrigen] = useState([]);
  const [unidadPlazo, setUnidadPlazo] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI);
      if (response?.status === 200) {
        setData(formatReceived(response.data));
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const getRequisitos = async (params = null) => {
    try {
      const resCatReq = await API.get('/catalogos/catalogos-de-requisitos', { params });
      return {
        ...resCatReq.data,
        results: formatReceived(resCatReq.data.results),
      };
    } catch (error) {
      onError(error, setLoading);
      return { results: [] };
    }
  };

  useEffect(() => {
    let mounted = true;
    const fetchAll = async () => {
      try {
        setLoading(true);
        const resPlazo = await API.get('/catalogos/unidades-de-plazo');
        const resDocTramite = await API.get('/empresas/tipos-de-documento-para-tramites/');
        const resIP = await API.get('/tramites/tipos-de-accesos/');
        const resEmisores = await API.get('tramites/emisores-de-documentos/');
        const resInstitucion = await API.get('/tramites/instituciones-de-origen/');
        const resTipoReq = await API.get('/tramites/tipos-de-requisitos/');
        if (mounted) {
          setUnidadPlazo(resPlazo.data || []);
          setDocParaTramite(resDocTramite.data || []);
          setListaDeAcceso(resIP.data || []);
          setEmisorDocumento(resEmisores.data || []);
          setInsitucionDeOrigen(resInstitucion.data || []);
          setTipoDeRequisito(resTipoReq.data || []);
          await fetchData();
          setLoading(false);
        }
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();
    return () => { mounted = false; };
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
    setVisibleAlert(false);
    requisitos.current = { results: [] };
    setRequisitosTemp({ results: [] });
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const files = ['documento_anexo'];
      const config = hasFiles(values, files) ? {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      } : {};
      const formattedvalues = getFormatedValues(values, {
        files,
        formData: config.headers,
        clean: true,
      });
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, formattedvalues, config);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        await form.validateFields();
        const response = await API.patch(`${baseURI}${key}/`, formattedvalues, config);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickAdd = () => {
    setVisible(true);
    setSelectedRowKeys([]);
    form.resetFields();
  };

  const _getRequisito = async (id) => {
    if (id) {
      const requisito = await getRequisito(id);
      setRequisitosTemp({
        count: 1,
        next: null,
        previous: null,
        results: [requisito],
      });
    }
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const response = await API.get(`${baseURI}${key}`);
      const { fundamento_legal, ...values } = response.data;
      _getRequisito(response.data.catalogo_de_requisitos);
      if (fundamento_legal?.length) {
        const promises = [];
        fundamento_legal.forEach((id) => {
          const res = API.get(`/configuracion/fundamentos-legales/${id}`);
          promises.push(res);
        });
        const responses = promises.length ? await Promise.all(promises) : [];
        const fundamentos = responses.map((fundamento) => fundamento.data);
        form.setFieldsValue({
          fundamento_legal,
          fundamento_legal_objects: fundamentos,
        });
      }
      form.setFieldsValue(values);
      setHasVigencia(response.tiene_vigencia);

      setLoading(false);
      setVisible(true);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const allowedExtensions = [
    'pdf', 'png', 'jpg',
  ];

  const columns = [
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 300,
    },

    {
      titleText: 'Tipo de Requisito',
      dataIndex: 'tipo_de_requisito',
      key: 'tipo_de_requisito',
      width: 300,
      render: (val) => (tipoDeRequisito.find((item) => item.id === val)?.descripcion),
    },
    {
      titleText: 'Emisor',
      dataIndex: 'emisor_del_documento',
      key: 'emisor_del_documento',
      width: 300,
      render: (val) => emisorDocumento.find((item) => item.id === val)?.descripcion,
    },
    {
      titleText: 'Observaciones',
      dataIndex: 'observaciones',
      key: 'observaciones',
      width: 300,
    },
  ];
  const required = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [
      required,
    ],
    url: [
      {
        type: 'url',
        message: 'Ingrese una URL válida',
      },

    ],
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            mobileColIndex={0}
            rowKey="id"
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            allowExport={false}
          />
        ) : (
          <Card
            bordered={false}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
              />
            )}
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Requisito`}
          >
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              scrollToFirstError
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="catalogo_de_requisitos"
                    label="Catálogo de Requisitos"
                    hasFeedback
                    rules={rules.required}
                  >
                    <Select
                      dataSource={
                        requisitosTemp.results?.length
                          ? requisitosTemp : requisitos.current
                      }
                      usingExternalDataSource={Boolean(requisitosTemp.results?.length)}
                      onClear={() => {
                        form.resetFields(['conceptos_de_ingreso']);
                        setRequisitosTemp({ results: [] });
                      }}
                      pagination={{
                        getter: getRequisitos,
                        setter: (dataSource) => {
                          requisitos.current = dataSource;
                        },
                        config: { auto: true, qName: 'nombre' },
                      }}
                      labelProp="nombre"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={16}>
                  <Form.Item
                    name="descripcion"
                    label="Nombre"
                    hasFeedback
                    rules={rules.required}
                  >
                    <Input allowClear />

                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="url"
                    rules={rules.url}
                    label="URL"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Cantidad de Originales"
                    name="cantidad_de_originales"
                    required
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Cantidad de Copias"
                    name="cantidad_de_copias"
                    required
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="tipo_de_requisito"
                    label="Tipo de Requisito"
                    hasFeedback
                    rules={rules.required}

                  >
                    <Select dataSource={tipoDeRequisito} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="uso_adicional"
                    label="Uso Adicional"
                    hasFeedback
                    tooltip={{
                      title: 'Uso adicional en procesos internos',
                    }}
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="institucion_de_origen"
                    label="Institución de Origen"
                    hasFeedback
                  >
                    <Select dataSource={institucionDeOrigen} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="lista_de_acceso"
                    label="Forma de acceso electrónico"
                    hasFeedback
                    tooltip={{
                      title: 'Estación de trabajo a la que se le permite el acceso',
                    }}
                  >
                    <Select dataSource={listaDeAcceso} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="emisor_del_documento"
                    label="Emisor del Documento"
                    hasFeedback
                  >
                    <Select dataSource={emisorDocumento} />

                  </Form.Item>
                </Col>
                {!allowsEdoc ? (
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="permite_documento_electronico"
                      label="¿Permite Documento Electrónico?"
                      hasFeedback
                      tooltip={{
                        title: '¿Se puede recibir electrónicamente?',
                      }}
                    >
                      <Select onChange={setAllowsEdoc} trueFalse />
                    </Form.Item>
                  </Col>
                ) : (
                  <>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="permite_documento_electronico"
                        label="¿Recepción electrónica?"
                        hasFeedback
                      >
                        <Select onChange={setAllowsEdoc} trueFalse />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="requiere_almacenar_datos"
                        label="¿Requiere Almacenar Datos?"
                        hasFeedback
                        tooltip={{
                          title: '¿Requiere almacenar el dato?',
                        }}
                      >
                        <Select trueFalse allowClear />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="proteccion_de_datos_personales"
                        label="¿Proteger Datos Personales?"
                        hasFeedback
                      >
                        <Select trueFalse allowClear />
                      </Form.Item>
                    </Col>
                    <Uploader
                      title="Documento Anexo"
                      limitMB={50}
                      file={form.getFieldValue('documento_anexo')}
                      formItemName="documento_anexo"
                      allowedExtensions={allowedExtensions}
                      onError={onError}
                      tooltip={{
                        title: `Extensiones Permitidas para el Documento: ${allowedExtensions}`,
                      }}
                    />
                  </>
                )}
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="tipo_de_documento_para_tramite"
                    label="Tipo de Documento para Trámite"
                    hasFeedback
                  >
                    <Select dataSource={docParaTramite} labelProp="nombre" />

                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="tiene_vigencia"
                    label="¿Tiene Vigencia?"
                    hasFeedback
                  >
                    <Select onChange={setHasVigencia} trueFalse />

                  </Form.Item>
                </Col>
                {hasVigencia && (
                  <>
                    <Col
                      xs={24}
                      sm={24}
                      md={8}
                    >
                      <Form.Item
                        name="vigencia_en_dias"
                        label="Vigencia"
                      >
                        <NumericInput
                          required
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}

                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="parte_del_formato"
                    label="¿Parte del formato?"
                    tooltip="Forma parte de un formato: si/no"
                  >
                    <Select trueFalse />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="unidad_de_plazo"
                    label="Unidad de Plazo"
                    tooltip="Tiempo promedio en consegiur requisito para su presentación:
                    Tipo"
                  >
                    <Select dataSource={unidadPlazo} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    name="tiempo_promedio_requisito"
                    label="Tiempo Promedio del Requisito"
                    tooltip="Tiempo promedio en conseguir el requisito para su presentación:
                    Número de Días"
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="visto_bueno"
                    label="¿Requiere visto bueno?"
                    tooltip="Es necesario alguna firma, validación certificación, autorización o
                    visto bueno de un tercero."
                  >
                    <Select trueFalse />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="requisito_de_un_tramite"
                    label="¿Requisito de un Trámite?"
                    tooltip="El requisito solicitado es un trámite que se debe realizar'
                    con alguna dependencia gubernamental."
                  >
                    <Select trueFalse />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    name="observaciones"
                    label="Breve Descripción del Documento"
                  >
                    <Input.TextArea allowClear autoSize={{ minRows: 2, maxRows: 4 }} />
                  </Form.Item>
                </Col>
                <FundamentosTransfer
                  formFundamento={form}
                />
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content={`Requisito ${form.getFieldValue('descripcion')}`}
        />
      </Spin>
    </Row>
  );
};
export default Requisitos;
