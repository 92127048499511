import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { Layout as AntLayout, Grid, Drawer } from 'antd';
import { useSelector } from 'react-redux';
import GlobalStyles from './global-styles';
import Header, { MenuItems, HEADER_HEIGHT } from './Header';
import Footer from './Footer';

const Layout = ({
  children,
  entidad,
  setEditing,
  editing,
  theme,
}) => {
  const _appTheme = useSelector(({ app }) => app.theme);
  const appTheme = entidad?.tema || {
    color_de_fondo_primario: _appTheme.backgroundColor,
    color_de_fondo_secundario: _appTheme.activeBackgroundColor,
    color_de_texto_primario: _appTheme.backgroundColor,
    color_de_texto_secundario: _appTheme.primaryText,
  };
  const screens = Grid.useBreakpoint();
  const [collapsed, setCollapsed] = useState(true);
  const [selectedKey, setSelectedey] = useState('home');

  useEffect(() => {
    const mainScrollable = document.querySelector('#mainScrollable');
    const handleScroll = () => {
      const { scrollTop } = mainScrollable;
      const windowHeigth = document.body.clientHeight - HEADER_HEIGHT;
      let key = null;
      const sections = mainScrollable.querySelectorAll('.section');
      sections.forEach((section) => {
        const sectionHeigth = section.offsetHeight;
        const sectionTop = section.offsetTop;
        const sectionIsVisible = scrollTop > sectionTop - windowHeigth
          && scrollTop < sectionTop + windowHeigth;
        const screenMiddle = scrollTop + (windowHeigth / 2);
        const sectionAboveMiddleScreen = screenMiddle > sectionTop
          && screenMiddle < sectionTop + sectionHeigth;
        if (!key && sectionIsVisible && sectionAboveMiddleScreen) {
          key = section.id;
        }
      });
      if (key !== selectedKey || (!key && selectedKey === 'about')) {
        setSelectedey(key || 'home');
      }
    };
    mainScrollable.addEventListener('scroll', handleScroll);
    return () => mainScrollable.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <ThemeProvider theme={theme || appTheme}>
      <GlobalStyles />
      <AntLayout style={{
        height: 'auto',
        minHeight: '100vh',
        overflow: 'hidden',
      }}
      >
        <Header
          entidad={entidad}
          setCollapsed={setCollapsed}
          collapsed={collapsed}
          selectedKey={selectedKey}
          editing={editing}
          setEditing={setEditing}
        />
        <Content style={{ overflow: 'hidden' }}>
          { children }
        </Content>
        <Footer entidad={entidad} editing={editing} />
      </AntLayout>
      {!screens.lg && (
        <Drawer
          title="Menú"
          placement="left"
          open={!collapsed && screens.lg === false}
          onClose={() => setCollapsed(true)}
          bodyStyle={{ padding: 0 }}
        >
          <MenuItems
            mode="vertical"
            setCollapsed={setCollapsed}
            selectedKey={selectedKey}
            collapsed={collapsed}
          />
        </Drawer>
      )}
    </ThemeProvider>
  );
};

const Content = styled(AntLayout.Content)`
  left: 0;
  width: 100%;
  padding: 0;
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  entidad: PropTypes.shape({
    logo: PropTypes.string,
    tema: PropTypes.shape({
      color_de_fondo_primario: PropTypes.string,
      color_de_fondo_secundario: PropTypes.string,
      color_de_texto_primario: PropTypes.string,
      color_de_texto_secundario: PropTypes.string,
    }),
    external_link: PropTypes.string,
  }).isRequired,
  setEditing: PropTypes.func.isRequired,
  editing: PropTypes.bool,
  theme: PropTypes.shape({
    color_de_fondo_primario: PropTypes.string,
    color_de_fondo_secundario: PropTypes.string,
    color_de_texto_primario: PropTypes.string,
    color_de_texto_secundario: PropTypes.string,
  }),
};

Layout.defaultProps = {
  editing: false,
  theme: null,
};

export default Layout;
