import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Modal,
} from 'antd';

import { SET_PADRONES } from 'store/reducers/catalogs';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import { onSuccess, onError } from 'utils/handlers';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import { useSelector } from 'react-redux';
import fetchSome from 'utils/fetchSome';

const baseURI = 'tramites/hechos-vitales/';

const TableHechoVital = ({
  selectedRowKey,
  callback,
}) => {
  const [formTable] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [catTramite, setCatTramite] = useState([]);
  const [beneficiarios, setBeneficiarios] = useState([]);
  const padrones = useSelector(({ catalogs }) => catalogs.padrones);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI, {
        params: {
          plantilla_de_tramite: selectedRowKey,
        },
      });

      if (response?.status === 200) {
        setData(response.data);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);

      if (!padrones.length) {
        await fetchSome('catalogos/padrones/', SET_PADRONES);
      }

      const resCatTramite = await API.get('/tramites/categorias-de-tramites/');
      setCatTramite(resCatTramite.data || []);

      const resBeneficiarios = await API.get('/tramites/tipos-de-beneficiarios/');
      setBeneficiarios(resBeneficiarios.data || []);

      if (selectedRowKey) {
        await fetchData();
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();

    return () => API.source.cancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    formTable.resetFields();
    setVisibleAlert(false);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await formTable.validateFields();
      const values = formTable.getFieldsValue();
      const [key] = selectedRowKeys;
      if (selectedRowKey) {
        values.plantilla_de_tramite = selectedRowKey;
        if (!key) {
          const response = await API.post(baseURI, values);
          if (response?.status === 201) {
            onSuccess(response, 'Agregado correctamente');
            onCancel();
            await fetchData();
          }
        } else {
          const response = await API.put(`${baseURI}${key}/`, values);
          if (response?.status === 200) {
            onSuccess(response, 'Actualizado correctamente');
            onCancel();
            await fetchData();
          }
        }
      } else {
        const clone = [...data];
        if (!key) {
          clone.push({
            ...values,
            key: clone.length + 1,
          });
          setData(clone);
          callback(clone);
          onCancel();
        } else {
          const res = clone.map((item) => (item.key === key ? { ...values, key } : item));
          setData(res);
          callback(res);
          onCancel();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      if (selectedRowKey) {
        if (key) {
          const response = await API.delete(`${baseURI}${key}/`);
          if (response?.status === 204) {
            onSuccess(response, 'Eliminado correctamente');
            onCancel();
            await fetchData();
          }
        }
      } else {
        const clone = data.filter((item) => item.key !== key);
        const normalized = clone.map((item, idx) => ({ ...item, key: idx }));
        setData(normalized);
        callback(normalized);
        onCancel();
        setVisibleAlert(false);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickAdd = () => {
    setVisible(true);
    setSelectedRowKeys([]);
    formTable.resetFields();
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const handleOnRowClick = (record) => {
    const _key = selectedRowKey ? record.id : record.key;
    setSelectedRowKeys([_key]);
    formTable.setFieldsValue(record);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const columns = [
    {
      titleText: 'El trámite va dirigido a:',
      dataIndex: 'beneficiario',
      key: 'beneficiario',
      width: 300,
      render: (val) => padrones.find((e) => e.id === val)?.descripcion,
    },
    {
      titleText: 'Beneficiarios del Trámite',
      dataIndex: 'beneficiarios_del_tramite',
      key: 'beneficiarios_del_tramite',
      width: 300,
      render: (val) => {
        const _beneficiario = val.map((e) => beneficiarios.find((i) => i.id === e)?.descripcion);
        return _beneficiario.map((e) => ` ${e}`).toString();
      },
    },
    {
      titleText: 'Categoría del Trámite',
      dataIndex: 'categoria_del_tramite',
      key: 'categoria_del_tramite',
      width: 300,
      render: (val) => {
        const _categoria = val.map((e) => catTramite.find((i) => i.id === e)?.descripcion);
        return _categoria.map((e) => ` ${e}`).toString();
      },
    },

  ];

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es Requerido',
      },
    ],
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            mobileColIndex={0}
            rowKey={selectedRowKey ? 'id' : 'key'}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            allowImport
            baseURI={baseURI}
          />
        ) : (
          <Modal
            open={visible}
            closable={false}
            footer={null}
            title={(
              <FormSubmitControls
                label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Hecho Vital`}
                onFinish={onFinish}
                onCancel={onCancel}
                loading={loading}
              />
        )}
            keyboard={!loading}
            maskClosable={!loading}
            onCancel={onCancel}
            forceRender
          >
            <Form
              layout="vertical"
              form={formTable}
              onFinish={onFinish}
              scrollToFirstError
            >
              <Row gutter={10}>
                <Col span={24}>
                  <Form.Item
                    name="beneficiario"
                    rules={rules.required}
                    label="El trámite va dirigido a:"
                    hasFeedback
                  >
                    <Select dataSource={padrones} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="beneficiarios_del_tramite"
                    rules={rules.required}
                    label="Beneficiarios del Trámite"
                    hasFeedback
                  >
                    <Select dataSource={beneficiarios} mode="multiple" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="categoria_del_tramite"
                    rules={rules.required}
                    label="Categoría del Trámite"
                    hasFeedback
                  >
                    <Select dataSource={catTramite} mode="multiple" />
                  </Form.Item>
                </Col>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          </Modal>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content="El Hecho Vital seleccionado"
        />
      </Spin>
    </Row>
  );
};
TableHechoVital.propTypes = {

  selectedRowKey: PropTypes.number,
  callback: PropTypes.func.isRequired,

};
TableHechoVital.defaultProps = {
  selectedRowKey: null,
};

export default TableHechoVital;
