import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Col,
} from 'antd';
import PropTypes from 'prop-types';
import Select from 'components/Select';
import { getLadas } from 'api/usuarios';
import { toInteger } from 'utils/normalizers';

const LadaNumero = ({
  form,
  names,
  fullSize,
  disabled,
  breakPoints: bPoints,
  ladaOnly,
  rules: _rules,
  hasFeedback,
  required,
  tooltip,
  label,
  validateTrigger,
}) => {
  const ladas = useSelector(({ catalogs }) => catalogs.ladas);
  const ladaDefaultID = useSelector(({ auth }) => auth.entidad?.lada);
  const ladaDefault = ladas.find((e) => e.id === ladaDefaultID);
  const collapsedSidebar = useSelector(({ app }) => app.collapsedSidebar);

  useEffect(() => {
    getLadas();
  }, []);

  const extraRules = _rules?.[names?.numero_de_telefono]?.length
    ? _rules?.[names?.numero_de_telefono] : [];

  const rules = {
    [names.numero_de_telefono]: [
      ...extraRules,
      {
        validateTrigger: 'onChange',
        pattern: /^[0-9]{10}$/,
        message: 'Ingrese un número teléfonico válido',
      },
      {
        validator: async (rule, value) => {
          if (!value && form.getFieldValue(names.lada) && required) {
            throw new Error('El campo es requerido.');
          }
        },
      },
    ],
  };

  const mdBp = bPoints.md || (collapsedSidebar ? 8 : 16);
  const lgBp = bPoints.lg || (collapsedSidebar ? 8 : 16);
  const xlBp = bPoints.xl || (collapsedSidebar ? 8 : 16);
  const xxlBp = bPoints.xxl || (collapsedSidebar ? 8 : 16);

  const defaultBreakPoints = {
    xs: 24,
    sm: 24,
    md: mdBp,
    lg: lgBp,
    xl: xlBp,
    xxl: xxlBp,
  };

  const span = { span: 24 };
  const breakPoints = (fullSize ? span : defaultBreakPoints);

  const Lada = () => (
    <Form.Item
      rules={rules[names.lada]}
      name={names.lada}
      hasFeedback={hasFeedback}
      label="Lada"
      required={required}
    >
      <Select
        allowClear
        filterOption={(input, option) => {
          const normalizedInput = input.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '').toLowerCase();
          const children = option?.label?.props?.children;
          const normalizedText = `${children?.[0]}${children?.[2]}`
            .normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
          return normalizedText.indexOf(normalizedInput) >= 0;
        }}
        style={{ width: ladaOnly ? '100%' : 100 }}
        popupClassName="lada-dropdown wrap-text"
        disabled={disabled}
        placeholder={!disabled ? ladaDefault?.lada : ''}
        dataSource={ladas}
        render={(item) => (
          <>
            {item.lada}
            <br />
            {item.pais}
          </>
        )}
      />
    </Form.Item>
  );

  if (ladaOnly) {
    return <Lada />;
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Col {...breakPoints}>
      <Form.Item noStyle>
        <Input.Group className="select-input lada-num" style={{ display: 'flex' }} compact>
          <Lada />
          <Form.Item
            name={names.numero_de_telefono}
            label={label}
            tooltip={tooltip}
            normalize={toInteger}
            rules={rules?.[names?.numero_de_telefono]}
            hasFeedback={hasFeedback}
            validateTrigger={validateTrigger}
            style={{ width: '100%' }}
            required={required}
          >
            <Input
              allowClear
              disabled={disabled}
              maxLength={10}
            />
          </Form.Item>
        </Input.Group>
      </Form.Item>
    </Col>
  );
};

LadaNumero.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
  names: PropTypes.exact({
    lada: PropTypes.string,
    numero_de_telefono: PropTypes.string,
  }),
  tooltip: PropTypes.shape({
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.node,
    ]),
  }),
  fullSize: PropTypes.bool,
  disabled: PropTypes.bool,
  breakPoints: PropTypes.exact({
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    xxl: PropTypes.number,
  }),
  ladaOnly: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  rules: PropTypes.object,
  hasFeedback: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  validateTrigger: PropTypes.string,
};

LadaNumero.defaultProps = {
  names: {
    lada: 'lada',
    numero_de_telefono: 'numero_de_telefono',
  },
  fullSize: false,
  disabled: false,
  breakPoints: {
    md: null,
    lg: null,
    xl: null,
    xxl: null,
  },
  ladaOnly: false,
  rules: null,
  hasFeedback: true,
  required: true,
  tooltip: null,
  label: 'Número',
  validateTrigger: 'onChange',
};

export default LadaNumero;
