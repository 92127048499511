import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Select,
} from 'antd';
import { defaultSeparators } from 'mocks/defaultSeparators';

const { Option } = Select;
const Separador = ({ rule, disabled }) => {
  const [separators, setSeparators] = React.useState(defaultSeparators);

  return (
    <Form.Item
      name="separador"
      label="Separador"
      rules={rule}
    >
      <Select
        allowClear
        showSearch
        optionFilterProp="children"
        filterOption={(input, opt) => opt.children.toLowerCase()
          .indexOf(input.toLowerCase()) >= 0}
        onSearch={(value) => {
          if (value && !separators.some((e) => e.value === value)) {
            setSeparators([...separators, { label: value, value }]);
          }
        }}
        onInputKeyDown={(e) => {
          if (e.keyCode !== 8) {
            if (e.target.value?.length) {
              e.preventDefault();
            }
          }
        }}
        disabled={disabled}
      >
        {separators.map((item) => (
          <Option value={item.value} key={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

Separador.propTypes = {
  rule: PropTypes.arrayOf(PropTypes.shape()),
  disabled: PropTypes.bool,
};

Separador.defaultProps = {
  rule: [],
  disabled: false,
};

export default Separador;
