import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Form,
  Spin,
} from 'antd';

import Select from 'components/Select';
import { onError } from 'utils/handlers';
import API from 'utils/api';
import { formatReceived } from 'utils/formatValues';
import fetchSome from 'utils/fetchSome';
import {
  SET_MOTIVOS_SOLICITUD,
  SET_ESTADOS_SOLICITUD,
} from 'store/reducers/catalogs';
import MultipleMap from 'components/MultipleMap';

const Mapa = () => {
  const {
    motivosSolicitudes,
    estadosSolicitud,
  } = useSelector((state) => state.catalogs);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [locations, setLocations] = useState([]);

  const fetchData = async () => {
    const response = await API.get('/solicitudes/solicitudes/');
    setData(formatReceived(response.data.results));
    setLocations(formatReceived(response.data.results));
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      if (!estadosSolicitud?.length) {
        fetchSome('/solicitudes/estado-de-solicitudes/', SET_ESTADOS_SOLICITUD);
      }

      if (!motivosSolicitudes?.length) {
        fetchSome('/solicitudes/motivos-de-las-solicitudes/', SET_MOTIVOS_SOLICITUD);
      }

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    // eslint-disable-next-line
  },[])

  const filterData = () => {
    const clone = [...data].filter((e) => e.latitud || e.longitud);
    let output = [];
    const values = form.getFieldsValue();
    const motivoMatch = motivosSolicitudes?.find((e) => e?.id === values.motivo_de_la_solicitud);

    if (values.estado_de_la_solicitud) {
      output = clone?.filter((e) => e?.estado_de_la_solicitud === values.estado_de_la_solicitud);
    }
    if (motivoMatch) {
      output = clone.filter((e) => e?.motivo_de_la_solicitud?.descripcion
        ?.includes(motivoMatch?.descripcion));
    }

    if (values.estado_de_la_solicitud || motivoMatch) {
      return output;
    }
    return clone;
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin spinning={loading} tip="Cargando...">
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
          >
            <Row gutter={10}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="estado_de_la_solicitud"
                  label="Estado de la Solicitud"
                >
                  <Select
                    dataSource={estadosSolicitud
                      .sort((a, b) => (a.prioridad < b.prioridad ? -1 : 1))}
                    render={(e) => `${e.nombre}`}
                    onChange={() => {
                      setLocations(filterData());
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="motivo_de_la_solicitud"
                  label="Motivo de la Solicitud"
                >
                  <Select
                    dataSource={motivosSolicitudes}
                    onChange={() => {
                      setLocations(filterData());
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
        <MultipleMap interactive={false} dataSource={locations} height={650} />
      </Spin>
    </Row>
  );
};

export default Mapa;
