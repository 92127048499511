import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Row,
  Col,
  Spin,
  Modal,
  Input,
  message,
} from 'antd';

import { onError, onSuccess } from 'utils/handlers';
import Table from 'components/Table';
import API from 'utils/api';
import FormSubmitControls from 'components/FormSubmitControls';
import Select from 'components/Select';
import ModalDelete from 'components/ModalDelete';
import NumericInput from 'components/NumericInput';
import fetchSome from 'utils/fetchSome';
import {
  SET_CATEGORIAS_DE_REQUISITOS,
  SET_PADRONES,
} from 'store/reducers/catalogs';

const baseURI = 'catalogos/catalogo-de-requisitos-administrador/';
const CatRequisitos = () => {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState({ results: [] });
  const [currentPage, setCurrentPage] = useState(1);
  const {
    estadosGlobales,
    categoriasDeRequisitos,
    padrones,
  } = useSelector((state) => state.catalogs);

  const fetchData = async (page = 1, q = null) => {
    try {
      setLoading(true);
      setCurrentPage(page);
      const res = await API.get(baseURI, { params: { page, q } });
      if (res?.status === 200) {
        setData(res.data);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      if (!categoriasDeRequisitos.length) {
        await fetchSome('/catalogos/categorias-para-tramites/', SET_CATEGORIAS_DE_REQUISITOS);
      }

      if (!padrones.length) {
        await fetchSome('/catalogos/padrones/', SET_PADRONES);
      }
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };
  const onClickEdit = () => {
    const [key] = selectedRowKeys;
    const match = data.find((item) => item.id === key);
    if (match?.modificable) {
      setVisible(true);
    } else {
      message.info('No se puede modificar este registro');
    }
  };

  const onClickDelete = () => {
    const [key] = selectedRowKeys;
    const match = data.find((item) => item.id === key);
    if (match?.modificable) {
      setVisibleAlert(true);
    } else {
      message.info('No se puede eliminar este registro');
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (!selectedRowKeys.length) {
        const response = await API.post('catalogos/catalogo-de-requisitos-administrador/', values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        await form.validateFields();
        const response = await API.put(`${'catalogos/catalogo-de-requisitos-administrador/'}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${'catalogos/catalogo-de-requisitos-administrador/'}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          onCancel();
          await fetchData();
          setVisibleAlert(false);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const columns = [
    {
      titleText: 'Categoría',
      dataIndex: 'categoria',
      key: 'categoria',
      width: 200,
    },

    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 200,
    },
    {
      titleText: 'Número',
      dataIndex: 'numero',
      key: 'numero',
      width: 200,
    },
  ];

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es Requerido',
      },
    ],
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin
        tip="Cargando..."
        spinning={loading}
      >
        <Table
          cols={columns}
          data={data.results}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
          loading={loading}
          pagination={false}
          paginationCallback={fetchData}
          count={data.count}
          currentPage={currentPage}
          rowKey="id"
          allowImport
          baseURI="catalogos/catalogo-de-requisitos-administrador/"
        />
        <Modal
          open={visible}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Requisito`}
              onFinish={onFinish}
              onCancel={onCancel}
              loading={loading}
            />
            )}
          keyboard={!loading}
          maskClosable={!loading}
          footer={null}
          closable={false}
          forceRender
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{ estados_globales: 1 }}

          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="categoria"
                  label="Categoría"
                  rules={rules.required}
                >
                  <Select dataSource={categoriasDeRequisitos} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <NumericInput
                  name="numero"
                  label="Número"
                  required
                />
              </Col>
              <Col span={24}>
                <Form.Item
                  name="padron"
                  rules={rules.required}
                  label="Padrón"
                >
                  <Select dataSource={padrones} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="identificador"
                  label="Identificador"
                  rules={rules.required}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="nombre"
                  label="Nombre"
                  rules={rules.required}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="estados_globales"
                  label="Estado"
                >
                  <Select disabled={!selectedRowKeys.length} dataSource={estadosGlobales} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`Requisito ${form.getFieldValue('nombre')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
};

export default CatRequisitos;
