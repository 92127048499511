/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import {
  Typography,
  Col,
  Button,
  Form,
  Input,
  Row,
  Tooltip,
} from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import API from 'utils/api';
import { themesAsArray } from 'themes';
import { onError, onSuccess } from 'utils/handlers';
import {
  EyeOutlined,
  SaveOutlined,
  QuestionCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { capitalizeFirstLetter } from 'utils/URIFormat';
import { URL_PATTERN } from 'utils/patterns';
import { socialIcons } from './Footer';

const validUrls = {
  facebook: [
    'facebook.com',
    'fb.com',
  ],
  twitter: [
    'twitter.com',
  ],
  instagram: [
    'instagram.com',
  ],
  youtube: [
    'youtube.com',
    'youtu.be',
  ],
  linkedin: [
    'linkedin.com',
  ],
};

export const rules = {
  email: [
    {
      type: 'email',
      message: 'Ingrese un correo electrónico válido',
    },
  ],
  url: [
    { required: true },
    {
      pattern: URL_PATTERN,
      message: 'Ingrese una url válida',
    },
  ],
  link: [
    {
      validator: async (rule, value) => {
        if (value) {
          const [formatedPrefix, ...rest] = value.replace(/(https|http):\/\//, '')
            .replace('www.', '').split('/');
          const url = rest.join('/');
          if (value && !validUrls[rule.field].includes(formatedPrefix)) {
            throw new Error(`Ingrese una url de ${capitalizeFirstLetter(rule.field)} válida`);
          }
          if (value && !URL_PATTERN.test(url)) {
            throw new Error('Ingrese una url válida');
          }
        }
      },
    },
  ],
  hexa: [
    { required: true },
    {
      pattern: /^#(?:[0-9a-fA-F]{3,4}){1,2}$/,
      message: 'Ingrese un hexadecimal válido',
    },
  ],
};

const landingThemes = themesAsArray
  .filter((theme) => theme.backgroundColor !== theme.primaryText).map((theme, idx) => ({
    key: idx,
    color_de_fondo_primario: theme.backgroundColor,
    color_de_fondo_secundario: theme.activeBackgroundColor,
    color_de_texto_primario: theme.backgroundColor,
    color_de_texto_secundario: theme.primaryText,
  }));

export const SiteLink = ({ name = 'url' }) => (
  <Form.Item name={name} label="Link del sitio" rules={rules.url}>
    <Input addonBefore="https://migob.mx/" allowClear />
  </Form.Item>
);

export const FormItemEmail = () => (
  <Form.Item name="correo_electronico" label="Correo eléctronico" rules={rules.email}>
    <Input allowClear />
  </Form.Item>
);

export const ThemeConfig = ({
  form,
  setTheme,
  onValuesChange,
  extra,
  formLayout = 'horizontal',
  entidad = {},
  showSubmitButton = false,
  onFinish,
}) => {
  const [personalizedTheme, setPersonalizedTheme] = useState(false);
  const [selectedColorKey, setSelectedColorKey] = useState(null);
  const _appTheme = useSelector(({ app }) => app.theme);
  const appTheme = {
    key: landingThemes.find((e) => e.color_de_fondo_primario === _appTheme.backgroundColor)?.key,
    color_de_fondo_primario: _appTheme.backgroundColor,
    color_de_fondo_secundario: _appTheme.activeBackgroundColor,
    color_de_texto_primario: _appTheme.backgroundColor,
    color_de_texto_secundario: _appTheme.primaryText,
  };
  const theme = entidad?.tema || appTheme;

  useEffect(() => {
    form.setFieldsValue(theme);
    const _selectedColorKey = entidad?.tema ? landingThemes
      .find((e) => e.color_de_fondo_primario === entidad.tema?.color_de_fondo_primario)?.key
      : appTheme.key;
    setSelectedColorKey(_selectedColorKey);
    if (!_selectedColorKey) {
      setPersonalizedTheme(true);
    }
  }, [entidad]);

  return (
    <div>
      <Form
        name="theme"
        form={form}
        layout={formLayout}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        {extra}
        <Title level={3}>Elegir tema</Title>
        <Row justify="center">
          {landingThemes.map((_theme) => (
            <Col
              span={12}
              style={{
                height: 'auto',
                marginBottom: 5,
                display: 'flex',
                justifyContent: 'center',
              }}
              key={_theme.key}
            >
              <Button
                onClick={() => {
                  setSelectedColorKey(_theme.key);
                  form.setFieldsValue(_theme);
                  setPersonalizedTheme(false);
                  if (setTheme) {
                    setTheme(_theme);
                  }
                }}
                style={{
                  height: 'auto',
                  border: selectedColorKey === _theme.key ? '1px solid black' : 'none',
                }}
              >
                <FlexRow gutter={10}>
                  <Square fill={_theme.color_de_fondo_primario} />
                  <Square fill={_theme.color_de_texto_secundario} style={{ marginLeft: 10 }} />
                </FlexRow>
              </Button>
            </Col>
          ))}
          <Col span={24} style={{ marginBottom: 5 }}>
            <Button
              onClick={() => setPersonalizedTheme(true)}
              block
            >
              Personalizar
            </Button>
          </Col>
        </Row>
        {personalizedTheme && (
          <>
            <br />
            <Title level={4}>
              Personalizar tema
              <Tooltip title="Se admiten formatos #RGB, #RGBA, #RRGGBB y #RRGGBBAA">
                <QuestionCircleOutlined style={{ paddingLeft: 5 }} />
              </Tooltip>
            </Title>
          </>
        )}
        <Row gutter={5}>
          <Col span={12}>
            <Form.Item
              label="Fondo primario"
              name="color_de_fondo_primario"
              rules={rules.hexa}
              normalize={(val) => val.toUpperCase()}
              hidden={!personalizedTheme}
            >
              <Input maxLength={7} allowClear />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Fondo secundario"
              name="color_de_fondo_secundario"
              rules={rules.hexa}
              normalize={(val) => val.toUpperCase()}
              hidden={!personalizedTheme}
            >
              <Input maxLength={7} allowClear />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Texto primario"
              name="color_de_texto_primario"
              rules={rules.hexa}
              normalize={(val) => val.toUpperCase()}
              hidden={!personalizedTheme}
            >
              <Input maxLength={7} allowClear />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Texto secundario"
              name="color_de_texto_secundario"
              rules={rules.hexa}
              normalize={(val) => val.toUpperCase()}
              hidden={!personalizedTheme}
            >
              <Input maxLength={7} allowClear />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item hidden={!showSubmitButton}>
              <Button block htmlType="submit">Guardar</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const Configurations = ({
  setEditing,
  entidad,
  setTheme,
  setLoading,
  fetchData,
}) => {
  const timeoutRef = useRef();
  const [socialLinks, setSocialLinks] = useState([]);
  const [themeForm] = Form.useForm();
  const [pageForm] = Form.useForm();
  const [socialLinksForm] = Form.useForm();

  useEffect(() => {
    const _socialLinks = Object.keys(socialIcons).map((e) => ({
      name: e.replace('Outlined', '').toLowerCase(),
      label: e.replace('Outlined', ''),
      icon: socialIcons[e],
    }));
    setSocialLinks(_socialLinks);
    const linksEntries = entidad.redes_sociales.map((e) => [
      e.icono.replace('Outlined', '').toLowerCase(),
      e.url]);
    socialLinksForm.setFieldsValue(Object.fromEntries(linksEntries));
    pageForm.setFieldsValue({ url: entidad.url, correo_electronico: entidad.email });
  }, [entidad]);

  const onFinishAll = async () => {
    try {
      await themeForm.validateFields();
      await pageForm.validateFields();
      await socialLinksForm.validateFields();

      await themeForm.submit();
      await pageForm.submit();
      await socialLinksForm.submit();
    } catch (err) {
      onError(err);
    }
  };

  const showPreview = async () => themeForm.validateFields().then(() => setEditing(false));

  const onValuesChange = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      try {
        await themeForm.validateFields();
      } catch (er) {
        // validate error
      }
    }, 500);
  };

  const authorize = async () => {
    const response = await API.patch(`tramites/pagina-promocional/${entidad.id}/`, {
      estados_globales: 4,
    });
    onSuccess(response, 'Autorizado correctamente');
    await fetchData();
  };

  const beforeAuthrize = async () => {
    try {
      setLoading(true);
      await authorize();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const authorizeRequirementsPassed = entidad.banner
    && entidad.contacto && !!entidad.about_items.length;

  return (
    <Container>
      <FlexCol>
        <ThemeConfig
          onValuesChange={onValuesChange}
          form={themeForm}
          setTheme={setTheme}
          entidad={entidad}
        />
        <Form name="page" layout="vertical" form={pageForm}>
          <SiteLink rules={rules} />
          <FormItemEmail rules={rules} />
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Form>
        <Title level={4}>Redes sociales</Title>
        <Form
          name="social_links"
          labelCol={{ span: 3 }}
          form={socialLinksForm}
        >
          {socialLinks.map((e) => (
            <Form.Item
              name={e.name}
              label={<e.icon style={{ fontSize: 22 }} />}
              rules={rules.link}
              key={e.name}
            >
              <Input allowClear />
            </Form.Item>
          ))}
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Form>
        <Button onClick={onFinishAll} style={{ marginBottom: 5 }}>
          Guardar
          <SaveOutlined />
        </Button>
        {authorizeRequirementsPassed ? (
          <Button
            disabled={entidad.estados_globales?.id === 4 || !authorizeRequirementsPassed}
            onClick={beforeAuthrize}
            style={{ marginBottom: 5 }}
          >
            Autorizar
            <CheckCircleOutlined />
          </Button>
        ) : (
          <Tooltip title="Es requerido Banner, Sección de Contacto y al menos un elemento en Nosotros">
            <Button style={{ marginBottom: 5 }} disabled block>
              Autorizar
              <CheckCircleOutlined />
            </Button>
          </Tooltip>
        )}
        <Button onClick={showPreview}>
          Vista previa
          <EyeOutlined />
        </Button>
      </FlexCol>
    </Container>
  );
};

const Container = styled.div`
  box-shadow: -9px 0px 19px rgba(0, 0, 0, 0.25);
  background-color: #FFFFFF;
  height: 100vh;
  position: fixed;
  width: 350px;
  padding: 20px;
  overflow-y: scroll;
`;

const Title = styled(Typography.Title)`
  text-align: center;
  margin: 0;
`;

const FlexRow = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const FlexCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Square = styled(Col)`
  width: 30px;
  height: 30px;
  background-color: ${(props) => props.fill};
`;

Configurations.propTypes = {
  setEditing: PropTypes.func.isRequired,
  setTheme: PropTypes.func.isRequired,
  entidad: PropTypes.shape({
    redes_sociales: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    tema: PropTypes.shape({
      color_de_fondo_primario: PropTypes.string,
      color_de_fondo_secundario: PropTypes.string,
      color_de_texto_primario: PropTypes.string,
      color_de_texto_secundario: PropTypes.string,
    }),
  }).isRequired,
  fetchData: PropTypes.func.isRequired,
};

export default Configurations;
