import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select as AntSelect } from 'antd';
import usePaginatedFetcher from 'hooks/usePaginatedFetcher';

const filterOptionDefault = (input, option) => option.label?.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
  .indexOf(input.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()) >= 0;

const getTrueFalseOps = (ops) => [{ label: ops?.[0] || 'Sí', value: true }, { label: ops?.[1] || 'No', value: false }];
const activeOps = [{ label: 'Activo', value: true }, { label: 'Inactivo', value: false }];
export const defRender = (item) => item?.descripcion
  || (typeof item === 'string' || typeof item === 'number' ? item : '');

const Select = ({
  filterOption,
  render,
  dataSource: externalDataSource,
  keyProp,
  labelProp,
  disabled,
  valueProp,
  keyLabelRender,
  trueFalse,
  activeInactive,
  async: _async,
  loading: _loading,
  pagination,
  style,
  usingExternalDataSource,
  onClear,
  onChange,
  ...props
}) => {
  const [
    dataSource,
    fetchDataSource,
    loading,
  ] = pagination ? usePaginatedFetcher(
    pagination.getter,
    {
      ...pagination.config,
      auto: usingExternalDataSource ? false : Boolean(pagination.config?.auto),
    },
  ) : [];
  const ref = useRef(null);
  const page = useRef(1);
  const q = useRef(null);
  const [options, setOptions] = useState([]);

  const keyLabelRenderFunc = (item) => `${item[keyProp]} - ${item[labelProp]}`;

  const getLabel = (e) => {
    if (keyLabelRender) {
      return keyLabelRenderFunc(e);
    }
    if (render) {
      return render(e);
    }
    if (labelProp) {
      return defRender(e[labelProp]);
    }
    return defRender(e);
  };

  const map = (e) => ({
    label: getLabel(e),
    value: e[valueProp],
    disabled: !!e.disabled,
  });

  useEffect(() => {
    const getLabelAsync = async (e) => {
      const val = await render(e);
      return val;
    };
    const asyncMap = async (e) => {
      const label = await getLabelAsync(e);
      return ({
        label,
        value: e[valueProp],
        disabled: !!e.disabled,
      });
    };

    const getOptionsAsync = async () => {
      const _options = _async
        ? await Promise.all([...externalDataSource.map(asyncMap)]) : externalDataSource.map(map);
      setOptions(_options);
    };

    if (!pagination || usingExternalDataSource) {
      if (trueFalse) {
        setOptions(getTrueFalseOps(trueFalse));
      } else if (activeInactive) {
        setOptions(activeOps);
      } else if (_async) {
        getOptionsAsync();
      } else {
        // try {
        const mapped = (externalDataSource?.results || externalDataSource)
          .filter((e) => e).map(map);
        setOptions(mapped);
        // } catch (error) {
        // console.log(externalDataSource);
        // }
      }
    }
  }, [externalDataSource, usingExternalDataSource]);

  useEffect(() => {
    if (pagination && !usingExternalDataSource) {
      if (pagination.config?.selfValue && !dataSource.results.length) {
        pagination.setter(externalDataSource);
        setOptions(externalDataSource.results.map(map));
      } else {
        pagination.setter(dataSource);
        setOptions(dataSource.results.map(map));
      }
    }
  }, [dataSource]);

  const clearRef = () => {
    clearTimeout(ref.current);
    ref.current = null;
  };

  const handleChange = (_q = null) => {
    if (pagination) {
      q.current = _q;
      clearRef();
      ref.current = setTimeout(async () => {
        await fetchDataSource(1, _q, pagination.params);
        clearRef();
      }, 2000);
      page.current = 1;
    }
  };

  useEffect(() => {
    if (pagination && !usingExternalDataSource && !pagination.config?.selfValue) {
      fetchDataSource(1, q.current, pagination.params);
    }
  }, [pagination?.params]);

  return (
    <AntSelect
      showSearch
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      allowClear={!trueFalse}
      onSearch={handleChange}
      onChange={(val) => {
        if (!val) {
          onClear();
        }
        onChange(val);
      }}
      onClear={() => {
        handleChange();
        onClear();
      }}
      disabled={disabled}
      showArrow={!disabled && !trueFalse}
      optionFilterProp="children"
      filterOption={!pagination && filterOption}
      notFoundContent={options.length ? 'No hay coincidencias' : 'No hay datos'}
      options={options}
      popupClassName="wrap-text"
      onPopupScroll={(e) => {
        if (fetchDataSource && dataSource.next) {
          const { scrollHeight, scrollTop, clientHeight } = e.target;
          if ((scrollHeight - Math.ceil(scrollTop) === clientHeight
            && scrollTop !== 0) && dataSource?.next) {
            setTimeout(() => fetchDataSource((dataSource.page || page.current) + 1,
              q.current, pagination.params));
            page.current += 1;
          }
        }
      }}
      loading={_loading || (loading && !pagination?.config?.selfValue)}
      style={{ width: '100%', ...style }}
    />
  );
};

Select.propTypes = {
  filterOption: PropTypes.func,
  dataSource: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      count: PropTypes.number,
      results: PropTypes.arrayOf(PropTypes.shape({})),
    })]),
  render: PropTypes.func,
  valueProp: PropTypes.string,
  keyProp: PropTypes.string,
  disabled: PropTypes.bool,
  keyLabelRender: PropTypes.bool,
  labelProp: PropTypes.string,
  trueFalse: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.string)]),
  activeInactive: PropTypes.bool,
  onClear: PropTypes.func,
  async: PropTypes.bool,
  loading: PropTypes.bool,
  pagination: PropTypes.shape({
    getter: PropTypes.func.isRequired,
    setter: PropTypes.func.isRequired,
    config: PropTypes.shape({
      auto: PropTypes.bool,
      selfValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    params: PropTypes.shape({}),
  }),
  style: PropTypes.shape({}),
  usingExternalDataSource: PropTypes.bool,
  onChange: PropTypes.func,
};

Select.defaultProps = {
  filterOption: filterOptionDefault,
  dataSource: [],
  render: null,
  valueProp: 'id',
  keyProp: 'clave',
  disabled: false,
  keyLabelRender: false,
  labelProp: 'descripcion',
  trueFalse: false,
  activeInactive: false,
  async: false,
  loading: false,
  pagination: null,
  style: {},
  usingExternalDataSource: false,
  onChange: () => { },
  onClear: () => { },
};

export default Select;
