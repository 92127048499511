import React from 'react';
import PropTypes from 'prop-types';
import {
  Pagination as AntPagination, Row,
} from 'antd';

const Pagination = ({
  callback,
  page,
  setPage,
  count,
  showTotal,
}) => (
  <Row
    align="middle"
    justify={showTotal ? 'space-between' : 'start'}
    style={{ width: '100%', marginTop: 15 }}
  >
    {!!showTotal && (
      <b>
        {`Mostrando ${page * 10 - 9}-${page * 10 > count ? count : page * 10} de ${count} `}
        {`${typeof showTotal === 'string' ? showTotal : ''}`}
      </b>
    )}
    <AntPagination
      onChange={(pag) => {
        setPage(pag);
        callback(pag);
      }}
      total={count}
      current={page}
      showSizeChanger={false}
      showQuickJumper={false}
    />
  </Row>
);

Pagination.propTypes = {
  count: PropTypes.number,
  callback: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  q: PropTypes.shape({}),
  showTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

Pagination.defaultProps = {
  count: 0,
  q: null,
  showTotal: false,
};

export default Pagination;
