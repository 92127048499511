import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Modal,
  Spin,
  message,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import { getCodigosPostales, getEstados, getMunicipios } from 'api/catalogos';
import Select from 'components/Select';

const SearchDireccion = ({
  callback,
  setVisible,
  visible,
  addressPropName,
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const estado = Form.useWatch('estado', form);
  const [dataSource, setDataSource] = useState([]);
  const [filteredStates, setFilteredStates] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const municipalities = useRef({ results: [] });

  const s = async (page = 1, q = null) => {
    setLoading(true);
    setCurrentPage(page);
    const { asentamiento, municipio } = form.getFieldsValue();
    const _data = await getCodigosPostales({
      page,
      d_asenta: asentamiento,
      municipio,
      ...q,
    });
    const results = _data.results.map((item) => ({
      ...item,
      key: item.id,
      nom_mun: item.municipio.nom_mun,
      nombre_de_AGEE: item.municipio.estado.nombre_de_AGEE,
    }));
    setDataSource({ ..._data, results });
    setLoading(false);
  };

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      setLoading(true);
      const _estados = await getEstados();
      if (mounted) {
        setFilteredStates(_estados);
      }
      setLoading(false);
    };
    if (visible) {
      fetchData();
    }
    return () => { mounted = false; };
    // eslint-disable-next-line
  }, [visible]);

  const changeState = async (stateID) => {
    setLoading(true);
    form.setFieldsValue({
      estado: stateID,
      municipio: undefined,
    });
    municipalities.current = { results: [] };
    setLoading(false);
  };

  const rules = {
    required: [{ required: true }],
  };

  const columns = [
    {
      titleText: 'Código',
      dataIndex: 'd_codigo',
      key: 'd_codigo',
      render: (text, record) => (
        <Button
          type="link"
          className="primary-text no-color"
          onClick={() => callback(record)}
          style={{ padding: 0 }}
        >
          {text}
        </Button>
      ),
    },
    {
      titleText: 'Asentamiento',
      dataIndex: 'd_asenta',
      key: 'd_asenta',
    },
    {
      titleText: 'Tipo de Asentamiento',
      dataIndex: 'd_tipo_asenta',
      key: 'd_tipo_asenta',
      width: 200,
    },
    {
      titleText: 'Municipio',
      dataIndex: 'nom_mun',
      key: 'nom_mun',
    },
    {
      titleText: 'Estado',
      dataIndex: 'nombre_de_AGEE',
      key: 'nombre_de_AGEE',
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    municipalities.current = { results: [] };
  };

  return (
    <Modal
      width="90%"
      visible={visible}
      closable={false}
      footer={null}
      title={(
        <FormSubmitControls
          label="Búsqueda Código Postal"
          onFinish={async () => {
            const [key] = selectedRowKeys;
            if (key) {
              const _match = await getCodigosPostales({ id: key });
              callback(_match.results);
            } else {
              message.warn('Seleccione un elemento');
            }
          }}
          onCancel={onCancel}
          loading={loading}
        />
      )}
      onCancel={onCancel}
      keyboard={!loading}
      maskClosable={!loading}
      forceRender
      centered
      destroyOnClose
    >
      <Spin
        tip="Cargando..."
        spinning={loading}
      >
        <Form
          name={`search_${addressPropName}`}
          layout="vertical"
          form={form}
          onValuesChange={async (changedValues) => {
            if (changedValues.pais >= 0) {
              // changeCountry(pais);
            } else if (changedValues.estado >= 0) {
              await changeState(changedValues.estado);
            }
          }}
          onFinish={() => s()}
          scrollToFirstError
        >
          <Row gutter={10}>
            <Col className="gutter-row" md={6} sm={24} xs={24}>
              <Form.Item
                label="Estado"
                name="estado"
                rules={rules.required}
              >
                <Select dataSource={filteredStates} labelProp="nombre_de_AGEE" />
              </Form.Item>
            </Col>

            <Col className="gutter-row" md={6} sm={24} xs={24}>
              <Form.Item
                label="Municipio"
                name="municipio"
                rules={rules.required}
              >
                <Select
                  dataSource={municipalities.current}
                  labelProp="nom_mun"
                  pagination={{
                    getter: getMunicipios,
                    setter: (data) => {
                      municipalities.current = data;
                    },
                    params: { estado },
                    config: { auto: false, qName: 'nom_mun' },
                  }}
                  disabled={!estado}
                />
              </Form.Item>
            </Col>

            <Col md={6} sm={24} xs={24}>
              <Form.Item
                label="Asentamiento/Colonia"
                name="asentamiento"
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col md={6} sm={24} xs={24}>
              <Form.Item>
                <Button
                  type="link"
                  htmlType="submit"
                  style={{ marginTop: 20 }}
                  className="primary-on-hover"
                >
                  <SearchOutlined />
                  {' '}
                  Buscar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
      <Col span={24}>
        <Table
          cols={columns}
          mobileCols={[columns[1]]}
          data={dataSource.results}
          dataSource={dataSource}
          noControls
          allowSort={false}
          allowSearch={false}
          rowSelection={rowSelection}
          onRow={(record) => ({
            onDoubleClick: () => callback(record),
            onClick: () => {
              setSelectedRowKeys([record.key]);
            },
          })}
          currentPage={currentPage}
          pagination={false}
          paginationCallback={s}
          count={dataSource.count}
        />
      </Col>
    </Modal>
  );
};

SearchDireccion.propTypes = {
  callback: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  setVisible: PropTypes.func.isRequired,
  addressPropName: PropTypes.string.isRequired,
};

SearchDireccion.defaultProps = {
  visible: false,
};

export default SearchDireccion;
