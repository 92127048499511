/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { AuditOutlined } from '@ant-design/icons';

import SeguimientoDeSolicitud from 'views/Solicitudes/SeguimientoSolicitudes';
import ValidacionDeFichasPorAutorizar from 'views/MiBuzon/ValidacionDeFichasPorAutorizar';
import MisFichasEnProceso from 'views/MiBuzon/MisFichasEnProceso/index';
import MisFichasPublicadas from 'views/MiBuzon/FichasPublicadas/index';
import blank from './blank';

export default {
  title: 'Mi Buzón',
  icon: () => <AuditOutlined />,
  showInSidebar: true,
  type: 'private',
  children: [
    // {
    //   title: 'Ventanilla Unica',
    //   icon: null,
    //   withoutLayout: true,
    //   showInSidebar: true,
    //   component: Ventanilla,
    //   type: 'private',
    // },
    {
      title: 'Validación de Fichas por Autorizar',
      icon: null,
      component: ValidacionDeFichasPorAutorizar,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Mis Fichas en Proceso',
      icon: null,
      component: MisFichasEnProceso,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Fichas Publicadas',
      icon: null,
      component: MisFichasPublicadas,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Mis Oficios',
      icon: null,
      component: blank,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Mis Apoyos Sociales',
      icon: null,
      component: blank,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Mis Incidentes',
      icon: null,
      type: 'private',
      showInSidebar: true,
      children: [
        {
          title: 'Seguimiento',
          icon: null,
          type: 'private',
          showInSidebar: true,
          component: SeguimientoDeSolicitud,
        },
      ],
    },

  ],
};
