import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Spin,
  Col,
  Modal,
  Input,
} from 'antd';
import { useSelector } from 'react-redux';

import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Select from 'components/Select';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import { createClave } from 'utils/createClave';
import { SET_TIPOS_DE_DATO } from 'store/reducers/catalogs';
import fetchSome from 'utils/fetchSome';

const baseURI = '/configuracion/variables/';

const Variables = ({
  mutable,
}) => {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const tiposDeDato = useSelector(({ catalogs }) => catalogs.tiposDeDato);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (!tiposDeDato.length) {
        await fetchSome('/configuracion/tipos-de-datos/', SET_TIPOS_DE_DATO);
      }

      const res = await API.get(baseURI);
      setData(res.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();

      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        await form.validateFields();
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }

      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const onClickAdd = () => {
    onCancel();
    createClave(data, form, 'identificador');
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
    nombre: [
      requiredRule,
      {
        validator: async (rule, value) => {
          if (value) {
            const clone = [...data];
            const match = clone.find((e) => e.nombre === value);
            const num = new RegExp(/^[0-9]/);
            if (num.test(value)) {
              throw new Error('Nombre no válido, no puede comenzar con un número.');
            } else if (!selectedRowKeys.length && match) {
              throw new Error('El Nombre debe ser único');
            }
          }
        },
      },
    ],
    descripcion: [
      requiredRule,
      {
        validator: async (rule, value) => {
          const clone = [...data];
          const match = clone.find((e) => e.descripcion === value);
          if (!selectedRowKeys.length && match) {
            throw new Error('La descripción debe ser única');
          }
        },
      },
    ],
  };

  const columns = [
    {
      titleText: 'Identificador',
      key: 'identificador',
      dataIndex: 'identificador',
    },
    {
      titleText: 'Nombre',
      key: 'nombre',
      dataIndex: 'nombre',
    },
    {
      titleText: 'Descripción',
      key: 'descripcion',
      dataIndex: 'descripcion',
    },
    {
      titleText: 'Tipo de Variable',
      key: 'tipo_de_variable',
      dataIndex: 'tipo_de_variable',
      render: (val) => tiposDeDato.find((e) => e.id === val)?.descripcion,
    },
    {
      titleText: 'Es Script',
      key: 'es_script',
      dataIndex: 'es_script',
      render: (val) => (val ? 'Si' : 'No'),
    },
  ];

  return (
    <>
      <Spin tip="Cargando..." spinning={loading}>
        <br />
        <Table
          cols={columns}
          mobileCols={[columns[1]]}
          data={data}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          controls={{
            onClickAdd,
            onClickDelete,
            onClickEdit,
          }}
          noControls={!mutable}
          allowSort={false}
          allowSearch={false}
          rowKey="id"
        />
        <Modal
          open={visible}
          closable={false}
          footer={null}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Variable`}
              onFinish={onFinish}
              onCancel={onCancel}
              loading={loading}
            />
          )}
          keyboard={!loading}
          maskClosable={!loading}
          onCancel={onCancel}
          forceRender
        >
          <Col span={24}>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              initialValues={{ estados_globales: 1 }}
              name="variables"
            >
              <Col span={24}>
                <Form.Item
                  name="identificador"
                  rules={rules.required}
                  label="Identificador"
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="nombre"
                  rules={rules.nombre}
                  label="Nombre"
                >
                  <Input allowClear autoComplete="off" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="descripcion"
                  rules={rules.descripcion}
                  label="Descripción"
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="tipo_de_variable"
                  rules={rules.required}
                  label="Tipo de Variable"
                >
                  <Select dataSource={tiposDeDato} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="es_script"
                  rules={rules.required}
                  label="¿Es Script?"
                >
                  <Select trueFalse />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="estados_globales"
                  rules={rules.required}
                  label="Estado"
                  hasFeedback
                >
                  <Select
                    disabled={!selectedRowKeys.length}
                    dataSource={estadosGlobales}
                  />
                </Form.Item>
              </Col>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Form>
          </Col>
        </Modal>
      </Spin>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        content="la variable"
      />
    </>
  );
};

Variables.propTypes = {
  mutable: PropTypes.bool.isRequired,
};

export default Variables;
