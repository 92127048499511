import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Spin,
  Row,
  Col,
  Modal,
  message,
} from 'antd';

import {
  SearchOutlined,
  DeleteOutlined,
  ArrowLeftOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { onError } from 'utils/handlers';
import FormSubmitControls from 'components/FormSubmitCustomControls';
import Table from 'components/Table';
import { getUnidadesDeRecaudacion } from 'api/configuracion/unidadesDeRecaudacion';
import { getUsuarios } from 'api/usuarios/usuarios';
import Select from './Select';

const BusquedaAvanzadaCajeros = ({
  visible,
  setVisible,
  callback,
  dataExterno,
  setDataExterno,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [unidadesDeRecaudacion, setUnidadesDeRecaudacion] = useState([]);

  useEffect(() => () => {
    let mounted = true;

    const fetchData = async () => {
      const _unidadesDeRecaudacion = await getUnidadesDeRecaudacion();
      if (mounted) {
        setUnidadesDeRecaudacion(_unidadesDeRecaudacion);
      }
    };
    fetchData();
    return () => { mounted = false; };
  }
  // eslint-disable-next-line
  , [visible]);

  const onCancel = () => {
    setVisible(false);
    setData([]);
    if (setDataExterno) {
      setDataExterno([]);
    }
    setSelectedRowKeys([]);
    form.resetFields();
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      setSelectedRowKeys([]);
      const params = form.getFieldsValue();
      const keys = Object.keys(params);
      const validProps = keys.filter((prop) => params[prop]);
      if (validProps.length) {
        const response = await getUsuarios({ params });
        if (response?.length) {
          setData(response);
        } else {
          message.warn('No se encontró ningún usuario.');
          setData([]);
        }
      } else {
        message.warn('Debe ingresar al menos un campo.');
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onFinishSearch = async (record) => {
    callback(record);
    onCancel();
  };

  const handleLocalSearch = () => {
    const values = form.getFieldsValue();
    const keys = Object.keys(values);
    const validProps = keys.filter((prop) => values[prop]);
    if (validProps.length) {
      const normalizeText = (text = '') => text?.toLowerCase()
        .normalize('NFD').replace(/[\u0300-\u036f]/g, '');

      const validateIncludes = (obj, prop) => {
        if (validProps.includes(prop)) {
          const normalizedValue = normalizeText(obj[prop]);
          const normalizedInput = normalizeText(values[prop]);
          return normalizedValue.includes(normalizedInput);
        }
        return false;
      };

      const validateEquals = (obj, prop) => obj[prop] === values[prop];
      const filtered = data.filter((item) => validateIncludes(item, 'ciudadano')
        || validateEquals(item, 'uso_de_predio'));
      setData(filtered);
    } else {
      message.warn('Debe ingresar al menos un campo');
    }
  };

  useEffect(() => {
    setData(dataExterno);
    // eslint-disable-next-line
  }, [dataExterno]);

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const add = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const match = data.find((e) => e.id === key);
      onFinishSearch(match);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };
  const controls = [
    {
      text: 'Regresar',
      icon: <ArrowLeftOutlined />,
      onClick: () => onCancel(),
    },
    {
      text: 'Limpiar',
      icon: <DeleteOutlined />,
      onClick: () => {
        setData([]);
        form.resetFields();
      },
    },
  ];

  const rules = [
    {
      numero_de_celular: [
        {
          pattern: /^[0-9]{10}$/,
          message: 'Ingrese un número teléfonico válido',
        },
      ],
      email: [
        {
          type: 'email',
        },
      ],
    },
  ];

  const columns = [
    {
      titleText: 'Nombre',
      dataIndex: 'first_name',
      key: 'first_name',
      width: 300,
    },
    {
      titleText: 'Apellido Paterno',
      dataIndex: 'last_name',
      key: 'last_name',
      width: 300,
    },
    {
      titleText: 'Apellido Materno',
      dataIndex: 'second_last_name',
      key: 'second_last_name',
      width: 300,
    },
    {
      titleText: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 300,
    },
  ];

  return (
    <>
      <Modal
        width="90%"
        open={visible}
        closable={false}
        footer={null}
        title={(
          <FormSubmitControls
            label="Búsqueda Avanzada"
            controls={controls}
            loading={loading}
          />
        )}
        onCancel={onCancel}
        maskClosable={!loading}
        keyboard={!loading}
        forceRender
        centered
      >
        <Spin tip="Cargando..." spinning={loading}>
          <Form
            form={form}
            layout="vertical"
            onFinish={dataExterno?.length ? handleLocalSearch : handleSearch}
            name="busquedaAvanzadaCajeros"
          >
            <Row gutter={10}>
              <Col
                xs={24}
                sm={24}
                md={8}
              >
                <Form.Item
                  name="first_name"
                  label="Nombre"
                  hasFeedback
                >

                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
              >
                <Form.Item
                  name="last_name"
                  label="Apellido Paterno"
                  hasFeedback
                >

                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
              >
                <Form.Item
                  name="second_last_name"
                  label="Apellido Materno"
                  hasFeedback

                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
              >
                <Form.Item
                  name="numero_de_empleado"
                  label="Numero de Empleado"
                  hasFeedback

                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
              >
                <Form.Item
                  name="numero_de_empleado"
                  label="Numero de Empleado"
                  hasFeedback

                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              {/* numero_de_celular */}
              <Col
                xs={24}
                sm={24}
                md={8}
              >
                <Form.Item
                  name="numero_de_celular"
                  label="Numero de Teléfono"
                  hasFeedback
                  rules={rules.numero_de_celular}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
              >
                <Form.Item
                  name="correo_electronico"
                  label="Correo Electrónico"
                  hasFeedback
                  rules={rules.email}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
              >
                <Form.Item
                  name="unidad_de_recaudacion"
                  label="Unidad de Recaudación"
                  hasFeedback
                >
                  <Select dataSource={unidadesDeRecaudacion} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[10, 5]} justify="end">
              <Col xs={24} sm={24} md={6} lg={4}>
                <Button block htmlType="submit">
                  <SearchOutlined />
                  Buscar
                </Button>
              </Col>
              <Col xs={24} sm={24} md={6} lg={4}>
                <Button
                  onClick={add}
                  block
                  disabled={!selectedRowKeys.length}
                >
                  <PlusOutlined />
                  Cargar
                </Button>
              </Col>
            </Row>
          </Form>
          <Col span={24}>
            <Table
              cols={columns}
              data={data}
              noControls
              allowSort={false}
              allowSearch={false}
              rowSelection={rowSelection}
              onRow={(record) => ({
                onClick: () => {
                  setSelectedRowKeys([record.id]);
                },
                onDoubleClick: add,
              })}
              rowKey="id"
            />
          </Col>
        </Spin>
      </Modal>
    </>
  );
};

BusquedaAvanzadaCajeros.propTypes = {
  callback: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  dataExterno: PropTypes.arrayOf(PropTypes.shape()),
  setDataExterno: PropTypes.func,
};

BusquedaAvanzadaCajeros.defaultProps = {
  dataExterno: [],
  setDataExterno: null,
};

export default BusquedaAvanzadaCajeros;
