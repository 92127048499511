import React, { useEffect, useState, useMemo } from 'react';
import {
  Row,
  Spin,
  Col,
  Card,
  Segmented,
} from 'antd';
import Table from 'components/Table';
import { onError } from 'utils/handlers';
import API from 'utils/api';
import { EyeOutlined } from '@ant-design/icons';
import { FULL_DATE_FRONTEND_FORMAT, getFullName, toMoment } from 'utils/formatValues';
import FormSubmitControls from 'components/FormSubmitControls';
import Vista from 'views/MiBuzon/MisFichasEnProceso/Vista';
import { isAdmin } from 'utils/permission';
import { useSelector } from 'react-redux';

const baseURI = '/tramites/plantillas-de-retys/';

const FichasPublicadas = () => {
  const user = useSelector(({ auth }) => auth.user);
  const [currentTabKey, setCurrentTabKey] = useState('editar');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const plantilla = useMemo(() => data.find((e) => e.id === selectedRowKeys[0]), [selectedRowKeys]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const params = {};
      if (!isAdmin()) {
        if (user.unidad_responsable) {
          params.plantilla_de_tramites__departamentos = user.unidad_responsable.id;
        } else if (user.unidad_operativa) {
          params.dependencia = user.unidad_operativa.id;
        }
      }
      const _plantillasRetys = (await API.get(
        baseURI,
        {
          params: {
            ...params,
            plantilla_de_tramites__estado_de_ficha__id: 3,
          },
        },
      )).data;
      const _estados = (await API.get('/tramites/estados-de-fichas/')).data;
      const _historiales = (await API.get('/tramites/historiales-de-retys/')).data;
      const procesos = (await API.get('/tramites/procesos-de-tipo-de-tramite/')).data;
      const subProcesos = (await API.get('/tramites/subprocesos-de-tipo-de-tramite/')).data;
      const opciones = (await API.get('/tramites/opciones-de-tipo-de-tramite/')).data;
      const respuestas = (await API.get('/tramites/respuestas-de-tipo-de-tramite/')).data;
      const subOpciones = (await API.get('/tramites/subopciones-de-tipo-de-tramite/')).data;
      const subRespuestas = (await API.get('/tramites/subrespuestas-de-tipo-de-tramite/')).data;
      const contenTypesTramites = (await API.get('/catalogos/content-types-tramites/')).data;

      const _data = _plantillasRetys
        .map(({
          cadena,
          canal_de_presentacion_plantilla,
          casos_de_los_requisitos,
          comentario_general,
          costeo_estandar,
          citerio_de_resolucion_de_tramite,
          documento_de_salida_de_tramite,
          familia_del_tramite_plantilla,
          formato_requerido,
          fundamento_legal,
          fundamento_legal_monto,
          oficina_de_atencion,
          plazo_maximo,
          plazo_prevencion,
          sinonimo,
          subsector_economico_de_plantilla,
          tipo_de_resolucion_plantilla,
          ..._plantilla
        }) => {
          const { plantilla_de_tramites } = _plantilla;
          plantilla_de_tramites.unidad_operativa = plantilla_de_tramites
            .departamentos[0].unidad_operativa;
          const _historial = _historiales
            .filter((e) => e.plantilla_de_tramites === plantilla_de_tramites.id);
          const historial = _historial.map((h) => ({
            ...h,
            fecha_de_creacion: toMoment(h.fecha_de_creacion),
            estado: _estados.find((e) => e.id === h.estado),
          })).reverse();

          const valuesProceso = {};
          if (plantilla_de_tramites.content_type_tramite) {
            const keysByLvl = {
              1: 'tipo_de_tramite_proceso',
              2: 'tipo_de_tramite_sub_proceso',
              3: 'tipo_de_tramite_opciones',
              4: 'tipo_de_tramite_respuesta',
              5: 'tipo_de_tramite_sub_opciones',
              6: 'tipo_de_tramite_sub_respuesta',
            };
            const sourcesByLvl = {
              1: procesos,
              2: subProcesos,
              3: opciones,
              4: respuestas,
              5: subOpciones,
              6: subRespuestas,
            };
            let lvl = contenTypesTramites[contenTypesTramites
              .findIndex((e) => e.id === plantilla_de_tramites.content_type_tramite)].nivel;
            let value = plantilla_de_tramites.object_id_tramite;
            while (lvl) {
              // eslint-disable-next-line no-loop-func
              const match = sourcesByLvl[lvl].find((e) => e.id === value);
              valuesProceso[keysByLvl[lvl]] = match;
              if (lvl !== 1) {
                value = sourcesByLvl[lvl - 1]
                  // eslint-disable-next-line no-loop-func
                  .find((e) => match[keysByLvl[lvl - 1]]?.includes?.(e.id)
                    || match[keysByLvl[lvl - 1]] === e.id).id;
              }
              lvl -= 1;
            }
          }

          return {
            id: _plantilla.id,
            folio: _plantilla.folio || plantilla_de_tramites.homoclave,
            plantilla_de_tramites: {
              ...plantilla_de_tramites,
              ...valuesProceso,
              cadena,
              canal_de_presentacion_plantilla,
              casos_de_los_requisitos,
              comentario_general,
              costeo_estandar,
              citerio_de_resolucion_de_tramite,
              documento_de_salida_de_tramite,
              familia_del_tramite_plantilla,
              formato_requerido,
              fundamento_legal,
              fundamento_legal_monto,
              oficina_de_atencion,
              plazo_maximo,
              plazo_prevencion,
              sinonimo,
              subsector_economico_de_plantilla,
              tipo_de_resolucion_plantilla,
            },
            historial,
            usuario_modifico: historial[0]?.usuario_creo,
            fecha_modificacion: historial[0]?.fecha_de_creacion,
          };
        });
      const filtered = _data
        .filter(({ plantilla_de_tramites }) => plantilla_de_tramites.estado_de_ficha.id === 3);
      setData(filtered);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // eslint-disable-next-line react/prop-types
  const LabelInfo = ({ label, info }) => (
    <>
      <Col xs={12} sm={12} md={4}>
        {label}
        :
      </Col>
      <Col xs={12} sm={12} md={8}>
        {info}
      </Col>
    </>
  );

  const columns = [
    {
      titleText: 'Ficha de Retys',
      dataIndex: 'id',
      key: 'id',
      width: '100%',
      render: (_, record) => (
        <Row style={{ userSelect: 'none' }}>
          <LabelInfo
            label="Nombre"
            info={record.plantilla_de_tramites.nombre}
          />
          <LabelInfo
            label="Fecha"
            info={record.fecha_modificacion?.format(FULL_DATE_FRONTEND_FORMAT)}
          />
          <LabelInfo
            label="Folio"
            info={record.folio}
          />
          <LabelInfo
            label="Dependencia"
            info={record.plantilla_de_tramites.departamentos[0]?.unidad_operativa.descripcion}
          />
          <LabelInfo
            label="Estado"
            info={record.plantilla_de_tramites.estado_de_ficha.descripcion}
          />
          <LabelInfo
            label="Usuario"
            info={getFullName(record.usuario_modifico, 'numero_de_empleado')}
          />
        </Row>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
  };

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    setCurrentTabKey('editar');
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            mobileColIndex={0}
            rowKey="id"
            handleOnRowClick={handleOnRowClick}
            allowSearch={false}
            customActions={[
              {
                onClick: () => {
                  setCurrentTabKey('vista');
                  setVisible(true);
                },
                icon: EyeOutlined,
                text: 'Vista',
              },
            ]}
            expandable={false}
          />
        ) : (
          <Card
            className="form-container"
            title="Ficha de Retys"
            extra={(
              <FormSubmitControls
                onCancel={onCancel}
                allowSaveAndContinue
                onCancelText="Cancelar"
              />
            )}
            bordered={false}
          >
            <Segmented
              size="large"
              value={currentTabKey}
              onChange={setCurrentTabKey}
              options={[
                {
                  icon: <EyeOutlined />,
                  label: 'Vista',
                  value: 'vista',
                },
              ]}
            />
            <Col span={24}>
              {currentTabKey === 'vista' && (
                <Vista plantilla={plantilla} />
              )}
            </Col>
          </Card>
        )}
      </Spin>
    </Row>
  );
};

export default FichasPublicadas;
