/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const A = ({
  children,
  external,
  useAnchor,
  style,
  className,
  ...props
}) => {
  let { href } = props;
  const regexp = /http|https/;
  if (external && !regexp.test(href)) {
    href = `https://${href}`;
  }
  if (external || useAnchor) {
    const aProps = useAnchor ? {} : { target: '_blank', rel: 'noreferrer' };
    return (
      <a
        {...aProps}
        href={href}
        style={style}
        className={className}
      >
        {children}
      </a>
    );
  }

  return (
    <Link to={href}>
      {children}
    </Link>
  );
};

A.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  external: PropTypes.bool,
  useAnchor: PropTypes.bool,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.shape(),
};

A.defaultProps = {
  external: false,
  useAnchor: false,
  style: {},
  className: null,
};

export default A;
