import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Input,
  Card,

} from 'antd';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import { onSuccess, onError } from 'utils/handlers';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import NumericInput from 'components/NumericInput';

const baseURI = 'tramites/costeos-estandar/';

const TableCosteo = ({
  selectedRowKey,
  callback,
}) => {
  const [formTable] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [personaQueCumple, setPersonaQueCumple] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI, {
        params: {
          plantilla_de_tramites: selectedRowKey,
        },
      });

      if (response?.status === 200) {
        setData(response.data);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resPersona = await API.get('/tramites/tipos-de-personas-que-cumplen-con-los-requisitos');
      setPersonaQueCumple(resPersona.data || []);
      if (selectedRowKey) {
        await fetchData();
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();

    return () => API.source.cancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    formTable.resetFields();
    setVisibleAlert(false);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await formTable.validateFields();
      const values = formTable.getFieldsValue();
      const [key] = selectedRowKeys;
      if (selectedRowKey) {
        values.plantilla_de_tramites = selectedRowKey;
        if (!key) {
          const response = await API.post(baseURI, values);
          if (response?.status === 201) {
            onSuccess(response, 'Agregado correctamente');
            onCancel();
            await fetchData();
          }
        } else {
          const response = await API.patch(`${baseURI}${key}/`, values);
          if (response?.status === 200) {
            onSuccess(response, 'Actualizado correctamente');
            onCancel();
            await fetchData();
          }
        }
      } else {
        const clone = [...data];
        if (!key) {
          clone.push({
            ...values,
            key: clone.length + 1,
          });
          setData(clone);
          callback(clone);
          onCancel();
        } else {
          const res = clone.map((item) => (item.key === key ? { ...values, key } : item));
          setData(res);
          callback(res);
          onCancel();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      if (selectedRowKey) {
        if (key) {
          const response = await API.delete(`${baseURI}${key}/`);
          if (response?.status === 204) {
            onSuccess(response, 'Eliminado correctamente');
            onCancel();
            await fetchData();
          }
        }
      } else {
        const clone = data.filter((item) => item.key !== key);
        const normalized = clone.map((item, idx) => ({ ...item, key: idx }));
        setData(normalized);
        callback(normalized);
        onCancel();
        setVisibleAlert(false);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickAdd = () => {
    setVisible(true);
    setSelectedRowKeys([]);
    formTable.resetFields();
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const handleOnRowClick = (record) => {
    const _key = selectedRowKey ? record.id : record.key;
    setSelectedRowKeys([_key]);
    formTable.setFieldsValue(record);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const columns = [
    {
      titleText: 'Nombre del Requisito',
      dataIndex: 'nombre_del_requisito',
      key: 'nombre_del_requisito',
      width: 300,
    },
    {
      titleText: 'Cantidad de Personas',
      dataIndex: 'cantidad_de_personas',
      key: 'cantidad_de_personas',
      width: 300,
    },
  ];

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
    limit_hours: [
      {
        validator: async (r, value) => {
          if (value?.horas_para_cumplir_con_el_requisito <= 0) {
            throw new Error('Ingrese una cantidad mayor a 0 ');
          }
          if (value > 24) {
            throw new Error('Ingrese una hora valida menor a 24');
          }
        },
      },
    ],
    limit_days: [
      {
        validator: async (r, value) => {
          if (value > 31) {
            throw new Error('Ingrese una día valido menor a 31 ó 30 según el mes');
          }
        },
      },
    ],
    limit_minutes: [
      {
        validator: async (r, value) => {
          if (value > 59) {
            throw new Error('Ingrese una cantidad de minutos menor a 60');
          }
        },
      },
    ],
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            mobileColIndex={0}
            rowKey={selectedRowKey ? 'id' : 'key'}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            allowImport
            baseURI={baseURI}
          />
        ) : (
          <Card
            bordered={false}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
              />
            )}
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Costeo Estándar`}
          >
            <Form
              layout="vertical"
              form={formTable}
              onFinish={onFinish}
              scrollToFirstError
              initialValues={{
                horas_de_espera_para_pagar: 0,
                minutos_de_espera_para_pagar: 0,
                dias_para_cumplir_con_el_requisito: 0,
              }}
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="nombre_del_requisito"
                    label="Nombre del Requisito"
                  >
                    <Input allowClear maxLength={100} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    name="cantidad_de_personas"
                    label="Cantidad De Personas Externas"
                    tooltip="Cuantas Personas Externas se Necesitan para Cumplir con el Trámite"
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="¿Necesita Traslado?"
                    name="necesita_traslado"
                    tooltip={{
                      title: 'Para cumplir con el requisito es necesario trasladarse fuera del lugar de trabajo o domicilio',
                    }}
                  >
                    <Select trueFalse />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    name="horas_de_espera_para_pagar"
                    label="Horas de Espera para Pagar"
                    tooltip={{
                      title: 'Número de horas que toma realizar el pago.',
                    }}
                    rules={rules.limit_hours}
                  />
                </Col>

                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    name="minutos_de_espera_para_pagar"
                    label="Minutos de Espera para Pagar"
                    rules={rules.limit_minutes}
                    tooltip={{
                      title: 'Tiempo de espera en pagos y oficinas (minutos)',
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="¿Es Requisito Nuevo?"
                    name="requisito_nuevo"
                    tooltip={{
                      title: '¿Requisito nuevo o preexistente?',
                    }}
                  >
                    <Select trueFalse />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="¿Cumple con el Requisito?"
                    name="cumple_con_el_requisito"
                  >
                    <Select trueFalse />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Días para Cumplir"
                    name="dias_para_cumplir_con_el_requisito"
                    maxLength={2}
                    rules={rules.limit_days}
                    tooltip={{
                      title: 'Indica los días para cumplir con el requisito',
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Horas para Cumplir"
                    name="horas_para_cumplir_con_el_requisito"
                    rules={rules.limit_hours}
                    maxLength={2}
                    tooltip={{
                      title: 'Indica las horas para cumplir con el requisito',
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Minutos para Cumplir"
                    name="minutos_para_cumplir_con_el_requisito"
                    maxLength={2}
                    rules={rules.limit_minutes}
                    tooltip={{
                      title: 'Indica los minutos para cumplir con el requisito',
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="¿Valido para Otro Proceso?"
                    name="requisito_valido_para_otro_proceso"
                    tooltip={{
                      title: '¿El requisito podrá ser utilizado en otros procesos?',
                    }}
                  >
                    <Select trueFalse />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="¿Persona que cumple con el requisito?"
                    name="tipos_de_personas_que_cumplen_con_los_requisitos"
                    rules={rules.required}
                  >
                    <Select dataSource={personaQueCumple} />
                  </Form.Item>
                </Col>

                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content="El Costeo seleccionado"
        />
      </Spin>
    </Row>
  );
};
TableCosteo.propTypes = {

  selectedRowKey: PropTypes.number,
  callback: PropTypes.func.isRequired,

};
TableCosteo.defaultProps = {
  selectedRowKey: null,
};

export default TableCosteo;
