import API from 'utils/api';
import { onSuccess, onError } from 'utils/handlers';

export const baseURI = 'configuracion/dependencias/';

/**
 * GET Dependencias
 * @returns {Promise<Array<object>>}
 */
export const getDependencias = async (params = null) => {
  try {
    const response = await API.get(baseURI, { params });
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Dependencia
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getDependencia = async (id) => {
  try {
    const response = await API.get(`${baseURI}${id}`);
    return response.data;
  } catch (err) {
    onError(err);
    return null;
  }
};

/**
 * GET Horarios Dependencia
 * @param {number} id
 * @param {object} values
 * @returns {Promise<Array<object>>}
 */
export const getHorariosDependencia = async (id, values) => {
  try {
    const response = await API.get(`${baseURI}${id}/horarios/`, values);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * POST Dependencia
  * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const postDependencia = async (values) => {
  try {
    const response = await API.post(baseURI, values);
    onSuccess(response, 'Agregado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * PATCH Dependencia
 * @param {number} id
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const patchDependencia = async (id, values) => {
  try {
    const response = await API.patch(`${baseURI}${id}`, values);
    onSuccess(response, 'Editado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * DELETE Dependencia
 * @param {number} id
 * @returns {Promise<object|boolean>}
 */
export const deleteDependencia = async (id) => {
  try {
    const response = await API.delete(`${baseURI}${id}`);
    onSuccess(response, 'Eliminado correctamente');
    return true;
  } catch (err) {
    onError(err);
    return false;
  }
};
