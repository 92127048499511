/* eslint-disable no-param-reassign */
// Identificaciones
export const RFC_PATTERN = /^(([ÑA-Z|ña-z|&amp;]{3}|[A-Z|a-z]{4})\d{2}((0[1-9]|1[012])(0[1-9]|1\d|2[0-8])|(0[13456789]|1[012])(29|30)|(0[13578]|1[02])31)(\w{2})([A|a|0-9]{1}))$|^(([ÑA-Z|ña-z|&amp;]{3}|[A-Z|a-z]{4})([02468][048]|[13579][26])0229)(\w{2})([A|a|0-9]{1})$/;
export const CURP_PATTERN = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
export const INE_PATTERN = /^([ÑA-Z|ña-z]{4}|[ÑA-Z|ña-z]{6})([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-9]|2[0-9]|3[012])([HM|hm])([0-9]{3})$/;
export const URL_PATTERN = /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/;

export const digitoVerificador = (curp17) => {
  const diccionario = '0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ';
  let lngSuma = 0.0;
  let lngDigito = 0.0;
  for (let i = 0; i < 17; i += 1) {
    lngSuma += diccionario.indexOf(curp17.charAt(i)) * (18 - i);
  }
  lngDigito = 10 - (lngSuma % 10);
  if (lngDigito === 10) {
    return 0;
  }
  return lngDigito.toString();
};

// Decimales
const MAX_PRE_DIGIT_COUNT = 12;
const MAX_POST_DIGIT_COUNT = 4;
const DEFAULT_PRE_DIGIT_COUNT = 12;
// const DEFAULT_POST_DIGIT_COUNT = 2;
// const DEFAULT_POST_DIGIT_COUNT = monedaDefault?.decimales || 2;
/**
 * Función para crear expreciones regulares para valores decimales
 * @param pre digitos a la izquierda del punto decimal
 * @param post digitos a la derecha del punto decimal
 * @param req el valor es requerido
 */
export const createDecimalRegExp = (
  moneda = null,
  config = {},
) => {
  let {
    pre = DEFAULT_PRE_DIGIT_COUNT,
    post,
  } = config;
  const { req = false } = config;
  const DEFAULT_POST_DIGIT_COUNT = moneda?.decimales ?? MAX_POST_DIGIT_COUNT;
  pre = pre >= 0 ? pre : DEFAULT_PRE_DIGIT_COUNT;
  post = post >= 0 ? post : DEFAULT_POST_DIGIT_COUNT;
  pre = pre > MAX_PRE_DIGIT_COUNT ? MAX_PRE_DIGIT_COUNT : pre;
  post = post > MAX_POST_DIGIT_COUNT ? MAX_POST_DIGIT_COUNT : post;
  const regexp = new RegExp(`^(\\d){${req ? 1 : 0},${pre}}(\\.\\d{0,${post}})?$`);
  const maxLength = pre + post + 1;
  return [regexp, maxLength];
};

export default {
  RFC_PATTERN,
  CURP_PATTERN,
  INE_PATTERN,
  createDecimalRegExp,
  digitoVerificador,
};
