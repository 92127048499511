const makeHandleOnFinish = (onFinish, complexForms, currentTabKey) => (_continue = false) => {
  const match = complexForms.find((e) => e.key === currentTabKey);
  if (match) {
    match.formInstance.onFinishHandler(null, _continue);
  } else {
    onFinish(_continue);
  }
};

export const makeGetOnFinish = (
  basicKeys,
  complexForms,
  currentTabKey,
  onFinish,
  mutable = true,
) => () => {
  if (!mutable) {
    return null;
  }
  if (basicKeys.includes(currentTabKey)) {
    return makeHandleOnFinish(onFinish, complexForms, currentTabKey);
  }
  const match = complexForms.find((e) => e.key === currentTabKey);
  if (match) {
    const id = match.formInstance.getFieldValue('id');
    if ((!id) || (id)) {
      return makeHandleOnFinish(onFinish, complexForms, currentTabKey);
    }
  }
  return null;
};

export const makeOnChangeTabKey = (
  basicKeys,
  complexForms,
  currentTabKey,
  setCurrentTabKey,
  showMsg,
) => (key) => {
  const fromBasic = basicKeys.includes(currentTabKey);
  const toBasic = basicKeys.includes(key);
  const match = complexForms.find((e) => e.key === currentTabKey);
  if (!match) {
    if (fromBasic && toBasic) {
      setCurrentTabKey(key);
    } else if (fromBasic) {
      showMsg(key);
    } else {
      setCurrentTabKey(key);
    }
  } else {
    const id = match.formInstance.getFieldValue('id');
    if ((!id) || (id)) {
      match.formInstance.onFinishHandler(key, true);
    } else {
      setCurrentTabKey(key);
    }
  }
};
