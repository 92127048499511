import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Input,
  Card,
} from 'antd';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import { onSuccess, onError } from 'utils/handlers';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import FundamentosTransfer from './FundamentosTransfer';

const baseURI = 'tramites/canales-de-presentacion-plantilla/';

const TableCanalDePresentacion = ({
  selectedRowKey,
  callback,
}) => {
  const [formTable] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [proceso, setProceso] = useState([]);
  const [procesoMatched, setProcesoMatched] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI, {
        params: {
          plantilla_de_tramites: selectedRowKey,
        },
      });

      if (response?.status === 200) {
        setData(response.data);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resProceso = await API.get('tramites/canales-de-presentacion/');

      setProceso(resProceso.data || []);
      if (selectedRowKey) {
        await fetchData();
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.source.cancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    formTable.resetFields();
    setVisibleAlert(false);
    setProcesoMatched(0);
  };

  const matchProceso = (val) => {
    if (val) {
      const found = proceso.find((e) => e.id === val)?.proceso;
      setProcesoMatched(found);
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await formTable.validateFields();
      const values = formTable.getFieldsValue();
      const [key] = selectedRowKeys;
      if (selectedRowKey) {
        values.plantilla_de_tramites = selectedRowKey;
        if (!key) {
          const response = await API.post(baseURI, values);
          if (response?.status === 201) {
            onSuccess(response, 'Agregado correctamente');
            onCancel();
            await fetchData();
          }
        } else {
          const response = await API.put(`${baseURI}${key}/`, values);
          if (response?.status === 200) {
            onSuccess(response, 'Actualizado correctamente');
            onCancel();
            await fetchData();
          }
        }
      } else {
        const clone = [...data];
        if (!key) {
          clone.push({
            ...values,
            key: clone.length + 1,
          });
          setData(clone);
          callback(clone);
          onCancel();
        } else {
          const res = clone.map((item) => (item.key === key ? { ...values, key } : item));
          setData(res);
          callback(res);
          onCancel();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      if (selectedRowKey) {
        if (key) {
          const response = await API.delete(`${baseURI}${key}/`);
          if (response?.status === 204) {
            onSuccess(response, 'Eliminado correctamente');
            onCancel();
            await fetchData();
          }
        }
      } else {
        const clone = data.filter((item) => item.key !== key);
        const normalized = clone.map((item, idx) => ({ ...item, key: idx }));
        setData(normalized);
        callback(normalized);
        onCancel();
        setVisibleAlert(false);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickAdd = () => {
    setVisible(true);
    setSelectedRowKeys([]);
    formTable.resetFields();
    setProcesoMatched(0);
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      if (selectedRowKey) {
        const [key] = selectedRowKeys;
        const res = await API.get(`${baseURI}${key}`);
        const { fundamento_legal, ...values } = res.data;
        if (fundamento_legal?.length) {
          const promises = [];
          fundamento_legal.forEach((id) => {
            const response = API.get(`/configuracion/fundamentos-legales/${id}`);
            promises.push(response);
          });
          const responses = promises.length ? await Promise.all(promises) : [];
          const fundamentos = responses.map((fundamento) => fundamento.data);
          formTable.setFieldsValue({
            fundamento_legal,
            fundamento_legal_objects: fundamentos,
          });
        }
        matchProceso(values.canal_de_presentacion);
        formTable.setFieldsValue(values);
      } else {
        if (data?.fundamento_legal?.length) {
          const promises = [];
          data.fundamento_legal.forEach((id) => {
            const response = API.get(`/configuracion/fundamentos-legales/${id}`);
            promises.push(response);
          });
          const responses = promises.length ? await Promise.all(promises) : [];
          const fundamentos = responses.map((fundamento) => fundamento.data);
          formTable.setFieldsValue({
            fundamento_legal: data?.fundamento_legal,
            fundamento_legal_objects: fundamentos,
          });
        }
        formTable.setFieldsValue(data);
      }

      setVisible(true);
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    const _key = selectedRowKey ? record.id : record.key;
    setSelectedRowKeys([_key]);
    formTable.setFieldsValue(record);
  };
  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const columns = [
    {
      titleText: 'Canal de presentación',
      dataIndex: 'canal_de_presentacion',
      key: 'canal_de_presentacion',
      width: 300,
      render: (val) => (proceso.find((item) => item.key || item.id === val)?.descripcion),
    },
    {
      titleText: 'Pasos Presenciales',
      dataIndex: 'pasos_presenciales',
      key: 'pasos_presenciales',
      width: 300,
    },
    {
      titleText: 'Ubicación',
      dataIndex: 'ubicacion',
      key: 'ubicacion',
      width: 300,
    },
    {
      titleText: 'Pasos por otro Método',
      dataIndex: 'pasos_por_otro_metodo',
      key: 'pasos_por_otro_metodo',
      width: 300,
    },
  ];

  const rules = [
    {
      url: [
        {
          type: 'url',
          message: 'Ingrese un URL válido',
        },
      ],
      numero_telefonico: [
        {
          pattern: /^[0-9]{10}$/,
          message: 'Ingrese un número teléfonico válido',
        },
      ],
    },
  ];

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            mobileColIndex={0}
            rowKey={selectedRowKey ? 'id' : 'key'}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            allowImport
            baseURI={baseURI}
          />
        ) : (
          <Card
            bordered={false}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
              />
            )}
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Canal de Presentación`}
          >
            <Form
              layout="vertical"
              form={formTable}
              onFinish={onFinish}
              scrollToFirstError
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="canal_de_presentacion"
                    label="Canal de Presentación"
                  >
                    <Select
                      dataSource={proceso}
                      onChange={matchProceso}
                    />
                  </Form.Item>
                </Col>

                {procesoMatched === 1 && (
                  <>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="ubicacion"
                        label="Ubicación"
                      >
                        <Input allowClear />
                      </Form.Item>
                    </Col>
                  </>
                )}
                {procesoMatched === 2 && (
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name="ubicacion"
                      label="URL"
                      rules={rules.url}
                    >
                      <Input allowClear />
                    </Form.Item>
                  </Col>
                )}
                {procesoMatched === 3 && (
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name="ubicacion"
                      label="Número Telefónico"
                      rules={rules.numero_telefonico}
                    >
                      <Input allowClear />
                    </Form.Item>
                  </Col>
                )}
                {procesoMatched === 4 && (
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name="ubicacion"
                      label="Código"
                    >
                      <Input allowClear />
                    </Form.Item>
                  </Col>
                )}
                {procesoMatched === 5 && (
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name="ubicacion"
                      label="Otro"
                    >
                      <Input allowClear />
                    </Form.Item>
                  </Col>
                )}
                {procesoMatched === 1 && (
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name="pasos_presenciales"
                      label={`Pasos en ${proceso.find((e) => e?.proceso === procesoMatched)?.descripcion || ''}`}
                    >
                      <Input.TextArea allowClear autoSize={{ minRows: 2, maxRows: 4 }} />
                    </Form.Item>
                  </Col>
                )}
                {(procesoMatched !== 0 && procesoMatched !== 1) && (
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name="pasos_por_otro_metodo"
                      label={`Pasos por ${proceso.find((e) => e?.proceso === procesoMatched)?.descripcion || ''}`}
                    >
                      <Input.TextArea allowClear autoSize={{ minRows: 2, maxRows: 4 }} />

                    </Form.Item>
                  </Col>
                )}

                {/* <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="tramite_sin_acudir"
                    label="Trámite sin Acudir"
                    hasFeedback
                    tooltip={{
                      title: 'Se puede hacer el trámite en línea sin acudir
                       a oficinas gubernamentales',
                    }}
                  >
                    <Select trueFalse />
                  </Form.Item>
                </Col> */}
                {/* <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="documentos_en_linea"
                    label="Documentos en Línea"
                    hasFeedback
                    tooltip={{
                      title: 'Se pueden cargar o subir documentos en línea',
                    }}
                  >
                    <Select trueFalse />
                  </Form.Item>
                </Col> */}
                {/* <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="acuses_de_recepcion"
                    label="Acuses de Recepción"
                    hasFeedback
                  >
                    <Select trueFalse />
                  </Form.Item>
                </Col> */}
                {/* <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="respuesta_en_linea"
                    label="Respuesta en Línea"
                    hasFeedback
                  >
                    <Select trueFalse />
                  </Form.Item>
                </Col> */}
                <FundamentosTransfer formFundamento={formTable} />
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content="El Canal de Presentación Seleccionado"
        />
      </Spin>
    </Row>
  );
};
TableCanalDePresentacion.propTypes = {

  selectedRowKey: PropTypes.number,
  callback: PropTypes.func.isRequired,

};
TableCanalDePresentacion.defaultProps = {
  selectedRowKey: null,
};

export default TableCanalDePresentacion;
