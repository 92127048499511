/* eslint-disable react/prop-types */
/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Typography,
  Grid,
  Form,
  Input,
  Button,
  Spin,
  Modal,
  Result,
  Checkbox,
  DatePicker,
} from 'antd';
import { RightOutlined, CameraOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import fallback from 'assets/fallback.png';
import API from 'utils/api';
import LadaNumero from 'components/LadaNumero';
import ImageUploader from 'components/ImageUploader';
import services from 'mocks/services';
import { onError, onSuccess } from 'utils/handlers';
import getFormattedValues, { formatReceived, hasFiles } from 'utils/formatValues';
import A from './components/A';
import { HEADER_HEIGHT } from './components/Header';
import Layout from './components/Layout';
import Configurations, {
  ThemeConfig,
  SiteLink,
  FormItemEmail,
} from './components/Configurations';
import { socialIcons } from './components/Footer';

const Dots = () => (
  <div>
    {Array(11).fill(0).map((e, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <Row key={idx}>
        {Array(16).fill(0).map((i, _idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <Col key={_idx}>
            <Dot />
          </Col>
        ))}
      </Row>
    ))}
  </div>
);

const DeleteControls = ({ onClick }) => (
  <Row gutter={10} justify="end" style={{ width: '100%', padding: '0 10px' }}>
    <Col>
      <Form.Item>
        <Button onClick={onClick}>
          Eliminar
        </Button>
      </Form.Item>
    </Col>
  </Row>
);

const SubmitControls = ({ disabled, onClickCancel }) => (
  <Row gutter={10} justify="end" style={{ width: '100%', padding: '0 10px' }}>
    {onClickCancel && (
      <Col>
        <Form.Item>
          <Button disabled={disabled} onClick={onClickCancel}>
            Cancelar
          </Button>
        </Form.Item>
      </Col>
    )}
    <Col>
      <Form.Item>
        <Button disabled={disabled} htmlType="submit">
          Guardar
        </Button>
      </Form.Item>
    </Col>
  </Row>
);

const htmlTooltip = ({
  title: "Acepta etiquetas html 'b' y 'strong' para resaltar texto y 'br' para saltos de linea",
});

const baseURL = 'tramites/pagina-municipal';

const LandingEntidad = () => {
  const history = useHistory();
  const [formBanner] = Form.useForm();
  const [formContact] = Form.useForm();
  const [formAbout] = Form.useForm();
  const [formNews] = Form.useForm();
  const [getStartedForm] = Form.useForm();
  const screens = Grid.useBreakpoint();
  const [editing, setEditing] = useState(true);
  const [loading, setLoading] = useState(true);
  const [entidad, setEntidad] = useState(null);
  const [theme, setTheme] = useState(null);

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
    email: [
      requiredRule,
      {
        type: 'email',
        message: 'Ingrese un correo electrónico válido',
      },
    ],
    html: [
      requiredRule,
      {
        validator: async (rule, value) => {
          if (value) {
            const formattedValue = value
              .replace(/<b>/).replace(/<\/b>/)
              .replace(/<strong>/).replace(/<\/strong>/)
              .replace(/<br>/)
              .replace(/<br >/)
              .replace(/<br\/>/)
              .replace(/<br \/>/);
            if (/(<[^<>]+>|<>)/g.test(formattedValue)) {
              throw new Error('Ingrese un texto válido');
            }
          }
        },
      },
    ],
    url: [
      { required: true },
      {
        type: 'url',
        message: 'Ingrese una url válida',
      },
    ],
  };

  const fetchData = async () => {
    try {
      const response = await API.get('tramites/pagina-promocional');
      if (response?.status === 200) {
        const values = response.data;
        values.noticias = formatReceived(values.noticias);
        setEntidad(values);
        formBanner.setFieldsValue(values.banner);
        formContact.setFieldsValue(values.contacto);
      }
      setLoading(false);
    } catch (err) {
      if (err.response?.status !== 404) {
        onError(err, setLoading);
      } else {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
  }, []);

  const getShadowSpan = () => {
    if (screens.xxl) {
      return 8;
    }
    if (screens.md) {
      return 12;
    }
    return 24;
  };

  const Banner = () => (editing ? (
    <Form name="banner" form={formBanner} layout="vertical">
      <BannerWrapper editing={editing.toString()}>
        {editing && (
          <Title className="primary-color" style={{ margin: '10px 0 0 0' }}>Banner</Title>
        )}
        <Row gutter={10} style={{ height: '100%' }}>
          <Col md={24} lg={12}>
            <Col span={24}>
              <Form.Item name="titulo" tooltip={htmlTooltip} label="Título" rules={rules.html}>
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="texto" tooltip={htmlTooltip} label="Texto" rules={rules.html}>
                <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="usar_sombra" valuePropName="checked">
                <Checkbox>Usar sombra</Checkbox>
              </Form.Item>
            </Col>
          </Col>
          <Col md={24} lg={12}>
            <ImageUploader
              form={formBanner}
              formItemLabel="Imagen"
              formItemName="imagen"
              imageURL={formBanner.getFieldValue('imagen')}
              icon={<CameraOutlined />}
              allowClear={false}
              height={256}
              required
            />
          </Col>
          {editing && (
            <SubmitControls />
          )}
        </Row>
      </BannerWrapper>
    </Form>
  ) : (
    <BannerWrapper>
      <ImgBanner src={entidad.banner.imagen || fallback} alt="banner entidad" />
      {!!entidad.banner.usar_sombra && <Shadow />}
      <Row align="middle" justify="center" style={{ height: '100%' }}>
        <Col span={getShadowSpan()} style={{ padding: 25 }}>
          <Title level={1} style={{ fontWeigth: 'bold', color: '#FFFFFF' }}>
            <div dangerouslySetInnerHTML={{ __html: entidad.banner.titulo || '<b>Título</b>' }} />
          </Title>
          {!screens.xs && (
            <Text style={{ fontSize: 16, color: '#FFFFFF' }}>
              <div dangerouslySetInnerHTML={{ __html: entidad.banner.texto || '<b>Texto</b>' }} />
            </Text>
          )}
        </Col>
      </Row>
    </BannerWrapper>
  ));

  const Services = () => !editing && (
    <ServicesContainer>
      <ServicesWrapper
        justifyContent={
          document.body.offsetWidth * 0.8 >= (services.length * 150)
          && screens.md
        }
      >
        {(services).map((service) => (
          <ServiceWrapper key={service.id}>
            <Service>
              <A href={service.url} external>
                <Img src={service.imagen} />
              </A>
            </Service>
          </ServiceWrapper>
        ))}
      </ServicesWrapper>
    </ServicesContainer>
  );

  const AboutItem = ({
    imagen, nombre, contenido,
  }) => (
    <AboutItemContainer>
      <Col span={imagen && screens.md ? 12 : 24}>
        <Title level={3} className="primary-color">
          {nombre}
        </Title>
        <Text>
          <div dangerouslySetInnerHTML={{ __html: contenido }} />
        </Text>
      </Col>
      {imagen && (
        <Col span={screens.md ? 12 : 24} style={{ maxHeight: 250 }}>
          <Img src={imagen} style={{ maxHeight: 250 }} />
        </Col>
      )}
    </AboutItemContainer>
  );

  const onDeleteArticle = async (id) => {
    try {
      setLoading(true);
      const response = await API.delete(`tramites/sobre-articulos/${id}`);
      onSuccess(response, 'Artículo eliminado correctamente');
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const confirmDeleteArticle = (initialValues = {}) => {
    Modal.confirm({
      onOk: () => onDeleteArticle(initialValues.id),
      content: (
        <>
          Seguro que desea eliminar el artículo
          {' '}
          &quot;
          {initialValues.nombre}
          &quot;
          ?
        </>
      ),
      loadingConfirm: loading,
    });
  };

  const AboutItemForm = ({ initialValues = {}, idx = -1 }) => (
    <AboutItemContainer>
      <Form
        name={`about_item_${idx}`}
        form={idx === -1 ? formAbout : null}
        layout="vertical"
        style={{ width: '100%' }}
        initialValues={initialValues}
      >
        {idx === -1 && editing && (
          <Title level={3} style={{ marginRight: 'auto' }}>Nuevo artículo</Title>
        )}
        <Row gutter={10}>
          <Col md={24} lg={12}>
            <Col span={24}>
              <Form.Item name="nombre" label="Título" tooltip={htmlTooltip} rules={rules.html}>
                <Input disabled={idx !== -1} allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="contenido" label="Contenido" tooltip={htmlTooltip} rules={rules.html}>
                <Input.TextArea
                  autoSize={{ minRows: 4 }}
                  disabled={idx !== -1}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Col>
          <Col md={24} lg={12}>
            <ImageUploader
              form={formAbout}
              formItemName="imagen"
              formItemLabel="Imagen"
              imageURL={formAbout.getFieldValue('imagen') || initialValues.imagen}
              icon={<CameraOutlined />}
              height={screens.md ? 215 : 150}
              disabled={idx !== -1}
            />
          </Col>
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
        </Row>
        <Row
          justify={idx === -1 ? 'space-between' : 'end'}
          gutter={10}
          style={{ width: '100%', marginBottom: 10 }}
        >
          {idx !== -1 ? (
            <DeleteControls onClick={() => confirmDeleteArticle(initialValues)} />
          ) : (
            <SubmitControls />
          )}
        </Row>
      </Form>
    </AboutItemContainer>
  );

  const About = () => (
    <AboutContainer editing={editing.toString()}>
      <Title className="primary-color" style={{ margin: '10px 0 0 0' }}>Nosotros</Title>
      {entidad.about_items.map((item, idx) => (editing
        ? (
          <AboutItemForm
            key={item.id}
            idx={idx}
            initialValues={item}
          />
        )
        // eslint-disable-next-line react/jsx-props-no-spreading
        : <AboutItem key={item.id || idx} {...item} />))}
      {editing && (
        <AboutItemForm />
      )}
    </AboutContainer>
  );

  const Contact = () => (
    <ContactContainer editing={editing.toString()}>
      <Title className="primary-color" style={{ margin: '10px 0 0 0' }}>Contacto</Title>
      <Row gutter={10}>
        {editing ? (
          <Col xs={0} sm={0} md={24} lg={12}>
            <Form layout="vertical" name="contact" form={formContact}>
              <Col span={24}>
                <Form.Item name="texto" label="Texto" tooltip={htmlTooltip} rules={rules.html}>
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <ImageUploader
                  form={formContact}
                  formItemName="imagen"
                  formItemLabel="Imagen"
                  imageURL={formContact.getFieldValue('imagen')}
                  icon={<CameraOutlined />}
                  height={screens.lg ? 235 : 278}
                  allowClear={false}
                  required
                />
              </Col>
              {editing && (
                <SubmitControls />
              )}
            </Form>
          </Col>
        ) : (
          <Col
            xs={0}
            sm={0}
            md={10}
            lg={12}
            style={{ padding: screens.lg ? 20 : 10 }}
          >
            <Dots />
            <ContactImg src={entidad?.contacto.imagen || fallback} />
            <ContactTextWrapper>
              <Title level={4}>
                <div dangerouslySetInnerHTML={{ __html: entidad?.contacto.texto || 'Texto' }} />
              </Title>
            </ContactTextWrapper>
          </Col>
        )}
        <Col xs={editing ? 0 : 24} sm={editing ? 0 : 24} md={editing ? 0 : 14} lg={12}>
          {!editing && (
            <Title className="primary-color" style={{ textAlign: 'center' }}>
              Contactanos
            </Title>
          )}
          <Form
            layout="vertical"
            style={!editing ? { padding: screens.md ? '10px 40px' : '10px 20px' } : {}}
          >
            <Row gutter={10}>
              <Col xs={24} sm={12} md={12}>
                <Form.Item label="Nombre completo" name="nombre">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12}>
                <Form.Item label="Correo electrónico" name="correo_electronico">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={editing ? 24 : 12} lg={editing ? 24 : 12} xl={12}>
                <Form.Item label="Asunto" name="asunto">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={editing ? 24 : 12} lg={editing ? 24 : 12} xl={12}>
                <LadaNumero
                  form={{}}
                  disabled
                  ladaDefault={entidad?.lada?.id}
                  fullSize
                  required
                />
              </Col>
              <Col span={24}>
                <Form.Item label="Comentario" name="comentario">
                  <Input.TextArea autoSize={{ minRows: 3 }} disabled />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <Button
                    style={{ fontWeight: 'bold' }}
                    htmlType="submit"
                    block
                  >
                    Enviar
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </ContactContainer>
  );

  const onDeleteNewsItem = async (id) => {
    try {
      setLoading(true);
      const response = await API.delete(`tramites/noticias/${id}`);
      onSuccess(response, 'Artículo eliminado correctamente');
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const confirmDeleteNewsItem = (initialValues = {}) => {
    Modal.confirm({
      onOk: () => onDeleteNewsItem(initialValues.id),
      content: (
        <>
          Seguro que desea eliminar la noticia
          {' '}
          &quot;
          {initialValues.titulo}
          &quot;
          ?
        </>
      ),
      loadingConfirm: loading,
    });
  };

  const NewsItem = ({ item = {}, width, idx = -1 }) => (
    <NewsCard style={{ width, height: editing ? 570 : 440 }}>
      {!editing ? (
        <NewsCardContentContainer style={{ padding: editing ? 0 : 15 }}>
          <Img src={item.imagen} />
          <Text style={{ color: '#404040', textAlign: 'right', display: 'block' }}>
            {item.fecha.format('DD/MM/YYYY')}
          </Text>
          <Title style={{ margin: 0 }} level={4} className="primary-color ellipsis">
            {item.titulo}
          </Title>
          <Text className="ellipsis">{item.contenido}</Text>
          <Row justify="end" style={{ paddingTop: 10 }}>
            <NewsLink href={item.enlace} external>
              <Text style={{ color: 'inherit', marginbottom: 3 }}>Saber más</Text>
              <RightOutlined />
            </NewsLink>
          </Row>
        </NewsCardContentContainer>
      ) : (
        <NewsCardContentContainer editing={editing.toString()}>
          <Form
            name="news"
            form={idx === -1 ? formNews : null}
            layout="vertical"
            initialValues={item}
          >
            <Col span={24}>
              <ImageUploader
                form={formNews}
                formItemLabel="Imagen"
                formItemName="imagen"
                imageURL={formNews.getFieldValue('imagen') || item.imagen}
                icon={<CameraOutlined />}
                disabled={idx !== -1}
                allowClear={false}
                height={150}
                required
              />
            </Col>
            <Col span={24}>
              <Form.Item name="fecha" label="Fecha" rules={rules.required}>
                <DatePicker
                  placeholder=""
                  format="DD/MM/YYYY"
                  disabled={idx !== -1}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="titulo" label="Título" tooltip={htmlTooltip} rules={rules.html}>
                <Input disabled={idx !== -1} allowClear maxLength={100} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="contenido" label="Contenido" tooltip={htmlTooltip} rules={rules.html}>
                <Input.TextArea
                  autoSize={{ minRows: 4 }}
                  disabled={idx !== -1}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="enlace" label="Enlace" rules={rules.url}>
                <Input disabled={idx !== -1} allowClear />
              </Form.Item>
            </Col>
            {idx !== -1 ? (
              <DeleteControls onClick={() => confirmDeleteNewsItem(item)} />
            ) : (
              <SubmitControls />
            )}
          </Form>
        </NewsCardContentContainer>
      )}
    </NewsCard>
  );

  const NewsGallery = () => (
    <Row gutter={[15, 15]}>
      {entidad.noticias.map((item, idx) => (
        <Col
          xs={24}
          sm={24}
          md={editing ? 24 : 8}
          lg={editing ? 12 : 8}
          xxl={editing ? 6 : 8}
          key={item.id}
        >
          <NewsItem width="100%" item={item} idx={idx} />
        </Col>
      ))}
      {editing && (
        <Col xs={24} sm={24} md={24} lg={12} xxl={8}>
          <NewsItem width="100%" />
        </Col>
      )}
    </Row>
  );

  const NewsCarrousel = () => (
    <NewsCarrouselContainer
      justifyContent={
          document.body.offsetWidth * 0.8 >= entidad?.noticias.length * 150 && screens.md
        }
    >
      {entidad.noticias.map((item) => (
        <NewsItemWrapper key={item.id}>
          <NewsItem item={item} width={350} />
        </NewsItemWrapper>
      ))}
    </NewsCarrouselContainer>
  );

  const News = () => {
    if (!editing && !entidad?.noticias.length) {
      return null;
    }
    const NewsComponent = entidad?.noticias.length < 8 || editing ? NewsGallery : NewsCarrousel;
    return (
      <NewsContainer>
        <Title className="primary-color">Noticias</Title>
        <NewsComponent />
      </NewsContainer>
    );
  };

  const onFinishPage = async (values) => {
    const keys = ['url', 'correo_electronico'];
    const changed = keys.some((k) => entidad.tema[k] !== values[k]);
    if (changed) {
      const response = await API.patch(`tramites/pagina-promocional/${entidad.id}/`, values);
      onSuccess(response, 'Actualizado correctamente');
    }
  };

  const onFinishSocialLinks = async (_values) => {
    // eslint-disable-next-line no-param-reassign
    delete _values.estados_globales;
    const socialIconsNames = Object.keys(socialIcons);
    const values = Object.keys(_values).map((key) => {
      const icono = socialIconsNames.find((e) => e.toLowerCase().includes(key));
      return {
        icono,
        url: _values[key],
      };
    });
    const requests = values.map((item) => {
      const match = entidad.redes_sociales.find((e) => e.icono === item.icono);
      if (!match) {
        return item.url ? API.post('tramites/redes-sociales/', item) : null;
      } if (!item.url) {
        return API.delete(`tramites/redes-sociales/${match.id}`);
      } if (item.url !== match.url) {
        return API.patch(`tramites/redes-sociales/${match.id}`, item);
      }
      return null;
    }).filter((e) => e);
    if (requests.length) {
      const responses = await Promise.all(requests);
      onSuccess(responses, 'Redes sociales actualizadas');
    }
  };

  const onFinishTheme = async (values) => {
    const keys = Object.keys(values);
    const changed = keys.some((k) => entidad.tema[k] !== values[k]);
    if (changed) {
      const response = await API.patch(`tramites/temas/${entidad.tema.id}/`, values);
      onSuccess(response, 'Tema actualizado correctamente');
    }
  };

  const onFinishContact = async (values) => {
    const files = ['imagen'];
    const config = hasFiles(values, files) ? {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    } : {};
    const formattedValues = getFormattedValues(values, {
      files,
      formData: config.headers,
      clean: false,
    });
    setLoading(true);
    if (!entidad.contacto) {
      const response = await API.post(`${baseURL}/secciones-de-contacto/`, formattedValues, config);
      onSuccess(response, 'Sección de contacto actualizada correctamente');
    } else {
      if (!config.headers) {
        delete formattedValues.imagen;
      }
      const response = await API.patch(`${baseURL}/secciones-de-contacto/${entidad.contacto.id}/`, formattedValues, config);
      onSuccess(response, 'Sección de contacto guardada correctamente');
    }
  };

  const onFinishBanner = async (values) => {
    const files = ['imagen'];
    const config = hasFiles(values, files) ? {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    } : {};
    const formattedValues = getFormattedValues(values, {
      files,
      formData: config.headers,
      clean: false,
    });
    if (!entidad.banner?.titulo) {
      const response = await API.post(`${baseURL}/banners/`, formattedValues, config);
      onSuccess(response, 'Banner actualizado correctamente');
    } else {
      if (!config.headers) {
        delete formattedValues.imagen;
      }
      const response = await API.patch(`${baseURL}/banners/${entidad.banner.id}/`, formattedValues, config);
      onSuccess(response, 'Banner guardado correctamente');
    }
  };

  const onFinishAboutArticle = async (values) => {
    const files = ['imagen'];
    const config = hasFiles(values, files) ? {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    } : {};
    const formattedValues = getFormattedValues(values, {
      files,
      formData: config.headers,
      clean: false,
    });
    const response = await API.post('tramites/sobre-articulos/', formattedValues, config);
    onSuccess(response, 'Artículo guardado correctamente');
    formAbout.resetFields();
  };

  const onFinishNewsItem = async (values) => {
    const files = ['imagen'];
    const config = hasFiles(values, files) ? {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    } : {};
    const formattedValues = getFormattedValues(values, {
      files,
      formData: config.headers,
      clean: false,
    });
    const response = await API.post('tramites/noticias/', formattedValues, config);
    onSuccess(response, 'Noticia guardada correctamente');
    formNews.resetFields();
  };

  const onFormFinish = async (name, { values: _values, forms }) => {
    try {
      setLoading(true);
      const form = forms[name];
      if (form) {
        await form.validateFields();
      }
      const values = _values;
      if (name === 'page') {
        await onFinishPage(values);
      }
      values.estados_globales = 4;
      if (name.includes('about_item')) {
        await onFinishAboutArticle(values);
      }
      if (name === 'banner') {
        await onFinishBanner(values);
      }
      if (name === 'contact') {
        await onFinishContact(values);
      }
      if (name === 'theme') {
        await onFinishTheme(values);
      }
      if (name === 'social_links') {
        await onFinishSocialLinks(values);
      }
      if (name === 'news') {
        await onFinishNewsItem(values);
      }
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onFinishGetStarted = async (_values) => {
    try {
      setLoading(true);
      await getStartedForm.validateFields();
      const { uri, correo_electronico, ...tema } = _values;
      const values = { uri, correo_electronico, tema };
      const response = await API.post('tramites/pagina-promocional', values);
      onSuccess(response, 'Guardado correctamente');
      await fetchData();
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const GetStarted = () => (
    <Loading>
      <GetStartedContainer align="middle" justify="center">
        <Col xs={22} sm={12} md={10} lg={7} xxl={5}>
          <ThemeConfig
            form={getStartedForm}
            onFinish={onFinishGetStarted}
            extra={(
              <>
                <Title style={{ textAlign: 'center' }}>Antes de empezar</Title>
                <SiteLink name="uri" />
                <FormItemEmail />
              </>
            )}
            formLayout="vertical"
            showSubmitButton
          />
        </Col>
      </GetStartedContainer>
    </Loading>
  );

  const Loading = ({ children }) => (children ? (
    <Spin tip="Cargando" spinning={loading} style={{ height: '100vh' }}>
      {children}
    </Spin>
  ) : (
    <Row align="middle" justify="center" style={{ height: '100vh' }}>
      <Spin tip="Cargando" spinning={loading} size="large" />
    </Row>
  ));

  if (!entidad && !loading) {
    return <GetStarted />;
  }

  if (loading && !entidad) {
    return <Loading />;
  }

  if (editing && !screens.md) {
    return (
      <Row align="middle" justify="center" style={{ height: '100vh', padding: 25 }}>
        <Result
          title="Se requiere un área de trabajo más grande para el modo edición"
          extra={(
            <Button onClick={() => history.push('/dashboard')}>
              Ir al dashboard
            </Button>
          )}
        />
      </Row>
    );
  }

  return (
    <Loading>
      <Form.Provider onFormFinish={onFormFinish}>
        <Row>
          <Col
            style={{
              height: '100vh',
              width: `calc(100vw - ${editing ? 350 : 0}px)`,
              overflow: 'hidden scroll',
            }}
            id="mainScrollable"
          >
            <Layout editing={editing} setEditing={setEditing} entidad={entidad} theme={theme}>
              <Banner />
              <Services />
              <About />
              <News />
              <Contact />
            </Layout>
          </Col>
          <Col style={{ width: editing ? 350 : 0 }}>
            <Configurations
              setTheme={setTheme}
              entidad={entidad}
              setEditing={setEditing}
              editing={editing}
              setLoading={setLoading}
              fetchData={fetchData}
            />
          </Col>
        </Row>
      </Form.Provider>
    </Loading>
  );
};

const GetStartedContainer = styled(Row)`
  width: 100%;
  height: 100%;
  padding: 20px 0;
`;

const BannerWrapper = styled.div.attrs({ className: 'section', id: 'home' })`
  width: 100%;
  height: ${(props) => (props.editing ? '100%' : '50vh')};
  position: relative;
  overflow: hidden;
  margin-top: ${HEADER_HEIGHT}px;
`;

const Shadow = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background: linear-gradient(89.54deg,
  rgba(9, 9, 9, 0.76) 0.43%,
  rgba(9, 9, 9, 0.415625) 52.63%,
  rgba(9, 9, 9, 0.324279) 73.81%,
  rgba(9, 9, 9, 0.175111) 89.32%,
  rgba(9, 9, 9, 0.0787997) 99.64%,
    rgba(9, 9, 9, 0) 99.65%);
`;

const Img = styled.img.attrs({ draggable: false })`
  width: 100%;
  object-fit: cover;
  user-select: none;
`;

const ImgBanner = styled(Img)`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const Title = styled(Typography.Title)`
  margin: 0;
`;

const Text = styled(Typography.Text)`
  font-size: 16px;
`;

const ServicesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: auto;
  @media only screen and (min-width: 767px) {
    height: 0;
    /* margin-bottom: 40px; */
  }
`;

const ServicesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.justifyContent ? 'center' : '')};
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  @media only screen and (min-width: 767px) {
    z-index: 1;
    width: 80vw;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: #FFFFFF;
  }
`;

const ServiceWrapper = styled.div`
  scroll-snap-align: start;
  padding: 10px;
`;

const Service = styled.div`
  width: 100px;
  height: 100px;
  margin: 0 15px;
`;

const AboutContainer = styled(Row).attrs({ gutter: 15, className: 'section', id: 'about' })`
  padding: 0px 20px 20px 20px;
  margin-top: 60px;
  .about-item-container:nth-child(even) {
    flex-flow: ${(props) => (props.editing ? 'row' : 'row-reverse')} wrap;
    .ant-form .ant-row:nth-child(2) {
      /* flex-flow: row-reverse wrap; */
    }
  }
`;

const AboutItemContainer = styled(Row).attrs({ className: 'about-item-container', gutter: [10, 10] })`
  width: 100%;
  padding: 10px 0px;
  img {
    border-radius: 12px;
  }
`;

const NewsCarrouselContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.justifyContent ? 'center' : '')};
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  width: 100vw;
`;

const NewsContainer = styled.div.attrs({ className: 'section', id: 'news' })`
  padding: 15px;
  margin-top: 20px;
`;

const NewsItemWrapper = styled.div`
  scroll-snap-align: start;
  padding: 10px;
`;

const NewsCard = styled.div`
  border-radius: 12px;
  box-shadow: 1px 0px 2px 2px #cacaca;
  background-color: #FFFFFF;
  max-width: calc(100vw - 50px);
`;

const NewsCardContentContainer = styled.div`
  padding: ${(props) => (props.editing ? 0 : 15)}px;
  height: 100%;
  img {
    border-radius: 12px;
    height: 200px;
  }
  span.ant-typography.ellipsis {
    -webkit-line-clamp: 4;
    height: 100px;
    -webkit-line-clamp: 4;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
  h4.ant-typography.ellipsis {
    -webkit-line-clamp: 2;
    height: 50px;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
`;

const ContactContainer = styled(Row).attrs({ gutter: 15, className: 'section', id: 'contact' })`
  max-height: ${(props) => (props.editing ? 'auto' : 'clamp(480px, 30vw, 35vw)')};
  overflow: hidden;
  padding: 0 20px 20px 20px;
  margin-top: 20px;
  @media only screen and (max-width: 767px) {
    max-height: ${(props) => (props.editing ? 'auto' : '800px')};
  }
`;

const NewsLink = styled(A)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Dot = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  margin: 4px;
  background-color: ${({ theme }) => theme.color_de_fondo_primario};
`;

const ContactImg = styled(Img)`
  width: 80%;
  max-height: 300px;
  transform: translateX(7vw) translateY(-8vh);
  /* @media only screen and (max-width: 767px) {
  transform: translateX(100px);
  } */
`;

const ContactTextWrapper = styled.div`
  width: 80%;
  /* transform: translateX(80%) translateY(-200px); */
  transform: translateX(11vw) translateY(-16vh);
  background: ${({ theme }) => theme.color_de_fondo_primario};
  text-align: center;
  padding: 25px;
  .ant-typography {
    color: #FFFFFF;
  }
`;

export default LandingEntidad;
