import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Row,
  Col,
  Spin,
  Card,
  Input,
} from 'antd';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import { onSuccess, onError } from 'utils/handlers';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import Uploader from 'components/Uploader';
import getFormatedValues, { hasFiles, formatReceived } from 'utils/formatValues';

import FundamentosTransfer from './FundamentosTransfer';

const baseURI = 'tramites/formatos-requeridos/';
const TableAnexos = ({
  selectedRowKey,
  callback,
}) => {
  const [formTable] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [isElectronic, setIsElectronic] = useState(false);

  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI, {
        params: {
          plantilla_de_tramite: selectedRowKey,
        },
      });
      setData(formatReceived(response.data));
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      if (selectedRowKey) {
        await fetchData();
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();

    return () => API.source.cancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    formTable.resetFields();
    setVisibleAlert(false);
  };
  const patchFundamentosLegales = async (id, values) => {
    try {
      setLoading(true);
      await API.patch(`${baseURI}${id}`, {
        fundamento_legal: values.fundamento_legal,
      });
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await formTable.validateFields();
      const values = formTable.getFieldsValue();
      const [key] = selectedRowKeys;
      const _clone = { ...values };
      delete values.fundamento_legal;
      values.plantilla_de_tramite = selectedRowKey;

      const files = ['descargar_archivo'];
      const config = hasFiles(values, files) ? {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      } : {};
      const formattedValues = getFormatedValues(values, {
        files,
        formData: config.headers,
        clean: true,
      });
      if (selectedRowKey) {
        values.plantilla_de_tramite = selectedRowKey;
        if (!key) {
          const response = await API.post(baseURI, formattedValues, config);
          if (response?.status === 201) {
            onSuccess(response, 'Agregado correctamente');
            await patchFundamentosLegales(response.data.id, _clone);
            onCancel();
            await fetchData();
          }
        } else {
          const response = await API.put(`${baseURI}${key}/`, formattedValues, config);
          if (response?.status === 200) {
            onSuccess(response, 'Actualizado correctamente');
            await patchFundamentosLegales(response.data.id, _clone);
            onCancel();
            await fetchData();
          }
        }
      } else {
        const clone = [...data];
        if (!key) {
          clone.push({
            ...values,
            config,
            key: clone.length + 1,
          });
          setData(clone);
          callback(clone);
          onCancel();
        } else {
          const res = clone.map((item) => (item.key === key
            ? { ...values, config, key } : item));
          setData(res);
          callback(res);
          onCancel();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      if (selectedRowKey) {
        if (key) {
          const response = await API.delete(`${baseURI}${key}/`);
          if (response?.status === 204) {
            onSuccess(response, 'Eliminado correctamente');
            onCancel();
            await fetchData();
          }
        }
      } else {
        const clone = data.filter((item) => item.key !== key);
        const normalized = clone.map((item, idx) => ({ ...item, key: idx }));
        setData(normalized);
        callback(normalized);
        onCancel();
        setVisibleAlert(false);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickAdd = () => {
    setVisible(true);
    setSelectedRowKeys([]);
    formTable.resetFields();
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      if (selectedRowKey) {
        const [key] = selectedRowKeys;
        const res = await API.get(`${baseURI}${key}`);
        const { fundamento_legal, ...values } = res.data;
        if (fundamento_legal?.length) {
          const promises = [];
          fundamento_legal.forEach((id) => {
            const response = API.get(`/configuracion/fundamentos-legales/${id}`);
            promises.push(response);
          });
          const responses = promises.length ? await Promise.all(promises) : [];
          const fundamentos = responses.map((fundamento) => fundamento.data);
          formTable.setFieldsValue({
            fundamento_legal,
            fundamento_legal_objects: fundamentos,
          });
        }
        formTable.setFieldsValue(values);
      } else {
        if (data?.fundamento_legal?.length) {
          const promises = [];
          data.fundamento_legal.forEach((id) => {
            const response = API.get(`/configuracion/fundamentos-legales/${id}`);
            promises.push(response);
          });
          const responses = promises.length ? await Promise.all(promises) : [];
          const fundamentos = responses.map((fundamento) => fundamento.data);
          formTable.setFieldsValue({
            fundamento_legal: data?.fundamento_legal,
            fundamento_legal_objects: fundamentos,
          });
        }
        formTable.setFieldsValue(data);
      }

      setVisible(true);
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const handleOnRowClick = (record) => {
    const _key = selectedRowKey ? record.id : record.key;
    setSelectedRowKeys([_key]);
    formTable.setFieldsValue(record);
    setIsElectronic(record?.formato_electronico);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const columns = [
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 300,
    },

  ];

  const rules = {
    url: [
      {
        type: 'url',
        message: 'Ingrese un URL válido',
      },
    ],
  };

  const allowedExtensions = [
    'pdf', 'png', 'jpg',
  ];

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            mobileColIndex={0}
            rowKey={selectedRowKey ? 'id' : 'key'}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            allowImport
            baseURI={baseURI}
          />
        ) : (
          <Card
            bordered={false}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
              />
             )}
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Formato Anexo`}
          >
            <Form
              layout="vertical"
              form={formTable}
              onFinish={onFinish}
              scrollToFirstError
              initialValues={{ fundamento_legal: [] }}
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="descripcion"
                    label="Descripción"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="identificador_de_formato"
                    label="Identificador del Formato"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="liga_descarga_de_formato"
                    label="Liga de descarga del Formato"
                    hasFeedback
                    tooltip="Liga para descargar el formato"
                    rules={rules.url}

                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="formato_electronico"
                    label="¿El formato se puede recibir electrónicamente?"
                    hasFeedback
                  >
                    <Select trueFalse allowClear onChange={setIsElectronic} />
                  </Form.Item>
                </Col>
                {isElectronic && (
                <Uploader
                  title="Formato Electrónico"
                  limitMB={50}
                  file={formTable.getFieldValue('descargar_archivo')}
                  formItemName="descargar_archivo"
                  allowedExtensions={allowedExtensions}
                  tooltip={{
                    title: `Extensiones Permitidas para el Documento: ${allowedExtensions}`,
                  }}
                />
                )}
                <FundamentosTransfer
                  formFundamento={formTable}
                />
              </Row>
            </Form>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content="El Formato Anexo"
        />
      </Spin>
    </Row>
  );
};
TableAnexos.propTypes = {

  selectedRowKey: PropTypes.number,
  callback: PropTypes.func.isRequired,

};
TableAnexos.defaultProps = {
  selectedRowKey: null,
};

export default TableAnexos;
