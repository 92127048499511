/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import MapboxWorker from 'mapbox-gl/dist/mapbox-gl-csp-worker';
import { useSelector } from 'react-redux';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = 'pk.eyJ1IjoiZ2FsZzY1MyIsImEiOiJja28zYzRrMHkwb3U1MnhvaTVxdWprbnVnIn0.omb5-WD9j_SsvDxzUJFvdQ';

const MapaMultiple = ({
  dataSource,
  height,
}) => {
  const mapContainer = useRef();
  const theme = useSelector(({ app }) => app.theme);
  const [map, setMap] = useState();

  const resizeMap = () => {
    setTimeout(() => {
      if (map) {
        map.resize();
      }
    });
  };

  useEffect(() => {
    let latitud = 0;
    let longitud = 0;
    document.querySelectorAll('#map .mapboxgl-marker').forEach((m) => m.remove());
    navigator.geolocation.getCurrentPosition((e) => {
      latitud = e.coords.latitude;
      longitud = e.coords.longitude;
    });
    const _map = new mapboxgl.Map({
      container: mapContainer.current,
      style: theme.name.toLowerCase() !== 'dark' ? 'mapbox://styles/mapbox/streets-v11'
        : 'mapbox://styles/mapbox/dark-v9',
      center: [longitud || 0, latitud || 0],
      zoom: longitud ? 12 : 9,
    });

    const _features = [...dataSource].filter((item) => item.latitud || item.longitud).map((e) => ({
      type: 'Feature',
      properties: {
        ...e,
        popUp:
        `<div>
          <h3>${e.motivo_de_la_solicitud.descripcion || ''}</h3>
          <p>${e.comentario || ''}</p>
          <a href="/solicitudes/seguimiento?id=${e.id}" >
            Dar Seguimiento
          </a>
        </div>`,
      },
      geometry: {
        type: 'Point',
        coordinates: [e.longitud, e.latitud],
      },
    }));

    _map.on('load', () => {
      _map.addSource('solicitudes', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          crs: {
            type: 'name',
            properties: {
              name: 'urn:ogc:def:crs:OGC:1.3:CRS84',
            },
          },
          features: _features,
        },
        cluster: true,
        clusterMaxZoom: 12,
        clusterRadius: 50,
      });

      _map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'solicitudes',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#51bbd6',
            100,
            '#f1f075',
            750,
            '#f28cb1',
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      });

      _map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'solicitudes',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 14,
        },
      });

      _map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'solicitudes',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#11b4da',
          'circle-radius': 6,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff',
        },
      });

      _map.on('click', 'clusters', (e) => {
        const features = _map.queryRenderedFeatures(e.point, {
          layers: ['clusters'],
        });
        const clusterId = features[0].properties.cluster_id;
        _map.getSource('solicitudes').getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;
            _map.easeTo({
              center: features[0].geometry.coordinates,
              zoom,
            });
          },
        );
      });

      _map.on('click', 'unclustered-point', (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const { popUp } = e.features[0].properties;
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(popUp)
          .addTo(_map);
      });

      _map.on('mouseenter', 'clusters', () => {
        _map.getCanvas().style.cursor = 'pointer';
      });
      _map.on('mouseleave', 'clusters', () => {
        _map.getCanvas().style.cursor = '';
      });
    });

    if (!longitud) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((e) => {
          const _coords = [e.coords.longitude, e.coords.latitude];
          _map.jumpTo({ center: _coords, zoom: 12 });
        });
      }
    }
    setMap(_map);
    setTimeout(resizeMap);

    return () => _map.remove;
  }, [theme, dataSource]);

  useEffect(resizeMap, []);

  return (
    <div style={{ width: '100%' }}>
      <div id="map" style={{ height }} ref={mapContainer} />
    </div>
  );
};

MapaMultiple.propTypes = {
  height: PropTypes.number,
  dataSource: PropTypes.arrayOf(PropTypes.shape()),
};

MapaMultiple.defaultProps = {
  dataSource: [],
  height: 350,
};

export default MapaMultiple;
