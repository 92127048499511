import React, { useEffect, useState } from 'react';
import {
  Row,
  Spin,
  Col,
  Form,
  Input,
  Button,
} from 'antd';
import Table from 'components/Table';
import { onError, onSuccess } from 'utils/handlers';
import API from 'utils/api';
import Select from 'components/Select';
import { RollbackOutlined, SaveOutlined } from '@ant-design/icons';
import { DATE_FRONTEND_FORMAT, getFullName, toMoment } from 'utils/formatValues';
import { useSelector } from 'react-redux';
import { isAdmin } from 'utils/permission';

const baseURI = 'tramites/plantillas-de-retys/';

const ValidacionDeFichasPorAutorizar = () => {
  const user = useSelector(({ auth }) => auth.user);
  const roles = useSelector(({ auth }) => auth.roles);
  const [form] = Form.useForm();
  const estado_de_validacion_de_ficha = Form.useWatch('estado_de_validacion_de_ficha', form);
  const [data, setData] = useState([]);
  const [configFlujos, setConfigFlujos] = useState(null);
  const [estadosDeValidacion, setEstadosDeValidacion] = useState([]);
  const [estadoFicha, setEstadoFicha] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const fetchData = async () => {
    try {
      const [_confFlujosRetys] = (await API.get(
        'tramites/configuraciones-de-flujos-retys/',
        { params: { proceso_de_retys: 1, requiere_validar: true } },
      )).data;
      if (_confFlujosRetys) {
        const _flujos = (await API.get('tramites/flujos-de-retys/',
          { configuracion_de_flujo_de_retys: _confFlujosRetys.id })).data;
        const params = {};
        if (!isAdmin()) {
          if (user.unidad_responsable) {
            params.plantilla_de_tramites__departamentos = user.unidad_responsable.id;
          } else if (user.unidad_operativa) {
            params.dependencia = user.unidad_operativa.id;
          }
        }
        const _plantillasRetys = (await API.get(
          baseURI,
          {
            params: {
              ...params,
              configuracion_de_flujo_de_retys: _confFlujosRetys.id,
              estado_de_validacion_de_ficha: 1,
            },
          },
        )).data;
        const _estados = (await API.get('tramites/estados-de-validacion-de-fichas/')).data;
        setEstadosDeValidacion(_estados);
        const _roles = (await API.get('catalogos/grupos/'))
          .data.map((r) => ({ ...r, name: r.name.split(' - ')[0] }));
        const flujos = _flujos
          .filter((f) => f.configuracion_de_flujo_de_retys === _confFlujosRetys.id);
        _confFlujosRetys.flujos = flujos.map((f) => ({
          ...f,
          roles: f.roles.map((r) => _roles.find((_r) => _r.id === r)),
        }));
        setConfigFlujos(_confFlujosRetys);
        const _data = _plantillasRetys.map((_plantilla) => ({
          id: _plantilla.id,
          folio: _plantilla.folio,
          plantilla_de_tramites: _plantilla.plantilla_de_tramites,
          estado_de_validacion_de_ficha: _estados
            .find((e) => e.id === _plantilla.estado_de_validacion_de_ficha),
          fecha_de_creacion: toMoment(_plantilla.fecha_de_creacion),
          usuario_creo: _plantilla.usuario_creo,
        }));
        setData(_data);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => fetchData(), []);

  // eslint-disable-next-line react/prop-types
  const LabelInfo = ({ label, info }) => (
    <>
      <Col xs={12} sm={12} md={4}>
        {label}
        :
      </Col>
      <Col xs={12} sm={12} md={8}>
        {info}
      </Col>
    </>
  );

  const columns = [
    {
      titleText: 'Ficha de Retys',
      dataIndex: 'id',
      key: 'id',
      width: '100%',
      render: (_, record) => (
        <Row>
          <LabelInfo
            label="Nombre"
            info={record.plantilla_de_tramites.nombre}
          />
          <LabelInfo
            label="Progreso"
            info="0%"
          />
          <LabelInfo
            label="Fecha"
            info={record.fecha_de_creacion?.format(DATE_FRONTEND_FORMAT)}
          />
          <LabelInfo
            label="Folio"
            info={record.folio}
          />
          <LabelInfo
            label="Dependencia"
            info={record.plantilla_de_tramites.departamentos[0]?.unidad_operativa.descripcion}
          />
          <LabelInfo
            label="Estado"
            info={record.estado_de_validacion_de_ficha?.descripcion}
          />
          <LabelInfo
            label="Rol a seguir"
            info={configFlujos.flujos[0]?.roles.map((e) => e.name) || 'No hay flujos registrados'}
          />
          <LabelInfo
            label="Usuario"
            info={getFullName(record.usuario_creo)}
          />
        </Row>
      ),
    },
  ];

  const rules = { required: [{ required: true }] };

  const rowSelection = {
    selectedRowKeys,
    type: 'checkbox',
    getCheckboxProps: (record) => ({
      disabled: record.estado_de_validacion_de_ficha?.id !== 1,
    }),
    onChange: (_selectedRowKeys) => setSelectedRowKeys(_selectedRowKeys),
  };

  const handleOnRowClick = (record) => {
    if (record.estado_de_validacion_de_ficha?.id === 1) {
      if (!selectedRowKeys.includes(record.id)) {
        setSelectedRowKeys([...selectedRowKeys, record.id]);
      } else {
        setSelectedRowKeys(selectedRowKeys.filter((e) => e !== record.id));
      }
      setEstadoFicha(record.estado_de_validacion_de_ficha.id);
    }
  };

  const onCancel = () => {
    setSelectedRowKeys([]);
    form.resetFields();
    setEstadoFicha(null);
  };

  const log = async (key) => {
    const { comentario } = form.getFieldsValue();
    const match = data.find((e) => e.id === key);
    const _estado = estadosDeValidacion
      .find((e) => e.id === estado_de_validacion_de_ficha)?.descripcion;
    const valuesPost = {
      estado: 1,
      comentario: comentario || `Marcado como ${_estado}`,
      movimiento: comentario ? null : `Marcado como ${_estado}`,
      configuracion_de_flujo_de_retys: configFlujos.id,
      plantilla_de_tramites: match.plantilla_de_tramites.id,
    };
    const res = await API.post('tramites/historiales-de-retys/', valuesPost);
    if (res.status === 201) {
      await API.patch(`${baseURI}${key}/`, { estado_de_validacion_de_ficha });
      const valuesByState = {
        // Marcar como Borrador si se autoriza
        2: { estado_de_ficha: 1 },
        // Cancelar si no procede
        3: { estados_globales: 5 },
      };
      const valuesState = valuesByState[estado_de_validacion_de_ficha];
      if (valuesState) {
        await API.patch(`tramites/plantillas-de-tramites/${match.plantilla_de_tramites.id}`,
          valuesState);
      }
    }
    return true;
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const _estado = estadosDeValidacion
        .find((e) => e.id === estado_de_validacion_de_ficha)?.descripcion;
      const promises = selectedRowKeys.map((key) => log(key));
      const [saved] = await Promise.all(promises);
      if (saved) {
        onSuccess({}, `Fichas de Retys marcada como ${_estado}`, 5);
        onCancel();
        await fetchData();
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  if (loading && !configFlujos) {
    return (
      <Row align="center" justify="middle" className="container" style={{ height: '100%' }}>
        <Spin tip="Cargando..." spinning={loading} size="large" />
      </Row>
    );
  }

  if (!loading && !configFlujos) {
    return null;
  }

  if (configFlujos.requiere_validar && !roles.some((r) => r.id === configFlujos.roles)) {
    return (
      <b>No cuentas con el rol necesario</b>
    );
  }

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          scrollToFirstError
        >
          <Row justify="end">
            <Form.Item noStyle>
              <Button
                disabled={!selectedRowKeys.length}
                htmlType="submit"
                type="link"
              >
                <SaveOutlined />
                Guardar
              </Button>
            </Form.Item>
            <Form.Item noStyle>
              <Button
                disabled={!selectedRowKeys.length}
                type="link"
                onClick={onCancel}
              >
                <RollbackOutlined />
                Cancelar
              </Button>
            </Form.Item>
          </Row>
          <Row gutter={10}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item label="Marcar como" name="estado_de_validacion_de_ficha" rules={rules.required}>
                <Select
                  disabled={!selectedRowKeys.length}
                  dataSource={estadosDeValidacion.filter((e) => e.id !== estadoFicha)}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            {estado_de_validacion_de_ficha === 3 && (
              <Col xs={24} sm={24} md={16}>
                <Form.Item label="Observaciones" name="comentario" rules={rules.required}>
                  <Input allowClear />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          mobileColIndex={0}
          rowKey="id"
          handleOnRowClick={handleOnRowClick}
          allowSearch={false}
          allowColumnSelector={false}
        />
      </Spin>
    </Row>
  );
};

export default ValidacionDeFichasPorAutorizar;
