import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Spin,
  Col,
  Row,
  Card,
  Input,
} from 'antd';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import ModalDelete from 'components/ModalDelete';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import Select from 'components/Select';
import { getUsuarios } from 'api/usuarios/usuarios';
import PlainTransfer, { createFetcher } from 'components/PlainTransfer';

const baseURL = 'tramites/ventanillas/';

const Ventanillas = ({ oficina, departamento, mutable }) => {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [roles, setRoles] = useState([]);
  const [dataSourcePlantillas, setDataSourcePlantillas] = useState({ results: [] });

  const getPlantillas = async (params = null) => {
    try {
      const response = await API.get('/tramites/plantillas-de-tramites/', {
        params: {
          ...params,
          departamentos: departamento,
        },
      });
      return response.data;
    } catch (error) {
      onError(error, setLoading);
      return { results: [] };
    }
  };

  const fetchPlantillasDeTramites = createFetcher(getPlantillas);

  const fetchData = async () => {
    if (oficina) {
      const response = await API.get(`${baseURL}/`, { params: { oficina } });
      setData(response.data);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const _usuarios = await getUsuarios();
      setUsuarios(_usuarios);
      const _roles = (await API.get('/catalogos/grupos/'))
        .data.map((e) => ({ ...e, name: e.name.split('-')[0] }));
      setRoles(_roles);
      await fetchData();
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
    setSelectedRowKeys([]);
    setVisibleAlert(false);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      values.oficina = oficina;
      const [key] = selectedRowKeys;
      const response = await (key
        ? API.patch(`${baseURL}${key}`, values) : API.post(baseURL, values));
      onSuccess(response);
      onCancel();
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const onClickAdd = async () => {
    onCancel();
    // await fetchPlantillasDeTramites(
    //   { page: 1 },
    //   setLoading,
    //   [],
    //   { results: [] },
    //   setDataSourcePlantillas,
    // );
    setVisible(true);
  };

  const getPlantilla = async (id) => {
    try {
      const response = await API.get(`tramites/plantillas-de-tramites/${id}`);
      return response.data;
    } catch (error) {
      onError(error);
    }
    return null;
  };

  const onClickEdit = async () => {
    const values = data.find((e) => e.id === selectedRowKeys[0]);
    const promises = values.plantillas_de_tramites.map((id) => getPlantilla(id));
    const results = promises.length
      ? (await Promise.all(promises)).filter((e) => e) : [];
    await fetchPlantillasDeTramites(
      { page: 1 },
      setLoading,
      values.plantillas_de_tramites,
      { results },
      setDataSourcePlantillas,
    );
    setVisible(true);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
    usuario: [
      {
        validator: async (r, val) => {
          if (val && form.getFieldsValue().rol) {
            throw new Error('No puede haber rol y usuario');
          }
        },
      },
    ],
    rol: [
      {
        validator: async (r, val) => {
          if (val && form.getFieldsValue().usuario) {
            throw new Error('No puede haber rol y usuario');
          }
        },
      },
    ],
  };

  const columns = [
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
    },
  ];

  const onClickDelete = () => setVisibleAlert(true);

  const deleteItem = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const response = await API.delete(`${baseURL}/${key}`);
      onSuccess(response);
      onCancel();
      await fetchAll();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            noControls={!mutable}
            allowSort={false}
            allowSearch={false}
            rowKey="id"
            mobileColIndex={0}
          />
        ) : (
          <Card
            title={(
              <FormSubmitControls
                label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Ventanilla`}
                onFinish={onFinish}
                onCancel={onCancel}
                loading={loading}
              />
            )}
          >
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="descripcion"
                    label="Descripción"
                    rules={rules.required}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="usuario"
                    label="Usuario"
                    rules={rules.usuario}
                  >
                    <Select dataSource={usuarios} labelProp="email" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="rol"
                    label="Rol"
                    rules={rules.rol}
                  >
                    <Select dataSource={roles} labelProp="name" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <PlainTransfer
                    fetchData={fetchPlantillasDeTramites}
                    dataSource={dataSourcePlantillas}
                    setDataSource={setDataSourcePlantillas}
                    label="Trámites a atender"
                    formItemName="plantillas_de_tramites"
                    form={form}
                    filterProp="nombre"
                    filterPropLabel="nombre"
                    setLoading={setLoading}
                    rules={rules.required}
                  />
                </Col>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`la Ventanilla ${form.getFieldValue('descripcion')}`}
        />
      </Spin>
    </Row>
  );
};

Ventanillas.propTypes = {
  oficina: PropTypes.number,
  departamento: PropTypes.number,
  mutable: PropTypes.bool.isRequired,
};

Ventanillas.defaultProps = {
  oficina: null,
  departamento: null,
};

export default Ventanillas;
