/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef, useState } from 'react';
import {
  Input,
  Form,
  Typography,
  Col,
  Row,
} from 'antd';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import MapboxWorker from 'mapbox-gl/dist/mapbox-gl-csp-worker';
import { useSelector } from 'react-redux';
import API from 'utils/api';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { logger } from 'utils/debug';

mapboxgl.workerClass = MapboxWorker;
const { REACT_APP_MAPBOX_TOKEN } = process.env;
mapboxgl.accessToken = REACT_APP_MAPBOX_TOKEN;

const Mapa = ({
  form,
  interactive,
  showAddress,
  currentStep,
  height,
  hiddenInputs,
  callbackAddress,
}) => {
  const mapContainer = useRef();
  const theme = useSelector(({ app }) => app.theme);
  const [fullAddress, setFullAddress] = useState();
  const [map, setMap] = useState();

  const getFullAddress = async (lat, lon) => {
    try {
      const baseURL = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';
      const URL = `${baseURL}${lat},${lon}.json`;
      const res = await API.get(URL, {
        params: {
          access_token: mapboxgl.accessToken,
        },
      });
      setFullAddress(res.data?.features[0]?.place_name);
      if (typeof callbackAddress === 'function') {
        callbackAddress(res.data?.features[0]?.place_name);
      }
    } catch (err) {
      logger(err);
    }
  };

  const resizeMap = () => {
    setTimeout(() => {
      if (map) {
        map.resize();
      }
    });
  };

  useEffect(() => {
    document.querySelectorAll('#map .mapboxgl-marker').forEach((m) => m.remove());
    const { longitud, latitud } = form.getFieldsValue();
    const _map = new mapboxgl.Map({
      container: mapContainer.current,
      style: theme.name.toLowerCase() !== 'dark' ? 'mapbox://styles/mapbox/streets-v11'
        : 'mapbox://styles/mapbox/dark-v9',
      center: [longitud || 0, latitud || 0],
      zoom: longitud ? 12 : 9,
      interactive,
    });
    _map.on('load', () => {
      _map.resize();
      if (longitud && latitud) {
        document.querySelectorAll('#map .mapboxgl-marker').forEach((m) => m.remove());
        new mapboxgl.Marker({ draggable: interactive, color: 'red' })
          .setLngLat([longitud || 0, latitud || 0])
          .on('dragend', (evt) => {
            if (showAddress) {
              getFullAddress(evt.target._lngLat.lat, evt.target._lngLat.lng);
            }
            form.setFieldsValue({
              longitud: evt.target._lngLat.lng,
              latitud: evt.target._lngLat.lat,
            });
          })
          .addTo(_map);
      }
    });
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl,
    });
    geocoder.on('result', (e) => {
      const [lon, lat] = e.result.center;
      form.setFieldsValue({ longitud: lon, latitud: lat });
      document.querySelectorAll('#map .mapboxgl-marker').forEach((m) => m.remove());
      const mrkr = new mapboxgl.Marker({ draggable: interactive, color: 'red' })
        .setLngLat(e.result.center)
        .addTo(_map);
      mrkr.on('dragend', (evt) => {
        if (showAddress) {
          getFullAddress(evt.target._lngLat.lat, evt.target._lngLat.lng);
        }
        form.setFieldsValue({
          longitud: evt.target._lngLat.lng,
          latitud: evt.target._lngLat.lat,
        });
      });
    });
    if (interactive) {
      _map.addControl(geocoder);
    }
    if (!longitud) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((e) => {
          _map.resize();
          const _coords = [e.coords.longitude, e.coords.latitude];
          form.setFieldsValue({
            longitud: e.coords.longitude,
            latitud: e.coords.latitude,
          });
          _map.jumpTo({ center: _coords });
          document.querySelectorAll('#map .mapboxgl-marker').forEach((m) => m.remove());
          new mapboxgl.Marker({ draggable: interactive, color: 'red' })
            .setLngLat(_coords)
            .addTo(_map).on('dragend', (evt) => {
              if (showAddress) {
                getFullAddress(evt.target._lngLat.lat, evt.target._lngLat.lng);
              }
              form.setFieldsValue({
                longitud: evt.target._lngLat.lng,
                latitud: evt.target._lngLat.lat,
              });
            });
        });
      }
    }

    if (showAddress) {
      getFullAddress(longitud, latitud);
    }
    setMap(_map);
    setTimeout(resizeMap);

    return () => _map.remove;
  }, [map?._placementDirty]);

  useEffect(resizeMap, [currentStep]);

  return (
    <div style={{ width: '100%' }}>
      {(showAddress && fullAddress) && (
        <div>
          <br />
          <Typography.Text>
            {`Ubicación: ${fullAddress}`}
          </Typography.Text>
        </div>
      )}
      <div id="map" style={{ height }} ref={mapContainer} />
      <Row gutter={10}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item label="Longitud" hidden={hiddenInputs} name="longitud"><Input disabled /></Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item label="Latitud" hidden={hiddenInputs} name="latitud"><Input disabled /></Form.Item>
        </Col>
      </Row>
    </div>
  );
};

Mapa.propTypes = {
  callbackAddress: PropTypes.func,
  form: PropTypes.object.isRequired,
  interactive: PropTypes.bool,
  showAddress: PropTypes.bool,
  currentStep: PropTypes.any,
  height: PropTypes.any,
  hiddenInputs: PropTypes.bool,
};

Mapa.defaultProps = {
  callbackAddress: () => {},
  interactive: true,
  showAddress: false,
  currentStep: null,
  height: 350,
  hiddenInputs: true,
};

export default Mapa;
