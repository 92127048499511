// Constants
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const SET_PERIODO_FISCAL_SELECTED = 'SET_PERIODO_FISCAL_SELECTED';
export const UPDATE_ENTIDAD_INFO = 'UPDATE_ENTIDAD_INFO';
// Initial state
const initialState = {
  isAuthenticated: false,
  token: null,
  user: null,
  roles: [],
  refreshToken: null,
  entidad: null,
};

// Action handlers
const handleLogin = (state, action) => {
  const { payload } = action;
  if (payload) {
    const {
      token,
      refreshToken,
      user,
      roles,
    } = payload;

    const { entidad } = user;
    delete user.entidad;

    return {
      ...state,
      isAuthenticated: true,
      token,
      refreshToken,
      user,
      roles,
      entidad,
    };
  }
  return state;
};

const handleLogout = () => ({
  ...initialState,
});

const handleRefreshToken = (state, action) => {
  const { payload } = action;
  if (payload) {
    return {
      ...state,
      token: payload,
    };
  }
  return state;
};

const handleUserInfo = (state, { payload }) => {
  const {
    user,
    roles,
  } = payload;
  if (roles === undefined) {
    return {
      ...state,
      user,
    };
  }
  return {
    ...state,
    user,
    roles,
  };
};

const handleEntidadInfo = (state, payload) => {
  const clone = { ...state };
  delete clone.entidad;
  const {
    entidad,
  } = payload;
  const { configuracion } = entidad;
  delete entidad.configuracion;
  return {
    ...clone,
    entidad: {
      ...entidad,
      ...configuracion,
    },
  };
};

const handlePeriodoFiscal = (state, payload) => {
  const clone = { ...state };
  delete clone.periodoFiscalSelected;
  return {
    ...clone,
    periodoFiscalSelected: payload.payload,
  };
};

const ACTION_HANDLER = {
  [`${LOGIN}`]: handleLogin,
  [`${LOGOUT}`]: handleLogout,
  [`${UPDATE_USER_INFO}`]: handleUserInfo,
  [`${REFRESH_TOKEN}`]: handleRefreshToken,
  [`${UPDATE_ENTIDAD_INFO}`]: handleEntidadInfo,
  [`${SET_PERIODO_FISCAL_SELECTED}`]: handlePeriodoFiscal,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLER[action.type];
  return handler ? handler(state, action) : state;
};
