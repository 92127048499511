/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Spin,
  Form,
  Button,
  Input,
  Modal,
  Checkbox,
  DatePicker,
} from 'antd';
import {
  DoubleRightOutlined,
  RightOutlined,
} from '@ant-design/icons';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Select from 'components/Select';
import { isMutable } from 'utils/estadosGlobales';
import formatValues, { formatReceived, FULL_DATE_FRONTEND_FORMAT } from 'utils/formatValues';
import { sortItems } from 'utils/sorters';

export const baseURI = '/tramites/flujos-de-retys/';

const options = [
  {
    label: 'Puede Pasar a Borrador',
    value: 'se_puede_pasar_a_borrador',
  },
  {
    label: 'Puede Validar',
    value: 'se_puede_validar',
  },
  {
    label: 'Puede Publicar',
    value: 'se_puede_publicar',
  },
  {
    label: 'Pasar a Borrador',
    value: 'notificar_si_pasa_a_borrador',
  },
  {
    label: 'Validar',
    value: 'notificar_al_validar',
  },
  {
    label: 'Solo a propietario',
    value: 'notificar_al_publicar',
  },
];

const ProcedimientoRetys = ({ selectedRowKey, onChangeData, proceso }) => {
  const [form] = Form.useForm();
  const puedePublicar = Form.useWatch('booleans', form)?.includes('se_puede_publicar');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [roles, setRoles] = useState([]);
  const [mutable, setMutable] = useState(true);

  const fetchData = async () => {
    if (selectedRowKey) {
      try {
        setLoading(true);
        const res = await API
          .get(baseURI, { params: { configuracion_de_flujo_de_retys: selectedRowKey } });
        setData(res.data.sort(sortItems('posicion')));
        setLoading(false);
      } catch (err) {
        onError(err, setLoading);
      }
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resRoles = await API.get('/catalogos/grupos/');
      setRoles(resRoles.data.map((e) => ({ ...e, name: e.name.split(' - ')[0] })) || []);

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    onChangeData(data);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setSelectedRowKeys([]);
    form.resetFields();
    setVisible(false);
    setMutable(true);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      options.forEach((item) => {
        values[item.value] = values.booleans.includes(item.value);
      });
      values.configuracion_de_flujo_de_retys = selectedRowKey;
      values.posicion = values.posicion || data.length + 1;
      delete values.booleans;
      if (selectedRowKey) {
        if (!selectedRowKeys.length) {
          const res = await API.post(baseURI, formatValues(values, {}, true));
          if (res?.status === 201) {
            onSuccess(res, 'Agregado correctamente');
            onCancel();
            await fetchData();
          }
        } else {
          const [key] = selectedRowKeys;
          const res = await API.put(`${baseURI}${key}/`, formatValues(values, {}, true));
          if (res?.status === 200) {
            onSuccess(res, 'Actualizado correctamente');
            onCancel();
            await fetchData();
          }
        }
      } else {
        const [key] = selectedRowKeys;
        if (key) {
          onSuccess({}, 'Actualizado correctamente');
          onCancel();
          setData(data.map((e) => (e.id === key ? { ...e, ...values } : e)));
        } else {
          onSuccess({}, 'Agregado correctamente');
          onCancel();
          values.id = data.length + 1;
          values.posicion = values.id;
          setData([...data, values]);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      if (selectedRowKey) {
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      } else {
        onSuccess({}, 'Eliminado correctamente');
        setVisibleAlert(false);
        onCancel();
        setData(data.filter((e) => e.id !== key)
          .map((e, idx) => ({ ...e, id: idx + 1, posicion: idx + 1 })));
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    const clone = { ...formatReceived(record), booleans: [] };
    options.forEach((opt) => {
      if (clone[opt.value]) {
        clone.booleans.push(opt.value);
      }
      delete clone[opt.value];
    });
    form.setFieldsValue(clone);
    setMutable(isMutable(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Posición',
      dataIndex: 'posicion',
      key: 'posicion',
      width: 100,
    },
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 150,
    },
    {
      titleText: 'Roles',
      dataIndex: 'roles',
      key: 'roles',
      width: 250,
      render: (val) => new Intl.ListFormat('es')
        .format(val.map((r) => roles.find((t) => t.id === r)?.name)),
    },
    {
      titleText: 'Descripción del Flujo',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 250,
    },
  ];

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const moveToLocal = (where) => {
    const [key] = selectedRowKeys;
    const clone = [...data];
    const match = clone.find((item) => item.id === key);
    const filtered = clone.filter((item) => item.id !== key);
    switch (where) {
      case 'first':
        filtered.splice(0, 0, match);
        break;
      case 'up':
        filtered.splice(match.posicion - 2, 0, match);
        break;
      case 'down':
        filtered.splice(match.posicion, 0, match);
        break;
      case 'last':
        filtered.splice(clone.length, 0, match);
        break;
      default:
        break;
    }
    const final = filtered.map((i, idx) => ({ ...i, posicion: idx + 1 }));
    setData(final);
  };

  const moveTo = async (where) => {
    try {
      setLoading(true);
      if (selectedRowKey) {
        const clone = [...data];
        const [key] = selectedRowKeys;
        const match = clone.find((item) => item.id === key);
        let posicion = 0;
        switch (where) {
          case 'first':
            posicion = 1;
            break;
          case 'up':
            posicion = match.posicion - 1;
            break;
          case 'down':
            posicion = match.posicion + 1;
            break;
          case 'last':
            posicion = clone.length;
            break;
          default:
            posicion = match.posicion;
            break;
        }
        const res = await API.patch(`${baseURI}${key}/`, { posicion });
        if (res?.status === 200) {
          await fetchData();
        }
      } else {
        moveToLocal(where);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const SortControls = () => (
    <Col className="sorting">
      <Button
        type="link"
        icon={<DoubleRightOutlined />}
        disabled={!selectedRowKeys.length
          || data.find((e) => e.id === selectedRowKeys[0]).posicion <= 2}
        onClick={() => moveTo('first')}
      />
      <Button
        type="link"
        icon={<RightOutlined />}
        disabled={!selectedRowKeys.length
          || data.find((e) => e.id === selectedRowKeys[0]).posicion < 2}
        onClick={() => moveTo('up')}
      />
      <Button
        type="link"
        icon={<RightOutlined />}
        disabled={!selectedRowKeys.length
          || data.find((e) => e.id === selectedRowKeys[0]).posicion === data.length}
        onClick={() => moveTo('down')}
      />
      <Button
        type="link"
        icon={<DoubleRightOutlined />}
        disabled={!selectedRowKeys.length
          || data.find((e) => e.id === selectedRowKeys[0]).posicion >= data.length - 1}
        onClick={() => moveTo('last')}
      />
    </Col>
  );

  return (
    <Row className="container" id="sort-rows">
      <Spin tip="Cargando..." spinning={loading}>
        <Row>
          <Col style={{ width: 'calc(100% - 50px)' }}>
            <Table
              cols={columns}
              data={data}
              rowSelection={rowSelection}
              handleOnRowClick={handleOnRowClick}
              loading={loading}
              controls={{
                onClickAdd,
                onClickEdit,
                onClickDelete,
              }}
              mobileColIndex={0}
              rowKey="id"
            />
          </Col>
          <SortControls />
        </Row>
        <Modal
          open={visible}
          title={(
            <FormSubmitControls
              label={`${!selectedRowKeys.length ? 'Agregar' : 'Editar'} paso`}
              onFinish={onFinish}
              onCancel={onCancel}
              mutable={mutable}
            />
          )}
          onCancel={onCancel}
          footer={null}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          forceRender
        >
          <Form
            name="general"
            form={form}
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError
            initialValues={{ booleans: [] }}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Nombre"
                  rules={rules.required}
                  name="nombre"
                  hasFeedback
                >
                  <Input.TextArea
                    allowClear
                    disabled={!mutable}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Descripción"
                  rules={rules.required}
                  name="descripcion"
                  hasFeedback
                >
                  <Input.TextArea
                    allowClear
                    disabled={!mutable}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="roles"
                  rules={rules.required}
                  label="Roles"
                  hasFeedback
                >
                  <Select mode="multiple" dataSource={roles} labelProp="name" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="booleans" label="">
                  <Checkbox.Group>
                    <Row>
                      {options.map((item, idx) => (
                        <React.Fragment key={item.value}>
                          <Col span={8}>
                            <Checkbox value={item.value}>{item.label}</Checkbox>
                          </Col>
                          {idx === 2 && (
                            <Col span={24}>
                              <b>Notificar al:</b>
                            </Col>
                          )}
                        </React.Fragment>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
              {(puedePublicar && proceso !== 1) && (
                <Col span={24}>
                  <Form.Item label="Fecha de publicación" name="fecha_de_publicacion">
                    <DatePicker
                      showTime
                      format={FULL_DATE_FRONTEND_FORMAT}
                      placeholder=""
                      disabledDate={(date) => date.isBefore(new Date(), 'day')}
                    />
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Form.Item
                  label="Rol a notificar"
                  name="rol_a_notificar"
                  hasFeedback
                >
                  <Select mode="multiple" dataSource={roles} labelProp="name" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
            <Form.Item name="posicion" hidden>
              <Input />
            </Form.Item>
          </Form>
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content="El Paso seleccionado"
          loading={loading}
        />
      </Spin>
    </Row>
  );
};

export default ProcedimientoRetys;
