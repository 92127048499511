import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Input,
  Card,
  Tabs,
  Checkbox,
} from 'antd';
import {
  ImportOutlined, InfoCircleOutlined,
} from '@ant-design/icons';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import { onSuccess, onError } from 'utils/handlers';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import NumericInput from 'components/NumericInput';

const baseURI = '/tramites/plantillas-de-flujo-de-validacion/';
const { TabPane } = Tabs;

const PlantillaDeFlujoDeValidacion = () => {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [useScript, setUseScript] = useState(false);
  const [puestoResponsable, setPuestoResponsable] = useState(false);
  const [checkedValues, setCheckedValues] = useState([]);
  const [activeTab, setActiveTab] = useState('general');
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI);
      if (response?.status === 200) {
        setData(response.data);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resPuestos = await API.get('/configuracion/puestos/');
      setPuestoResponsable(resPuestos.data || []);

      await fetchData();
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();

    return () => API.source.cancel;
    // eslint-disable-next-line
  }, []);
  const options = [
    {
      label: 'Pasa a Borrador',
      value: 'pasa_a_borrador',
    },
    {
      label: 'Para Validación',
      value: 'para_validacion',
    },
    {
      label: 'Notifica al Propietario',
      value: 'notifica_al_propietario',
    },
    {
      label: 'Valida el Flujo',
      value: 'valida_el_flujo',
    },
    {
      label: 'Regresa a Borrador',
      value: 'regresa_a_borrador',
    },
  ];
  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
    setVisibleAlert(false);
    setCheckedValues([]);
    setActiveTab('general');
  };

  const onChange = (checked) => {
    setCheckedValues(checked);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      options.forEach((item) => {
        values[`${item.value}`] = !!checkedValues.find((i) => i === item.value);
      });
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        await form.validateFields();
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading, [form], setActiveTab);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const handleOnRowClick = (record) => {
    const arr = [];
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
    setUseScript(record.usa_script);
    options.forEach((item) => {
      if (record[item.value]) {
        arr.push(item.value);
      }
    });
    setCheckedValues(arr);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const columns = [
    {
      titleText: 'Indice',
      dataIndex: 'indice',
      key: 'indice',
      width: 300,
    },
    {
      titleText: 'Nombre de Estado de Flujo',
      dataIndex: 'nombre_de_estado_de_flujo',
      key: 'nombre_de_estado_de_flujo',
      width: 300,
    },
    {
      titleText: 'Descripción del Estado de Flujo',
      dataIndex: 'descripcion_del_estado_de_flujo',
      key: 'descripcion_del_estado_de_flujo',
      width: 300,
    },
    {
      titleText: 'Puesto Responsable',
      dataIndex: 'puesto_responsable',
      key: 'puesto_responsable',
      width: 300,
      render: (val) => (puestoResponsable.find((item) => item.id === val)?.descripcion),

    },
  ];

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es Requerido',
      },
    ],
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            mobileColIndex={0}
            rowKey="id"
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            allowExport={false}
          />
        ) : (
          <Card
            bordered={false}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
              />
             )}
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Plantilla de Flujo`}
          >
            <Tabs
              type="card"
              onChange={setActiveTab}
              activeKey={activeTab}
            >
              <TabPane
                tab={(
                  <span>
                    <ImportOutlined />
                    General
                  </span>
              )}
                key="general"
                forceRender
              >
                <Form
                  layout="vertical"
                  form={form}
                  name="general"
                  onFinish={onFinish}
                  scrollToFirstError
                >
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={8}>

                      <NumericInput
                        name="indice"
                        label="Indice"
                        hasFeedback
                        required
                      />

                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="nombre_de_estado_de_flujo"
                        label="Nombre del Estado del Flujo"
                        hasFeedback
                        rules={rules.required}
                      >
                        <Input allowClear />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="descripcion_del_estado_de_flujo"
                        label="Descripción del Estado de Flujo"
                        hasFeedback
                      >
                        <Input allowClear />

                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>

                      <NumericInput
                        name="paso_del_cual_depende"
                        label="Paso del Cual Depende"
                        required
                      />

                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="puesto_responsable"
                        label="Puesto Responsable"
                      >
                        <Select dataSource={puestoResponsable} />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="puestos_a_notificar"
                        label="Puestos a Notificar"
                      >
                        <Select mode="multiple" dataSource={puestoResponsable} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="usa_script"
                        label="¿Usa Script?"
                        hasFeedback
                        rules={rules.required}
                      >
                        <Select onChange={setUseScript} trueFalse />

                      </Form.Item>
                    </Col>
                    {useScript && (
                    <>
                      <Col
                        xs={24}
                        sm={24}
                        md={8}
                      >
                        <Form.Item
                          name="dias_para_ejecutar"
                          label="Dias Para Ejecutar"
                        >
                          <NumericInput
                            required
                          />
                        </Form.Item>
                      </Col>
                    </>
                    )}

                    <Form.Item hidden>
                      <Button htmlType="submit" />
                    </Form.Item>
                  </Row>
                </Form>
              </TabPane>
              <TabPane
                tab={(
                  <span>
                    <InfoCircleOutlined />
                    Información de la Plantilla
                  </span>
                      )}
                key="info"
                forceRender
              >
                <Row gutter={10}>
                  <Checkbox.Group
                    onChange={onChange}
                    value={checkedValues}
                  >
                    <Row>
                      {options.map((item) => (
                        <Col
                          xs={24}
                          sm={24}
                          md={8}
                          key={item.value}
                        >
                          <Checkbox value={item.value}>{item.label}</Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Row>
              </TabPane>
            </Tabs>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content={`Flujo de validacion ${form.getFieldValue('descripcion_del_estado_de_flujo')}`}
        />
      </Spin>
    </Row>
  );
};
export default PlantillaDeFlujoDeValidacion;
