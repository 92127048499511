import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Card,
  DatePicker,
} from 'antd';

import API from 'utils/api';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import getFormatedValues, { DATE_FRONTEND_FORMAT, formatReceived, hasFiles } from 'utils/formatValues';
import Select from 'components/Select';
import { onError, onSuccess } from 'utils/handlers';
import Uploader from 'components/Uploader';

const baseURI = '/configuracion/fundamentos-legales/';

const FundamentosLegales = () => {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [nombreDeOrdenamiento, setNombreDeOrdenamiento] = useState([]);
  const [ambito, setAmbito] = useState([]);
  const [tipoDeAmbito, setTipoDeAmbito] = useState([]);

  const fetchData = async () => {
    try {
      const res = await API.get(baseURI);
      if (res?.status === 200) {
        setData(formatReceived(res.data));
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      const resAmbito = await API.get('/catalogos/ambito-de-ordenamiento/');
      setAmbito(resAmbito.data || []);
      const resTipoAmbito = await API.get('/catalogos/tipo-de-ordenamiento/');
      setTipoDeAmbito(resTipoAmbito.data || []);
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const files = ['documento'];
      const config = hasFiles(values, files) ? {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      } : {};
      const formattedvalues = getFormatedValues(values, {
        files,
        formData: config.headers,
        clean: true,
      });
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, formattedvalues, config);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        await form.validateFields();
        const response = await API.patch(`${baseURI}${key}/`, formattedvalues, config);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          onCancel();
          await fetchData();
          setVisibleAlert(false);
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };
  const filter = async () => {
    const { ambito_de_ordenamiento, tipo_de_ordenamiento } = form.getFieldsValue();
    if (ambito_de_ordenamiento && tipo_de_ordenamiento) {
      const resNombre = await API.get('/catalogos/nombre-de-ordenamiento/', {
        params: { ambito_de_ordenamiento, tipo_de_ordenamiento },
      });
      setNombreDeOrdenamiento(formatReceived(resNombre.data || []));
    } else {
      setNombreDeOrdenamiento([]);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue({
      ...record,
      nombre_de_ordenamiento: record?.nombre_de_ordenamiento.id,
      ambito_de_ordenamiento: record?.nombre_de_ordenamiento.ambito_de_ordenamiento.id,
      tipo_de_ordenamiento: record?.nombre_de_ordenamiento.tipo_de_ordenamiento.id,
    });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = async () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
    hipervinculo: [
      {
        type: 'url',
        message: 'Ingrese un hipervínculo válido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Área Responsable',
      dataIndex: 'area_responsable',
      key: 'area_responsable',
      width: 300,
    },
    {
      titleText: 'Denominación',
      dataIndex: 'denominacion',
      key: 'denominacion',
      width: 300,
    },
    {
      titleText: 'Artículo',
      dataIndex: 'articulo',
      key: 'articulo',
      width: 200,
    },
    {
      titleText: 'Normatividad',
      dataIndex: 'normatividad',
      key: 'normatividad',
      width: 200,
    },
    {
      titleText: 'Fecha de Publicación',
      dataIndex: 'fecha_publicacion',
      key: 'fecha_publicacion',
      width: 300,
      render: (val) => val?.format('DD/MM/YYYY'),
    },

  ];

  const allowedExtensions = [
    'pdf',
  ];

  const fillFields = (val) => {
    if (val) {
      const clone = [...nombreDeOrdenamiento];
      const match = clone.find((i) => i.id === val);
      form.setFieldsValue({
        denominacion: match?.descripcion || '',
        fecha_publicacion: match?.fecha_de_publicacion || '',
        fecha_de_vigor: match?.fecha_de_vigor || '',
      });
    }
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            rowKey="id"
            mobileColIndex={0}
          />
        ) : (
          <Card
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Fundamento Legal`}
            extra={(
              <FormSubmitControls onFinish={onFinish} onCancel={onCancel} />
            )}
            bordered={false}
          >

            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              scrollToFirstError
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="ambito_de_ordenamiento"
                    label="Ámbito del Ordenamiento"
                    hasFeedback
                  >
                    <Select
                      dataSource={ambito}
                      onChange={filter}
                      rules={rules.required}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="tipo_de_ordenamiento"
                    label="Tipo Ordenamiento"
                    hasFeedback
                    rules={rules.required}
                  >
                    <Select
                      dataSource={tipoDeAmbito}
                      onChange={filter}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="nombre_de_ordenamiento"
                    label="Nombre del Ordenamiento"
                    hasFeedback
                    rules={rules.required}
                  >
                    <Select
                      dataSource={nombreDeOrdenamiento}
                      onChange={fillFields}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="denominacion"
                    label="Denominación"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="area_responsable"
                    label="Área responsable"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="articulo"
                    label="Artículo"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="hipervinculo"
                    label="Hipervínculo"
                    rules={rules.hipervinculo}
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="fecha_publicacion"
                    label="Fecha de publicación"
                    hasFeedback
                  >
                    <DatePicker
                      format={DATE_FRONTEND_FORMAT}
                      placeholder=""
                    // disabledDate={(date) => date.isAfter(new Date())}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="fecha_de_vigor"
                    label="Fecha de Vigor"
                    hasFeedback
                  >
                    <DatePicker
                      placeholder=""
                      format={DATE_FRONTEND_FORMAT}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="fraccion"
                    label="Fracción"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="inciso"
                    label="Inciso"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="parrafo"
                    label="Párrafo"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="numero"
                    label="Número"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="letra"
                    label="Letra"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="otro_fundamento_legal"
                    label="Otro Fundamento Legal"
                    hasFeedback
                  >

                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Uploader
                  title="Documento"
                  limitMB={50}
                  file={form.getFieldValue('documento')}
                  formItemName="documento"
                  allowedExtensions={allowedExtensions}
                  onError={onError}
                  tooltip={{
                    title: `Extensiones Permitidas para el Documento: ${allowedExtensions}`,
                  }}
                />
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content={`Fundamento Legal Responsable del Área ${form.getFieldValue('area_responsable')}`}
        />
      </Spin>
    </Row>
  );
};

export default FundamentosLegales;
