import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Card,
} from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import FormSubmitControls from 'components/FormSubmitControls';
import FormSubmitCustomControls from 'components/FormSubmitCustomControls';
import API from 'utils/api';
import getFormattedValues, { hasFiles, formatReceived } from 'utils/formatValues';

import { onError, onSuccess } from 'utils/handlers';
import Select from 'components/Select';
import NumericInput from 'components/NumericInput';
import PlainTransfer from 'components/PlainTransfer';
import Uploader from 'components/Uploader';
import FundamentosTransfer from 'components/Catalogos/FundamentosTransfer';

const baseURI = '/tramites/requisitos/';

const RequisitosTransfer = ({
  formTable,
  formItemName,
  rules: transferRules,
  onChangeRequisitos,
  setDataSourceRequisitos,
  dataSourceRequisitos,
}) => {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [hasVigencia, setHasVigencia] = useState(false);
  const [allowsEdoc, setAllowsEdoc] = useState(false);
  const [requisitosTemp, setRequisitosTemp] = useState({ results: [] });
  const requisitos = useRef({ results: [] });
  const [emisorDocumento, setEmisorDocumento] = useState([]);
  const [tipoDeRequisito, setTipoDeRequisito] = useState([]);
  const [listaDeAcceso, setListaDeAcceso] = useState([]);
  const [docParaTramite, setDocParaTramite] = useState([]);
  const [institucionDeOrigen, setInsitucionDeOrigen] = useState([]);
  const [unidadPlazo, setUnidadPlazo] = useState([]);

  const fetchData = async () => {
    try {
      const response = await API.get(baseURI);
      setDataSourceRequisitos(formatReceived(response.data.map((e) => ({
        ...e,
        descripcion: `${e?.descripcion || ''} - 
        No. de Originales : ${e?.cantidad_de_originales || ''} -
        No. de Copias: ${e?.cantidad_de_copias} - 
        Observaciones : ${e?.observaciones || '-'} 
        `,
      }))));
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const getRequisitos = async (params = null) => {
    try {
      const resCatReq = await API.get('/catalogos/catalogos-de-requisitos', { params });
      return {
        ...resCatReq.data,
        results: formatReceived(resCatReq.data.results),
      };
    } catch (error) {
      onError(error, setLoading);
      return { results: [] };
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resPlazo = await API.get('/catalogos/unidades-de-plazo');
      setUnidadPlazo(resPlazo.data || []);
      const resDocTramite = await API.get('/empresas/tipos-de-documento-para-tramites/');
      setDocParaTramite(resDocTramite.data || []);
      const resAcceso = await API.get('/tramites/tipos-de-accesos/');
      setListaDeAcceso(resAcceso.data || []);
      const resEmisores = await API.get('/tramites/emisores-de-documentos/');
      setEmisorDocumento(resEmisores.data || []);
      const resInstitucion = await API.get('/tramites/instituciones-de-origen/');
      setInsitucionDeOrigen(resInstitucion.data || []);
      const resTipoReq = await API.get('/tramites/tipos-de-requisitos/');
      setTipoDeRequisito(resTipoReq.data || []);

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.source.cancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
    requisitos.current = { results: [] };
    setRequisitosTemp({ results: [] });
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const files = ['documento_anexo'];
      const config = hasFiles(values, files) ? {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      } : {};
      const formattedvalues = getFormattedValues(values, {
        files,
        formData: config.headers,
        clean: true,
      });
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, formattedvalues, config);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        await form.validateFields();
        const response = await API.put(`${baseURI}${key}/`, formattedvalues);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClick = () => {
    setVisible(true);
    setSelectedRowKeys([]);
    form.resetFields();
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
    url: [
      {
        type: 'url',
        message: 'Ingrese un URL válido',
      },
    ],
  };

  const allowedExtensions = ['PDF', 'PNG', 'JPG'];

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Row>
            <Col span={24}>
              <Row justify="end">
                <FormSubmitCustomControls
                  controls={[
                    {
                      text: 'Agregar Requisito',
                      onClick,
                      icon: <PlusOutlined />,
                    },
                  ]}
                />
              </Row>
            </Col>
            <Col span={24}>
              <PlainTransfer
                dataSource={dataSourceRequisitos}
                label="Requisitos"
                form={formTable}
                formItemName={formItemName}
                filterProp="descripcion"
                rules={transferRules}
                onChange={onChangeRequisitos}
              />
            </Col>
          </Row>
        ) : (
          <Card
            bordered={false}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
              />
            )}
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Requisito`}
          >
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              scrollToFirstError
              initialValues={{ fundamento_legal: [] }}
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="catalogo_de_requisitos"
                    label="Catálogo de Requisitos"
                    hasFeedback
                    rules={rules.required}
                  >
                    <Select
                      dataSource={
                        requisitosTemp.results?.length
                          ? requisitosTemp : requisitos.current
                      }
                      usingExternalDataSource={Boolean(requisitosTemp.results?.length)}
                      onClear={() => {
                        form.resetFields(['conceptos_de_ingreso']);
                        setRequisitosTemp({ results: [] });
                      }}
                      pagination={{
                        getter: getRequisitos,
                        setter: (dataSource) => {
                          requisitos.current = dataSource;
                        },
                        config: { auto: true, qName: 'nombre' },
                      }}
                      labelProp="nombre"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={16}>
                  <Form.Item
                    name="descripcion"
                    label="Nombre"
                    hasFeedback
                    rules={rules.required}
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="url"
                    rules={rules.url}
                    label="URL"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Cantidad de Originales"
                    name="cantidad_de_originales"
                    required
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Cantidad de Copias"
                    name="cantidad_de_copias"
                    required
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="tipo_de_requisito"
                    label="Tipo de Requisito"
                    hasFeedback
                    rules={rules.required}
                  >
                    <Select dataSource={tipoDeRequisito} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="uso_adicional"
                    label="Uso Adicional"
                    hasFeedback
                    tooltip="Uso adicional en procesos internos"
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="institucion_de_origen"
                    label="Institución de Origen"
                    hasFeedback
                  >
                    <Select dataSource={institucionDeOrigen} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="lista_de_acceso"
                    label="Forma de acceso electrónico"
                    hasFeedback
                    tooltip="Estación de trabajo a la que se le permite el acceso"
                  >
                    <Select dataSource={listaDeAcceso} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="emisor_del_documento"
                    label="Emisor del Documento"
                    hasFeedback
                  >
                    <Select dataSource={emisorDocumento} />

                  </Form.Item>
                </Col>
                {!allowsEdoc ? (
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="permite_documento_electronico"
                      label="¿Permite Documento Electrónico?"
                      hasFeedback
                      tooltip="¿Se puede recibir electrónicamente?"
                    >
                      <Select onChange={setAllowsEdoc} trueFalse />
                    </Form.Item>
                  </Col>
                ) : (
                  <>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="permite_documento_electronico"
                        label="¿Recepción electrónica?"
                        hasFeedback
                      >
                        <Select onChange={setAllowsEdoc} trueFalse />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="requiere_almacenar_datos"
                        label="¿Requiere Almacenar Datos?"
                        hasFeedback
                        tooltip="¿Requiere almacenar el dato?"
                      >
                        <Select trueFalse allowClear />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="proteccion_de_datos_personales"
                        label="¿Proteger Datos Personales?"
                        hasFeedback
                      >
                        <Select trueFalse allowClear />
                      </Form.Item>
                    </Col>
                    <Uploader
                      title="Documento Anexo"
                      limitMB={50}
                      file={form.getFieldValue('documento_anexo')}
                      formItemName="documento_anexo"
                      allowedExtensions={allowedExtensions}
                      onError={onError}
                      tooltip={`Extensiones Permitidas para el Documento: ${allowedExtensions}`}
                    />
                  </>
                )}
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="tipo_de_documento_para_tramite"
                    label="Tipo de Documento para Trámite"
                    hasFeedback
                  >
                    <Select dataSource={docParaTramite} labelProp="nombre" />

                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="tiene_vigencia"
                    label="¿Tiene Vigencia?"
                    hasFeedback
                  >
                    <Select onChange={setHasVigencia} trueFalse />

                  </Form.Item>
                </Col>
                {hasVigencia && (
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                >
                  <Form.Item
                    name="vigencia_en_dias"
                    label="Vigencia"
                  >
                    <NumericInput
                      required
                    />
                  </Form.Item>
                </Col>
                )}
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="parte_del_formato"
                    label="¿Parte del formato?"
                    tooltip="Forma parte de un formato: si/no"
                  >
                    <Select trueFalse />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="unidad_de_plazo"
                    label="Unidad de Plazo"
                    tooltip="Tiempo promedio en conseguir requisito para su presentación:
                    Tipo"
                  >
                    <Select dataSource={unidadPlazo} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    name="tiempo_promedio_requisito"
                    label="Tiempo Promedio del Requisito"
                    tooltip="Tiempo promedio en conseguir el requisito para su presentación:
                    Número de Días"
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="visto_bueno"
                    label="¿Requiere visto bueno?"
                    tooltip="Es necesario alguna firma, validación certificación, autorización o
                    visto bueno de un tercero."
                  >
                    <Select trueFalse />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="requisito_de_un_tramite"
                    label="¿Requisito de un Trámite?"
                    tooltip="El requisito solicitado es un trámite que se debe realizar
                    con alguna dependencia gubernamental."
                  >
                    <Select trueFalse />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    name="observaciones"
                    label="Breve Descripción del Documento"
                  >
                    <Input.TextArea allowClear autoSize={{ minRows: 2, maxRows: 4 }} />
                  </Form.Item>
                </Col>
                <FundamentosTransfer
                  formFundamento={form}
                />
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          </Card>
        )}
      </Spin>
    </Row>
  );
};

RequisitosTransfer.propTypes = {
  formTable: PropTypes.shape({}).isRequired,
  formItemName: PropTypes.string,
  rules: PropTypes.arrayOf(PropTypes.shape({})),
  dataSourceRequisitos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChangeRequisitos: PropTypes.func.isRequired,
  setDataSourceRequisitos: PropTypes.func.isRequired,
};

RequisitosTransfer.defaultProps = {
  formItemName: 'requisitos',
  rules: [],
};

export default RequisitosTransfer;
