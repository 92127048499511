import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Card,
  message,
  Input,
} from 'antd';

import Table from 'components/Table';
import Separador from 'components/Separador';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import { isMutable } from 'utils/estadosGlobales';
import SegmentosFoliador from './SegmentosFoliador';

const Foliadores = () => {
  const [form] = Form.useForm();
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);

  const [segmentos, setSegmentos] = useState([]);
  const [mutable, setMutable] = useState(true);

  const baseURL = 'configuracion/configuraciones-de-segmentos-de-folios/';

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURL);
      if (res?.status === 200) {
        setData(res.data);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setMutable(true);
    setVisibleAlert(false);
  };

  const postSegmentos = async (id) => {
    const promises = [];
    segmentos.forEach((segmento) => {
      const promise = API.post('configuracion/segmentos-de-folios/', {
        ...segmento,
        configuracion_de_folio: id,
      });
      promises.push(promise);
    });
    if (promises.length && id) {
      await Promise.all(promises);
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const hasConsecutivo = segmentos.some((s) => s.tipo_de_segmento === 1);
      if (!selectedRowKeys.length) {
        if (hasConsecutivo) {
          const response = await API.post(baseURL, values);
          if (response?.status === 201) {
            await postSegmentos(response.data.id);
            onSuccess(response, 'Agregado correctamente');
            onCancel();
            await fetchData();
          }
        }
      } else {
        const [rowKey] = selectedRowKeys;
        const response = await API.put(`${baseURL}${rowKey}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      if (selectedRowKeys.length) {
        if (mutable) {
          const [rowKey] = selectedRowKeys;
          const response = await API.delete(`${baseURL}${rowKey}/`);
          if (response?.status === 204) {
            onSuccess(response, 'Eliminado correctamente');
            onCancel();
            await fetchData();
          }
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
    setMutable(isMutable(record.estados_globales));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    setVisible(true);
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    if (mutable) {
      setVisibleAlert(true);
    } else {
      message.info('No se puede eliminar un Foliador autorizado', 2.5);
      onCancel();
    }
  };

  const columns = [
    {
      titleText: 'Separador',
      dataIndex: 'separador',
      key: 'separador',
      width: 100,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 150,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 150,
      render: (val) => estadosGlobales.find((t) => t.id === val)?.descripcion,
    },
  ];

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin
        tip="Cargando..."
        spinning={loading}
      >
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            loading={loading}
            mobileColIndex={0}
            rowKey="id"
          />
        ) : (
          <Card
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Foliador`}
            extra={(
              <FormSubmitControls
                // allowAuthorize
                // baseURI={baseURL}
                onFinish={mutable ? onFinish : false}
                onCancel={onCancel}
                mutable={mutable}
                loading={loading}
              />
            )}
            bordered={false}
          >
            <Col span={24}>
              <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                scrollToFirstError
                initialValues={{ estados_globales: 1 }}
              >
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="descripcion"
                      rules={rules.required}
                      label="Descripción"
                      hasFeedback
                    >
                      <Input allowClear />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={8}>
                    <Separador disabled={!mutable} rule={rules.required} />
                  </Col>

                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="estados_globales"
                      rules={rules.required}
                      label="Estado"
                      hasFeedback
                    >
                      <Select
                        disabled={!selectedRowKeys.length
                          || !mutable}
                        dataSource={estadosGlobales}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <SegmentosFoliador
                      idConfiguracion={selectedRowKeys[0]}
                      setSegmentos={setSegmentos}
                      isMutable={mutable}
                    />
                  </Col>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Row>
              </Form>
            </Col>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`Foliador ${form.getFieldValue('descripcion')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
};

export default Foliadores;
