/* eslint-disable import/no-dynamic-require */
import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Typography,
  message,
} from 'antd';
import { useDispatch } from 'react-redux';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import '../index.scss';
import { useHistory } from 'react-router-dom';
import API from 'utils/api';
import fetchSome from 'utils/fetchSome';
import { onError } from 'utils/handlers';
import { defSigob, themesAsArray } from 'themes';
import { SET_PERIODO_FISCAL_SELECTED, LOGIN, LOGOUT } from 'store/reducers/auth';
import { CLEAN, SET_THEME } from 'store/reducers/app';
import { CLEAR } from 'store/reducers/catalogs';
import styled from 'styled-components';

const { Title } = Typography;
const ENV_NAME = process.env.REACT_APP_ENV_NAME || 'migob';
const logo = require(`../../../assets/${ENV_NAME}/logo.png`);
const bg = require(`../../../assets/${ENV_NAME}/bg.png`);

const styles = {
  migob: {
    bgColor: '#eef3f7',
    fiColor: '',
    footerBg: '#184759',
    btnColor: '#184759',
    linkBtnColor: '#D2B25B',
    linkBtnActiveColor: '#184759',
    tittleColor: '#FFFFFF',
    txtShaddow: '',
  },
  nayarit: {
    bgColor: '#4F494C',
    fiColor: '#FFFFFF',
    footerBg: '#4F494C',
    btnColor: '#6C3440',
    linkBtnColor: '#FFFFFF',
    linkBtnActiveColor: '#6C3440', // no lika
    tittleColor: '#FFFFFF',
    txtShaddow: '',
  },
  repuve: {
    bgColor: '#4F494C',
    fiColor: '#FFFFFF',
    footerBg: '#4F494C',
    btnColor: '#6C3440',
    linkBtnColor: '#FFFFFF',
    linkBtnActiveColor: '#6C3440', // no lika
    tittleColor: '#FFFFFF',
    txtShaddow: '',
  },
  juarez: {
    bgColor: '#79898F',
    fiColor: '#FFFFFF',
    footerBg: 'transparent',
    btnColor: '#55283e',
    linkBtnColor: '#FFFFFF',
    linkBtnActiveColor: '#5F2A46',
    tittleColor: '#5F2A46',
    txtShaddow: '0px 4px 4px rgba(255, 255, 255, 0.25)',
  },
}[ENV_NAME];

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch({ type: LOGOUT });
    dispatch({ type: CLEAN });
    dispatch({ type: CLEAR });
  }, []);

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const response = await API.post('usuarios/login/', values);
      if (response?.status === 200) {
        const resInfoUser = await API.get('usuarios/id/', {
          headers: {
            authorization: `Bearer ${response.data.access}`,
          },
        });
        if (resInfoUser?.status === 200) {
          const { data: infoUser } = resInfoUser;
          const token = response.data.access;
          if (infoUser.entidad) {
            const theme = themesAsArray.find((t) => t.name === infoUser.tema) || defSigob;
            dispatch({
              type: SET_THEME,
              payload: theme,
            });
            dispatch({
              type: LOGIN,
              payload: {
                token,
                refreshToken: response.data.refresh,
                user: { ...infoUser },
                last_login: response.data.last_login,
                roles: infoUser.roles,
              },
            });
            dispatch({
              type: SET_PERIODO_FISCAL_SELECTED,
              payload: infoUser.entidad.periodo_fiscal_vigente,
            });
            await fetchSome('catalogos/estados-globales/', 'SET_ESTADOS_GLOBALES');
          } else {
            message.warn('No se ha podido iniciar sesión');
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rules = {
    email: [{ type: 'email', required: true }],
    password: [{ required: true }],
  };

  return (
    <Container
      align="center"
      justify="end"
      className="container-login"
    >
      <Footer>
        <Title
          style={{
            fontWeight: 'bold',
            marginBottom: 15,
            color: styles.tittleColor,
            textShadow: styles.txtShaddow,
          }}
          level={2}
        >
          Planificador de Trámites
        </Title>
      </Footer>
      <Col xs={0} sm={0} md={18} className="background-container" />
      <LoginFormWrapper xs={24} sm={24} md={6}>
        <Spin tip="Cargando..." spinning={loading} style={{ width: '100%', height: '100%' }}>
          <LoginFormContainer md={24} sm={16}>
            <Row>
              <img
                src={logo}
                width="100"
                alt="logo"
              />
            </Row>
            <br />
            {ENV_NAME === 'migob' && (
              <Title
                style={{
                  fontWeight: 'bold',
                  color: 'gray',
                }}
                level={2}
              >
                Bienvenido
              </Title>
            )}
            <br />
            <Form
              name="login"
              layout="vertical"
              form={form}
              onFinish={onFinish}
            >
              <Form.Item
                name="email"
                rules={rules.email}
                label="Correo"
              >
                <Input prefix={<MailOutlined />} />
              </Form.Item>
              <Form.Item
                name="password"
                rules={rules.password}
                label="Contraseña"
              >
                <Input.Password placeholder="Introduzca Contraseña" prefix={<LockOutlined />} />
              </Form.Item>

              <Form.Item style={{ height: 32, marginBottom: 0 }}>
                <Button
                  type="link"
                  style={{ width: '100%', textAlign: 'right' }}
                  className="auth-button"
                  onClick={() => history.push('/recuperar-contrasena')}
                >
                  ¿Olvidó su contraseña?
                </Button>
              </Form.Item>

              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  className="auth-button"
                  htmlType="submit"
                  shape="round"
                  disabled={loading}
                  block
                >
                  Iniciar sesión
                </Button>
              </Form.Item>
            </Form>
          </LoginFormContainer>
        </Spin>
      </LoginFormWrapper>
    </Container>
  );
};

const Footer = styled(Col)`
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: transparent;
  opacity: .8;
  width: 75%;
  height: 110px;
  z-index: 0;
  background-color: ${styles.footerBg};
  z-index: 2;

  .ant-typography {
    color: #FFFFFF;
    line-height: 110px;
    font-size: xx-large;
    text-align: center;
  }

  @media only screen and (min-height: 700px), (min-width: 1000px) {
    display: unset;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

const LoginFormWrapper = styled(Col)`
  z-index: 3;
  @media only screen and (max-height: 700px) and (max-width: 1000px) {
    width: 100vw !important;
    height: calc(100vh - 110px) !important;
  }

  @media only screen and (max-height: 700px) and (max-width: 1000px) {
    height: 100vh !important;
  }
`;

const LoginFormContainer = styled(Col)`
  border-radius: 12px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: ${styles.bgColor} !important;

  @media only screen and (min-width: 768px) {
    border-radius: 0;
  }

  #login {
    padding: 0 15px;
    width: 100%;
    max-width: 300px;
  }

  .ant-form-vertical .ant-form-item-label>label {
    height: 22px;
    text-transform: capitalize;
    color: ${styles.fiColor};
  }
`;

const Container = styled(Row)`
  height: 100vh;
  background-color: #eef3f7 !important;
  overflow: hidden;
  .background-container{
    background-size: cover;
    background-image: url(${bg});
    background-position: center 0;
    z-index: 1;
  }

  .ant-spin-nested-loading>div>.ant-spin {
    max-height: 100vh !important;
  }

  .ant-spin-blur {
    opacity: 1;
  }

  .ant-spin-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .auth-button:not(.ant-btn-link) {
    background-color: ${styles.btnColor} !important;
    color: #FFFFFF !important;

    &:hover {
      border: none !important;
    }
  }

  .auth-button.ant-btn-link * {
    color: ${styles.linkBtnColor} !important;
  }
  .auth-button.ant-btn-link:hover * {
    color: ${styles.linkBtnActiveColor} !important;
  }
`;

export default Login;
