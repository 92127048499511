/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Spin,
  Col,
  Row,
  message,
} from 'antd';

import {
  CheckCircleOutlined,
} from '@ant-design/icons';

import API from 'utils/api';
import {
  onError,
  onSuccess,
} from 'utils/handlers';
import Select from 'components/Select';
import { isMutable } from 'utils/estadosGlobales';

const baseURI = '/configuracion/configuraciones-de-uso-de-folios/';

const ConfiguracionDeFolios = ({
  setForm,
  disabled,
  setCurrentTabKey,
  callback,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [folios, setFolios] = useState([]);
  const [usos, setUsos] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      form.onFinishHandler = onFinishHandler;
      setForm(form);
      const res = await API.get(baseURI);
      const mappedData = res.data.map((e) => ({ ...e, mutable: isMutable(e.estados_globales) }));

      setData(mappedData);

      if (mappedData?.length) {
        const entries = mappedData.map((e) => [e.uso_de_folio, e.configuracion_de_folio]);
        const values = Object.fromEntries(entries);
        form.setFieldsValue(values);
      }

      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resFolios = await API.get('/configuracion/configuraciones-de-segmentos-de-folios/', {
        params: {
          estados_globales: 4,
        },
      });
      setFolios(resFolios.data);

      const resUsos = await API.get('/configuracion/usos-de-folios/');
      setUsos(resUsos.data);

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const showAddressMsg = (tabKey) => {
    message.info({
      content: (
        <>
          <br />
          <Row style={{ width: '100%' }}>
            Los cambios que hayan sido realizados serán descartados.
          </Row>
          <Row align="middle" style={{ width: '100%' }}>
            ¿Desea continuar?
            <Button
              type="link"
              onClick={() => {
                setCurrentTabKey(tabKey);
                message.destroy();
                fetchData();
              }}
            >
              Si
            </Button>
            <Button
              type="link"
              onClick={() => message.destroy()}
            >
              No
            </Button>
          </Row>
        </>
      ),
    });
  };

  const onFinishHandler = async (tabKey = null, _continue = true) => {
    try {
      if (tabKey) {
        showAddressMsg(tabKey);
      } else {
        await onFinish(null, _continue);
      }
    } catch (err) {
      onError(err);
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const results = [];
      let res;
      const keys = Object.keys(values);
      const clone = [...data];
      keys.forEach((e) => {
        const uso_de_folio = parseInt(e, 10);
        res = {
          uso_de_folio,
          configuracion_de_folio: values[e],
        };
        results.push(res);
      });

      const promises = [];
      results.forEach((e) => {
        const configMatch = clone
          .find((i) => i.configuracion_de_folio === e.configuracion_de_folio);
        let promise;
        if (configMatch) {
          promise = API.put(`${baseURI}${configMatch.id}/`, e);
        } else {
          promise = API.post(baseURI, e);
        }
        promises.push(promise);
      });

      if (promises?.length) {
        const responses = await Promise.all(promises);
        onSuccess(responses, 'Actualizado Correctamente');
        await fetchData();
      }

      callback();

      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rules = {
    required: [
      {
        required: !disabled,
        message: 'El campo es requerido',
      },
    ],
  };

  const updateConfig = async (values) => {
    try {
      setLoading(true);
      const res = await API.put(`${baseURI}${values.id}/`, {
        ...values,
        estados_globales: 4,
      });
      if (res.status === 200) {
        onSuccess(res, 'Autorizado');
        await fetchData();
      }

      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  return (
    <Spin tip="Cargando..." spinning={loading}>
      <br />
      <Col span={24}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{ estados_globales: 1 }}
          name="folios"
        >
          <Row gutter={10}>
            {usos.map((i) => {
              const clone = [...data];
              const match = clone.find((e) => e.uso_de_folio === i.id
                && e.configuracion_de_folio === form.getFieldValue(`${i.id}`));

              return (
                <Col xs={24} sm={24} md={8} key={i.id}>
                  <Form.Item
                    name={i.id}
                    rules={rules.required}
                    label={i.nombre}
                  >
                    <Select
                      dataSource={folios.map((e) => ({
                        ...e,
                        disabled: clone
                          .find((item) => item.configuracion_de_folio === e.id),
                      }))}
                      disabled={disabled}
                    />
                  </Form.Item>
                  {(match?.mutable && form.getFieldValue(`${i.id}`)) && (
                    <Button
                      onClick={() => updateConfig(match)}
                      style={{ width: '100%' }}
                      className="no-color success"
                      type="link"
                    >
                      <CheckCircleOutlined />
                      Autorizar
                    </Button>
                  )}
                </Col>
              );
            })}
          </Row>
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Form>
      </Col>
    </Spin>
  );
};

ConfiguracionDeFolios.propTypes = {
  setForm: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  setCurrentTabKey: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
};

export default ConfiguracionDeFolios;
