import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Card,
  Tabs,
  Input,
  Typography,
  Grid,
  message,
} from 'antd';
import {
  DollarOutlined,
  FileDoneOutlined,
  ImportOutlined,
  InfoCircleOutlined,
  SettingOutlined,
  SolutionOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import { onSuccess, onError } from 'utils/handlers';
import ModalDelete from 'components/ModalDelete';
import TableOficinas from 'components/Catalogos/TableOficinas';
import PlainTransfer, { createFetcher } from 'components/PlainTransfer';
import TableCasos from 'components/TableCasos';
import Select from 'components/Select';
import NumericInput from 'components/NumericInput';
import styled from 'styled-components';
import TablePlazoMax from 'components/Catalogos/TablePlazoMax';
import TableCriterios from 'components/Catalogos/TableCriterios';
import TableComentarioGeneral from 'components/Catalogos/TableComentarioGeneral';
import TableCadenas from 'components/Catalogos/TableCadenas';
import TableSinonimos from 'components/Catalogos/TableSinonimos';
import TablePlazoPrev from 'components/Catalogos/TablePlazoPrevencion';
import TableCosteo from 'components/Catalogos/TableCosteo';
import TableDocumentoSalida from 'components/Catalogos/TableDocumentoDeSalida';
import TableTiposDeResolucion from 'components/Catalogos/TableTiposResolucion';
import TableCanalDePresentacion from 'components/Catalogos/TableCanalPresentacion';
import TableAnexos from 'components/Catalogos/TableAnexos';
import { useSelector } from 'react-redux';
import FundamentosTransfer from 'components/Catalogos/FundamentosTransfer';
import TableFamiliaDelTramite from 'components/Catalogos/TableFamiliaDeTramites';
import TableHechoVital from 'components/Catalogos/TableHechosVitales';
import TableSubSectores from 'components/Catalogos/TableSubSectores';
import getFormatedValues, { hasFiles, toMoment } from 'utils/formatValues';
import { InputSearchPro } from 'components/InputSearch';
import BusquedaAvanzadaUsers from 'components/BusquedaAvanzadaUsers';
import { sortItems } from 'utils/sorters';
import Editar from 'views/MiBuzon/MisFichasEnProceso/Editar';
import { isAdmin } from 'utils/permission';
import RequisitosTransfer from 'components/Catalogos/RequisitosTransfer';
import { getCatalogoDePadrones } from 'api/configuracion/padrones';
import FormCargos from './FormCargos';
import FormAdeudo from './FormAdeudo';
import PasosParaTramites from './PasosParaTramites';

const baseURI = '/tramites/plantillas-de-tramites/';
const { Text } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;

const longLabels = {
  infoBasica1: 'Información Básica I',
  infoBasica2: 'Información Básica II',
  infoBasica3: 'Información Básica III',
  infoComplementaria1: 'Información Complementaria I',
  infoComplementaria2: 'Información Complementaria II',
  interoperabilidad: 'Interoperabilidad',
  costeo: 'Costeo Estándar',
  oficinaDeAtencion: 'Oficina de Atención',
  anexos: 'Formatos Requeridos',
  configurador: 'Configurador',
  citas: 'Citas',
};

const initialValuesCargos = {
  es_tramite_gratuito: true,
  es_concepto_interno: false,
  linea_capturada: false,
  es_cuota_fija: false,
  tipos_de_cargos: [],
  metodologia_de_pago_para_calculo: null,
  clave_de_computo: null,
  periodicidad: null,
  pago_continuo: false,
  pago_moral: false,
  moneda: null,
  aplica_iva: false,
  saldo_a_favor: false,
  parte_actualizada: false,
  recargos: false,
  multa_por_correccion: false,
  seguimiento_por_internet: false,
  pago_de_multiples_tramites: false,
  etapa_de_pago: null,
  descripcion_de_pago: null,
};
const initialValuesConfiguradorGeneral = {
  requiere_padron: false,
  tipo_de_padron: null,
  configuracion_de_padron: null,
  requiere_gis: false,
};
const initialValuesBasica1Descripcion = {
  conservar_informacion: false,
  detalle_conservar_informacion: null,
};
const initialValuesBasica2Canal = { agenda_cita: false, link_de_agenda_cita: null };
const initialValuesComplementariaTics = {
  cuenta_con_sistema_de_gestion: false,
  necesita_sistema_back_office: false,
  seguimiento_por_internet: false,
  pago_electronico: false,
  clave_rupa: false,
};
const initialValues = {
  ...initialValuesCargos,
  ...initialValuesConfiguradorGeneral,
  ...initialValuesBasica1Descripcion,
  ...initialValuesBasica2Canal,
  ...initialValuesComplementariaTics,
};

const Tramite = () => {
  const user = useSelector(({ auth }) => auth.user);
  const padrones = useSelector(({ catalogs }) => catalogs.padrones);
  const periodoFiscalSelected = useSelector(({ auth }) => auth.periodoFiscalSelected);
  // Formularios necesarios
  const [formAdeudo] = Form.useForm();
  const [formCargos] = Form.useForm();
  const [formConfiguracionGeneral] = Form.useForm();
  const [formPadronSecundario] = Form.useForm();
  const [form] = Form.useForm();
  const [formCadena] = Form.useForm();
  const [formCanal] = Form.useForm();
  const [formComentario] = Form.useForm();
  const [formCosteo] = Form.useForm();
  const [formDescripcion] = Form.useForm();
  const [formDocSalida] = Form.useForm();
  const [formFamilia] = Form.useForm();
  const [formHecho] = Form.useForm();
  const [formInter] = Form.useForm();
  const [formInteracciones] = Form.useForm();
  const [formMonto] = Form.useForm();
  const [formOrganizaciones] = Form.useForm();
  const [formPersonas] = Form.useForm();
  const [formPlazoMax] = Form.useForm();
  const [formPlazoPrev] = Form.useForm();
  const [formPlazoReal] = Form.useForm();
  const [formProceso] = Form.useForm();
  const [formResolucion] = Form.useForm();
  const [formSinonimos] = Form.useForm();
  const [formSubSector] = Form.useForm();
  const [formAnexos] = Form.useForm();
  const [formTics] = Form.useForm();
  const [formOficina] = Form.useForm();
  const [formRequisitos] = Form.useForm();
  const [formRequisitosInteroperabilidad] = Form.useForm();
  const [formVariables] = Form.useForm();
  const [formCitas] = Form.useForm();
  const departamentosFormOrganizaciones = Form.useWatch('departamentos', formOrganizaciones);
  const requiere_padron = Form.useWatch('requiere_padron', formPadronSecundario);
  const tipo_de_padron = Form.useWatch('tipo_de_padron', formPadronSecundario);
  const configuracion_de_padron = Form.useWatch('configuracion_de_padron', formPadronSecundario);
  const conservar_informacion = Form.useWatch('conservar_informacion', formDescripcion);
  const agenda_cita = Form.useWatch('agenda_cita', formCanal);
  const cuenta_con_sistema_de_gestion = Form.useWatch('cuenta_con_sistema_de_gestion', formTics);
  const requiere_cita = Form.useWatch('requiere_cita', formCitas);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [procesoTramite, setprocesoTramite] = useState(0);
  const [selectedSub, setSelectedSub] = useState(0);
  const [answerSelected, setAnswerSelected] = useState(0);
  const [visibleAdvancedQueja, setVisibleAdvancedQueja] = useState(false);
  const [visibleAdvancedSupervisor, setVisibleAdvancedSupervisor] = useState(false);
  const [visibleAdvancedResponsable, setVisibleAdvancedResponsable] = useState(false);

  // Variables para Utilizar en selects (Catalogos) ,Tablas externas, etc
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const [casos, setCasos] = useState([]);
  const [cadenas, setCadenas] = useState([]);
  const [criterio, setCriterio] = useState([]);
  const [plazoMax, setPlazoMax] = useState([]);
  const [comentario, setComentario] = useState([]);
  const [costeo, setCosteo] = useState([]);
  const [oficina, setOficina] = useState([]);
  const [sinonimos, setSinonimos] = useState([]);
  // const [tipoDePersona, setTipoDePersona] = useState([]);
  const [catalogosDePago, setCatalogosDeCanales] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [documentoDeSalida, setDocumentoDeSalida] = useState([]);
  const [unidadPlazo, setUnidadPlazo] = useState([]);
  const [canal, setCanal] = useState([]);
  const [anexos, setAnexos] = useState([]);
  const [programaRelacionado, setProgramaRelacionado] = useState();
  const [dependencia, setDependencia] = useState([]);
  const [dependenciaTemp, setDependenciaTemp] = useState();
  const [hecho, setHecho] = useState([]);
  const [ambitoOrdenamiento, setAmbitoOrdenamiento] = useState([]);
  const [moneda, setMoneda] = useState([]);
  const [dataSourceCargos, setDataSourceCargos] = useState({ results: [] });
  const [dataSourceCargosConsulta, setDataSourceCargosConsulta] = useState({ results: [] });
  const [dataSourceRequisitos, setDataSourceRequisitos] = useState([]);
  const [selected, setSelected] = useState();

  // Variables para Datos filtrados
  const [departamentosFiltered, setDepartamentosFiltered] = useState([]);
  const [etapaDePago, setEtapaDePago] = useState([]);

  // Variables para busquedas de usuarios
  const [usuarioQueja, setUsuarioQueja] = useState([]);
  const [usuarioResponsable, setUsuarioResponsable] = useState([]);
  const [usuarioSupervisor, setUsuarioSupervisor] = useState([]);

  const [estadoDeFicha, setEstadoDeFicha] = useState([]);
  const [estadoDeValidacionDeFicha, setEstadoDeValidacionDeFicha] = useState([]);
  const [familia, setFamilia] = useState([]);
  const [meses, setMeses] = useState([]);
  const [plazoPrevencion, setPlazoPrevencion] = useState([]);
  const [subSectores, setSubSector] = useState([]);
  const [tipoDeTramite, setTipoDeTramite] = useState([]);
  const [tipoResolucion, setTipoResolucion] = useState([]);
  const [contenTypesTramites, setContenTypesTramites] = useState([]);
  const [tecTramite, setTecTramite] = useState([]);

  // Variables de estado para los fetch del proceso
  const [filteredSubProceso, setFilteredSubProceso] = useState([]);
  const [filteredOpciones, setFilteredOpciones] = useState([]);
  const [filteredAns, setFilteredAns] = useState([]);
  const [filteredSubOpt, setFilteredSubOpt] = useState([]);
  const [filteredSubAns, setFilteredSubAns] = useState([]);
  const [tipoTramiteProceso, setTipoTramiteProceso] = useState([]);
  const [subProcesos, setSubProcesos] = useState([]);
  const [opcionesProceso, setOpcionesProceso] = useState([]);
  const [respuestasProceso, setRespuestasProceso] = useState([]);
  const [subOpciones, setSubOpciones] = useState([]);
  const [subRespuestas, setSubRespuestas] = useState([]);
  const [configuracionesDePadrones, setConfiguracionesDePadrones] = useState([]);
  const [foliadores, setFoliadores] = useState([]);

  // Variables para el uso de Tabs Laterales y Horizontales
  const [activeSideTab, setActiveSideTab] = useState('infoBasica1');
  const [activeTab, setActiveTab] = useState('infoBasica1_departamentos');

  // Variable para hacer el responsive de la mejor manera
  const screens = Grid.useBreakpoint();

  const [configFlujos, setConfigFlujos] = useState(null);
  const [plantillaDeRetys, setPlantillaDeRetys] = useState(null);
  const [personasInteresadas, setPersonasInteresadas] = useState([]);

  const [variables, setVariables] = useState([]);

  const fetchData = async (page = 1, q = null, conf = configFlujos) => {
    try {
      setLoading(true);
      setCurrentPage(page);
      const params = { page, ...q };
      if (!isAdmin()) {
        if (conf?.seguimiento_de_propietario) {
          params.usuario_creo = user.id;
        }
        if (user.unidad_responsable) {
          params.departamentos = user.unidad_responsable.id;
        } else if (user.unidad_operativa) {
          params.dependencia = user.unidad_operativa.id;
        }
      }
      const response = await API.get(baseURI, { params });
      setData(response.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const getTiposDeCargos = async (params = null) => {
    try {
      const resTipoDeCargo = await API.get('/recaudacion/tipos-de-cargo/', {
        params: {
          ...params,
          es_accesorio: false,
          tipo_de_padron: tipo_de_padron || 1,
          periodo_fiscal: periodoFiscalSelected?.id,
        },
      });
      return resTipoDeCargo.data;
    } catch (error) {
      onError(error, setLoading);
      return { results: [] };
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      await getCatalogoDePadrones();
      const [_confFlujosRetys] = (await API.get('tramites/configuraciones-de-flujos-retys/',
        { params: { proceso_de_retys: 1 } })).data;
      if (_confFlujosRetys) {
        const flujos = (await API.get('/tramites/flujos-de-retys/',
          { params: { configuracion_de_flujo_de_retys: _confFlujosRetys.id } }))
          .data.sort(sortItems('posicion'));
        const _roles = (await API.get('catalogos/grupos/'))
          .data.map((e) => ({ ...e, name: e.name.split(' - ')[0] }));
        _confFlujosRetys.flujos = flujos.map((f) => ({
          ...f,
          roles: f.roles.map((r) => _roles.find((_r) => _r.id === r)),
        }));
      }
      setConfigFlujos(_confFlujosRetys);
      // const resTipoDePersona = await API.get('/catalogos/tipos-de-personas/');
      // setTipoDePersona(resTipoDePersona.data || []);
      const [
        resMoneda,
        resEtapa,
        resCanales,
        resContent,
        resSubProceso,
        resOpciones,
        resAns,
        resSubOpt,
        resSubAns,
        resTipoTramiteProceso,
        resAmbito,
        resMeses,
        resPlazo,
        resTecTramite,
        resTipoTramite,
        resEstadoFicha,
        resEstadoValidacionFicha,
        resTipoResolucion,
        resProgramaRelacionado,
        resPersonasInteresadas,
        resConfiguracionesDePadrones,
        resFoliadores,
        resVariables,
      ] = (await Promise.allSettled([
        API.get('configuracion/monedas/'),
        API.get('tramites/etapas-de-pago'),
        API.get('/configuracion/catalogos-de-canales-de-pago/'),
        API.get('/catalogos/content-types-tramites/'),
        API.get('/tramites/subprocesos-de-tipo-de-tramite/'),
        API.get('/tramites/opciones-de-tipo-de-tramite/'),
        API.get('/tramites/respuestas-de-tipo-de-tramite/'),
        API.get('/tramites/subopciones-de-tipo-de-tramite/'),
        API.get('/tramites/subrespuestas-de-tipo-de-tramite/'),
        API.get('/tramites/procesos-de-tipo-de-tramite/'),
        API.get('/catalogos/ambito-de-ordenamiento/'),
        API.get('/catalogos/catalogos-del-mes/'),
        API.get('/catalogos/unidades-de-plazo'),
        API.get('/tramites/tecnologias-de-tramites/'),
        API.get('/tramites/tipos-de-tramites/'),
        API.get('/tramites/estados-de-fichas/'),
        API.get('/tramites/estados-de-validacion-de-fichas/'),
        API.get('/tramites/tipos-de-resoluciones/'),
        API.get('/tramites/programas-relacionados/'),
        API.get('/catalogos/tipos-de-personas-interesadas/'),
        API.get('/configuracion/configuracion-de-padrones/'),
        API.get('configuracion/configuraciones-de-segmentos-de-folios/'),
        API.get('/configuracion/variables/', { params: { tipo_de_variable__es_para_formula: false } }),
      ])).map((r) => r.value?.data || []);
      setMoneda(resMoneda);
      setEtapaDePago(resEtapa);
      setCatalogosDeCanales(resCanales);
      setContenTypesTramites(resContent);
      setSubProcesos(resSubProceso);
      setOpcionesProceso(resOpciones);
      setRespuestasProceso(resAns);
      setSubOpciones(resSubOpt);
      setSubRespuestas(resSubAns);
      setTipoTramiteProceso(resTipoTramiteProceso);
      setAmbitoOrdenamiento(resAmbito);
      setMeses(resMeses);
      setUnidadPlazo(resPlazo);
      setTecTramite(resTecTramite);
      setTipoDeTramite(resTipoTramite);
      setEstadoDeFicha(resEstadoFicha);
      setEstadoDeValidacionDeFicha(resEstadoValidacionFicha);
      setTipoResolucion(resTipoResolucion);
      setProgramaRelacionado(resProgramaRelacionado);
      setPersonasInteresadas(resPersonasInteresadas);
      setConfiguracionesDePadrones(resConfiguracionesDePadrones);
      setFoliadores(resFoliadores);
      setVariables(resVariables.filter((v) => v.tipo_de_variable > 3));

      const _dependencias = isAdmin() ? (await API.get('/configuracion/dependencias/')).data
        : [user.unidad_operativa].filter((e) => e);
      setDependencia(_dependencias);

      let _departamentos = [];
      if (isAdmin()) {
        _departamentos = (await API.get('/configuracion/departamentos/')).data;
      } else {
        _departamentos = user.unidad_responsable
          ? [user.unidad_responsable]
          : (await API.get('/configuracion/departamentos/',
            { params: { unidad_operativa: user.unidad_operativa?.id } })).data;
      }
      setDepartamentos(_departamentos);

      await fetchData(undefined, undefined, _confFlujosRetys);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.source.cancel;
    // eslint-disable-next-line
  }, []);

  const forms = [formAdeudo, formCargos, formConfiguracionGeneral, formPadronSecundario, form,
    formCadena, formCanal, formComentario, formCosteo, formRequisitosInteroperabilidad,
    formDescripcion, formDocSalida, formFamilia, formHecho, formInter, formInteracciones,
    formMonto, formOrganizaciones, formPersonas, formPlazoMax, formPlazoPrev, formPlazoReal,
    formResolucion, formAnexos, formTics, formOficina, formRequisitos, formVariables, formCitas,
    formProceso];

  const resetFields = () => forms.forEach((f) => f.resetFields());

  const onCancel = () => {
    setVisible(false);
    resetFields();
    setSelectedRowKeys([]);
    setDepartamentosFiltered([]);
    setprocesoTramite(0);
    setSelectedSub(0);
    setVisibleAlert(false);
    setActiveTab('infoBasica1_departamentos');
    setActiveSideTab('infoBasica1');
    setFilteredSubProceso([]);
    setFilteredOpciones([]);
    setFilteredAns([]);
    setFilteredSubOpt([]);
    setFilteredSubAns([]);
    setSinonimos([]);
    setCanal([]);
    setTipoResolucion([]);
    setCosteo([]);
    setComentario([]);
    setFamilia([]);
    setUsuarioQueja([]);
    setUsuarioResponsable([]);
    setUsuarioSupervisor([]);
    setprocesoTramite(0);
    setSelectedSub(0);
    setAnswerSelected(0);
    setPlantillaDeRetys();
    setDepartamentosFiltered([]);
    setDependenciaTemp();
    setSelected();
    setDataSourceCargos({ results: [] });
    setDataSourceCargosConsulta({ results: [] });
  };

  const validateForms = async () => {
    await form.validateFields();
    await formAnexos.validateFields();
    await formCadena.validateFields();
    await formCanal.validateFields();
    await formComentario.validateFields();
    await formCosteo.validateFields();
    await formDescripcion.validateFields();
    await formDocSalida.validateFields();
    await formFamilia.validateFields();
    await formHecho.validateFields();
    await formInter.validateFields();
    await formOrganizaciones.validateFields();
    await formPersonas.validateFields();
    await formPlazoMax.validateFields();
    await formPlazoReal.validateFields();
    await formResolucion.validateFields();
    await formSinonimos.validateFields();
    await formSubSector.validateFields();
    await formConfiguracionGeneral.validateFields();
    await formTics.validateFields();
    await formAdeudo.validateFields();
    await formCargos.validateFields();
    await formPadronSecundario.validateFields();
    await formCitas.validateFields();
  };

  const postAnexos = async (id) => {
    try {
      setLoading(true);
      if (anexos.length) {
        const promises = anexos.map((e) => {
          const values = {
            ...e,
            plantilla_de_tramite: id,
          };
          const files = ['descargar_archivo'];
          const config = hasFiles(values, files) ? {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          } : {};
          const formattedValues = getFormatedValues(values, {
            files,
            formData: config.headers,
            clean: true,
          });

          return API.post('tramites/formatos-requeridos/', formattedValues, config);
        });
        if (promises.length) {
          await Promise.all(promises);
        }
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const postSinonimos = async (id) => {
    try {
      setLoading(true);
      if (sinonimos.length && !sinonimos.every((e) => e.id)) {
        const promises = sinonimos.map((e) => API.post('tramites/sinonimos/', {
          ...e,
          plantilla_de_tramites: id,
        }));
        if (promises.length) {
          await Promise.all(promises);
        }
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const postCasos = async (id) => {
    try {
      setLoading(true);
      if (sinonimos.length && !sinonimos.every((e) => e.id)) {
        const promises = sinonimos.map((e) => API.post('/tramites/casos-para-tramites/', {
          ...e,
          plantilla_de_tramites: id,
        }));
        if (promises.length) {
          await Promise.all(promises);
        }
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const postCanalPresentacion = async (id) => {
    try {
      setLoading(true);
      if (canal.length && !canal.every((e) => e.id)) {
        const promises = canal.map((e) => API.post('tramites/canales-de-presentacion-plantilla/', {
          ...e,
          plantilla_de_tramites: id,
        }));
        if (promises.length) {
          await Promise.all(promises);
        }
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const postPlazoMax = async (id) => {
    try {
      setLoading(true);
      if (plazoMax.length) {
        const promises = plazoMax.map((e) => API.post('tramites/plazos-maximos/', {
          ...e,
          plantilla_de_tramites: id,
        }));
        if (promises.length) {
          await Promise.all(promises);
        }
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const postPlazoPrevencion = async (id) => {
    try {
      setLoading(true);
      if (plazoPrevencion.length) {
        const promises = plazoPrevencion.map((e) => API.post('tramites/plazos-prevenciones/', {
          ...e,
          plantilla_de_tramites: id,
        }));
        if (promises.length) {
          await Promise.all(promises);
        }
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const postTipoResolucion = async (id) => {
    try {
      setLoading(true);
      if (tipoResolucion.length) {
        const promises = tipoResolucion.map((e) => API.post('tramites/tipos-de-resoluciones-plantilla/', {
          ...e,
          plantilla_de_tramites: id,
        }));
        if (promises.length) {
          await Promise.all(promises);
        }
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const postCriterio = async (id) => {
    try {
      setLoading(true);
      if (criterio.length) {
        const promises = criterio.map((e) => API.post('tramites/criterios-de-resolucion-de-tramite/', {
          ...e,
          plantilla_de_tramites: id,
        }));
        if (promises.length) {
          await Promise.all(promises);
        }
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const postComentario = async (id) => {
    try {
      setLoading(true);
      if (comentario.length) {
        const promises = comentario.map((e) => API.post('tramites/comentarios-generales/', {
          ...e,
          plantilla_de_tramites: id,
        }));
        if (promises.length) {
          await Promise.all(promises);
        }
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const postHechos = async (id) => {
    try {
      setLoading(true);
      if (hecho.length) {
        const promises = hecho.map((e) => API.post('tramites/hechos-vitales/', {
          ...e,
          plantilla_de_tramite: id,
        }));
        if (promises.length) {
          await Promise.all(promises);
        }
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const postDocumentoDeSalida = async (id) => {
    try {
      setLoading(true);
      if (documentoDeSalida.length) {
        const promises = documentoDeSalida.map((e) => API.post('tramites/documentos-de-salida-de-tramites/', {
          ...e,
          plantilla_de_tramites: id,
        }));
        if (promises.length) {
          await Promise.all(promises);
        }
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const postCadenas = async (id) => {
    try {
      setLoading(true);
      if (cadenas.length) {
        const promises = cadenas.map((e) => API.post('tramites/cadenas/', {
          ...e,
          plantilla_de_tramites: id,
        }));
        if (promises.length) {
          await Promise.all(promises);
        }
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const postCosteos = async (id) => {
    try {
      setLoading(true);
      if (costeo.length) {
        const promises = costeo.map((e) => API.post('tramites/costeos-estandar/', {
          ...e,
          plantilla_de_tramites: id,
        }));
        if (promises.length) {
          await Promise.all(promises);
        }
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const postFamilia = async (id) => {
    try {
      setLoading(true);
      if (familia.length) {
        const promises = familia.map((e) => API.post('tramites/familias-de-tramites-plantillas', {
          ...e,
          plantilla_de_tramite: id,
        }));
        if (promises.length) {
          await Promise.all(promises);
        }
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const postSubSectores = async (id) => {
    try {
      setLoading(true);
      if (subSectores.length) {
        const promises = subSectores.map((e) => API.post('/tramites/sub-sectores-economicos-de-plantilla/', {
          ...e,
          plantilla_de_tramite: id,
        }));
        if (promises.length) {
          await Promise.all(promises);
        }
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const reset = (prop = '') => {
    switch (prop) {
      case 'q_queja':
        setUsuarioQueja([]);
        formPersonas.resetFields(['q_queja']);
        break;
      case 'q_resolucion':
        setUsuarioResponsable([]);
        formPersonas.resetFields(['q_resolucion']);
        break;
      case 'q_rom':
        setUsuarioSupervisor([]);
        formPersonas.resetFields(['q_rom']);
        break;
      default:
        break;
    }
  };

  // Busqueda con SwitchCase para cada Variable, en vez de una funcion de cada uno de los inputs
  const unifiedSearch = async (name) => {
    const uriGet = 'usuarios/usuarios';
    switch (name) {
      case 'q_queja':
        try {
          setLoading(true);
          const { q_queja: q } = formPersonas.getFieldsValue();
          const response = await API.get(uriGet, {
            params: {
              email: q,
            },
          });
          if (response?.status === 200 && response?.data?.length) {
            formPersonas.setFieldsValue({
              usuario_responsable_de_queja: response.data[0].id,
            });
            const results = response.data.map((e) => ({
              ...e,
              nombre_completo: `${e?.first_name} ${e?.last_name || ''} ${e?.second_last_name || ''}`,
            }));
            setUsuarioQueja([results[0]]);
          } else {
            message.info('No se encontraron coincidencias');
            setUsuarioQueja([]);
          }
          setLoading(false);
        } catch (error) {
          onError(error, setLoading);
        }
        break;
      case 'q_resolucion':
        try {
          setLoading(true);
          const { q_resolucion: q } = formPersonas.getFieldsValue();
          const response = await API.get(uriGet, {
            params: {
              email: q,
            },
          });
          if (response?.status === 200 && response?.data?.length) {
            formPersonas.setFieldsValue({
              usuario_responsable_de_la_resolucion: response.data[0].id,
            });
            const results = response.data.map((e) => ({
              ...e,
              nombre_completo: `${e?.first_name} ${e?.last_name || ''} ${e?.second_last_name || ''}`,
            }));
            setUsuarioResponsable([results[0]]);
          } else {
            message.info('No se encontraron coincidencias');
            setUsuarioResponsable([]);
          }
          setLoading(false);
        } catch (err) {
          onError(err, setLoading);
        }
        break;
      case 'q_rom':
        try {
          setLoading(true);
          const { q_rom: q } = formPersonas.getFieldsValue();
          const response = await API.get(uriGet, {
            params: {
              email: q,
            },
          });
          if (response?.status === 200 && response?.data?.length) {
            formPersonas.setFieldsValue({
              usuario_supervisor_de_ROM: response.data[0].id,
            });
            const results = response.data.map((e) => ({
              ...e,
              nombre_completo: `${e?.first_name} ${e?.last_name || ''} ${e?.second_last_name || ''}`,
            }));
            setUsuarioSupervisor([results[0]]);
          } else {
            message.info('No se encontraron coincidencias');
            setUsuarioSupervisor([]);
          }
          setLoading(false);
        } catch (err) {
          onError(err, setLoading);
        }
        break;
      default:
        break;
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickAdd = () => {
    onCancel();
    formOrganizaciones.setFieldsValue({
      unidad_operativa: !isAdmin() && user.unidad_operativa
        ? user.unidad_operativa.id : null,
      departamentos: !isAdmin() && user.unidad_responsable
        ? [user.unidad_responsable.id] : [],
    });
    if (!isAdmin() && user.unidad_operativa) {
      setDepartamentosFiltered(departamentos);
    }
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const handleOnRowClick = (record) => {
    if (![4, 5].includes(record.estados_globales)) {
      setSelectedRowKeys([record.id]);
    }
  };

  const filteredDepartamentos = (val) => {
    if (val) {
      const filtered = departamentos.filter((e) => e.unidad_operativa?.id === val);
      setDepartamentosFiltered(filtered);
    }
  };

  const fetchSubProceso = (val) => {
    const filtered = subProcesos.filter((e) => e.tipo_de_tramite_proceso === val);
    setFilteredSubProceso(filtered);
  };

  const fetchOpciones = async (val) => {
    const filtered = opcionesProceso.filter((e) => e.tipo_de_tramite_sub_proceso?.includes(val));
    setFilteredOpciones(filtered);
  };

  const fetchRespuestas = async (val) => {
    const filtered = respuestasProceso.filter((e) => e.tipo_de_tramite_opciones?.includes(val));
    setFilteredAns(filtered);
  };

  const fetchSubOpciones = async (val) => {
    const filtered = subOpciones.filter((e) => e.tipo_de_tramite_respuesta?.includes(val));
    setFilteredSubOpt(filtered);
  };

  const fetchSubRespuestas = async (val) => {
    const filtered = subRespuestas.filter((e) => e.tipo_de_tramite_sub_opciones?.includes(val));
    setFilteredSubAns(filtered);
  };

  const fetchTiposDeCargos = createFetcher(getTiposDeCargos);

  const onClickEdit = async () => {
    try {
      const [key] = selectedRowKeys;
      if (!key) {
        return null;
      }
      setLoading(true);
      const res = await API.get(`${baseURI}${key}`);
      const { fundamento_legal, fundamento_legal_monto, ...values } = res.data;
      values.tipos_de_cargos = values.tipos_de_cargos.map((e) => e.id);
      values.configuracion_de_padron = values.configuracion_de_padron?.id;

      if (configFlujos) {
        const [_plantillaDeRetys] = (await API.get(
          'tramites/plantillas-de-retys/',
          {
            params: {
              configuracion_de_flujo_de_retys: configFlujos.id,
              plantilla_de_tramites: key,
            },
          },
        )).data;
        if (_plantillaDeRetys) {
          const { estado_de_validacion_de_ficha } = _plantillaDeRetys;
          const estado_validacion = estado_de_validacion_de_ficha?.id
            ? estado_de_validacion_de_ficha
            : estadoDeValidacionDeFicha.find((e) => e.id === estado_de_validacion_de_ficha);
          if (estado_validacion.id !== 2) {
            setLoading(false);
            return message.warn(`Ficha en estado "${estado_validacion.descripcion}". No es posible editar`);
          }
          const _historial = (await API.get('/tramites/historiales-de-retys/', {
            params: {
              configuracion_de_flujo_de_retys: configFlujos.id,
              plantilla_de_tramites: key,
            },
          })).data;
          const historial = _historial
            .map((h) => ({
              ...h,
              fecha_de_creacion: toMoment(h.fecha_de_creacion),
              usuario: h.usuario_creo,
              estado: estadoDeFicha.find((e) => e.id === h.estado),
            })).reverse();
          _plantillaDeRetys.historial = historial;
        }
        setPlantillaDeRetys(_plantillaDeRetys);
      }
      if (values?.departamentos?.length) {
        values.unidad_operativa = values.departamentos[0].unidad_operativa?.id;
        values.departamentos = values.departamentos.map((e) => e.id);
        const filterDepots = departamentos
          .filter((e) => e.unidad_operativa?.id === values.unidad_operativa);
        setDepartamentosFiltered(filterDepots.length ? filterDepots : values.departamentos);
        if (!dependencia.find((e) => e.id === values.unidad_operativa)) {
          setDependenciaTemp(values.departamentos[0].unidad_operativa);
        }
      }
      if (values?.usuario_responsable_de_queja) {
        const match = await API.get(`usuarios/usuarios/${values.usuario_responsable_de_queja}`);
        const matchedUser = {
          ...match.data,
          nombre_completo: `${match.data?.first_name} ${match.data?.last_name
            || ''} ${match.data?.second_last_name || ''}`,
        };
        formPersonas.setFieldsValue({
          usuario_responsable_de_queja: match.data.id,
        });
        setUsuarioQueja([matchedUser]);
      } else {
        setUsuarioQueja([]);
      }

      if (values?.usuario_responsable_de_la_resolucion) {
        const match = await API.get(`usuarios/usuarios/${values.usuario_responsable_de_la_resolucion}`);
        const matchedUser = {
          ...match.data,
          nombre_completo: `${match.data?.first_name} ${match.data?.last_name
            || ''} ${match.data?.second_last_name || ''}`,
        };
        formPersonas.setFieldsValue({
          usuario_responsable_de_la_resolucion: match.data.id,
        });
        setUsuarioResponsable([matchedUser]);
      } else {
        setUsuarioResponsable([]);
      }
      if (values?.usuario_supervisor_de_ROM) {
        const match = await API.get(`usuarios/usuarios/${values.usuario_supervisor_de_ROM}`);
        const matchedUser = {
          ...match.data,
          nombre_completo: `${match.data?.first_name} ${match.data?.last_name
            || ''} ${match.data?.second_last_name || ''}`,
        };
        formPersonas.setFieldsValue({
          usuario_supervisor_de_ROM: match.data.id,
        });
        setUsuarioSupervisor([matchedUser]);
      } else {
        setUsuarioSupervisor([]);
      }
      if (fundamento_legal?.length) {
        const promises = [];
        fundamento_legal.forEach((id) => {
          const response = API.get(`/configuracion/fundamentos-legales/${id}`);
          promises.push(response);
        });
        const responses = promises.length ? await Promise.all(promises) : [];
        const fundamentos = responses.map((fundamento) => fundamento.data);
        form.setFieldsValue({
          fundamento_legal,
          fundamento_legal_objects: fundamentos,
        });
      }

      if (fundamento_legal_monto?.length) {
        const promises = [];
        fundamento_legal_monto.forEach((id) => {
          const response = API.get(`/configuracion/fundamentos-legales/${id}`);
          promises.push(response);
        });
        const responses = promises.length ? await Promise.all(promises) : [];
        const fundamentos = responses.map((fundamento) => fundamento.data);
        formMonto.setFieldsValue({
          fundamento_legal_monto,
          fundamento_legal_objects: fundamentos,
        });
      }
      if (values?.tipos_de_persona_interesadas?.length) {
        const idsTipos = values.tipos_de_persona_interesadas.map((e) => e.id);
        values.tipos_de_persona_interesadas = idsTipos;
      }
      const promises = values.tipos_de_cargos.map((id) => getTiposDeCargos({ id }));
      const tiposDeCargos = promises.length
        ? (await Promise.all(promises)).map((r) => r.results).flat() : [];
      const promisesConsulta = values.tipos_de_cargos_consulta
        .map((id) => getTiposDeCargos({ id }));
      const tiposDeCargosConsulta = promisesConsulta
        ? (await Promise.all(promisesConsulta)).map((r) => r.results).flat() : [];
      await fetchTiposDeCargos(
        { page: 1 },
        setLoading,
        values.tipos_de_cargos,
        { results: tiposDeCargos },
        setDataSourceCargos,
      );
      await fetchTiposDeCargos(
        { page: 1 },
        setLoading,
        values.tipos_de_cargos_consulta,
        { results: tiposDeCargosConsulta },
        setDataSourceCargosConsulta,
      );

      // filteredSector(values.proceso_economico);
      form.setFieldsValue(values);
      formOrganizaciones.setFieldsValue(values);
      formDescripcion.setFieldsValue(values);
      formPlazoReal.setFieldsValue(values);
      formMonto.setFieldsValue(values);
      formHecho.setFieldsValue(values);
      formFamilia.setFieldsValue(values);
      formSubSector.setFieldsValue(values);
      formTics.setFieldsValue(values);
      formInter.setFieldsValue(values);
      formInteracciones.setFieldsValue(values);
      formCanal.setFieldsValue(values);
      formComentario.setFieldsValue(values);
      formRequisitos.setFieldsValue(values);
      formAdeudo.setFieldsValue(values);
      formCargos.setFieldsValue(values);
      formConfiguracionGeneral.setFieldsValue(values);
      formPadronSecundario.setFieldsValue(values);
      formRequisitosInteroperabilidad.setFieldsValue(values);
      formVariables.setFieldsValue(values);
      formCitas.setFieldsValue(values);

      if (values.content_type_tramite) {
        const valuesProceso = {};
        const keysByLvl = {
          1: 'tipo_de_tramite_proceso',
          2: 'tipo_de_tramite_sub_proceso',
          3: 'tipo_de_tramite_opciones',
          4: 'tipo_de_tramite_respuesta',
          5: 'tipo_de_tramite_sub_opciones',
          6: 'tipo_de_tramite_sub_respuesta',
        };
        const sourcesByLvl = {
          1: tipoTramiteProceso,
          2: subProcesos,
          3: opcionesProceso,
          4: respuestasProceso,
          5: subOpciones,
          6: subRespuestas,
        };
        let lvl = contenTypesTramites[contenTypesTramites
          .findIndex((e) => e.id === values.content_type_tramite)]?.nivel;
        let value = values.object_id_tramite;
        while (lvl) {
          valuesProceso[keysByLvl[lvl]] = value;
          // eslint-disable-next-line no-loop-func
          const match = sourcesByLvl[lvl].find((e) => e.id === value);

          if (lvl !== 1) {
            value = sourcesByLvl[lvl - 1]
              // eslint-disable-next-line no-loop-func
              .find((e) => match[keysByLvl[lvl - 1]]?.includes?.(e.id)
                || match[keysByLvl[lvl - 1]] === e.id).id;
          }
          lvl -= 1;
        }
        if (valuesProceso.tipo_de_tramite_proceso) {
          setprocesoTramite(valuesProceso.tipo_de_tramite_proceso);
          fetchSubProceso(valuesProceso.tipo_de_tramite_proceso);
        }
        if (valuesProceso.tipo_de_tramite_sub_proceso) {
          setSelectedSub(valuesProceso.tipo_de_tramite_sub_proceso);
          fetchOpciones(valuesProceso.tipo_de_tramite_sub_proceso);
        }
        if (valuesProceso.tipo_de_tramite_opciones) {
          fetchRespuestas(valuesProceso.tipo_de_tramite_opciones);
        }
        if (valuesProceso.tipo_de_tramite_respuesta) {
          setAnswerSelected(valuesProceso.tipo_de_tramite_respuesta);
          fetchSubOpciones(valuesProceso.tipo_de_tramite_respuesta);
        }
        if (valuesProceso.tipo_de_tramite_sub_opciones) {
          fetchSubRespuestas(valuesProceso.tipo_de_tramite_sub_opciones);
        }
        formProceso.setFieldsValue(valuesProceso);
      }
      setSelected(res.data);
      setLoading(false);
      setTimeout(() => setVisible(true));
    } catch (error) {
      onError(error, setLoading);
    }
    return null;
  };

  const postHistorial = async () => {
    const movimiento = longLabels[activeSideTab];
    const values = {
      movimiento,
      estado: 1,
      configuracion_de_flujo_de_retys: configFlujos.id,
      plantilla_de_tramites: selectedRowKeys[0],
    };
    await API.post('tramites/historiales-de-retys/', values);
  };

  const defaultTabBySideTab = {
    infoBasica1: 'infoBasica1_general',
    infoBasica2: 'infoBasica2_canalAtencion',
    infoBasica3: 'infoBasica3_tipoResolucion',
    infoComplementaria1: 'infoComplementaria1_comentario',
    infoComplementaria2: 'infoComplementaria2_hechoVital',
    interoperabilidad: 'interoperabilidad_requisitosGenerales',
    costeo: 'costeo_costeoEstandar',
    oficinaDeAtencion: 'oficinaDeAtencion_oficina',
    anexos: 'anexos_anexos',
    configurador: 'configurador_general',
    estado: 'estado_estado',
  };

  const onFinish = async (_continue = false) => {
    try {
      setLoading(true);
      await validateForms();
      const values = forms.reduce((_values, _form, idx) => {
        if (idx === forms.length - 1) return _values;
        return ({ ..._values, ..._form.getFieldsValue() });
      }, initialValues);
      const valuesProceso = formProceso.getFieldsValue();
      if (valuesProceso.tipo_de_tramite_sub_respuesta) {
        values.content_type_tramite = contenTypesTramites.find((e) => e.nivel === 6).id;
        values.object_id_tramite = valuesProceso.tipo_de_tramite_sub_respuesta;
      } else if (valuesProceso.tipo_de_tramite_sub_opciones) {
        values.content_type_tramite = contenTypesTramites.find((e) => e.nivel === 5).id;
        values.object_id_tramite = valuesProceso.tipo_de_tramite_sub_opciones;
      } else if (valuesProceso.tipo_de_tramite_respuesta) {
        values.content_type_tramite = contenTypesTramites.find((e) => e.nivel === 4).id;
        values.object_id_tramite = valuesProceso.tipo_de_tramite_respuesta;
      } else if (valuesProceso.tipo_de_tramite_opciones) {
        values.content_type_tramite = contenTypesTramites.find((e) => e.nivel === 3).id;
        values.object_id_tramite = valuesProceso.tipo_de_tramite_opciones;
      } else if (valuesProceso.tipo_de_tramite_sub_proceso) {
        values.content_type_tramite = contenTypesTramites.find((e) => e.nivel === 2).id;
        values.object_id_tramite = valuesProceso.tipo_de_tramite_sub_proceso;
      }

      if (!selectedRowKeys.length) {
        values.anexos = anexos;
        values.cadena = cadenas;
        values.canal_de_presentacion = canal;
        values.casos = casos;
        values.centro_de_costos = oficina;
        values.comentario_general = comentario;
        values.costeo = costeo;
        values.criterio = criterio;
        values.documento_de_salida = documentoDeSalida;
        values.familia_del_tramite = familia;
        values.hecho_vital = hecho;
        values.plazo_maximo = plazoMax;
        values.plazo_prevencion = plazoPrevencion;
        values.sinonimos = sinonimos;
        values.tipo_de_resolucion = tipoResolucion;
        values.sub_sectores = subSectores;
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          await Promise.all([
            postAnexos(response.data.id),
            postSinonimos(response.data.id),
            postCanalPresentacion(response.data.id),
            postPlazoMax(response.data.id),
            postPlazoPrevencion(response.data.id),
            postTipoResolucion(response.data.id),
            postCriterio(response.data.id),
            postComentario(response.data.id),
            postHechos(response.data.id),
            postDocumentoDeSalida(response.data.id),
            postCadenas(response.data.id),
            postCosteos(response.data.id),
            postFamilia(response.data.id),
            postSubSectores(response.data.id),
            postCasos(response.data.id),
          ]);
          onSuccess(response, 'Agregado correctamente');
          if (_continue) {
            await fetchData();
            setSelectedRowKeys([response.data.id]);
            setTimeout(() => onClickEdit());
          } else {
            onCancel();
            await fetchData();
          }
        }
      } else {
        const [key] = selectedRowKeys;
        await form.validateFields();
        values.casos = casos;
        values.plazo_maximo = plazoMax;
        values.criterio = criterio;
        values.comentario_general = comentario;
        values.cadenas = cadenas;
        values.sinonimos = sinonimos;
        values.costeo = costeo;
        values.plazo_prevencion = plazoPrevencion;
        values.documentoDeSalida = documentoDeSalida;
        values.tipo_de_resolucion = tipoResolucion;
        values.canal_de_presentacion = canal;
        const response = await API.patch(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          if (configFlujos) {
            await postHistorial();
          }
          onSuccess(response, 'Actualizado correctamente');
          if (_continue) {
            await fetchData();
            setTimeout(() => onClickEdit());
          } else {
            onCancel();
            await fetchData();
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading, forms, (tab) => {
        const [_activeSideTab] = tab.split('_');
        if (_activeSideTab !== activeSideTab) {
          setActiveSideTab(tab.split('_')[0]);
        }
        if (activeTab !== tab) {
          setActiveTab(tab);
        }
      });
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
    getCheckboxProps: (record) => ({
      disabled: [4, 5].includes(record.estados_globales),
    }),
  };

  const columns = [
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 300,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 300,
    },
    {
      titleText: 'Estado de Ficha',
      dataIndex: 'estado_de_ficha',
      key: 'estado_de_ficha',
      width: 200,
      render: (val, record) => {
        if (record.estados_globales !== 5) {
          return estadoDeFicha.find((e) => e.id === val)?.descripcion;
        }
        return estadoDeValidacionDeFicha.at(-1)?.descripcion;
      },
    },
    {
      titleText: 'Dependencia',
      key: 'dependencia_descripcion',
      dataIndex: 'dependencia_descripcion',
      render: (val, record) => record?.departamentos[0]?.unidad_operativa?.descripcion,
      filterName: 'dependencia_descripcion',
      width: 300,
    },
  ];

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es Requerido',
      },
    ],
    email: [
      {
        type: 'email',
        message: 'Ingrese un correo electrónico válido.',
      },
    ],
    hipervinculo: [
      {
        type: 'url',
        message: 'Iniciar link/URL con https://',
      },
    ],
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <TemaAplicado>
          {!visible ? (
            <Table
              cols={columns}
              data={data.results}
              rowSelection={rowSelection}
              mobileColIndex={0}
              rowKey="id"
              handleOnRowClick={handleOnRowClick}
              controls={{
                onClickAdd,
                onClickEdit,
                onClickDelete,
              }}
              allowImport
              baseURI={baseURI}
              pagination={false}
              paginationCallback={fetchData}
              count={data.count}
              currentPage={currentPage}
              showTotal="Fichas existentes"
            />
          ) : (
            <Card
              bordered={false}
              extra={(
                <FormSubmitControls
                  onFinish={onFinish}
                  onCancel={onCancel}
                  allowSaveAndContinue={!configFlujos || !!selectedRowKeys.length}
                />
              )}
              className="form-container"
              title={`${selectedRowKeys.length ? `Editar ${selected?.nombre?.toLowerCase()}` : 'Agregar Tramite'}`}
            >
              <Tabs
                tabPosition={screens.md ? 'left' : 'top'}
                onChange={(key) => {
                  setActiveSideTab(key);
                  setActiveTab(defaultTabBySideTab[key]);
                }}
                activeKey={activeSideTab}
              >
                {(configFlujos && !selectedRowKeys.length) ? (
                  <TabPane
                    key="infoBasica1"
                    tab={(
                      <span className="required-mark">
                        <InfoCircleOutlined />
                        {screens.md ? 'Información Básica I' : 'Info. Básica I'}
                      </span>
                    )}
                    forceRender
                  >
                    <Tabs
                      type="card"
                      onChange={setActiveTab}
                      activeKey={activeTab}
                    >
                      <TabPane
                        tab={(
                          <span className="required-mark">
                            <ImportOutlined />
                            Datos Generales
                          </span>
                        )}
                        key="infoBasica1_general"
                        forceRender
                      >
                        <Form
                          layout="vertical"
                          form={form}
                          name="infoBasica1_general"
                          onFinish={onFinish}
                          scrollToFirstError
                          initialValues={{ estado_de_ficha: 1 }}
                        >
                          <Row gutter={10}>
                            <Col xs={24} sm={24} md={8}>
                              <Form.Item
                                name="nombre"
                                label="Nombre Oficial del Trámite"
                                rules={rules.required}
                              >
                                <TextArea allowClear autoSize={{ minRows: 2, maxRows: 4 }} />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8}>
                              <Form.Item
                                name="descripcion"
                                label="Descripción"
                                rules={rules.required}
                              >
                                <TextArea
                                  allowClear
                                  autoSize={{ minRows: 2, maxRows: 4 }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8}>
                              <Form.Item
                                name="nombre_interno_del_tramite"
                                label="Nombre Interno del Trámite"
                                hasFeedback
                              >
                                <TextArea allowClear autoSize={{ minRows: 2, maxRows: 4 }} />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8}>
                              <Form.Item
                                name="estado_de_ficha"
                                label="Estado de la Ficha"
                                rules={rules.required}
                              >
                                <Select dataSource={estadoDeFicha} disabled />
                              </Form.Item>
                            </Col>
                            <Form.Item hidden>
                              <Button htmlType="submit" />
                            </Form.Item>
                          </Row>
                        </Form>
                      </TabPane>
                      <TabPane
                        tab={(
                          <span className="required-mark">
                            <ImportOutlined />
                            Organizaciones
                          </span>
                        )}
                        key="infoBasica1_departamentos"
                        forceRender
                      >
                        <Form
                          layout="vertical"
                          form={formOrganizaciones}
                          name="infoBasica1_departamentos"
                          onFinish={onFinish}
                          scrollToFirstError
                        >
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              label="Dependencia"
                              name="unidad_operativa"
                              rules={rules.required}
                            >
                              <Select
                                onChange={(e) => filteredDepartamentos(e)}
                                dataSource={dependenciaTemp ? [dependenciaTemp] : dependencia}
                                disabled={selectedRowKeys.length}
                                allowClear
                              />
                            </Form.Item>
                          </Col>
                          <PlainTransfer
                            dataSource={departamentosFiltered}
                            label="Departamentos"
                            form={formOrganizaciones}
                            formItemName="departamentos"
                            filterProp="descripcion"
                            rules={rules.required}
                            disabled={selectedRowKeys.length}
                          />
                        </Form>
                      </TabPane>
                    </Tabs>
                  </TabPane>
                ) : (
                  <>
                    <TabPane
                      key="infoBasica1"
                      tab={(
                        <span className="required-mark">
                          <InfoCircleOutlined />
                          {screens.md ? 'Información Básica I' : 'Info. Básica I'}
                        </span>
                      )}
                      forceRender
                    >
                      <Tabs
                        type="card"
                        onChange={setActiveTab}
                        activeKey={activeTab}
                      >
                        <TabPane
                          tab={(
                            <span className="required-mark">
                              <ImportOutlined />
                              Datos Generales
                            </span>
                          )}
                          key="infoBasica1_general"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={form}
                            name="infoBasica1_general"
                            onFinish={onFinish}
                            scrollToFirstError
                            initialValues={{ fundamento_legal: [], clasificacion: true }}
                          >
                            <Row gutter={10}>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="nombre"
                                  label="Nombre Oficial del Trámite"
                                  rules={rules.required}
                                >
                                  <TextArea allowClear autoSize={{ minRows: 2, maxRows: 4 }} />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="descripcion"
                                  label="Descripción"
                                  rules={rules.required}
                                >
                                  <TextArea
                                    allowClear
                                    autoSize={{ minRows: 2, maxRows: 4 }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="nombre_interno_del_tramite"
                                  label="Nombre Interno del Trámite"
                                  hasFeedback
                                >
                                  <TextArea allowClear autoSize={{ minRows: 2, maxRows: 4 }} />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="clasificacion"
                                  label="Clasificación"
                                >
                                  <Select
                                    trueFalse={['Trámite', 'Servicio']}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="estado_de_ficha"
                                  label="Estado de la Ficha"
                                  rules={rules.required}
                                >
                                  <Select dataSource={estadoDeFicha} />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="homoclave"
                                  label="Homoclave"
                                  tooltip={configFlujos
                                    ? 'Definido por Configuración de Flujos' : ''}
                                >
                                  <Input disabled={!!configFlujos} allowClear maxLength={50} />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  label="Ámbito del Trámite"
                                  name="ambito_de_ordenamiento"
                                >
                                  <Select dataSource={ambitoOrdenamiento} />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  label="Programa Relacionado"
                                  name="programa_relacionado_a_tramite"
                                >
                                  <Select dataSource={programaRelacionado} />
                                </Form.Item>
                              </Col>
                              <FundamentosTransfer
                                formFundamento={form}
                              />
                              <Form.Item hidden>
                                <Button htmlType="submit" />
                              </Form.Item>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane
                          tab={(
                            <span className="required-mark">
                              <ImportOutlined />
                              Organizaciones
                            </span>
                          )}
                          key="infoBasica1_departamentos"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formOrganizaciones}
                            name="infoBasica1_departamentos"
                            onFinish={onFinish}
                            scrollToFirstError
                          >
                            <Col xs={24} sm={24} md={8}>
                              <Form.Item
                                label="Dependencia"
                                name="unidad_operativa"
                                rules={rules.required}
                              >
                                <Select
                                  onChange={(e) => filteredDepartamentos(e)}
                                  dataSource={dependencia}
                                  allowClear
                                />
                              </Form.Item>
                            </Col>
                            <PlainTransfer
                              dataSource={departamentosFiltered}
                              label="Departamentos"
                              form={formOrganizaciones}
                              formItemName="departamentos"
                              filterProp="descripcion"
                              rules={rules.required}
                            />
                          </Form>
                        </TabPane>
                        <TabPane
                          tab={(
                            <span>
                              <InfoCircleOutlined />
                              Descripción del Trámite
                            </span>
                          )}
                          key="infoBasica1_descripcionTramite"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formDescripcion}
                            name="infoBasica1_descripcionTramite"
                            onFinish={onFinish}
                            scrollToFirstError
                            initialValues={initialValuesBasica1Descripcion}
                          >
                            <Row gutter={10}>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="tipo_de_tramite"
                                  label="Tipo de Trámite"
                                  hasFeedback
                                >
                                  <Select
                                    dataSource={tipoDeTramite}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="resolucion_vinculada"
                                  label="Resolución Vinculada"
                                  hasFeedback
                                  tooltip={{
                                    title: 'La resolución de este trámite está vinculada con la presentación de otros trámites',
                                  }}
                                >
                                  <Select trueFalse />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="estado_de_la_empresa"
                                  label="Estado de la Empresa"
                                  tooltip={{
                                    title: 'El trámite o servicio se realiza cuando una empresa se encuentre en:',
                                  }}
                                  hasFeedback
                                >
                                  <Input allowClear />
                                </Form.Item>
                              </Col>

                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="resolucion_es_requisito"
                                  label="¿La Resolución es Requisito?"
                                  hasFeedback
                                  tooltip="La resolución es requisito de otro trámite"
                                >
                                  <Select trueFalse />
                                </Form.Item>
                              </Col>
                              {/* <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                  name="tipos_de_personas"
                                  label="¿Quién puede Solicitar El Trámite?"
                                >
                                  <Select dataSource={tipoDePersona} mode="multiple" />
                                </Form.Item>
                              </Col> */}
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="nombre_de_la_modalidad"
                                  label="Nombre de la Modalidad"
                                  hasFeedback
                                  tooltip={{
                                    title: 'Si existe modalidad agregarla aqui, de lo contrario puede ir vacio',
                                  }}
                                >
                                  <Input allowClear />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="tipos_de_persona_interesadas"
                                  label="Tipo de Personas Interesadas"
                                >
                                  <Select dataSource={personasInteresadas} mode="multiple" />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="conservar_informacion"
                                  label="¿Conservar Información?"
                                  tooltip={{
                                    title: '¿Este trámite o servicio requiere conservar la información para fines de acreditación, inspección y verificación con motivo del trámite o servicio?',
                                  }}
                                >
                                  <Select trueFalse />
                                </Form.Item>
                              </Col>
                              {conservar_informacion && (
                                <Col xs={24} sm={24} md={12}>
                                  <Form.Item
                                    name="detalle_conservar_informacion"
                                    label="Detalle de Conservar Información"
                                    hasFeedback
                                  >
                                    <TextArea allowClear autoSize={{ minRows: 2, maxRows: 4 }} />
                                  </Form.Item>
                                </Col>
                              )}
                              <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                  name="casos_de_presentacion"
                                  label="¿En qué casos se debe presentar el trámite?"
                                  hasFeedback
                                >
                                  <TextArea allowClear autoSize={{ minRows: 2, maxRows: 4 }} />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                  name="informacion_adicional"
                                  label="Información Adicional"
                                  hasFeedback
                                  tooltip={{
                                    title: 'Información que sea útil para que el interesado realice el trámite',
                                  }}
                                >
                                  <TextArea allowClear autoSize={{ minRows: 2, maxRows: 4 }} />
                                </Form.Item>
                              </Col>
                              <Form.Item hidden>
                                <Button htmlType="submit" />
                              </Form.Item>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane
                          tab={(
                            <span>
                              <InfoCircleOutlined />
                              Sinónimos
                            </span>
                          )}
                          key="infoBasica1_sinonimos"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formSinonimos}
                            name="infoBasica1_sinonimos"
                            onFinish={onFinish}
                            scrollToFirstError
                          >
                            <Row gutter={10}>
                              <TableSinonimos
                                callback={setSinonimos}
                                selectedRowKey={selectedRowKeys[0]}
                              />
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane
                          tab={(
                            <span>
                              <InfoCircleOutlined />
                              {screens.md ? 'Persona Relacionada' : 'P. Rel'}
                            </span>
                          )}
                          key="infoBasica1_persona"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formPersonas}
                            name="infoBasica1_persona"
                            onFinish={onFinish}
                            scrollToFirstError
                          >
                            <Row gutter={15}>
                              <InputSearchPro
                                label="Responsable de Queja"
                                tooltip="Búsqueda por Correo del usuario"
                                name="q_queja"
                                rules={rules.email}
                                onClickAdvanced={() => setVisibleAdvancedQueja(true)}
                                onPressEnter={() => unifiedSearch('q_queja')}
                                breakPoints={{
                                  md: 16,
                                }}
                                resultProps={{
                                  name: 'usuario_responsable_de_queja',
                                  label: 'Responsable de Queja',
                                  dataSource: usuarioQueja,
                                  labelProp: 'nombre_completo',
                                  onClear: () => reset('q_queja'),
                                  breakPoints: {
                                    md: 16,
                                  },
                                }}
                              />
                              {usuarioQueja && (
                                <Col span={24}>
                                  <Col>
                                    <Text strong>
                                      {usuarioQueja[0]?.nombre_completo}
                                    </Text>
                                  </Col>
                                  {usuarioQueja[0]?.numero_de_empleado && (
                                    <Col>
                                      <Text>
                                        Número  de Empleado:
                                        {' '}
                                        {usuarioQueja[0]?.numero_de_empleado}
                                      </Text>
                                    </Col>
                                  )}
                                  {usuarioQueja[0]?.funcionario?.puesto && (
                                    <Col>
                                      <Text>
                                        Cargo:
                                        {' '}
                                        {usuarioQueja[0]?.funcionario.puesto?.descripcion}
                                      </Text>
                                    </Col>
                                  )}
                                  {usuarioQueja[0]?.email && (
                                    <Text>
                                      Correo Electrónico:
                                      {' '}
                                      {usuarioQueja[0]?.email}
                                    </Text>
                                  )}
                                  {usuarioQueja[0]?.numero_de_celular && (
                                    <Text>
                                      Teléfono:
                                      {' '}
                                      {usuarioQueja[0]?.numero_de_celular}
                                    </Text>
                                  )}
                                </Col>
                              )}
                            </Row>
                            <Row gutter={15}>
                              <InputSearchPro
                                label="Responsable de la Resolución"
                                tooltip="Búsqueda por Correo Electrónico"
                                name="q_resolucion"
                                rules={rules.email}
                                onClickAdvanced={() => setVisibleAdvancedResponsable(true)}
                                onPressEnter={() => unifiedSearch('q_resolucion')}
                                breakPoints={{
                                  md: 16,
                                }}
                                resultProps={{
                                  name: 'usuario_responsable_de_la_resolucion',
                                  label: 'Responsable de la Resolución',
                                  tooltip: 'Búsqueda por Correo Electrónico',
                                  dataSource: usuarioResponsable,
                                  labelProp: 'nombre_completo',
                                  onClear: () => reset('q_resolucion'),
                                  breakPoints: {
                                    md: 16,
                                  },
                                }}
                              />
                              {usuarioResponsable && (
                                <Col span={24}>
                                  <Col>
                                    <Text strong>
                                      {usuarioResponsable[0]?.nombre_completo}
                                    </Text>
                                  </Col>
                                  {usuarioResponsable[0]?.numero_de_empleado && (
                                    <Col>
                                      <Text>
                                        Número  de Empleado:
                                        {' '}
                                        {usuarioResponsable[0]?.numero_de_empleado}
                                      </Text>
                                    </Col>
                                  )}
                                  {usuarioResponsable[0]?.funcionario?.puesto && (
                                    <Col>
                                      <Text>
                                        Cargo:
                                        {' '}
                                        {usuarioResponsable[0]?.funcionario.puesto?.descripcion}
                                      </Text>
                                    </Col>
                                  )}
                                  {usuarioResponsable[0]?.email && (
                                    <Col>
                                      <Text>
                                        Correo Electrónico:
                                        {' '}
                                        {usuarioResponsable[0]?.email}
                                      </Text>
                                    </Col>
                                  )}
                                  {usuarioResponsable[0]?.numero_de_celular && (
                                    <Text>
                                      Teléfono:
                                      {' '}
                                      {usuarioResponsable[0]?.numero_de_celular}
                                    </Text>
                                  )}
                                </Col>
                              )}
                            </Row>
                            <Row gutter={15}>
                              <InputSearchPro
                                label="Usuario Supervisor de ROM"
                                tooltip="Búsqueda por Correo Electrónico"
                                name="q_rom"
                                onClickAdvanced={() => setVisibleAdvancedSupervisor(true)}
                                onPressEnter={() => unifiedSearch('q_rom')}
                                rules={rules.email}
                                breakPoints={{
                                  md: 16,
                                }}
                                resultProps={{
                                  name: 'usuario_supervisor_de_ROM',
                                  label: 'Supervisor de ROM',
                                  dataSource: usuarioSupervisor,
                                  labelProp: 'nombre_completo',
                                  onClear: () => reset('q_rom'),
                                  breakPoints: {
                                    md: 16,
                                  },
                                }}
                              />
                              {usuarioSupervisor && (
                                <Col span={24}>
                                  <Col>
                                    <Text strong>
                                      {usuarioSupervisor[0]?.nombre_completo}
                                    </Text>
                                  </Col>
                                  {usuarioSupervisor[0]?.numero_de_empleado && (
                                    <Col>
                                      <Text>
                                        Número  de Empleado:
                                        {' '}
                                        {usuarioSupervisor[0]?.numero_de_empleado}
                                      </Text>
                                    </Col>
                                  )}
                                  {usuarioSupervisor[0]?.funcionario?.puesto && (
                                    <Col>
                                      <Text>
                                        Cargo:
                                        {' '}
                                        {usuarioSupervisor[0]?.funcionario.puesto?.descripcion}
                                      </Text>
                                    </Col>
                                  )}
                                  {usuarioSupervisor[0]?.email && (
                                    <Text>
                                      Correo Electrónico:
                                      {' '}
                                      {usuarioSupervisor[0]?.email}
                                    </Text>
                                  )}
                                  {usuarioSupervisor[0]?.numero_de_celular && (
                                    <Text>
                                      Teléfono:
                                      {' '}
                                      {usuarioSupervisor[0]?.numero_de_celular}
                                    </Text>
                                  )}
                                </Col>
                              )}
                            </Row>
                            <Form.Item hidden>
                              <Button htmlType="submit" />
                            </Form.Item>
                          </Form>
                        </TabPane>
                      </Tabs>
                    </TabPane>
                    <TabPane
                      key="infoBasica2"
                      tab={(
                        <span>
                          <InfoCircleOutlined />
                          {screens.md ? 'Información Básica II' : 'Info. Básica II'}
                        </span>
                      )}
                      forceRender
                    >
                      <Tabs
                        type="card"
                        onChange={setActiveTab}
                        activeKey={activeTab}
                      >
                        <TabPane
                          tab={(
                            <span>
                              <InfoCircleOutlined />
                              Canal de Presentación
                            </span>
                          )}
                          key="infoBasica2_canalAtencion"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formCanal}
                            name="infoBasica2_canalAtencion"
                            onFinish={onFinish}
                            scrollToFirstError
                            initialValues={initialValuesBasica2Canal}
                          >
                            <Row gutter={10}>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="tramite_de_internet"
                                  label="¿Trámite de Internet?"
                                  tooltip="Se puede hacer el trámite en línea sin
                                    acudir a oficinas gubernamentales"
                                >
                                  <Select trueFalse />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="anexo_de_documentos"
                                  label="¿Permite Anexo de Documentos?"
                                  tooltip=" Indica si es posible cargar o subir documentos en línea"
                                >
                                  <Select trueFalse />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="recepcion_electronica"
                                  label="¿Recepción Electrónica?"
                                  tooltip="Se puede enviar y recibir información por medios
                                    electrónicos con los correspondientes acuses de recepción de datos y documentos"
                                >
                                  <Select trueFalse />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="resolucion_inmediata"
                                  label="¿Resolución Inmediata?"
                                  hasFeedback
                                  tooltip="Indica si el trámite tiene una resolución inmediata"
                                >
                                  <Select trueFalse />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="firma_electronica"
                                  label="¿Utiliza Firma Electrónica?"
                                  tooltip="Indica si utiliza firma electrónica"
                                >
                                  <Select trueFalse />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="agenda_cita"
                                  label="¿Se Puede Agendar Cita?"
                                  hasFeedback
                                  tooltip="¿Se puede agendar una cita para realizar el trámite?"
                                >
                                  <Select trueFalse />
                                </Form.Item>
                              </Col>
                              {agenda_cita && (
                                <Col xs={24} sm={24} md={8}>
                                  <Form.Item
                                    name="link_de_agenda_cita"
                                    label="Link de agenda de cita"
                                    hasFeedback
                                    tooltip="Liga electrónica para agendar una cita en linea para realizar el trámite"
                                    rules={rules.hipervinculo}
                                  >
                                    <Input allowClear />
                                  </Form.Item>
                                </Col>
                              )}
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="notificacion_de_estatus"
                                  label="Notificación de Estatus"
                                  hasFeedback
                                  tooltip="Se notifica al interesado sobre el estatus del trámite"
                                >
                                  <Select trueFalse />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="notificacion_de_informacion"
                                  label="Notificación de Información"
                                  hasFeedback
                                  tooltip="Se notifica al interesado en línea, sobre la información
                              faltante del trámite"
                                >
                                  <Select trueFalse />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="notificacion_de_plazo"
                                  label="Notificación de Plazo"
                                  hasFeedback
                                  tooltip="Se notifica al interesado en línea, sobre el plazo de
                              prevención del trámite"
                                >
                                  <Select trueFalse />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="notificacion_de_vencimiento"
                                  label="Notificación de Vencimiento"
                                  hasFeedback
                                  tooltip="Se notifica al interesado en línea, sobre el
                              vencimiento del plazo de respuesta del trámite"
                                >
                                  <Select trueFalse />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="resolucion_por_internet"
                                  label="¿Resolución por Internet?"
                                  tooltip="indica si la resolución o respuesta es por internet"
                                >
                                  <Select trueFalse />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={10}>
                              <TableCanalDePresentacion
                                callback={setCanal}
                                selectedRowKey={selectedRowKeys[0]}
                              />
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane
                          tab={(
                            <span>
                              <InfoCircleOutlined />
                              Plazo Máximo
                            </span>
                          )}
                          key="infoBasica2_plazoMaximo"
                          forceRender
                        >
                          <Row gutter={10}>
                            <TablePlazoMax
                              selectedRowKey={selectedRowKeys[0]}
                              callback={setPlazoMax}
                            />
                          </Row>
                        </TabPane>
                        <TabPane
                          tab={(
                            <span>
                              <InfoCircleOutlined />
                              Plazo de Prevención
                            </span>
                          )}
                          key="infoBasica2_plazoPrevencion"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formPlazoPrev}
                            name="infoBasica2_plazoPrevencion"
                            onFinish={onFinish}
                            scrollToFirstError
                          >
                            <Row gutter={10}>
                              <TablePlazoPrev
                                selectedRowKey={selectedRowKeys[0]}
                                callback={setPlazoPrevencion}
                              />
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane
                          tab={(
                            <span>
                              <InfoCircleOutlined />
                              Plazo Real
                            </span>
                          )}
                          key="infoBasica2_plazoReal"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formPlazoReal}
                            name="infoBasica2_plazoReal"
                            onFinish={onFinish}
                            scrollToFirstError
                          >
                            <Row gutter={10}>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="unidad_de_plazo_real"
                                  label="Unidad de Plazo Real"
                                >
                                  <Select dataSource={unidadPlazo} allowClear />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>

                                <NumericInput
                                  name="plazo_real"
                                  label="Plazo Real"
                                />
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="calendario_de_presentacion"
                                  label="Calendario de Presentación"
                                >
                                  <Select allowClear dataSource={meses} mode="multiple" />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="meses_de_recurrencia"
                                  label="Meses de Recurrencia"
                                >
                                  <Select allowClear dataSource={meses} mode="multiple" />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <NumericInput
                                  name="volumen_anual"
                                  label="Volumen Anual"
                                />
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <NumericInput
                                  name="numero_de_resoluciones_favorables"
                                  label="No. de Resoluciones Favorables"
                                />
                              </Col>
                              <Form.Item hidden>
                                <Button htmlType="submit" />
                              </Form.Item>
                            </Row>
                          </Form>
                        </TabPane>
                      </Tabs>
                    </TabPane>
                    <TabPane
                      tab={(
                        <span>
                          <InfoCircleOutlined />
                          {screens.md ? 'Información Básica III' : 'Info. Básica III'}

                        </span>
                      )}
                      key="infoBasica3"
                      forceRender
                    >
                      <Tabs
                        type="card"
                        onChange={setActiveTab}
                        activeKey={activeTab}
                      >
                        <TabPane
                          tab={(
                            <span>
                              <InfoCircleOutlined />
                              {screens.md ? 'Tipo de Resolución' : 'T. de Res.'}
                            </span>
                          )}
                          key="infoBasica3_tipoResolucion"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formResolucion}
                            name="infoBasica3_tipoResolucion"
                            onFinish={onFinish}
                            scrollToFirstError
                          >
                            <Row gutter={10}>
                              <TableTiposDeResolucion
                                selectedRowKey={selectedRowKeys[0]}
                                callback={setTipoResolucion}
                              />
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane
                          tab={(
                            <span>
                              <InfoCircleOutlined />
                              {screens.md ? 'Criterio de Resolución' : 'Crit. de Resol.'}
                            </span>
                          )}
                          key="infoBasica3_criteroDeResolucion"
                          forceRender
                        >
                          <TableCriterios
                            selectedRowKey={selectedRowKeys[0]}
                            callback={setCriterio}
                          />
                        </TabPane>
                        <TabPane
                          tab={(
                            <span>
                              <InfoCircleOutlined />
                              Monto de los derechos
                            </span>
                          )}
                          key="infoBasica3_monto"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formMonto}
                            name="infoBasica3_monto"
                            onFinish={onFinish}
                            scrollToFirstError
                            initialValues={{ fundamento_legal_monto: [], ...initialValuesCargos }}
                            onValuesChange={(changed) => formCargos.setFieldsValue(changed)}
                          >
                            <Row gutter={10}>
                              <FundamentosTransfer formFundamento={formMonto} formItemName="fundamento_legal_monto" />
                              <Form.Item hidden>
                                <Button htmlType="submit" />
                              </Form.Item>
                            </Row>
                            <FormCargos
                              getTiposDeCargos={fetchTiposDeCargos}
                              monedas={moneda}
                              etapasDePago={etapaDePago}
                              plantilla={selected}
                              setDataSourceCargos={setDataSourceCargos}
                              dataSourceCargos={dataSourceCargos}
                              onChangeTiposDeCargos={(tipos_de_cargos) => formCargos
                                .setFieldsValue({ tipos_de_cargos })}
                              transferToBottom
                            />
                          </Form>
                        </TabPane>
                      </Tabs>
                    </TabPane>
                    <TabPane
                      tab={(
                        <span>
                          <InfoCircleOutlined />
                          {screens.md ? 'Información Complementaria I' : 'Info. Compl. I'}
                        </span>
                      )}
                      key="infoComplementaria1"
                      forceRender
                    >
                      <Tabs
                        type="card"
                        onChange={setActiveTab}
                        activeKey={activeTab}
                      >
                        <TabPane
                          tab={(
                            <span>
                              <InfoCircleOutlined />
                              Comentario General
                            </span>
                          )}
                          key="infoComplementaria1_comentario"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formComentario}
                            name="infoComplementaria1_comentario"
                            onFinish={onFinish}
                            scrollToFirstError
                          >
                            <Col span={24}>
                              <NumericInput
                                label="Volúmen de Molestias o Quejas Anuales"
                                name="volumen_de_consultas"
                              />
                            </Col>
                            <TableComentarioGeneral
                              callback={setComentario}
                              selectedRowKey={selectedRowKeys[0]}
                            />
                          </Form>
                        </TabPane>
                        <TabPane
                          tab={(
                            <span>
                              <InfoCircleOutlined />
                              Subsector Económico
                            </span>
                          )}
                          key="infoComplementaria1_subsector"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formSubSector}
                            name="infoComplementaria1_subsector"
                            onFinish={onFinish}
                            scrollToFirstError
                          >
                            <Row gutter={10}>
                              <TableSubSectores
                                callback={setSubSector}
                                selectedRowKey={selectedRowKeys[0]}

                              />

                              <Form.Item hidden>
                                <Button htmlType="submit" />
                              </Form.Item>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane
                          tab={(
                            <span>
                              <InfoCircleOutlined />
                              TICS
                            </span>
                          )}
                          key="infoComplementaria1_tics"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formTics}
                            name="infoComplementaria1_tics"
                            onFinish={onFinish}
                            scrollToFirstError
                            initialValues={initialValuesComplementariaTics}
                          >
                            <Row gutter={10}>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="cuenta_con_sistema_de_gestion"
                                  label="¿Cuenta con Sistema de Gestión?"
                                >
                                  <Select trueFalse />
                                </Form.Item>
                              </Col>
                              {cuenta_con_sistema_de_gestion ? (
                                <>
                                  <Col xs={24} sm={24} md={8}>
                                    <Form.Item
                                      name="nombre_del_sistema"
                                      label="Nombre del Sistema"
                                    >
                                      <Input allowClear />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} sm={24} md={8}>
                                    <Form.Item
                                      name="tecnologia_del_sistema"
                                      label="Tecnología del Sistema"
                                    >
                                      <Select dataSource={tecTramite} mode="multiple" />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} sm={24} md={8}>
                                    <Form.Item
                                      name="otra_tecnologia_del_sistema"
                                      label="Otra Tecnología del Sistema"
                                    >
                                      <Input allowClear />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} sm={24} md={8}>
                                    <Form.Item
                                      name="adaptacion"
                                      label="Grado Adaptación"
                                      tooltip="Grado de adaptación que el sistema ofrece
                                   para envio o recepción de solicitudes electrónicas"
                                    >
                                      <Input allowClear />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} sm={24} md={8}>
                                    <Form.Item
                                      name="clave_rupa"
                                      label="¿Clave Rupa?"
                                    >
                                      <Select trueFalse />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} sm={24} md={8}>
                                    <Form.Item
                                      name="complejidad"
                                      label=" Grado de Complejidad"
                                    >
                                      <Input allowClear />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} sm={24} md={8}>

                                    <NumericInput
                                      name="peticiones"
                                      label="Peticiones"
                                    />
                                  </Col>
                                  <Col xs={24} sm={24} md={8}>
                                    <Form.Item
                                      name="criticidad_del_sistema"
                                      label="Criticidad del Sistema"
                                    >
                                      <Select trueFalse />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} sm={24} md={8}>
                                    <Form.Item
                                      name="disponibilidad_WS"
                                      label="Disponibilidad WS"
                                      tooltip="¿Disponibilidad de servicios mediante webservice?"
                                    >
                                      <Select trueFalse />
                                    </Form.Item>
                                  </Col>
                                </>
                              ) : (
                                <>
                                  <Col xs={24} sm={24} md={8}>
                                    <Form.Item
                                      name="necesita_sistema_back_office,"
                                      label="¿Necesita Sistema de Back Office?"
                                    >
                                      <Select trueFalse />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} sm={24} md={8}>
                                    <Form.Item
                                      name="seguimiento_por_internet,"
                                      label="¿Seguimiento por Internet?"
                                    >
                                      <Select trueFalse />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} sm={24} md={8}>
                                    <Form.Item
                                      name="pago_electronico,"
                                      label="¿Pago Electrónico?"
                                      tooltip={{
                                        title:
                                          '¿Utiliza pago electrónico (e5Cinco) de Derechos, Productos y Aprovechamientos (DPA´s)?',
                                      }}
                                    >
                                      <Select trueFalse />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} sm={24} md={8}>
                                    <Form.Item
                                      name="clave_rupa,"
                                      label="¿Clave Rupa?"
                                    >
                                      <Select trueFalse />
                                    </Form.Item>
                                  </Col>
                                </>
                              )}
                              <Form.Item hidden>
                                <Button htmlType="submit" />
                              </Form.Item>
                            </Row>
                          </Form>
                        </TabPane>
                      </Tabs>
                    </TabPane>
                    <TabPane
                      tab={(
                        <span>
                          <InfoCircleOutlined />
                          {screens.md ? 'Información Complementaria II' : 'Info. Compl. II'}
                        </span>
                      )}
                      key="infoComplementaria2"
                      forceRender
                    >
                      <Tabs
                        type="card"
                        onChange={setActiveTab}
                        activeKey={activeTab}
                      >
                        <TabPane
                          tab={(
                            <span>
                              <InfoCircleOutlined />
                              Hecho Vital
                            </span>
                          )}
                          key="infoComplementaria2_hechoVital"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formHecho}
                            name="infoComplementaria2_hechoVital"
                            onFinish={onFinish}
                            scrollToFirstError
                          >
                            <Row gutter={10}>
                              <TableHechoVital
                                callback={setHecho}
                                selectedRowKey={selectedRowKeys[0]}
                              />

                              <Form.Item hidden>
                                <Button htmlType="submit" />
                              </Form.Item>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane
                          tab={(
                            <span>
                              <InfoCircleOutlined />
                              Familia del Trámite
                            </span>
                          )}
                          key="infoComplementaria2_familiaTramite"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formFamilia}
                            name="infoComplementaria2_familiaTramite"
                            onFinish={onFinish}
                            scrollToFirstError
                          >
                            <Row gutter={10}>
                              <TableFamiliaDelTramite
                                callback={setFamilia}
                                selectedRowKey={selectedRowKeys[0]}
                              />
                              <Form.Item hidden>
                                <Button htmlType="submit" />
                              </Form.Item>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane
                          tab={(
                            <span>
                              <InfoCircleOutlined />
                              Procesos
                            </span>
                          )}
                          key="infoComplementaria2_procesoPlantilla"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formProceso}
                            name="infoComplementaria2_procesoPlantilla"
                            onFinish={onFinish}
                            scrollToFirstError
                            onValuesChange={(changed) => {
                              const _props = [
                                'tipo_de_tramite_proceso',
                                'tipo_de_tramite_sub_proceso',
                                'tipo_de_tramite_opciones',
                                'tipo_de_tramite_respuesta',
                                'tipo_de_tramite_sub_opciones',
                                'tipo_de_tramite_sub_respuesta',
                              ];
                              const prop = Object.keys(changed)[0];
                              const changedIdx = _props.indexOf(prop);
                              const propiedades = _props.splice(changedIdx + 1);
                              formProceso.resetFields(propiedades);
                            }}
                          >
                            <Row gutter={10}>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="tipo_de_tramite_proceso"
                                  label="Tipo de Trámite o Servicio"
                                >
                                  <Select
                                    dataSource={tipoTramiteProceso}
                                    onChange={(val) => fetchSubProceso(val)}
                                    onSelect={setprocesoTramite}
                                  />
                                </Form.Item>
                              </Col>
                              {procesoTramite === 1 && (
                                <>
                                  <Col xs={24} sm={24} md={8}>
                                    <Form.Item
                                      label="Seleccione una Opción"
                                      name="tipo_de_tramite_sub_proceso"
                                    >
                                      <Select
                                        dataSource={filteredSubProceso}
                                        onChange={(val) => fetchOpciones(val)}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} sm={24} md={8}>
                                    <Form.Item
                                      label="Seleccione una Opción"
                                      name="tipo_de_tramite_opciones"
                                    >
                                      <Select
                                        dataSource={filteredOpciones}
                                      />
                                    </Form.Item>
                                  </Col>
                                </>
                              )}
                              {procesoTramite === 2 && (
                                <>
                                  {(selectedSub === 5) && (

                                    <Col xs={24} sm={24} md={8}>
                                      <Form.Item
                                        label="Seleccione una Opción"
                                        name="tipo_de_tramite_sub_proceso"
                                      >
                                        <Select
                                          dataSource={filteredSubProceso}
                                          onChange={(val) => fetchOpciones(val)}
                                          onSelect={setSelectedSub}
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}
                                  {(selectedSub !== 5) && (
                                    <Col xs={24} sm={24} md={8}>
                                      <Form.Item
                                        label="Seleccione una Opción"
                                        name="tipo_de_tramite_sub_proceso"
                                      >
                                        <Select
                                          dataSource={filteredSubProceso}
                                          onChange={(val) => fetchOpciones(val)}
                                          onSelect={setSelectedSub}
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}
                                  {selectedSub === 3 && (
                                    <>
                                      <Col xs={24} sm={24} md={8}>
                                        <Form.Item
                                          label="Seleccione una Opción"
                                          name="tipo_de_tramite_opciones"
                                        >
                                          <Select
                                            dataSource={filteredOpciones}
                                            onChange={(val) => fetchRespuestas(val)}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={24} md={8}>
                                        <Form.Item
                                          label="Seleccione una Opción"
                                          name="tipo_de_tramite_respuesta"
                                        >
                                          <Select
                                            dataSource={filteredAns}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </>
                                  )}
                                  {(selectedSub !== 5 && selectedSub !== 3) && (
                                    <>
                                      <Col xs={24} sm={24} md={8}>
                                        <Form.Item
                                          label="Seleccione una Opción"
                                          name="tipo_de_tramite_opciones"
                                        >
                                          <Select
                                            dataSource={filteredOpciones}
                                            onChange={(val) => fetchRespuestas(val)}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={24} md={8}>
                                        <Form.Item
                                          label="Seleccione una Opción"
                                          name="tipo_de_tramite_respuesta"
                                        >
                                          <Select
                                            dataSource={filteredAns}
                                            onChange={(val) => fetchSubOpciones(val)}
                                            onSelect={setAnswerSelected}
                                          />
                                        </Form.Item>
                                      </Col>
                                      {answerSelected === 2 && (
                                        <>

                                          <Col xs={24} sm={24} md={8}>
                                            <Form.Item
                                              label="Seleccione una Opción"
                                              name="tipo_de_tramite_sub_opciones"
                                            >
                                              <Select
                                                dataSource={filteredSubOpt}
                                                onChange={(val) => fetchSubRespuestas(val)}
                                              />
                                            </Form.Item>
                                          </Col>
                                          <Col xs={24} sm={24} md={8}>
                                            <Form.Item
                                              label="Seleccione una Opción"
                                              name="tipo_de_tramite_sub_respuesta"
                                            >
                                              <Select
                                                dataSource={filteredSubAns}
                                              />
                                            </Form.Item>
                                          </Col>
                                        </>
                                      )}
                                      {answerSelected === 5 && (
                                        <>

                                          <Col xs={24} sm={24} md={8}>
                                            <Form.Item
                                              label="Seleccione una Opción"
                                              name="tipo_de_tramite_sub_opciones"
                                            >
                                              <Select
                                                dataSource={filteredSubOpt}
                                                onChange={(val) => fetchSubRespuestas(val)}
                                              />
                                            </Form.Item>
                                          </Col>
                                          <Col xs={24} sm={24} md={8}>
                                            <Form.Item
                                              label="Seleccione una Opción"
                                              name="tipo_de_tramite_sub_respuesta"
                                            >
                                              <Select
                                                dataSource={filteredSubAns}
                                              />
                                            </Form.Item>
                                          </Col>
                                        </>
                                      )}
                                      {answerSelected === 7 && (
                                        <>

                                          <Col xs={24} sm={24} md={8}>
                                            <Form.Item
                                              label="Seleccione una Opción"
                                              name="tipo_de_tramite_sub_opciones"
                                            >
                                              <Select
                                                dataSource={filteredSubOpt}
                                                onChange={(val) => fetchSubRespuestas(val)}
                                              />
                                            </Form.Item>
                                          </Col>
                                          <Col xs={24} sm={24} md={8}>
                                            <Form.Item
                                              label="Seleccione una Opción"
                                              name="tipo_de_tramite_sub_respuesta"
                                            >
                                              <Select
                                                dataSource={filteredSubAns}
                                              />
                                            </Form.Item>
                                          </Col>
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                              {procesoTramite === 3 && (
                                <>
                                  {(selectedSub === 7) && (
                                    <Col xs={24} sm={24} md={8}>
                                      <Form.Item
                                        label="Seleccione Una Opción"
                                        name="tipo_de_tramite_sub_proceso"
                                      >
                                        <Select
                                          dataSource={filteredSubProceso}
                                          onSelect={setSelectedSub}
                                          onChange={(val) => fetchOpciones(val)}
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}

                                  {(selectedSub !== 7) && (
                                    <Col xs={24} sm={24} md={8}>
                                      <Form.Item
                                        label="Seleccione Una Opción"
                                        name="tipo_de_tramite_sub_proceso"
                                      >
                                        <Select
                                          dataSource={filteredSubProceso}
                                          onSelect={setSelectedSub}
                                          onChange={(val) => fetchOpciones(val)}
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}
                                  {selectedSub === 8 && (
                                    <>
                                      <Col xs={24} sm={24} md={8}>
                                        <Form.Item
                                          label="Seleccione Una Opción"
                                          name="tipo_de_tramite_opciones"
                                        >
                                          <Select
                                            dataSource={filteredOpciones}
                                            onChange={(val) => fetchRespuestas(val)}

                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={24} md={8}>
                                        <Form.Item
                                          label="Seleccione Una Opción"
                                          name="tipo_de_tramite_respuesta"
                                        >
                                          <Select
                                            dataSource={filteredAns}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </>
                                  )}
                                  {selectedSub === 9 && (
                                    <>
                                      <Col xs={24} sm={24} md={8}>
                                        <Form.Item
                                          label="Seleccione Una Opción"
                                          name="tipo_de_tramite_opciones"
                                        >
                                          <Select
                                            dataSource={filteredOpciones}
                                            onChange={(val) => fetchRespuestas(val)}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={24} md={8}>
                                        <Form.Item
                                          label="Seleccione Una Opción"
                                          name="tipo_de_tramite_respuesta"
                                        >
                                          <Select
                                            dataSource={filteredAns}
                                            onChange={(val) => fetchSubOpciones(val)}
                                            onSelect={setAnswerSelected}
                                          />
                                        </Form.Item>
                                      </Col>
                                      {answerSelected === 12 && (
                                        <>
                                          <Col xs={24} sm={24} md={8}>
                                            <Form.Item
                                              label="Seleccione Una Opción"
                                              name="tipo_de_tramite_sub_opciones"
                                            >
                                              <Select
                                                dataSource={filteredSubOpt}
                                                onChange={(val) => fetchSubRespuestas(val)}
                                              />
                                            </Form.Item>
                                          </Col>
                                          <Col xs={24} sm={24} md={8}>
                                            <Form.Item
                                              label="Seleccione Una Opción"
                                              name="tipo_de_tramite_sub_respuesta"
                                            >
                                              <Select
                                                dataSource={filteredSubAns}
                                              />
                                            </Form.Item>
                                          </Col>
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                              {procesoTramite === 4 && (
                                <>
                                  {(selectedSub !== 11 && selectedSub !== 12) && (
                                    <Col xs={24} sm={24} md={8}>
                                      <Form.Item
                                        label="Seleccione Una Opción"
                                        name="tipo_de_tramite_sub_proceso"
                                      >
                                        <Select
                                          dataSource={filteredSubProceso}
                                          onSelect={setSelectedSub}
                                          onChange={(val) => fetchOpciones(val)}
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}
                                  {selectedSub === 10 && (
                                    <>
                                      <Col xs={24} sm={24} md={8}>
                                        <Form.Item
                                          label="Seleccione una Opción"
                                          name="tipo_de_tramite_opciones"
                                        >
                                          <Select
                                            dataSource={filteredOpciones}
                                            onChange={(val) => fetchRespuestas(val)}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={24} md={8}>
                                        <Form.Item
                                          label="Seleccione una Opción"
                                          name="tipo_de_tramite_respuesta"
                                        >
                                          <Select
                                            dataSource={filteredAns}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </>
                                  )}

                                  {selectedSub === 11 && (
                                    <>
                                      <Col xs={24} sm={24} md={8}>
                                        <Form.Item
                                          label="Seleccione una Opción"
                                          name="tipo_de_tramite_sub_proceso"
                                        >
                                          <Select
                                            onSelect={setSelectedSub}
                                            dataSource={filteredSubProceso}
                                            onChange={(val) => fetchOpciones(val)}

                                          />
                                        </Form.Item>
                                      </Col>
                                    </>
                                  )}
                                  {selectedSub === 12 && (
                                    <>
                                      <Col xs={24} sm={24} md={8}>
                                        <Form.Item
                                          label="Seleccione una Opción"
                                          name="tipo_de_tramite_sub_proceso"
                                        >
                                          <Select
                                            onSelect={setSelectedSub}
                                            dataSource={filteredSubProceso}
                                            onChange={(val) => fetchOpciones(val)}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </>
                                  )}
                                  {selectedSub === 13 && (
                                    <>
                                      <Col xs={24} sm={24} md={8}>
                                        <Form.Item
                                          label="Seleccione una Opción"
                                          name="tipo_de_tramite_opciones"
                                        >
                                          <Select
                                            dataSource={filteredOpciones}
                                            onChange={(val) => fetchRespuestas(val)}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={24} md={8}>
                                        <Form.Item
                                          label="Seleccione una Opción"
                                          name="tipo_de_tramite_respuesta"
                                        >
                                          <Select
                                            onSelect={setAnswerSelected}
                                            dataSource={filteredAns}
                                            onChange={(val) => fetchSubOpciones(val)}
                                          />
                                        </Form.Item>
                                      </Col>
                                      {(answerSelected === 16 || answerSelected === 17) && (
                                        <>
                                          <Col xs={24} sm={24} md={8}>
                                            <Form.Item
                                              label="Seleccione una Opción"
                                              name="tipo_de_tramite_sub_opciones"
                                            >
                                              <Select
                                                dataSource={filteredSubOpt}
                                                onChange={(val) => fetchSubRespuestas(val)}
                                              />
                                            </Form.Item>
                                          </Col>
                                          <Col xs={24} sm={24} md={8}>
                                            <Form.Item
                                              label="Seleccione una Opción"
                                              name="tipo_de_tramite_sub_respuesta"
                                            >
                                              <Select
                                                dataSource={filteredSubAns}
                                              />
                                            </Form.Item>
                                          </Col>
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}

                              {procesoTramite === 5 && (
                                <>
                                  <Col xs={24} sm={24} md={8}>
                                    <Form.Item
                                      label="Seleccione una Opción"
                                      name="tipo_de_tramite_sub_proceso"
                                    >
                                      <Select
                                        dataSource={filteredSubProceso}
                                      />
                                    </Form.Item>
                                  </Col>
                                </>
                              )}
                              {procesoTramite === 6 && (
                                <>
                                  <Col xs={24} sm={24} md={8}>
                                    <Form.Item
                                      label="Seleccione una Opción"
                                      name="tipo_de_tramite_sub_proceso"
                                    >
                                      <Select
                                        dataSource={filteredSubProceso}
                                        onChange={(val) => fetchOpciones(val)}
                                        onSelect={setSelectedSub}
                                      />
                                    </Form.Item>
                                  </Col>

                                  {selectedSub === 20 && (
                                    <>
                                      <Col xs={24} sm={24} md={8}>
                                        <Form.Item
                                          label="Seleccione una Opción"
                                          name="tipo_de_tramite_opciones"
                                        >
                                          <Select
                                            dataSource={filteredOpciones}
                                            onChange={(val) => fetchRespuestas(val)}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={24} md={8}>
                                        <Form.Item
                                          label="Seleccione una Opción"
                                          name="tipo_de_tramite_respuesta"
                                        >
                                          <Select
                                            dataSource={filteredAns}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </>
                                  )}
                                </>
                              )}
                            </Row>
                          </Form>
                        </TabPane>
                      </Tabs>
                    </TabPane>
                    <TabPane
                      tab={(
                        <span>
                          <FileDoneOutlined />
                          {screens.md ? 'Interoperabilidad' : 'Interop.'}
                        </span>
                      )}
                      key="interoperabilidad"
                      forceRender
                    >
                      <Tabs
                        type="card"
                        onChange={setActiveTab}
                        activeKey={activeTab}
                      >
                        <TabPane
                          tab={(
                            <span>
                              <ImportOutlined />
                              Requisitos Generales
                            </span>
                          )}
                          key="interoperabilidad_requisitosGenerales"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formRequisitosInteroperabilidad}
                            name="interoperabilidad_requisitosGenerales"
                            onFinish={onFinish}
                            scrollToFirstError
                            onValuesChange={(changed) => formRequisitos.setFieldsValue(changed)}
                          >
                            <Row gutter={10}>
                              <RequisitosTransfer
                                formItemName="requisitos_generales"
                                formTable={formRequisitosInteroperabilidad}
                                onChangeRequisitos={(requisitos_generales) => formRequisitos
                                  .setFieldsValue({ requisitos_generales })}
                                setDataSourceRequisitos={setDataSourceRequisitos}
                                dataSourceRequisitos={dataSourceRequisitos}
                              />
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane
                          tab={(
                            <span>
                              <ImportOutlined />
                              Casos de los Requisitos
                            </span>
                          )}
                          key="interoperabilidad_casosRequisitos"
                          forceRender
                        >
                          <Row gutter={10}>
                            <TableCasos
                              selectedRowKey={selectedRowKeys[0]}
                              callback={setCasos}
                              setDataSourceRequisitos={setDataSourceRequisitos}
                              dataSourceRequisitos={dataSourceRequisitos}
                            />
                          </Row>
                        </TabPane>
                        <TabPane
                          tab={(
                            <span>
                              <InfoCircleOutlined />
                              Documento de Salida
                            </span>
                          )}
                          key="interoperabilidad_documentoSalida"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formDocSalida}
                            name="interoperabilidad_documentoSalida"
                            onFinish={onFinish}
                            scrollToFirstError
                          >
                            <Row gutter={10}>
                              <TableDocumentoSalida
                                callback={setDocumentoDeSalida}
                                selectedRowKey={selectedRowKeys[0]}
                              />
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane
                          tab={(
                            <span>
                              <InfoCircleOutlined />
                              Cadena
                            </span>
                          )}
                          key="interoperabilidad_cadena"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formCadena}
                            name="interoperabilidad_cadena"
                            onFinish={onFinish}
                            scrollToFirstError
                          >
                            <Row gutter={10}>
                              <TableCadenas
                                callback={setCadenas}
                                selectedRowKey={selectedRowKeys[0]}
                              />
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane
                          tab={(
                            <span>
                              <InfoCircleOutlined />
                              Interacciones
                            </span>
                          )}
                          key="interoperabilidad_interacciones"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formInteracciones}
                            name="interoperabilidad_interacciones"
                            onFinish={onFinish}
                            scrollToFirstError
                          >
                            <Row gutter={10}>
                              <Col xs={24} sm={24} md={8}>
                                <NumericInput
                                  name="cantidad_de_pasos_total"
                                  label="Cantidad de Pasos Totales"
                                />
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <NumericInput
                                  name="cantidad_de_pasos_fuera"
                                  label="Cantidad de Pasos Fuera"
                                  tooltip={{
                                    title: 'Cantidad de pasos fuera de la dependencia.',
                                  }}
                                />
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <NumericInput
                                  name="cantidad_de_documentos"
                                  label="Cantidad de Documentos"
                                  tooltip={{
                                    title: 'Cantidad de documentos requeridos.',
                                  }}
                                />
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="razones_salir"
                                  label="Razones Salir"
                                  tooltip={{
                                    title: 'Razones por las cuales el ciudadano tiene que salir de la dependencia o entidad.',
                                  }}
                                >
                                  <TextArea allowClear autoSize={{ minRows: 2, maxRows: 4 }} />
                                </Form.Item>
                              </Col>
                              <Form.Item hidden>
                                <Button htmlType="submit" />
                              </Form.Item>
                            </Row>
                          </Form>
                        </TabPane>
                      </Tabs>
                    </TabPane>
                    <TabPane
                      tab={(
                        <span>
                          <SolutionOutlined />
                          Costeo Estándar
                        </span>
                      )}
                      key="costeo"
                      forceRender
                    >
                      <Tabs
                        type="card"
                        onChange={setActiveTab}
                        activeKey={activeTab}
                      >
                        <TabPane
                          tab={(
                            <span>
                              <InfoCircleOutlined />
                              Costeo Estándar
                            </span>
                          )}
                          key="costeo_costeoEstandar"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formCosteo}
                            name="costeo_costeoEstandar"
                            onFinish={onFinish}
                            scrollToFirstError
                          >
                            <Row gutter={10}>
                              <TableCosteo
                                selectedRowKey={selectedRowKeys[0]}
                                callback={setCosteo}
                              />
                            </Row>
                          </Form>
                        </TabPane>
                      </Tabs>
                    </TabPane>
                    <TabPane
                      tab={(
                        <span>
                          <SolutionOutlined />
                          Oficina de Atención
                        </span>
                      )}
                      key="oficinaDeAtencion"
                      forceRender
                    >
                      <Tabs
                        type="card"
                        onChange={setActiveTab}
                        activeKey={activeTab}
                      >
                        <TabPane
                          tab={(
                            <span>
                              <SolutionOutlined />
                              Oficina de Atención
                            </span>

                          )}
                          key="oficinaDeAtencion_oficina"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formOficina}
                            name="oficinaDeAtencion_oficina"
                            onFinish={onFinish}
                            scrollToFirstError
                          >
                            <Row gutter={10}>
                              <TableOficinas
                                callback={setOficina}
                                selectedRowKey={selectedRowKeys[0]}
                              />
                            </Row>
                          </Form>
                        </TabPane>
                      </Tabs>
                    </TabPane>
                    <TabPane
                      tab={(
                        <span>
                          <SolutionOutlined />
                          Formatos Requeridos
                        </span>
                      )}
                      key="anexos"
                      forceRender
                    >
                      <Tabs
                        type="card"
                        onChange={setActiveTab}
                        activeKey={activeTab}
                      >
                        <TabPane
                          tab={(
                            <span>
                              <SolutionOutlined />
                              Anexos
                            </span>
                          )}
                          key="anexos_anexos"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formAnexos}
                            name="anexos_anexos"
                            onFinish={onFinish}
                            scrollToFirstError
                          >
                            <Row gutter={10}>
                              <TableAnexos
                                callback={setAnexos}
                                selectedRowKey={selectedRowKeys[0]}
                              />
                            </Row>
                          </Form>
                        </TabPane>
                      </Tabs>
                    </TabPane>
                    <TabPane
                      tab={(
                        <span className="required-mark">
                          <SettingOutlined />
                          Configurador
                        </span>
                      )}
                      key="configurador"
                      forceRender
                    >
                      <Tabs
                        type="card"
                        onChange={setActiveTab}
                        activeKey={activeTab}
                      >
                        <TabPane
                          tab={(
                            <span className="required-mark">
                              <InfoCircleOutlined />
                              General
                            </span>
                          )}
                          key="configurador_general"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formConfiguracionGeneral}
                            name="configurador_general"
                            onFinish={onFinish}
                            scrollToFirstError
                            initialValues={{
                              es_propietario: false,
                              formato_para_folio: null,
                              catalogo_de_canales_de_pago: [2],
                              padrones_solicitante: [1, 15],
                            }}
                          >
                            <Row gutter={10}>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="padrones_solicitante"
                                  label="Padrones solicitante"
                                  rules={rules.required}
                                >
                                  <Select
                                    mode="multiple"
                                    dataSource={padrones.filter((p) => [1, 15].includes(p.id))}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="es_propietario"
                                  label="Solicitante propietario"
                                  rules={rules.required}
                                  tooltip={'Seleccione "No" para permitir la búsqueda de padrones secundarios en caso de haberlo, o "Sí" si desea que el trámite solo lo pueda solicitar el propietario del padrón secundario'}
                                >
                                  <Select trueFalse />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="formato_para_folio"
                                  label="Formato para folio"
                                >
                                  <Select dataSource={foliadores} />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="id_reporte_acuse"
                                  label="ID de reporte"
                                  tooltip="ID de reporte de acuse"
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={16}>
                                <Form.Item
                                  name="catalogo_de_canales_de_pago"
                                  label="Canales de Atención"
                                  tooltip="Canales de Atención a travez de los cuales puede solicitarse/iniciarse el trámite"
                                >
                                  <Select dataSource={catalogosDePago} mode="multiple" />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane
                          tab={(
                            <span>
                              <InfoCircleOutlined />
                              Padrón secundario
                            </span>
                          )}
                          key="configurador_padronSecundario"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formPadronSecundario}
                            name="configurador_padronSecundario"
                            onFinish={onFinish}
                            scrollToFirstError
                            initialValues={initialValuesConfiguradorGeneral}
                            preserve={false}
                          >
                            <Row gutter={10}>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="requiere_padron"
                                  label="Requiere padrón"
                                >
                                  <Select trueFalse />
                                </Form.Item>
                              </Col>
                              {requiere_padron && (
                                <Col xs={24} sm={24} md={8}>
                                  <Form.Item
                                    name="tipo_de_padron"
                                    label="Tipo de padrón"
                                    rules={rules.required}
                                  >
                                    <Select dataSource={padrones} />
                                  </Form.Item>
                                </Col>
                              )}
                              {tipo_de_padron && (
                                <Col xs={24} sm={24} md={8}>
                                  <Form.Item
                                    name="configuracion_de_padron"
                                    label="Configuración de padrón"
                                    rules={rules.required}
                                  >
                                    <Select
                                      dataSource={configuracionesDePadrones
                                        .filter((c) => c.padron === tipo_de_padron)}
                                    />
                                  </Form.Item>
                                </Col>
                              )}
                              {tipo_de_padron === 3 && (
                                <Col xs={24} sm={24} md={8}>
                                  <Form.Item
                                    name="requiere_gis"
                                    label="¿Require GIS?"
                                    rules={rules.required}
                                  >
                                    <Select trueFalse />
                                  </Form.Item>
                                </Col>
                              )}
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane
                          tab={(
                            <span>
                              <ImportOutlined />
                              Requisitos Generales
                            </span>
                          )}
                          key="configurador_requisitosGenerales"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formRequisitos}
                            name="configurador_requisitosGenerales"
                            onFinish={onFinish}
                            scrollToFirstError
                            onValuesChange={(changed) => formRequisitosInteroperabilidad
                              .setFieldsValue(changed)}
                          >
                            <Row gutter={10}>
                              <RequisitosTransfer
                                formItemName="requisitos_generales"
                                formTable={formRequisitos}
                                onChangeRequisitos={(requisitos_generales) => {
                                  formRequisitosInteroperabilidad
                                    .setFieldsValue({ requisitos_generales });
                                }}
                                setDataSourceRequisitos={setDataSourceRequisitos}
                                dataSourceRequisitos={dataSourceRequisitos}
                              />
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane
                          tab={(
                            <span>
                              <ImportOutlined />
                              Casos de los Requisitos
                            </span>
                          )}
                          key="configurador_casosRequisitos"
                          forceRender
                        >
                          <Row gutter={10}>
                            <TableCasos
                              selectedRowKey={selectedRowKeys[0]}
                              callback={setCasos}
                              setDataSourceRequisitos={setDataSourceRequisitos}
                              dataSourceRequisitos={dataSourceRequisitos}
                            />
                          </Row>
                        </TabPane>
                        <TabPane
                          tab={(
                            <span>
                              <SettingOutlined />
                              Variables
                            </span>
                          )}
                          key="configurador_variablesTramite"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formVariables}
                            name="configurador_variablesTramite"
                            onFinish={onFinish}
                            initialValues={{ variables: [] }}
                            scrollToFirstError
                          >
                            <Row gutter={10}>
                              <Col span={24}>
                                <PlainTransfer
                                  dataSource={variables}
                                  label="Variables"
                                  formItemName="variables"
                                  form={formVariables}
                                  filterProp="descripcion_de_variable"
                                />
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="id_acuse_variables"
                                  label="ID de reporte"
                                  tooltip="ID de reporte de acuse de variables"
                                  rules={rules.required}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane
                          tab={(
                            <span>
                              <DollarOutlined />
                              Cargos
                            </span>
                          )}
                          key="configurador_cargosTramite"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formCargos}
                            name="configurador_cargosTramite"
                            onFinish={onFinish}
                            initialValues={initialValuesCargos}
                            scrollToFirstError
                            preserve={false}
                            onValuesChange={(changed) => formMonto.setFieldsValue(changed)}
                          >
                            <FormCargos
                              getTiposDeCargos={fetchTiposDeCargos}
                              monedas={moneda}
                              etapasDePago={etapaDePago}
                              plantilla={selected}
                              setDataSourceCargos={setDataSourceCargos}
                              dataSourceCargos={dataSourceCargos}
                              onChangeTiposDeCargos={(tipos_de_cargos) => formMonto
                                .setFieldsValue({ tipos_de_cargos })}
                            />
                          </Form>
                        </TabPane>
                        <TabPane
                          tab={(
                            <span>
                              <DollarOutlined />
                              Consulta de Adeudo
                            </span>
                          )}
                          key="configurador_consultaAdeudo"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formAdeudo}
                            name="configurador_consultaAdeudo"
                            onFinish={onFinish}
                            initialValues={{
                              es_consulta_de_adeudo: false,
                              no_iniciar_con_adeudo: false,
                              permite_generar_sin_adeudo: false,
                              tipos_de_cargos_consulta: [],
                            }}
                            scrollToFirstError
                          >
                            <FormAdeudo
                              getTiposDeCargos={fetchTiposDeCargos}
                              dataSourceCargosConsulta={dataSourceCargosConsulta}
                              setDataSourceCargosConsulta={setDataSourceCargosConsulta}
                            />
                          </Form>
                        </TabPane>
                        <TabPane
                          tab={(
                            <span>
                              <ToolOutlined />
                              Proceso
                            </span>
                          )}
                          key="configurador_pasosDelTramite"
                          forceRender
                          disabled={!selectedRowKeys.length}
                        >
                          <PasosParaTramites
                            plantilla_de_tramite={selectedRowKeys[0]}
                            tipoDePadron={tipo_de_padron}
                            tipoDeAfectacion={configuracionesDePadrones
                              .find((c) => c.id === configuracion_de_padron)?.tipo_de_afectacion}
                            departamentos={departamentosFormOrganizaciones}
                            configuracionesDePadrones={configuracionesDePadrones}
                            variables={variables}
                            getTiposDeCargos={getTiposDeCargos}
                            setDataSourceRequisitos={setDataSourceRequisitos}
                            dataSourceRequisitos={dataSourceRequisitos}
                            foliadores={foliadores}
                          />
                        </TabPane>
                      </Tabs>
                    </TabPane>
                    <TabPane
                      tab={(
                        <span className="required-mark">
                          <SettingOutlined />
                          Citas
                        </span>
                      )}
                      key="citas"
                      forceRender
                    >
                      <Tabs
                        type="card"
                        onChange={setActiveTab}
                        activeKey={activeTab}
                      >
                        <TabPane
                          tab={(
                            <span className="required-mark">
                              <InfoCircleOutlined />
                              General
                            </span>
                          )}
                          key="general"
                          forceRender
                        >
                          <Form
                            layout="vertical"
                            form={formCitas}
                            name="citas_general"
                            onFinish={onFinish}
                            scrollToFirstError
                            initialValues={{ requiere_cita: false }}
                          >
                            <Row gutter={10}>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  name="requiere_cita"
                                  label="Requiere cita"
                                >
                                  <Select trueFalse />
                                </Form.Item>
                              </Col>
                              {requiere_cita && (
                                <>
                                  <Col xs={24} sm={24} md={16}>
                                    <Form.Item
                                      name="descripcion_cita"
                                      label="Descripción"
                                      rules={rules.required}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} sm={24} md={8}>
                                    <Form.Item
                                      name="configuracion_de_folio_cita"
                                      label="Formato para folio"
                                    >
                                      <Select dataSource={foliadores} />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} sm={24} md={8}>
                                    <Form.Item
                                      name="reporte_cita"
                                      label="ID de reporte"
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} sm={24} md={8}>
                                    <Form.Item
                                      name="generar_tramite_posterior_a_cita"
                                      label="Puede generar trámite posterior a cita"
                                    >
                                      <Select trueFalse />
                                    </Form.Item>
                                  </Col>
                                </>
                              )}
                            </Row>
                          </Form>
                        </TabPane>
                      </Tabs>
                    </TabPane>
                    {(plantillaDeRetys && configFlujos) && (
                      <TabPane
                        tab={(
                          <span>
                            <SolutionOutlined />
                            Estado
                          </span>
                        )}
                        key="estado"
                        forceRender
                      >
                        <Tabs
                          type="card"
                          onChange={setActiveTab}
                          activeKey={activeTab}
                        >
                          <TabPane
                            tab={(
                              <span>
                                <SolutionOutlined />
                                Estado
                              </span>
                            )}
                            key="estado_estado"
                            forceRender
                          >
                            <Editar
                              callback={async () => {
                                onCancel();
                                await fetchData();
                              }}
                              plantilla={plantillaDeRetys}
                              configFlujos={configFlujos}
                              setLoading={setLoading}
                            />
                          </TabPane>
                        </Tabs>
                      </TabPane>
                    )}
                  </>
                )}
              </Tabs>
            </Card>
          )}
        </TemaAplicado>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content="El Trámite seleccionado"
        />
        <BusquedaAvanzadaUsers
          visible={visibleAdvancedQueja || visibleAdvancedResponsable || visibleAdvancedSupervisor}
          setVisible={(val) => {
            if (visibleAdvancedQueja) {
              setVisibleAdvancedQueja(val);
            } else if (visibleAdvancedResponsable) {
              setVisibleAdvancedResponsable(val);
            } else if (visibleAdvancedSupervisor) {
              setVisibleAdvancedSupervisor(val);
            }
          }}
          callback={(item) => {
            const _selected = {
              ...item,
              nombre_completo: `${item?.first_name} ${item?.last_name
                || ''} ${item?.second_last_name || ''}`,
            };
            if (visibleAdvancedQueja) {
              setUsuarioQueja([_selected]);
              formPersonas.setFieldsValue({ usuario_responsable_de_queja: item.id });
            } else if (visibleAdvancedResponsable) {
              setUsuarioResponsable([_selected]);
              formPersonas.setFieldsValue({ usuario_responsable_de_la_resolucion: item.id });
            } else {
              setUsuarioSupervisor([_selected]);
              formPersonas.setFieldsValue({ usuario_supervisor_de_ROM: item.id });
            }
          }}
          dataExterno={[]}
          setDataExterno={() => { }}
        />
      </Spin>
    </Row>
  );
};

const TemaAplicado = styled.div`
.ant-tabs-left {
  overflow: hidden;
}

.ant-tabs-ink-bar {
  background: ${(props) => (props.theme.primaryText)};
  pointer-events: none;
}


.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: ${(props) => (props.theme.primaryText)};

  text-shadow: 0 0 0.25px currentColor;
}

.ant-tabs-tab span.required-mark {
  ::after {
    display: inline-block;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 18px;
    line-height: 1;
    font-weight: bold;
    content: "*";
  }
}
`;

export default Tramite;
