import React, { useState, useEffect, useId } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Card,
  DatePicker,
  Typography,
} from 'antd';

import { PlusOutlined } from '@ant-design/icons';
import FormSubmitControls from 'components/FormSubmitControls';
import FormSubmitCustomControls from 'components/FormSubmitCustomControls';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Select from 'components/Select';
import PlainTransfer from 'components/PlainTransfer';
import getFormatedValues, { DATE_FRONTEND_FORMAT, formatReceived, hasFiles } from 'utils/formatValues';
import Uploader from 'components/Uploader';

const baseURI = '/configuracion/fundamentos-legales';

const fundamentoToString = (e) => `${e?.denominacion || ''} -  Artículo: ${e?.articulo || ''} - Fracción: ${e?.fraccion || ''} - Inciso: ${e?.inciso || ''} - Párrafo: ${e?.parrafo || ''}`;

const FundamentosTransfer = ({
  formFundamento,
  formItemName,
}) => {
  const formName = useId();
  const [form] = Form.useForm();
  const [formFilter] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [nombreDeOrdenamiento, setNombreDeOrdenamiento] = useState([]);
  const [ambito, setAmbito] = useState([]);
  const [tipoDeAmbito, setTipoDeAmbito] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  // const fetchData = async () => {
  //   try {
  //     const res = await API.get(baseURI);
  //     if (res?.status === 200) {
  //       setData(formatReceived(res.data.map((e) => ({
  //         ...e,
  //         denominacion: Object.key(e).length === 1 ? 'key' : ` ${e
  //           ?.denominacion || ''} -  Artículo: ${e
  //           ?.articulo || ''} - Fracción: ${e
  //           ?.fraccion || ''} - Inciso: ${e
  //           ?.inciso || ''} - Párrafo: ${e
  //           ?.parrafo || ''} `,
  //       }))));
  //     }
  //     setLoading(false);
  //   } catch (err) {
  //     onError(err, setLoading);
  //   }
  // };

  const fetchAll = async () => {
    try {
      const resAmbito = await API.get('/catalogos/ambito-de-ordenamiento/');
      setAmbito(resAmbito.data || []);
      const resTipoAmbito = await API.get('/catalogos/tipo-de-ordenamiento/');
      setTipoDeAmbito(resTipoAmbito.data || []);
      // await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };
  useEffect(() => {
    fetchAll();
    setFilteredData((formFundamento.getFieldValue('fundamento_legal_objects') || []).map((e) => ({
      id: e.id,
      denominacion: fundamentoToString(e),
    })));
    return () => API.source.cancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
  };

  const filteredFilter = async () => {
    const {
      ambito_de_ordenamiento,
      tipo_de_ordenamiento,
      nombre_de_ordenamiento,
    } = formFilter.getFieldsValue();
    const fundamentos = formFundamento.getFieldValue('fundamento_legal_objects') || [];
    if (ambito_de_ordenamiento && tipo_de_ordenamiento && nombre_de_ordenamiento) {
      const resFilter = await API.get(`${baseURI}`, {
        params: { ambito_de_ordenamiento, tipo_de_ordenamiento, nombre_de_ordenamiento },
      });
      const _data = resFilter.data
        .concat(fundamentos.filter((e) => !resFilter.data.some((x) => x.id === e.id)) || []);
      setFilteredData(_data.map((e) => ({
        ...e,
        denominacion: fundamentoToString(e),
      })));
    } else {
      setFilteredData(fundamentos.map((e) => ({
        ...e,
        denominacion: fundamentoToString(e),
      })));
    }
  };

  const filterOutside = async () => {
    const { ambito_de_ordenamiento, tipo_de_ordenamiento } = formFilter.getFieldsValue();
    if (ambito_de_ordenamiento && tipo_de_ordenamiento) {
      const resNombre = await API.get('/catalogos/nombre-de-ordenamiento/', {
        params: { ambito_de_ordenamiento, tipo_de_ordenamiento },
      });
      setNombreDeOrdenamiento(formatReceived(resNombre.data || []));
    } else {
      setNombreDeOrdenamiento([]);
    }
  };

  const filter = async () => {
    const { ambito_de_ordenamiento, tipo_de_ordenamiento } = form.getFieldsValue();
    if (ambito_de_ordenamiento && tipo_de_ordenamiento) {
      const resNombre = await API.get('/catalogos/nombre-de-ordenamiento/', {
        params: { ambito_de_ordenamiento, tipo_de_ordenamiento },
      });
      setNombreDeOrdenamiento(formatReceived(resNombre.data || []));
    } else {
      setNombreDeOrdenamiento([]);
    }
  };

  const fillFields = (val) => {
    if (val) {
      const clone = [...nombreDeOrdenamiento];
      const match = clone.find((i) => i.id === val);
      form.setFieldsValue({
        denominacion: match?.descripcion || '',
        fecha_publicacion: match?.fecha_de_publicacion || '',
        fecha_de_vigor: match?.fecha_de_vigor || '',
      });
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const files = ['documento'];
      const config = hasFiles(values, files) ? {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      } : {};
      const formattedvalues = getFormatedValues(values, {
        files,
        formData: config.headers,
        clean: true,
      });
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, formattedvalues, config);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await filteredFilter();
          // await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        await form.validateFields();
        const response = await API.put(`${baseURI}${key}/`, formattedvalues, config);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          // await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClick = () => {
    setVisible(true);
    setSelectedRowKeys([]);
    form.setFieldsValue(formFilter.getFieldsValue());
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
    hipervinculo: [
      {
        type: 'url',
        message: 'Ingrese un hipervínculo válido',
      },
    ],
  };

  const allowedExtensions = ['pdf'];

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <>
            <Typography.Title level={4}>
              Filtrado de Fundamentos Legales
            </Typography.Title>
            <Form
              preserve
              layout="vertical"
              form={formFilter}
              scrollToFirstError
              name={formName}
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="ambito_de_ordenamiento"
                    label="Ámbito del Ordenamiento"
                    hasFeedback
                  >
                    <Select
                      dataSource={ambito}
                      onChange={() => {
                        filterOutside();
                        filteredFilter();
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="tipo_de_ordenamiento"
                    label="Tipo del Ordenamiento"
                    hasFeedback
                  >
                    <Select
                      dataSource={tipoDeAmbito}
                      onChange={() => {
                        filterOutside();
                        filteredFilter();
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="nombre_de_ordenamiento"
                    label="Nombre del Ordenamiento"
                    hasFeedback
                  >
                    <Select
                      dataSource={nombreDeOrdenamiento}
                      onChange={(val) => {
                        filteredFilter();
                        fillFields(val);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Row justify="end">
              <FormSubmitCustomControls
                controls={[
                  {
                    text: 'Agregar Fundamento Legal',
                    onClick,
                    icon: <PlusOutlined />,
                  },
                ]}
              />
            </Row>
            <PlainTransfer
              dataSource={filteredData || []}
              label="Fundamentos Legales"
              formItemName={formItemName}
              form={formFundamento}
              filterProp="denominacion"
              real
            />
          </>
        ) : (
          <Card
            bordered={false}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
              />
            )}
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Fundamento Legal`}
          >
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              scrollToFirstError
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="ambito_de_ordenamiento"
                    label="Ámbito del Ordenamiento"
                    rules={rules.required}
                    hasFeedback
                  >
                    <Select
                      dataSource={ambito}
                      onChange={filter}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="tipo_de_ordenamiento"
                    label="Tipo Ordenamiento"
                    rules={rules.required}
                    hasFeedback
                  >
                    <Select
                      dataSource={tipoDeAmbito}
                      onChange={filter}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="nombre_de_ordenamiento"
                    label="Nombre del Ordenamiento"
                    rules={rules.required}
                    hasFeedback
                  >
                    <Select
                      dataSource={nombreDeOrdenamiento}
                      onChange={fillFields}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="area_responsable"
                    label="Área responsable"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="denominacion"
                    label="Denominación"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="articulo"
                    label="Artículo"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="hipervinculo"
                    label="Hipervínculo"
                    hasFeedback
                    rules={rules.hipervinculo}
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="fecha_publicacion"
                    label="Fecha de publicación"
                    hasFeedback
                  >
                    <DatePicker
                      format="DD/MM/YYYY"
                      placeholder=""
                    // disabledDate={(date) => date.isAfter(new Date())}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="fecha_de_vigor"
                    label="Fecha de Vigor"
                    hasFeedback
                  >
                    <DatePicker
                      placeholder=""
                      format={DATE_FRONTEND_FORMAT}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="fraccion"
                    label="Fracción"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="inciso"
                    label="Inciso"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="parrafo"
                    label="Párrafo"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="numero"
                    label="Número"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="letra"
                    label="Letra"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="otro_fundamento_legal"
                    label="Otro Fundamento Legal"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Uploader
                  title="Documento"
                  limitMB={50}
                  file={form.getFieldValue('documento')}
                  formItemName="documento"
                  allowedExtensions={allowedExtensions}
                  onError={onError}
                  tooltip={{
                    title: `Extensiones Permitidas para el Documento: ${allowedExtensions}`,
                  }}
                />
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          </Card>
        )}
      </Spin>
    </Row>
  );
};
FundamentosTransfer.propTypes = {
  formFundamento: PropTypes.shape({
    getFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  formItemName: PropTypes.string,
};
FundamentosTransfer.defaultProps = {
  formItemName: 'fundamento_legal',

};
export default FundamentosTransfer;
