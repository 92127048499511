export const capitalizeFirstLetter = ([first, ...rest], locale = navigator.language) => [first.toLocaleUpperCase(locale), ...rest].join('');

export const normalizeCurrentUri = (currentURI, prefix = false) => {
  let output = currentURI.split(' ').map((x) => capitalizeFirstLetter(x)).join(' ');
  if (output.endsWith('s')) {
    output = output.slice(0, output.length - 1);
    if (output.endsWith('re')) {
      output = output.slice(0, output.length - 1);
    }
  }
  if (prefix) {
    return `el ${output}`;
  }
  return output;
};
