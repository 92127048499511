import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Spin,
  Row,
  Col,
  Modal,
  message,
} from 'antd';

import {
  SearchOutlined,
  DeleteOutlined,
  ArrowLeftOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import API from 'utils/api';
import { onError } from 'utils/handlers';
// import {
//   CURP_PATTERN,
//   digitoVerificador,
//   RFC_PATTERN,
//   INE_PATTERN,
// } from 'utils/patterns';
import FormSubmitControls from 'components/FormSubmitCustomControls';
import LadaNumero from 'components/LadaNumero';

import Table from 'components/Table';
import { postUsuario } from '../api/usuarios/usuarios';
import Select from './Select';

const BusquedaAvanzadaUsers = ({
  visible,
  setVisible,
  callback,
  dataExterno,
  setDataExterno,
}) => {
  const [form] = Form.useForm();
  const [formUser] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [funcion, setFuncion] = useState([]);
  const [visibleAddUser, setVisibleAddUser] = useState(false);

  const URIFUNCIONES = 'usuarios/funciones/';

  const fetchCatalogs = async () => {
    try {
      setLoading(true);
      const resFuncion = await API.get(URIFUNCIONES);
      setFuncion(resFuncion.data || []);
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  useEffect(() => {
    fetchCatalogs();
    return () => API.source.cancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setData([]);
    if (setDataExterno) {
      setDataExterno([]);
    }
    setSelectedRowKeys([]);
    form.resetFields();
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      setSelectedRowKeys([]);
      const params = form.getFieldsValue();
      const keys = Object.keys(params);
      const validProps = keys.filter((prop) => params[prop]);
      if (validProps.length) {
        const response = await API.get('usuarios/usuarios', {
          params,
        });
        if (response?.status === 200) {
          if (response.data?.length) {
            setData(response.data);
          } else {
            message.warn('No se encontro usuario', 2.5);
            setData([]);
          }
        }
      } else {
        message.warn('Debe ingresar al menos un campo');
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onFinishSearch = async (record) => {
    callback(record);
    onCancel();
  };

  const handleLocalSearch = () => {
    const values = form.getFieldsValue();
    const keys = Object.keys(values);
    const validProps = keys.filter((prop) => values[prop]);
    if (validProps.length) {
      const normalizeText = (text = '') => text?.toLowerCase()
        .normalize('NFD').replace(/[\u0300-\u036f]/g, '');

      const validateIncludes = (obj, prop) => {
        if (validProps.includes(prop)) {
          const normalizedValue = normalizeText(obj[prop]);
          const normalizedInput = normalizeText(values[prop]);
          return normalizedValue.includes(normalizedInput);
        }
        return false;
      };

      const validateEquals = (obj, prop) => obj[prop] === values[prop];
      const filtered = data.filter((item) => validateIncludes(item, 'ciudadano')
        || validateEquals(item, 'uso_de_predio'));
      setData(filtered);
    } else {
      message.warn('Debe ingresar al menos un campo');
    }
  };

  useEffect(() => {
    setData(dataExterno);
    // eslint-disable-next-line
  }, [dataExterno]);

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const add = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const match = data.find((e) => e.id === key);
      onFinishSearch(match);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };
  // const rules = {
  //   required: [
  //     {
  //       required: true,
  //       message: 'El campo es requerido',
  //     },
  //   ],
  //   RFC: [
  //     {
  //       validator: async (r, val) => {
  //         if (val) {
  //           if (!RFC_PATTERN.test(val) || val.length !== 13) {
  //             throw new Error('Ingrese un RFC válido');
  //           }
  //         }
  //       },
  //     },
  //   ],
  //   INE: [
  //     {
  //       pattern: INE_PATTERN,
  //       message: 'Ingrese un INE válido',
  //     },
  //   ],
  //   curp: [
  //     {
  //       validator: async (rule, value) => {
  //         if (value) {
  //           const validado = value.toUpperCase().match(CURP_PATTERN);
  //           if (!validado || validado[2] !== digitoVerificador(validado[1])) {
  //             throw new Error('Ingrese una CURP válida');
  //           }
  //         }
  //       },
  //     },
  //   ],
  //   email: [
  //     {
  //       required: true,
  //       message: 'El campo es requerido',
  //     },
  //     {
  //       type: 'email',
  //       message: 'Ingrese un correo electrónico válido',
  //     },
  //   ],
  //   email_alternativo: [
  //     {
  //       type: 'email',
  //       message: 'Ingrese un correo electrónico válido',
  //     },
  //   ],
  // };

  const controls = [
    {
      text: 'Regresar',
      icon: <ArrowLeftOutlined />,
      onClick: () => onCancel(),
    },
    {
      text: 'Limpiar',
      icon: <DeleteOutlined />,
      onClick: () => {
        setData([]);
        form.resetFields();
      },
    },
  ];

  const onCancelAdd = () => {
    setVisibleAddUser(false);
    setVisible(true);
    formUser.resetFields();
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await formUser.validateFields();

      const values = formUser.getFieldsValue();
      values.is_active = false;
      const saved = await postUsuario(values);

      if (saved) {
        callback(saved);
        onCancelAdd(saved);
        setVisible(false);
        formUser.resetFields();
      }

      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const controlsAdd = [
    {
      text: 'Regresar',
      icon: <ArrowLeftOutlined />,
      onClick: () => onCancelAdd(),
    },
    {
      text: 'Agregar',
      icon: <PlusOutlined />,
      onClick: () => onFinish(),
    },
  ];

  const columns = [
    {
      titleText: 'Nombre',
      dataIndex: 'first_name',
      key: 'first_name',
      width: 300,
    },
    {
      titleText: 'Apellido Paterno',
      dataIndex: 'last_name',
      key: 'last_name',
      width: 300,
    },
    {
      titleText: 'Apellido Materno',
      dataIndex: 'second_last_name',
      key: 'second_last_name',
      width: 300,
    },
    {
      titleText: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 300,
    },
  ];

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  return (
    <>
      <Modal
        width="90%"
        open={visible}
        closable={false}
        footer={null}
        title={(
          <Row justify="space-between">
            Búsqueda Avanzada
            <FormSubmitControls
              controls={controls}
              loading={loading}
            />
          </Row>
        )}
        onCancel={onCancel}
        maskClosable={!loading}
        keyboard={!loading}
        forceRender
        centered
      >
        <Spin tip="Cargando..." spinning={loading}>
          <Form
            form={form}
            layout="vertical"
            onFinish={dataExterno?.length ? handleLocalSearch : handleSearch}
            name="busquedaAvanzadaUsers"
          >
            <Row gutter={10}>
              <Col
                xs={24}
                sm={24}
                md={8}
              >
                <Form.Item
                  name="first_name"
                  label="Nombre"
                  hasFeedback
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
              >
                <Form.Item
                  name="last_name"
                  label="Apellido Paterno"
                  hasFeedback
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
              >
                <Form.Item
                  name="second_last_name"
                  label="Apellido Materno"
                  hasFeedback
                >
                  <Input allowClear />
                </Form.Item>
              </Col>

            </Row>
            <Row gutter={[10, 5]} justify="end">
              <Col xs={24} sm={24} md={6} lg={4}>
                <Button
                  block
                  onClick={() => {
                    setVisibleAddUser(true);
                  }}
                >
                  <PlusOutlined />
                  Agregar
                </Button>
              </Col>
              <Col xs={24} sm={24} md={6} lg={4}>
                <Button block htmlType="submit">
                  <SearchOutlined />
                  Buscar
                </Button>
              </Col>
              <Col xs={24} sm={24} md={6} lg={4}>
                <Button
                  onClick={add}
                  block
                  disabled={!selectedRowKeys.length}
                >
                  <PlusOutlined />
                  Cargar
                </Button>
              </Col>
            </Row>
          </Form>
          <Col span={24}>
            <Table
              cols={columns}
              data={data}
              noControls
              allowSort={false}
              allowSearch={false}
              rowSelection={rowSelection}
              onRow={(record) => ({
                onClick: () => {
                  setSelectedRowKeys([record.id]);
                },
                onDoubleClick: add,
              })}
              rowKey="id"
            />
          </Col>
        </Spin>
      </Modal>
      <Modal
        width="90%"
        open={visibleAddUser}
        closable={false}
        footer={null}
        title={(
          <Row justify="space-between">
            Agregar Usuario
            <FormSubmitControls
              controls={controlsAdd}
              loading={loading}
            />
          </Row>
        )}
        onCancel={onCancel}
        maskClosable={!loading}
        keyboard={!loading}
        forceRender
        centered
      >
        <Spin tip="Cargando..." spinning={loading}>
          <Form
            form={formUser}
            layout="vertical"
            onFinish={dataExterno?.length ? handleLocalSearch : handleSearch}
          >
            <Row gutter={10}>
              <Col
                xs={24}
                sm={24}
                md={8}
              >
                <Form.Item
                  name="first_name"
                  label="Nombre"
                  hasFeedback
                  rules={rules.required}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
              >
                <Form.Item
                  name="last_name"
                  label="Apellido Paterno"
                  hasFeedback
                  rules={rules.required}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
              >
                <Form.Item
                  name="second_last_name"
                  label="Apellido Materno"
                  hasFeedback
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
              >
                <Form.Item
                  name="email"
                  label="Correo Electrónico"
                  hasFeedback
                  rules={rules.required}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
              >
                <Form.Item
                  rules={rules.required}
                  name="password"
                  label="Contraseña"
                  hasFeedback
                >
                  <Input.Password allowClear />
                </Form.Item>
              </Col>
              <LadaNumero
                form={formUser}
                names={{
                  lada: 'lada',
                  numero_de_telefono: 'numero_de_celular',
                }}
                required
                breakPoints={{
                  md: 24,
                  lg: 12,
                  xl: 8,
                  xxl: 8,
                }}
              />
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="funcion_del_usuario_externo"
                  label="Función"
                  hasFeedback
                  rules={rules.required}
                >
                  <Select dataSource={funcion} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

BusquedaAvanzadaUsers.propTypes = {
  callback: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  dataExterno: PropTypes.arrayOf(PropTypes.shape()),
  setDataExterno: PropTypes.func,
};

BusquedaAvanzadaUsers.defaultProps = {
  dataExterno: [],
  setDataExterno: null,
};

export default BusquedaAvanzadaUsers;
