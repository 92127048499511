import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Input,
  Card,
} from 'antd';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import { onSuccess, onError } from 'utils/handlers';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';

const baseURI = 'tramites/documentos-de-salida-de-tramites/';

const TableDocumentoSalida = ({
  selectedRowKey,
  callback,
}) => {
  const [formTable] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [formatoFolio, setFormatoFolio] = useState([]);
  const [firmante, setFirmante] = useState([]);
  const [tipoDeDocumento, setTipoDeDocumento] = useState([]);
  const [firmaElectronica, setFirmaElectronica] = useState(false);
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI, {
        params: {
          plantilla_de_tramites: selectedRowKey,
        },
      });

      if (response?.status === 200) {
        setData(response.data);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resTipoDocumento = await API.get('/tramites/tipos-de-documentos/');
      setTipoDeDocumento(resTipoDocumento.data || []);
      const resFolio = await API.get('/configuracion/configuraciones-de-segmentos-de-folios/');
      setFormatoFolio(resFolio.data || []);
      const resFirmante = await API.get('/usuarios/usuarios/');
      setFirmante(resFirmante.data || []);

      if (selectedRowKey) {
        await fetchData();
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();

    return () => API.source.cancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    formTable.resetFields();
    setVisibleAlert(false);
    setFirmaElectronica(false);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await formTable.validateFields();
      const values = formTable.getFieldsValue();
      const [key] = selectedRowKeys;
      if (selectedRowKey) {
        values.plantilla_de_tramites = selectedRowKey;
        if (!key) {
          const response = await API.post(baseURI, values);
          if (response?.status === 201) {
            onSuccess(response, 'Agregado correctamente');
            onCancel();
            await fetchData();
          }
        } else {
          const response = await API.put(`${baseURI}${key}/`, values);
          if (response?.status === 200) {
            onSuccess(response, 'Actualizado correctamente');
            onCancel();
            await fetchData();
          }
        }
      } else {
        const clone = [...data];
        if (!key) {
          clone.push({
            ...values,
            key: clone.length + 1,
          });
          setData(clone);
          callback(clone);
          onCancel();
        } else {
          const res = clone.map((item) => (item.key === key ? { ...values, key } : item));
          setData(res);
          callback(res);
          onCancel();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      if (selectedRowKey) {
        if (key) {
          const response = await API.delete(`${baseURI}${key}/`);
          if (response?.status === 204) {
            onSuccess(response, 'Eliminado correctamente');
            onCancel();
            await fetchData();
          }
        }
      } else {
        const clone = data.filter((item) => item.key !== key);
        const normalized = clone.map((item, idx) => ({ ...item, key: idx }));
        setData(normalized);
        callback(normalized);
        onCancel();
        setVisibleAlert(false);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickAdd = () => {
    setVisible(true);
    setSelectedRowKeys([]);
    formTable.resetFields();
    setFirmaElectronica(false);
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const handleOnRowClick = (record) => {
    const _key = selectedRowKey ? record.id : record.key;
    setSelectedRowKeys([_key]);
    formTable.setFieldsValue(record);
    setFirmaElectronica(record?.lleva_firma_electronica);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es Requerido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Nombre del Documento',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 300,
    },
    {
      titleText: 'Folio',
      dataIndex: 'folio',
      key: 'folio',
      width: 300,
    },
    {
      titleText: 'Tipo de Documento',
      dataIndex: 'tipo_de_documento',
      key: 'tipo_de_documento',
      width: 300,
      render: (val) => (tipoDeDocumento.find((item) => item.id === val)?.descripcion),

    },
    {
      titleText: 'Tipo de Entrega del Documento',
      dataIndex: 'tipo_de_entrega_del_documento_de_salida',
      key: 'tipo_de_entrega_del_documento_de_salida',
      width: 300,
    },
    {
      titleText: 'Otro Tipo de Documento',
      dataIndex: 'otro_tipo_de_documento',
      key: 'otro_tipo_de_documento',
      width: 300,

    },
    {
      titleText: 'Formato de Folio',
      dataIndex: 'formato_para_folio',
      key: 'formato_para_folio',
      width: 300,
      render: (val) => (formatoFolio.find((item) => item.id === val)?.descripcion),

    },
    {
      titleText: 'Firmante',
      dataIndex: 'firmante',
      key: 'firmante',
      width: 300,
      render: (val) => (firmante.find((item) => item.id === val)?.email),

    },
  ];

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            mobileColIndex={0}
            rowKey={selectedRowKey ? 'id' : 'key'}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            baseURI={baseURI}
            allowImport
          />
        ) : (
          <Card
            bordered={false}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
              />
            )}
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Documento de Salida`}
          >

            <Form
              layout="vertical"
              name="general"
              form={formTable}
              onFinish={onFinish}
              scrollToFirstError
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="nombre"
                    label="Nombre del Documento"
                    hasFeedback
                    rules={rules.required}
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="folio"
                    label="Folio"
                    tooltip="Asignación de Folio"
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    hasFeedback
                    label="Tipo De Entrega Del Documento"
                    name="tipo_de_entrega_del_documento_de_salida"
                    tooltip={{
                      title: 'Tipo de entrega electrónica del documento de salida',
                    }}
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Tipo de Documento"
                    name="tipo_de_documento"
                    hasFeedback
                  >
                    <Select dataSource={tipoDeDocumento} />

                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="otro_tipo_de_documento"
                    label="Otro Tipo de Documento"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="lleva_firma_electronica"
                    label="¿Requiere Firma Electrónica?"
                    hasFeedback
                  >
                    <Select onChange={setFirmaElectronica} trueFalse />
                  </Form.Item>
                </Col>
                {firmaElectronica && (
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="firmante"
                      label="Firmante"
                      hasFeedback
                    >
                      <Select dataSource={firmante} labelProp="email" />
                    </Form.Item>
                  </Col>
                )}

                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="dato_de_salida"
                    label="Dato de Salida"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="uso_adicional"
                    label="Usos Adicionales"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    hasFeedback
                    label="Formato para folio"
                    name="formato_para_folio"
                  >
                    <Select dataSource={formatoFolio} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="entrega_fisica"
                    label="Entrega Física"
                    tooltip={{ title: 'El documento, ¿Se entrega físicamente?' }}
                  >
                    <Select trueFalse />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="almacenamiento_de_datos"
                    label="Almacenamiento de Datos"
                    tooltip={{ title: '¿Requiere almacenar el dato?' }}
                  >
                    <Select trueFalse />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="proteccion_de_datos"
                    label="Protección de Datos"
                    tooltip={{ title: '¿Protección de Datos Personales?' }}
                  >
                    <Select trueFalse />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    hasFeedback
                    label="Descripción del Documento"
                    name="descripcion"
                  >
                    <Input.TextArea allowClear autoSize={{ minRows: 3, maxRows: 5 }} />
                  </Form.Item>
                </Col>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content={`Documento de Salida con el Folio  ${formTable.getFieldValue('folio')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
};
TableDocumentoSalida.propTypes = {

  selectedRowKey: PropTypes.number,
  callback: PropTypes.func.isRequired,

};
TableDocumentoSalida.defaultProps = {
  selectedRowKey: null,
};

export default TableDocumentoSalida;
