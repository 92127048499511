import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Typography,
} from 'antd';
import API from 'utils/api';
import getQueryParams from 'utils/getQueryParams';
import Card from './components/Card';
import './index.scss';

const { Text } = Typography;

const CancelarRestablecerContrasena = () => {
  const history = useHistory();
  const codigo = getQueryParams('codigo', window.location.href);
  const [respuesta, setRespuesta] = useState([]);
  if (!codigo) {
    history.push('/inicio-de-sesion');
  }

  const verify = async () => {
    try {
      const response = await API.get(`usuarios/cancelar-restablecer-contrasena?codigo=${codigo}`);
      if (response?.status === 200) {
        setRespuesta('Se canceló con exito la petición para restablecer la contraseña.');
      } else {
        setRespuesta('Lo sentimos. Ha ocurrido un proceso inesperado, vuelva a intentarlo.');
      }
    } catch (err) {
      if (err?.response?.data?.detail) {
        setRespuesta(err.response.data.detail);
      } else {
        setRespuesta('Ha ocurrido un error inesperado inténtelo de nuevo más tarde.');
      }
    }
  };

  useEffect(() => {
    verify();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  return (
    <Card>
      <Text style={{ textAlign: 'center' }}>
        {respuesta}
      </Text>
      <br />
      <Button
        className="auth-submit-button"
        style={{
          width: '150px',
        }}
        onClick={() => history.push('/inicio-de-sesion')}
      >
        Continuar
      </Button>
    </Card>
  );
};

export default CancelarRestablecerContrasena;
