import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Breadcrumb,
  Typography,
} from 'antd';
import { useSelector } from 'react-redux';

const { Text, Title } = Typography;

const Bread = ({
  currentPath,
  isMobile,
  routes,
}) => {
  const entidad = useSelector(({ auth }) => auth.entidad);
  const user = useSelector(({ auth }) => auth.user);
  const split = [...currentPath].pop()?.split?.('/') || [];
  const splicedSplit = [...split].splice(currentPath.length - 1);

  const findItemNested = (arr, path) => (
  // eslint-disable-next-line
  arr.reduce((a, item) => {
      if (a) return a;
      if (item.path.replace('/', '') === path) return item;
      if (item.children) return findItemNested(item.children, path);
    }, null)
  );

  const getTitleOfRoute = (path, current = false) => {
    if (current && path === 'dashboard') {
      return (
        <>
          <Text className="primary-text">
            Dashboard
          </Text>
          <br />
          {entidad.razon_social && (
            <Text style={{ fontSize: 14 }}>
              {`Entidad: ${entidad.razon_social}`}
            </Text>
          )}
        </>
      );
    }
    const rrr = findItemNested(routes, path);
    return rrr?.title || '';
  };

  const getDisplay = () => {
    const sum = splicedSplit
      .reduce((prev, next) => prev + next.length, 0);
    if (isMobile || sum >= 40) {
      return 'none';
    }
    return 'unset';
  };

  return (
    <Row
      className="breadcrumb"
      align="middle"
      justify="space-between"
      style={{
        marginBottom: 10,
        height: 44,
      }}
    >
      <Title
        level={4}
        className="breadbrumb-title primary-text"
        style={{
          margin: 0,
          display: getDisplay(),
        }}
      >
        {getTitleOfRoute(split[split.length - 1], true)}
      </Title>
      {currentPath.includes('/dashboard') ? (
        <Text style={{ fontSize: 18 }} className="primary-text">
          {`Bienvenido ${user?.first_name?.split(' ').shift()} !!`}
        </Text>
      ) : (
        <Breadcrumb style={{ marginLeft: 'auto' }}>
          {splicedSplit.map((path) => (
            <Breadcrumb.Item key={path}>
              {getTitleOfRoute(path)}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      )}
    </Row>
  );
};

Bread.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  currentPath: PropTypes.arrayOf(PropTypes.string).isRequired,
  isMobile: PropTypes.bool,
};

Bread.defaultProps = {
  isMobile: false,
};

export default Bread;
