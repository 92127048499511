import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  message,
  Modal,
} from 'antd';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import { onSuccess, onError } from 'utils/handlers';
import ModalDelete from 'components/ModalDelete';
import { InputSearchPro } from 'components/InputSearch';
import BusquedaAvanzadaURS from 'components/BusquedaAvanzadaURS';

const baseURI = '/tramites/plantillas-de-tramites/';

const TableOficinas = ({
  selectedRowKey,
  callback,
}) => {
  const [formTable] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visibleAdvanced, setVisibleAdvanced] = useState(false);
  const [oficinaFound, setOficinaFound] = useState([]);
  const fetchData = async () => {
    try {
      if (selectedRowKey) {
        setLoading(true);
        const resCatalogo = await API.get(`${baseURI}${selectedRowKey}/`);
        if (resCatalogo?.status === 200) {
          const { centro_de_costos } = resCatalogo.data;
          let oficinasList = [];
          if (centro_de_costos?.length) {
            if (typeof centro_de_costos[0] !== 'number') {
              oficinasList = centro_de_costos.map((d) => d.id);
            } else {
              oficinasList = centro_de_costos;
            }
          }
          const promises = [];
          oficinasList.forEach((id) => {
            const res = API.get(`configuracion/caar/${id}`);
            promises.push(res);
          });
          const responses = promises.length ? await Promise.all(promises) : [];
          const datos = responses.map((res) => res.data);
          setData(datos);
        }
        setLoading(false);
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);

      if (selectedRowKey) {
        await fetchData();
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();

    return () => API.source.cancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    formTable.resetFields();
    setVisibleAlert(false);
    setOficinaFound([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await formTable.validateFields();
      const values = formTable.getFieldsValue();
      const dataMapped = data.map((e) => e.id);
      if (!data.find((d) => d.id === values.centro_de_costos)) {
        if (selectedRowKey) {
          const response = await API.patch(`${baseURI}${selectedRowKey}`, {
            centro_de_costos: [...dataMapped, values.centro_de_costos],
          });
          if (response?.status === 200) {
            onSuccess(response, 'Agregado correctamente');
            onCancel();
            await fetchData();
          }
        } else {
          const clone = [...data];
          clone.push(oficinaFound[0]);
          setData(clone);
          callback(clone.map((e) => e.id));
          onCancel();
        }
      } else {
        onSuccess(null, 'La Oficina de Atención ya se encuentra agregada');
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      if (selectedRowKey) {
        if (key) {
          const dataMapped = data.map((e) => e.id).filter((e) => e !== key);
          const response = await API.patch(`${baseURI}${selectedRowKey}/`, {
            centro_de_costos: dataMapped,
          });
          if (response?.status === 200) {
            onSuccess(response, 'Eliminado correctamente');
            onCancel();
            setVisibleAlert(false);
            await fetchData();
          }
        }
      } else {
        const clone = data.filter((item) => item.key !== key);
        const normalized = clone.map((item, idx) => ({ ...item, key: idx }));
        setData(normalized);
        callback(normalized.map((e) => e.id));
        onCancel();
        setVisibleAlert(false);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickAdd = () => {
    setVisible(true);
    setSelectedRowKeys([]);
    formTable.resetFields();
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const handleOnRowClick = (record) => {
    const _key = selectedRowKey ? record.id : record.key;
    setSelectedRowKeys([_key]);
    formTable.setFieldsValue(record);
  };
  const reset = () => {
    setOficinaFound([]);
    formTable.resetFields(['q']);
    formTable.validateFields(['q']);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const columns = [
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 300,
    },
  ];

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const busquedaOficinas = async () => {
    try {
      setLoading(true);
      await formTable.validateFields(['q']);
      const { q } = formTable.getFieldsValue(['q']);
      const response = await API.get('/configuracion/caar/', {
        params: {
          descripcion: q,
        },
      });
      if (response?.status === 200 && response?.data?.length) {
        const [responseData] = response.data;
        formTable.setFieldsValue({
          centro_de_costos: responseData.id,
        });
        setOficinaFound([responseData]);
      } else if (!response.data.length) {
        message.info('No se encontraron coincidencias');
        setOficinaFound([]);
      } else {
        message
          .info('Se encontraron demasiadas coincidencias, sea mas especifico con su busqueda');
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          mobileColIndex={0}
          rowKey={selectedRowKey ? 'id' : 'key'}
          handleOnRowClick={handleOnRowClick}
          controls={{
            onClickAdd,
            onClickDelete,
          }}
          allowExport={false}
        />
        <Modal
          open={visible}
          closable={false}
          footer={null}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Oficina de Atención`}
              onFinish={onFinish}
              onCancel={onCancel}
              loading={loading}
            />
          )}
          keyboard={!loading}
          maskClosable={!loading}
          onCancel={onCancel}
          forceRender
        >
          <Col span={24}>
            <Form
              layout="vertical"
              form={formTable}
              onFinish={onFinish}
              scrollToFirstError
            >
              <InputSearchPro
                label="Oficina de Atención"
                tooltip="Búsqueda por la Descripción de la Oficina de Atención"
                name="q"
                rules={rules.required}
                onClickAdvanced={() => setVisibleAdvanced(true)}
                onPressEnter={busquedaOficinas}
                breakPoints={{
                  md: 24,
                  lg: 24,
                  xl: 24,
                }}
                resultProps={{
                  name: 'centro_de_costos',
                  label: 'Oficina de Atención',
                  rules: rules.required,
                  dataSource: oficinaFound,
                  labelProp: 'descripcion',
                  onClear: reset,
                  breakPoints: {
                    md: 24,
                  },
                }}
              />
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Form>
          </Col>
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content={`Centro de Costos ${formTable.getFieldValue('descripcion')}`}
        />
      </Spin>
      <BusquedaAvanzadaURS
        visible={visibleAdvanced}
        setVisible={setVisibleAdvanced}
        callback={(selected) => {
          setOficinaFound([selected]);
          formTable.setFieldsValue({
            centro_de_costos: selected.id,
          });
        }}
        dataExterno={[]}
        setDataExterno={() => { }}
      />
    </Row>
  );
};
TableOficinas.propTypes = {
  selectedRowKey: PropTypes.number,
  callback: PropTypes.func.isRequired,

};
TableOficinas.defaultProps = {
  selectedRowKey: null,
};

export default TableOficinas;
