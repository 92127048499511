const { documentElement } = document;

export const docHeight = documentElement.clientHeight;
export const docWidth = documentElement.clientWidth;

// export const isMobile = docWidth <= 576;

export default {
  // isMobile,
  docHeight,
  docWidth,
};
