import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Card,
  Tabs,
} from 'antd';

import {
  ImportOutlined,
  UnorderedListOutlined,
  BankOutlined,
} from '@ant-design/icons';

import Table from 'components/Table';
import API from 'utils/api';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import { onError, onSuccess } from 'utils/handlers';
import Select from 'components/Select';
import fetchSome from 'utils/fetchSome';
import {
  SET_SUBCATEGORIAS,
  SET_CATEGORIAS_SOLICITUDES,
} from 'store/reducers/catalogs';
import PlainTransfer from 'components/PlainTransfer';
import { formatReceived } from 'utils/formatValues';
import NumericInput from 'components/NumericInput';

const { TabPane } = Tabs;

const baseURI = '/solicitudes/motivos-de-las-solicitudes/';

const MotivosDeSolicitudes = () => {
  const subcategorias = useSelector(({ catalogs }) => catalogs.subcategorias);
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const categoriasSolicitudes = useSelector(({ catalogs }) => catalogs.categoriasSolicitudes);
  const [formGeneral] = Form.useForm();
  const [formReglas] = Form.useForm();
  const [formAdministracion] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState('general');
  const [departamentos, setDepartamentos] = useState([]);
  const [dependencias, setDependencias] = useState([]);
  const [grupos, setGrupos] = useState([]);
  // const [usuarios, setUsuarios] = useState([]);
  // const [funcionarios, setFuncionarios] = useState([]);
  const [filteredSubCats, setFilteredSubCats] = useState([]);
  const [programas, setProgramas] = useState([]);
  const [departamentoFiltered, setDepartamentoFiltered] = useState([]);
  const fetchData = async () => {
    const res = await API.get(baseURI);
    setData(formatReceived(res.data) || []);
  };

  const fetchAll = async () => {
    try {
      setLoading(true);

      const resDepartamentos = await API.get('/configuracion/departamentos');
      setDepartamentos(resDepartamentos.data || []);

      const resDependencias = await API.get('/configuracion/dependencias/');
      setDependencias(resDependencias.data || []);

      const resGrupos = await API.get('/catalogos/grupos/');
      setGrupos(resGrupos.data || []);

      const resProgramas = await API.get('/solicitudes/programas-para-solicitud');
      setProgramas(resProgramas.data || []);

      // const resUsuarios = await API.get('/usuarios/usuarios/');
      // setUsuarios(resUsuarios.data.map((e) => ({
      //   ...e,
      //   nombre_completo: `${e.first_name || ''} ${e.last_name || ''}
      // ${e.second_last_name || ''} - ${e.email}`,
      // })) || []);

      // const resFuncionarios = await API.get('/usuarios/funcionario/');
      // setFuncionarios(resFuncionarios.data.map((e) => ({
      //   ...e,
      //   nombre_completo: `${e.nombre || ''} ${e.apellido_paterno || ''}
      //  ${e.apellido_materno || ''}`,
      // })) || []);

      if (!categoriasSolicitudes.length) {
        await fetchSome('/solicitudes/categoria-de-solicitudes/', SET_CATEGORIAS_SOLICITUDES);
      }

      if (!subcategorias.length) {
        await fetchSome('/solicitudes/subcategorias-de-las-solicitudes/', SET_SUBCATEGORIAS);
      }

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setActiveTab('general');
    formGeneral.resetFields();
    formReglas.resetFields();
    formAdministracion.resetFields();
    setSelectedRowKeys([]);
    setDepartamentoFiltered([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await formGeneral.validateFields();
      await formReglas.validateFields();
      await formAdministracion.validateFields();
      const values = {
        ...formGeneral.getFieldsValue(),
        ...formReglas.getFieldsValue(),
        ...formAdministracion.getFieldsValue(),
      };
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err,
        setLoading,
        [
          formGeneral,
          formReglas,
        ], setActiveTab);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          onCancel();
          setVisibleAlert(false);
          await fetchData();
        }
      }
      setLoading(true);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const filterSubCategoria = (categoria) => {
    let output = [];
    if (categoria) {
      output = subcategorias.filter((e) => e.categoria === categoria);
      formGeneral.resetFields(['subcategoria']);
    }
    setFilteredSubCats(output);
  };

  const findCategoria = (subCategoria) => {
    const { categoria } = subcategorias.find((e) => e.id === subCategoria);
    filterSubCategoria(categoria);
    formGeneral.setFieldsValue({
      categoria,
    });
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    findCategoria(record?.subcategoria);
    formGeneral.setFieldsValue(record);
    formReglas.setFieldsValue(record);
    formAdministracion.setFieldsValue(record);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
    },
    {
      titleText: 'Subcategoría',
      dataIndex: 'subcategoria',
      key: 'subcategoria',
      render: (val) => subcategorias.find((e) => e.id === val)?.descripcion,
    },
  ];
  const filteredDepartamentos = (val) => {
    if (val) {
      const filtered = departamentos.filter((e) => e.unidad_operativa?.id === val);
      setDepartamentoFiltered(filtered);
    }
  };
  return (
    <Row align="center" justify="center" className="container">
      <Spin
        tip="Cargando..."
        spinning={loading}
      >
        {!visible
          ? (
            <Table
              cols={columns}
              mobileCols={columns}
              data={data}
              rowSelection={rowSelection}
              handleOnRowClick={handleOnRowClick}
              controls={{
                onClickAdd,
                onClickEdit,
                onClickDelete,
              }}
              key="id"
            />
          )
          : (
            <Card
              className="form-container"
              title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Motivo de Solicitud`}
              extra={(
                <FormSubmitControls onFinish={onFinish} onCancel={onCancel} />
              )}
              bordered={false}
            >
              <Tabs
                type="card"
                onChange={setActiveTab}
                activeKey={activeTab}
              >
                <TabPane
                  tab={(
                    <span>
                      <ImportOutlined />
                      General
                    </span>
                  )}
                  key="general"
                  forceRender
                >
                  <Form
                    layout="vertical"
                    form={formGeneral}
                    name="general"
                    onFinish={onFinish}
                    initialValues={{ estados_globales: 1 }}
                    scrollToFirstError
                  >
                    <Row gutter={10}>
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item
                          name="categoria"
                          rules={rules.required}
                          label="Categoría"
                          hasFeedback
                        >
                          <Select
                            dataSource={categoriasSolicitudes}
                            onChange={filterSubCategoria}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item
                          name="subcategoria"
                          rules={rules.required}
                          label="Subcategoría"
                          hasFeedback
                        >
                          <Select dataSource={filteredSubCats} />
                        </Form.Item>
                      </Col>
                      {/* // tiempo_maximo_de_respuesta */}

                      <Col xs={24} sm={24} md={12}>
                        <Form.Item
                          name="descripcion"
                          rules={rules.required}
                          label="Descripción"
                          hasFeedback
                        >
                          <Input.TextArea autoSize={{ minRows: 4, maxRows: 5 }} allowClear />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item
                          name="instrucciones"
                          label="Instrucciones"
                          hasFeedback
                        >
                          <Input.TextArea autoSize={{ minRows: 4, maxRows: 5 }} allowClear />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="programa_para_solicitud"
                          label="Programa para Solicitud"
                          hasFeedback
                        >
                          <Select
                            dataSource={programas}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <NumericInput
                          name="tiempo_maximo_de_respuesta"
                          label="Tiempo Máximo de Respuesta"
                          hasFeedback
                        />
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <NumericInput
                          name="tiempo_de_respuesta_vencido"
                          label="Tiempo de Respuesta Vencido"
                          hasFeedback
                        />
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="estados_globales"
                          label="Estado"
                          hasFeedback
                        >
                          <Select
                            dataSource={estadosGlobales}
                            disabled={!selectedRowKeys.length}
                          />
                        </Form.Item>
                      </Col>
                      <Form.Item hidden>
                        <Button htmlType="submit" />
                      </Form.Item>
                    </Row>
                  </Form>
                </TabPane>
                <TabPane
                  tab={(
                    <span>
                      <UnorderedListOutlined />
                      Reglas
                    </span>
                  )}
                  key="reglas"
                  forceRender
                >
                  <Form
                    layout="vertical"
                    form={formReglas}
                    name="reglas"
                    onFinish={onFinish}
                    scrollToFirstError
                    initialValues={{
                      requiere_archivos: true,
                      requiere_ubicacion: true,
                      requiere_auntenticacion: true,
                    }}
                  >
                    <Row gutter={10}>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="requiere_archivos"
                          label="¿Requiere Archivos?"
                          hasFeedback
                        >
                          <Select trueFalse />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="requiere_ubicacion"
                          label="¿Requiere Ubicación?"
                          hasFeedback
                        >
                          <Select trueFalse />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="requiere_auntenticacion"
                          label="¿Requiere Autenticación?"
                          hasFeedback
                        >
                          <Select trueFalse />
                        </Form.Item>
                      </Col>

                      <Form.Item hidden>
                        <Button htmlType="submit" />
                      </Form.Item>
                    </Row>
                  </Form>
                </TabPane>
                <TabPane
                  tab={(
                    <span>
                      <BankOutlined />
                      Administración
                    </span>
                  )}
                  key="administracion"
                  forceRender
                >
                  <Form
                    layout="vertical"
                    form={formAdministracion}
                    name="administracion"
                    scrollToFirstError
                  >
                    <Row gutter={10}>
                      {/* <Col xs={24} sm={24} md={12}>
                        <Form.Item
                          label="Usuario que está Atendiendo"
                          name="usuario_que_esta_atendiendo"
                        >
                          <Select
                            dataSource={usuarios}
                            render={(val) => `${val.nombre_completo}`}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item
                          label="Funcionario que está Atendiendo"
                          name="funcionario_que_esta_atendiendo"
                        >
                          <Select
                            dataSource={funcionarios}
                            render={(val) => `${val.nombre_completo}`}
                          />
                        </Form.Item>
                      </Col> */}
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item
                          label="Dependencia"
                          name="dependencia"
                        >
                          <Select
                            dataSource={dependencias}
                            onChange={(e) => filteredDepartamentos(e)}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item
                          label="Departamento"
                          name="departamento"
                        >
                          <Select dataSource={departamentoFiltered} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <PlainTransfer
                          formItemName="grupos"
                          label="Grupos"
                          form={formAdministracion}
                          filterProp="name"
                          dataSource={grupos}
                          tooltip="Grupo o rol que se podra encargar de dar resolucion a la tarea, en 'caso de no tener ninguno asignado, cualquiera podra darle solcuion"
                        />
                      </Col>
                    </Row>
                  </Form>
                </TabPane>
              </Tabs>

            </Card>
          )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`El Motivo por ${formGeneral.getFieldValue('descripcion')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
};

export default MotivosDeSolicitudes;
