/* eslint-disable react/jsx-closing-bracket-location */
import React from 'react';
import {
  Row,
  Layout,
  Menu,
  Button,
  Col,
  Tooltip,
  Grid,
} from 'antd';
import PropTypes from 'prop-types';
import { MenuOutlined, LinkOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import fallback from 'assets/fallback.png';

const ToggleSidebarButton = ({ setCollapsed, collapsed }) => React.createElement(MenuOutlined, {
  style: {
    fontSize: '28px',
    lineHeight: '64px',
    padding: '0 26px',
  },
  onClick: () => setCollapsed(!collapsed),
});

ToggleSidebarButton.propTypes = {
  setCollapsed: PropTypes.func.isRequired,
  collapsed: PropTypes.bool,
};

ToggleSidebarButton.defaultProps = {
  collapsed: false,
};

export const HEADER_HEIGHT = 64;

export const MenuItems = ({
  mode,
  setCollapsed,
  selectedKey,
  collapsed,
}) => (
  <Menu
    mode={mode}
    selectedKeys={[selectedKey]}
    onClick={({ key }) => {
      const section = document.getElementById(key);
      const mainScrollable = document.querySelector('#mainScrollable');
      mainScrollable.scrollTo({ top: section.offsetTop - 64, behavior: 'smooth' });
      if (!collapsed) {
        setCollapsed(true);
      }
    }}
    items={[
      {
        key: 'home',
        label: 'Inicio',
      },
      {
        key: 'about',
        label: 'Nosotros',
      },
      {
        key: 'news',
        label: 'Noticias',
      },
      {
        key: 'contact',
        label: 'Contacto',
      },
    ]}
  />
);

MenuItems.propTypes = {
  setCollapsed: PropTypes.func.isRequired,
  selectedKey: PropTypes.string.isRequired,
  collapsed: PropTypes.bool,
  mode: PropTypes.oneOf(['vertical', 'horizontal']).isRequired,
};

MenuItems.defaultProps = {
  collapsed: false,
};

const Header = ({
  entidad,
  collapsed,
  setCollapsed,
  selectedKey,
  editing,
  setEditing,
}) => {
  const screens = Grid.useBreakpoint();
  return (
    <Layout.Header
      style={{
        padding: screens[editing ? 'lg' : 'md'] ? '0 30px' : 0,
        position: 'fixed',
        zIndex: 3,
        width: `calc(100vw - ${editing ? 350 : 0}px)`,
      }}
    >
      <Row align="middle" style={{ height: 64, overflow: 'hidden' }}>
        <Row>
          {screens[editing ? 'lg' : 'md'] === false && (
            <ToggleSidebarButton
              setCollapsed={setCollapsed}
              collapsed={collapsed}
            />
          )}
          {screens[editing ? 'lg' : 'md'] && (
            <div className="logo-wrapper">
              <Logo
                src={entidad.logo || fallback}
                alt="logo"
              />
            </div>
          )}
        </Row>
        {screens[editing ? 'lg' : 'md'] && (
          <MenuItems
            mode="horizontal"
            setCollapsed={setCollapsed}
            selectedKey={selectedKey}
            collapsed={collapsed}
          />
        )}
        <Col style={{ marginLeft: 'auto' }}>
          {entidad.sitio_externo && (
            <Button
              className={screens[editing ? 'lg' : 'md'] ? 'secondary-background' : 'secondary-color'}
              type={screens[editing ? 'lg' : 'md'] ? 'default' : 'link'}
          >
              <a href={entidad.sitio_externo} target="_blank" rel="noreferrer">
                {screens[editing ? 'lg' : 'md'] ? 'Sitio' : <LinkOutlined /> }
              </a>
            </Button>
          )}
          {!editing && (
            <Button type="link" style={{ margin: 5 }} onClick={() => setEditing(true)}>
              <Tooltip title="Salir de modo vista previa">
                <EyeInvisibleOutlined />
              </Tooltip>
            </Button>
          )}
        </Col>
      </Row>
    </Layout.Header>
  );
};

Header.propTypes = {
  entidad: PropTypes.shape({
    sitio_externo: PropTypes.string,
    logo: PropTypes.string,
    logotipo: PropTypes.string,
  }),
  setCollapsed: PropTypes.func.isRequired,
  collapsed: PropTypes.bool,
  selectedKey: PropTypes.string.isRequired,
  editing: PropTypes.bool,
  setEditing: PropTypes.func.isRequired,
};

Header.defaultProps = {
  entidad: {},
  collapsed: false,
  editing: false,
};

const Logo = styled.img`
  width: 150px;
  padding-right: 15px;
  max-height: 64px;
  object-fit: scale-down;
`;

export default Header;
