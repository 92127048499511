import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Row,
  Col,
} from 'antd';
import PlainTransfer from 'components/PlainTransfer';
import Select from 'components/Select';

const FormAdeudo = ({
  getTiposDeCargos,
  dataSourceCargosConsulta,
  setDataSourceCargosConsulta,
}) => {
  const formInstance = Form.useFormInstance();
  const es_consulta_de_adeudo = Form.useWatch('es_consulta_de_adeudo', formInstance);
  const no_iniciar_con_adeudo = Form.useWatch('no_iniciar_con_adeudo', formInstance);

  return (
    <Row gutter={10}>
      <Col xs={24} sm={24} md={8}>
        <Form.Item
          name="es_consulta_de_adeudo"
          label="¿Es consulta de adeudo?"
          rules={[{ required: true }]}
          tooltip={'Al seleccionar "Sí" deberá ingresar la lista de cargos que deben ligarse (en caso de encontrarse) y pagarse por medio del trámite'}
        >
          <Select
            trueFalse
            onChange={(val) => val && no_iniciar_con_adeudo
              && formInstance.setFieldValue('no_iniciar_con_adeudo', false)}
          />
        </Form.Item>
      </Col>
      {es_consulta_de_adeudo ? (
        <Col xs={24} sm={24} md={8}>
          <Form.Item
            name="permite_generar_sin_adeudo"
            label="¿Permite generar sin adeudo?"
            rules={[{ required: true }]}
            tooltip={'Seleccione "Sí", si es obligatorio que exista el adeudo para iniciar el tráamite'}
          >
            <Select trueFalse />
          </Form.Item>
        </Col>
      ) : (
        <Col xs={24} sm={24} md={8}>
          <Form.Item
            name="no_iniciar_con_adeudo"
            label="¿No iniciar con adeudo?"
            rules={[{ required: true }]}
            tooltip={'Al seleccionar "Sí" deberá ingresar la lista de cargos que en caso de encontrarse se impedirá el inicio del trámite'}
          >
            <Select trueFalse />
          </Form.Item>
        </Col>
      )}
      {(es_consulta_de_adeudo || no_iniciar_con_adeudo) && (
      <Col span={24}>
        <PlainTransfer
          fetchData={getTiposDeCargos}
          dataSource={dataSourceCargosConsulta}
          setDataSource={setDataSourceCargosConsulta}
          label="Cargos"
          formItemName="tipos_de_cargos_consulta"
          form={formInstance}
          filterProp="descripcion"
          paginated
        />
      </Col>
      )}
    </Row>
  );
};

FormAdeudo.propTypes = {
  getTiposDeCargos: PropTypes.func.isRequired,
  setDataSourceCargosConsulta: PropTypes.func.isRequired,
  dataSourceCargosConsulta: PropTypes.shape({}).isRequired,
};

FormAdeudo.defaultProps = {
};
export default FormAdeudo;
