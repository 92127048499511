import API from 'utils/api';
import { onSuccess, onError } from 'utils/handlers';

export const baseURI = 'configuracion/configuraciones-de-segmentos-de-folios/';

/**
 * GET segmentos de folios
 * @returns {Promise<Array<object>>}
 */
export const getConfiguracionesDeSegmentosDeFolios = async (params) => {
  try {
    const response = await API.get(baseURI, { params });
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * POST segmentos de folios
  * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const postConfiguracionesDeSegmentosDeFolios = async (values) => {
  try {
    const response = await API.post(baseURI, values);
    onSuccess(response, 'Agregado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * PATCH segmentos de folios
 * @param {number} id
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const patchConfiguracionesDeSegmentosDeFolios = async (id, values) => {
  try {
    const response = await API.patch(`${baseURI}${id}`, values);
    onSuccess(response, 'Editado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * DELETE segmentos de folios
 * @param {number} id
 * @returns {Promise<object|boolean>}
 */
export const deleteConfiguracionesDeSegmentosDeFolios = async (id) => {
  try {
    const response = await API.delete(`${baseURI}${id}`);
    onSuccess(response, 'Eliminado correctamente');
    return true;
  } catch (err) {
    onError(err);
    return false;
  }
};
