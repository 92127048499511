import API from 'utils/api';
import { formatReceived } from 'utils/formatValues';
import { onError } from 'utils/handlers';

// eslint-disable-next-line import/prefer-default-export
export const getRequisito = async (id) => {
  try {
    const response = await API.get(`/catalogos/catalogos-de-requisitos/${id}`);
    return formatReceived(response.data);
  } catch (err) {
    onError(err);
    return null;
  }
};
