import API from 'utils/api';
import getFormattedValues, { hasFiles } from 'utils/formatValues';
import { onSuccess, onError } from 'utils/handlers';

export const baseURI = 'usuarios/usuarios/';
const files = ['foto', 'firma_electronica'];

/**
 * GET Usuarios
 * @param {object} params - Objeto con los parámetros de búsqueda
 * @returns {Promise<Array<object>>}
 */
export const getUsuarios = async (params) => {
  try {
    const response = await API.get(baseURI, { params });
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Usuario
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getUsuario = async (id) => {
  try {
    const response = await API.get(`${baseURI}${id}`);
    return response.data;
  } catch (err) {
    onError(err);
    return null;
  }
};

/**
 * POST Usuario
  * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const postUsuario = async (values) => {
  try {
    const config = hasFiles(values, files) ? {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    } : {};
    const formattedValues = getFormattedValues(values, {
      files,
      formData: config.header,
      clean: false,
    });
    const response = await API.post(baseURI, formattedValues, config);
    onSuccess(response, 'Agregado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * PATCH Usuario
 * @param {number} id
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const patchUsuario = async (id, values) => {
  try {
    const config = hasFiles(values, files) ? {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    } : {};
    const formattedValues = getFormattedValues(values, {
      files,
      formData: config.header,
      clean: false,
    });
    const response = await API.patch(`${baseURI}${id}`, formattedValues, config);
    onSuccess(response, 'Editado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * PATCH Roles Usuario
 * @param {number} id
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const patchRolesUsuario = async (id, groups) => {
  try {
    const response = await API.patch(`usuarios/agregar-roles/${id}`, { groups });
    onSuccess(response, 'Editado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * PATCH Permisos Usuario
 * @param {number} id
 * @param {Array<number>} user_permissions
 * @returns {Promise<object|boolean>}
 */
export const patchPermisosUsuario = async (id, user_permissions) => {
  try {
    const response = await API.patch(`usuarios/agregar-permisos/${id}`, { user_permissions });
    onSuccess(response, 'Editado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * DELETE Usuario
 * @param {number} id
 * @param {Array<number>} user_permissions
 * @returns {Promise<object|boolean>}
 */
export const deleteUsuario = async (id, user_permissions) => {
  try {
    const response = await API.delete(`${baseURI}${id}`, { user_permissions });
    onSuccess(response, 'Eliminado correctamente');
    return true;
  } catch (err) {
    onError(err);
    return false;
  }
};
