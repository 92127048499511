/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  EditOutlined,
} from '@ant-design/icons';

// import FlujoDeValidacion from 'views/Retys/FlujoDeValidacion';
// import FlujoDeEdicionDeMonto from 'views/Retys/FlujoDeEdicionDeMonto';
// import FlujoDeModificacion from 'views/Retys/FlujoDeModificacion';
import EstadoDeFichas from 'views/Retys/EstadosDeLaFicha';
import ConfiguracionFlujoRetys from 'views/Retys/ConfiguracionFlujoRetys';
// import blank from './blank';

export default {
  title: 'Procedimientos',
  icon: () => <EditOutlined />,
  showInSidebar: true,
  type: 'private',
  children: [
    // {
    //   title: 'Flujo de Validación',
    //   icon: null,
    //   component: FlujoDeValidacion,
    //   type: 'private',
    //   showInSidebar: true,
    // },
    // {
    //   title: 'Flujo de Modificación',
    //   icon: null,
    //   component: FlujoDeModificacion,
    //   type: 'private',
    //   showInSidebar: true,
    // },
    // {
    //   title: 'Flujo de Edición de Monto',
    //   icon: null,
    //   component: FlujoDeEdicionDeMonto,
    //   type: 'private',
    //   showInSidebar: true,
    // },
    {
      title: 'Estados De las Fichas',
      icon: null,
      component: EstadoDeFichas,
      type: 'private',
      showInSidebar: true,
    },
    // {
    //   title: 'Flujo en Linea',
    //   icon: null,
    //   component: blank,
    //   type: 'private',
    //   showInSidebar: true,
    // },
    // {
    //   title: 'Procedimiento Ficha',
    //   icon: null,
    //   component: blank,
    //   type: 'private',
    //   showInSidebar: true,
    // },
    {
      title: 'Configuración Flujo De Retys',
      icon: null,
      component: ConfiguracionFlujoRetys,
      type: 'private',
      showInSidebar: true,
    },

  ],
};
