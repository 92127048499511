import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Spin,
  Row,
  Col,
  Modal,
  message,
} from 'antd';

import {
  SearchOutlined,
  DeleteOutlined,
  ArrowLeftOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import {
  CURP_PATTERN,
  digitoVerificador,
  RFC_PATTERN,
  INE_PATTERN,
} from 'utils/patterns';
import FormSubmitControls from 'components/FormSubmitCustomControls';
import Table from 'components/Table';
import keyGenerator from 'utils/keyGenerator';
import Select from './Select';

const BusquedaAvanzadaURS = ({
  visible,
  setVisible,
  callback,
  dataExterno,
  setDataExterno,
}) => {
  const [form] = Form.useForm();
  const [formAdd] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataForAdd, setDataForAdd] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [visibleAdd, setVisibleAdd] = useState(false);

  const fetchData = async () => {
    try {
      const resData = await API.get('/configuracion/caar');
      setDataForAdd(resData.data);
      const resResponsables = await API.get('/configuracion/departamentos/');
      setDepartamentos(resResponsables.data || []);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.source.cancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setData([]);
    setDataExterno([]);
    setSelectedRowKeys([]);
    form.resetFields();
  };

  const onCancelAdd = () => {
    setVisibleAdd(false);
    formAdd.resetFields();
  };
  const onChange = (id) => {
    if (id) {
      const parent = (departamentos).find((e) => e.id === id);
      keyGenerator(dataForAdd, formAdd, { filter: { parent } });
    } else {
      formAdd.resetFields(['id']);
    }
  };

  const requiredRule = [
    {
      required: true,
      message: 'El campo es requerido',
    },
  ];

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
    RFC: [
      {
        validator: async (r, val) => {
          if (val) {
            if (!RFC_PATTERN.test(val) || val.length !== 13) {
              throw new Error('Ingrese un RFC válido');
            }
          }
        },
      },
    ],
    INE: [
      {
        pattern: INE_PATTERN,
        message: 'Ingrese un INE válido',
      },
    ],
    curp: [
      {
        validator: async (rule, value) => {
          if (value) {
            const validado = value.toUpperCase().match(CURP_PATTERN);
            if (!validado || validado[2] !== digitoVerificador(validado[1])) {
              throw new Error('Ingrese una CURP válida');
            }
          }
        },
      },
    ],
    email: [
      {
        type: 'email',
        message: 'Ingrese un correo electrónico válido',
      },
    ],
    email_alternativo: [
      {
        type: 'email',
        message: 'Ingrese un correo electrónico válido',
      },
    ],
    clave: [
      requiredRule,
      {
        validator: async (rule, val) => {
          const value = val?.toString();
          const { unidad_responsable } = formAdd.getFieldsValue();
          if (value) {
            if (unidad_responsable) {
              let { clave } = departamentos.find((e) => e.id === unidad_responsable) || {};
              clave = clave?.toString();
              if (value.length <= clave?.length) {
                throw new Error('La clave debe tener una longitud mayor a la clave del Departamento');
              } else if (value.substr(0, clave.length) !== clave) {
                throw new Error('La clave debe comenzar con la clave del Departamento');
              }
            }
          }
        },
      },
    ],
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await formAdd.validateFields();
      const values = formAdd.getFieldsValue();
      const response = await API.post('/configuracion/caar/', values);
      if (response?.status === 201) {
        onSuccess(response, 'Agregado correctamente');
        onCancelAdd();
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const FormAddOficina = () => (
    <Form
      layout="vertical"
      name="general"
      form={formAdd}
      onFinish={onFinish}
      scrollToFirstError
    >
      <Row gutter={10}>
        <Col xs={24} sm={24} md={8}>
          <Form.Item
            name="unidad_responsable"
            rules={rules.required}
            label="Departamento"
            hasFeedback
          >
            <Select
              keyLabelRender
              dataSource={departamentos}
              onChange={onChange}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Form.Item
            name="clave"
            label="Clave"
            hasFeedback
            normalize={(val) => val?.toUpperCase()}
          >
            <Input
              className="upper"
              allowClear
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Form.Item
            name="descripcion"
            rules={rules.required}
            label="Descripción"
            hasFeedback
          >
            <Input allowClear />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
  const handleSearch = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      setSelectedRowKeys([]);
      const params = form.getFieldsValue();
      const keys = Object.keys(params);
      const validProps = keys.filter((prop) => params[prop]);
      if (validProps.length) {
        const response = await API.get('configuracion/caar/', {
          params,
        });
        if (response?.status === 200) {
          if (response.data?.length) {
            setData(response.data);
          } else {
            message.warn('No se encontro la Oficina de Atención', 2.5);
            setData([]);
          }
        }
      } else {
        message.warn('Debe ingresar al menos un campo');
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onFinishSearch = async (record) => {
    callback(record);
    onCancel();
  };

  const handleLocalSearch = () => {
    const values = form.getFieldsValue();
    const keys = Object.keys(values);
    const validProps = keys.filter((prop) => values[prop]);
    if (validProps.length) {
      const normalizeText = (text = '') => text?.toLowerCase()
        .normalize('NFD').replace(/[\u0300-\u036f]/g, '');

      const validateIncludes = (obj, prop) => {
        if (validProps.includes(prop)) {
          const normalizedValue = normalizeText(obj[prop]);
          const normalizedInput = normalizeText(values[prop]);
          return normalizedValue.includes(normalizedInput);
        }
        return false;
      };

      const validateEquals = (obj, prop) => obj[prop] === values[prop];
      const filtered = data.filter((item) => validateIncludes(item, 'ciudadano')
        || validateEquals(item, 'uso_de_predio'));
      setData(filtered);
    } else {
      message.warn('Debe ingresar al menos un campo');
    }
  };

  useEffect(() => {
    setData(dataExterno);
    // eslint-disable-next-line
  }, [dataExterno]);

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const add = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const match = data.find((e) => e.id === key);
      onFinishSearch(match);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };
  const contolsAdd = [
    {
      text: 'Regresar',
      icon: <ArrowLeftOutlined />,
      onClick: () => onCancelAdd(),
    },
    {
      text: 'Agregar',
      icon: <PlusOutlined />,
      onClick: () => onFinish(),
    },
  ];

  const controls = [
    {
      text: 'Regresar',
      icon: <ArrowLeftOutlined />,
      onClick: () => onCancel(),
    },
    {
      text: 'Limpiar',
      icon: <DeleteOutlined />,
      onClick: () => {
        setData([]);
        form.resetFields();
      },
    },
  ];

  const columns = [
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 300,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 300,
    },
    {
      titleText: 'Teléfono',
      dataIndex: 'numero_de_telefono',
      key: 'numero_de_telefono',
      width: 300,
    },
    {
      titleText: 'Correo Electrónico',
      dataIndex: 'correo_electronico',
      key: 'correo_electronico',
      width: 300,
    },
  ];

  return (
    <>
      <Modal
        width="90%"
        open={visible}
        closable={false}
        footer={null}
        title={(
          <Row justify="space-between">
            {visibleAdd ? 'Agregar Oficina' : 'Búsqueda Avanzada'}
            <FormSubmitControls
              controls={visibleAdd ? contolsAdd : controls}
            />
          </Row>
        )}
        onCancel={onCancel}
        forceRender
        centered
      >
        <Spin tip="Cargando..." spinning={loading}>
          {visibleAdd ? (
            <FormAddOficina />
          ) : (
            <Form
              form={form}
              layout="vertical"
              onFinish={dataExterno?.length ? handleLocalSearch : handleSearch}
            >
              <Row gutter={10}>
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                >
                  <Form.Item
                    name="clave"
                    label="Clave"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                >
                  <Form.Item
                    name="descripcion"
                    label="Descripción"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                >
                  <Form.Item
                    name="correo_electronico"
                    label="Correo Electrónico"
                    hasFeedback
                    rules={rules.email}
                  >

                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                >
                  <Form.Item
                    name="numero_de_telefono"
                    label="Número de Teléfono"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[10, 5]} justify="end">
                <Col xs={24} sm={24} md={6} lg={4}>
                  <Button
                    block
                    onClick={() => setVisibleAdd(true)}
                  >
                    <PlusOutlined />
                    Agregar Oficina
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={6} lg={4}>
                  <Button block htmlType="submit">
                    <SearchOutlined />
                    Buscar
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={6} lg={4}>
                  <Button
                    onClick={add}
                    block
                    disabled={!selectedRowKeys.length}
                  >
                    <PlusOutlined />
                    Agregar
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
          <Table
            cols={columns}
            data={data}
            noControls
            allowSort={false}
            allowSearch={false}
            rowSelection={rowSelection}
            onRow={(record) => ({
              onClick: () => {
                setSelectedRowKeys([record.id]);
              },
              onDoubleClick: add,
            })}
            rowKey="id"
          />
        </Spin>
      </Modal>
    </>
  );
};

BusquedaAvanzadaURS.propTypes = {
  callback: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  dataExterno: PropTypes.arrayOf(PropTypes.shape()),
  setDataExterno: PropTypes.func.isRequired,
};

BusquedaAvanzadaURS.defaultProps = {
  dataExterno: [],
};

export default BusquedaAvanzadaURS;
