import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Input,
  Card,
} from 'antd';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import { onSuccess, onError } from 'utils/handlers';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import PlainTransfer, { createFetcher } from 'components/PlainTransfer';
import { getUsuarios } from 'api/usuarios/usuarios';
import RequisitosTransfer from 'components/Catalogos/RequisitosTransfer';
import { DoubleRightOutlined, RightOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const baseURI = '/tramites/pasos-de-tramites/';

export const tiposDePasosEnum = Object.freeze({
  VALIDACION: 1,
  EMISION_DE_DOCUMENTO: 2,
  FIRMA_ELECTRONICA: 3,
  GIS: 4,
  VARIABLES: 5,
  MODIFICACION_SOLICITANTE: 6,
  MODIFICACION_AFECTADO: 7,
  VALIDACION_REQUISITOS: 8,
  CAMBIO_DE_PROPIETARIO: 9,
  REQUISITOS: 10,
  CARGOS: 11,
  ALTA_AFECTADO: 12,
  DIRECCION_PRINCIPAL: 13,
  DIRECCION_SECUNDARIA: 14,
  OBLIGACIONES: 15,
  EMPRESAS: 16,
  PARTICIPES: 17,
  FOTOGRAFIA: 18,
  CONSTRUCCIONES: 19,
  ACTIVIDADES_ECONOMICAS: 20,
  ACTIVIDADES_ECONOMICAS_SAT: 21,
  CONTRIBUYENTES_VINCULADOS: 22,
  CARGA_DE_DOCUMENTO: 23,
  VISTA_PADRON: 24,
  CITA: 25,
  ASIGNACION_CONSTANCIA: 58,
  SINCRONIZACION_PLACA: 59,
});

const {
  EMISION_DE_DOCUMENTO,
  VARIABLES,
  FIRMA_ELECTRONICA,
  REQUISITOS,
  CARGOS,
  GIS,
  MODIFICACION_SOLICITANTE,
  MODIFICACION_AFECTADO,
  CAMBIO_DE_PROPIETARIO,
  ALTA_AFECTADO,
  OBLIGACIONES,
  EMPRESAS,
  PARTICIPES,
  CONSTRUCCIONES,
  ACTIVIDADES_ECONOMICAS,
  ACTIVIDADES_ECONOMICAS_SAT,
  CONTRIBUYENTES_VINCULADOS,
  CITA,
  ASIGNACION_CONSTANCIA,
  SINCRONIZACION_PLACA,
} = tiposDePasosEnum;

export const afectacionesEnum = Object.freeze({
  ALTA: 1,
  MODIFICACION: 2,
  BAJA: 3,
  CONSULTA: 4,
});

const initialValues = {
  usuarios: [],
  groups: [],
  requisitos: [],
  variables: [],
  tipos_de_cargos: [],
  nombre: null,
  descripcion: null,
  instrucciones: null,
  id_de_reporte: null,
  configuracion_de_padron: null,
  paso_visible_solicitante: false,
  atiende_solicitante: false,
  detalle_visible_para_ciudadano: false,
  instrucciones_portal_ciudadano: null,
  firma_automatica: false,
};

const PasosParaTramites = ({
  plantilla_de_tramite,
  tipoDePadron,
  tipoDeAfectacion,
  departamentos,
  configuracionesDePadrones,
  variables,
  getTiposDeCargos,
  setDataSourceRequisitos,
  dataSourceRequisitos,
  foliadores,
}) => {
  const [form] = Form.useForm();
  const tipo_de_paso = Form.useWatch('tipo_de_paso', form);
  const firma_automatica = Form.useWatch('firma_automatica', form);
  const paso_visible_solicitante = Form.useWatch('paso_visible_solicitante', form);
  const atiende_solicitante = Form.useWatch('atiende_solicitante', form);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [_tiposDePasos, setTiposDePasos] = useState([]);
  const [data, setData] = useState([]);
  const [roles, setRoles] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [dataSourceCargos, setDataSourceCargos] = useState({ results: [] });

  const tiposDePasos = _tiposDePasos.filter((tp) => {
    if ([ASIGNACION_CONSTANCIA, SINCRONIZACION_PLACA]
      .includes(tp.id)) return tipoDePadron === 4;
    if ([GIS, PARTICIPES, CONSTRUCCIONES]
      .includes(tp.id)) return tipoDePadron === 3;
    if ([EMPRESAS, OBLIGACIONES, ACTIVIDADES_ECONOMICAS, ACTIVIDADES_ECONOMICAS_SAT,
      CONTRIBUYENTES_VINCULADOS].includes(tp.id)) return tipoDePadron === 15;
    if (tp.id === MODIFICACION_AFECTADO) {
      return !!tipoDeAfectacion;
    }
    if (tp.id === CAMBIO_DE_PROPIETARIO) {
      return tipoDeAfectacion === afectacionesEnum.MODIFICACION;
    }
    if (tp.id === ALTA_AFECTADO) {
      return tipoDeAfectacion === afectacionesEnum.ALTA;
    }
    return true;
  });

  const fetchData = async () => {
    if (!plantilla_de_tramite) return;
    try {
      setLoading(true);
      const response = await API.get(baseURI, { params: { plantilla_de_tramite } });
      setData(response.data.sort((a, b) => a.orden - b.orden));
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => fetchData, [plantilla_de_tramite]);

  useEffect(() => {
    let mounted = true;
    const fetchAll = async () => {
      try {
        setLoading(true);
        const resTiposPasos = await API.get('/tramites/tipos-de-pasos-de-tramites/');
        const resRoles = await API.get('/catalogos/grupos/');
        // pedir filtro a back de usuarios por departamentos, usuarios que tengan dicho departamento
        // que tengan unidad operativa que sea la padre de alguno de los departamentos y asi
        const _usuarios = await getUsuarios({ departamentos });
        if (mounted) {
          setTiposDePasos(resTiposPasos.data || []);
          setRoles(resRoles.data || []);
          setUsuarios(_usuarios);
          await fetchData();
          setLoading(false);
        }
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();

    return () => { mounted = false; };
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
    setVisibleAlert(false);
    setDataSourceCargos({ results: [] });
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = {
        ...initialValues,
        ...form.getFieldsValue(),
      };
      const [key] = selectedRowKeys;
      if (!key) {
        values.plantilla_de_tramite = plantilla_de_tramite;
      }
      const response = await (selectedRowKeys.length
        ? API.patch(`${baseURI}${key}/`, values)
        : API.post(baseURI, values));
      onSuccess(response, 'Actualizado correctamente');
      onCancel();
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchTiposDeCargos = createFetcher(getTiposDeCargos);

  const onClickAdd = async () => {
    await fetchTiposDeCargos(
      { page: 1 },
      setLoading,
      [],
      { results: [] },
      setDataSourceCargos,
    );
    setVisible(true);
    setSelectedRowKeys([]);
    form.resetFields();
    form.setFieldValue('orden', data.length + 1);
  };

  const onClickEdit = async () => {
    const match = data.find((e) => e.id === selectedRowKeys[0]);
    const tiposDeCargos = match.tipos_de_cargos.map((e) => e.id);
    const promises = tiposDeCargos.map((id) => getTiposDeCargos({ id }));
    const results = (await Promise.all(promises)).map((r) => r.results).flat();
    await fetchTiposDeCargos(
      { page: 1 },
      setLoading,
      tiposDeCargos,
      { results },
      setDataSourceCargos,
    );
    setVisible(true);
  };

  const onClickDelete = () => setVisibleAlert(true);

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue({
      ...record,
      tipos_de_cargos: record.tipos_de_cargos.map((tc) => tc.id),
      requisitos: record.requisitos.map((tc) => tc.id),
      configuracion_de_padron: record.configuracion_de_padron?.id,
    });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const columns = [
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 300,
    },
    {
      titleText: 'Tipo de paso',
      dataIndex: 'tipo_de_paso',
      key: 'tipo_de_paso',
      width: 300,
      render: (val) => tiposDePasos.find((tp) => tp.id === val)?.descripcion,
    },
  ];

  const required = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [required],
    usuarios: [
      {
        validator: async (_, value) => {
          if (firma_automatica && value.length !== 1) {
            throw new Error('Seleccione solo 1 usuario');
          }
        },
      },
    ],
  };

  const titlesIDReporteByTipoDePaso = {
    [EMISION_DE_DOCUMENTO]: 'para consumir/generar documento',
    [VARIABLES]: 'de acuse de variables',
    [CITA]: 'de acuse de cita',
  };

  const moveTo = async (where) => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const match = data.find((item) => item.id === key);
      const positionsMap = {
        first: 1,
        up: match.orden - 1,
        down: match.orden + 1,
        last: data.length,
      };
      const orden = positionsMap[where];
      await API.patch(`${baseURI}${key}/`, { orden });
      fetchData();
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const SortControls = () => (
    <SortContainer>
      <Button
        type="link"
        icon={<DoubleRightOutlined rotate={-90} />}
        disabled={!selectedRowKeys.length
          || data.find((e) => e.id === selectedRowKeys[0]).orden <= 2}
        onClick={() => moveTo('first')}
      />
      <Button
        type="link"
        icon={<RightOutlined rotate={-90} />}
        disabled={!selectedRowKeys.length
          || data.find((e) => e.id === selectedRowKeys[0]).orden < 2}
        onClick={() => moveTo('up')}
      />
      <Button
        type="link"
        icon={<RightOutlined rotate={90} />}
        disabled={!selectedRowKeys.length
          || data.find((e) => e.id === selectedRowKeys[0]).orden === data.length}
        onClick={() => moveTo('down')}
      />
      <Button
        type="link"
        icon={<DoubleRightOutlined rotate={90} />}
        disabled={!selectedRowKeys.length
          || data.find((e) => e.id === selectedRowKeys[0]).orden >= data.length - 1}
        onClick={() => moveTo('last')}
      />
    </SortContainer>
  );

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Row>
            <Col style={{ width: 'calc(100% - 50px)' }}>
              <Table
                cols={columns}
                data={data}
                rowSelection={rowSelection}
                mobileColIndex={0}
                rowKey="id"
                handleOnRowClick={handleOnRowClick}
                controls={{
                  onClickAdd,
                  onClickEdit,
                  onClickDelete,
                }}
                allowExport={false}
              />
            </Col>
            <SortControls />
          </Row>
        ) : (
          <Card
            bordered={false}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
              />
            )}
            bodyStyle={{ height: 'auto' }}
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Proceso`}
          >
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              initialValues={initialValues}
              preserve={false}
              scrollToFirstError
            >
              <Row gutter={10}>
                <Form.Item name="orden" hidden>
                  <Input disabled />
                </Form.Item>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="tipo_de_paso"
                    label="Tipo de paso"
                    rules={rules.required}
                  >
                    <Select
                      dataSource={tiposDePasos}
                      labelProp="descripcion"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={16}>
                  <Form.Item
                    name="nombre"
                    label="Nombre"
                    rules={rules.required}
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    name="descripcion"
                    label="descripcion"
                    rules={rules.required}
                  >
                    <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    name="instrucciones"
                    label="Instrucciones"
                    rules={rules.required}
                  >
                    <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} allowClear />
                  </Form.Item>
                </Col>
                {tipo_de_paso === FIRMA_ELECTRONICA && (
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="firma_automatica"
                      label="¿Firma automática?"
                    >
                      <Select trueFalse />
                    </Form.Item>
                  </Col>
                )}
                {tipo_de_paso === REQUISITOS && (
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="mostrar_requisitos_en_ficha"
                      label="¿Mostrar requisitos en ficha?"
                    >
                      <Select trueFalse />
                    </Form.Item>
                  </Col>
                )}
                {Object.keys(titlesIDReporteByTipoDePaso).includes(tipo_de_paso?.toString()) && (
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="id_de_reporte"
                      label="ID de reporte"
                      tooltip={`ID de reporte ${titlesIDReporteByTipoDePaso[tipo_de_paso]}`}
                      rules={tipo_de_paso === EMISION_DE_DOCUMENTO ? rules.required : []}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                )}
                {tipo_de_paso === CITA && (
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="configuracion_de_folio_cita"
                      label="Formato para folio cita"
                    >
                      <Select dataSource={foliadores} />
                    </Form.Item>
                  </Col>
                )}
                {tipo_de_paso === REQUISITOS && (
                  <Col span={24}>
                    <RequisitosTransfer
                      formItemName="requisitos"
                      formTable={form}
                      rules={rules.required}
                      setDataSourceRequisitos={setDataSourceRequisitos}
                      dataSourceRequisitos={dataSourceRequisitos}
                    />
                  </Col>
                )}
                {tipo_de_paso === VARIABLES && (
                  <Col span={24}>
                    <PlainTransfer
                      dataSource={variables}
                      label="Variables"
                      formItemName="variables"
                      form={form}
                      filterProp="descripcion_de_variable"
                      rules={rules.required}
                    />
                  </Col>
                )}
                {[CAMBIO_DE_PROPIETARIO, MODIFICACION_AFECTADO].includes(tipo_de_paso) && (
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="configuracion_de_padron"
                      label="Configuración de padrón"
                      rules={rules.required}
                    >
                      <Select
                        dataSource={configuracionesDePadrones
                          .filter((c) => c.padron === tipoDePadron && c.tipo_de_afectacion === 2)}
                      />
                    </Form.Item>
                  </Col>
                )}
                {tipo_de_paso === MODIFICACION_SOLICITANTE && (
                  <Form.Item
                    name="configuracion_de_padron"
                    label="Configuración de padrón"
                    rules={rules.required}
                  >
                    <Select
                      dataSource={configuracionesDePadrones
                        .filter((c) => [1, 15].includes(c.padron) && c.tipo_de_afectacion === 2)}
                    />
                  </Form.Item>
                )}
                {tipo_de_paso === CARGOS && (
                  <Col span={24}>
                    <PlainTransfer
                      fetchData={fetchTiposDeCargos}
                      dataSource={dataSourceCargos}
                      setDataSource={setDataSourceCargos}
                      label="Cargos"
                      formItemName="tipos_de_cargos"
                      form={form}
                      filterProp="descripcion"
                      filterPropLabel="descripción"
                    />
                  </Col>
                )}
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="paso_visible_solicitante"
                    label="¿Visible para solicitante?"
                    tooltip="El paso es visible en los canales externos (Portal ciudadano, App móvil)"
                  >
                    <Select trueFalse />
                  </Form.Item>
                </Col>
                {paso_visible_solicitante && (
                  <>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="atiende_solicitante"
                        label="¿Atiende solicitante?"
                        tooltip="El paso puede ser atendido en los canales externos (Portal ciudadano, App móvil)"
                      >
                        <Select
                          trueFalse
                          onChange={(val) => val && form.setFieldValue('detalle_visible_para_ciudadano', true)}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="detalle_visible_para_ciudadano"
                        label="¿Detalle visible solicitante?"
                        tooltip="Detalle completo visible en los canales externos (Portal ciudadano, App móvil)"
                      >
                        <Select trueFalse disabled={atiende_solicitante} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item
                        name="instrucciones_portal_ciudadano"
                        label="Instrucciones canales externos (Portal ciudadano, App móvil)"
                        rules={rules.required}
                      >
                        <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} allowClear />
                      </Form.Item>
                    </Col>
                  </>
                )}
                <Col span={24}>
                  <PlainTransfer
                    dataSource={usuarios}
                    label="Usuarios que atienden"
                    formItemName="usuarios"
                    filterProp="email"
                    filterPropLabel="correo electrónico"
                    form={form}
                    rules={rules.usuarios}
                  />
                </Col>
                <Col span={24}>
                  <PlainTransfer
                    label="Roles que atienden"
                    formItemName="groups"
                    filterProp="name"
                    filterPropLabel="descripción"
                    form={form}
                    dataSource={roles}
                  />
                </Col>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content={`Proceso ${form.getFieldValue('nombre')}`}
        />
      </Spin>
    </Row>
  );
};

PasosParaTramites.propTypes = {
  plantilla_de_tramite: PropTypes.number,
  tipoDePadron: PropTypes.number,
  tipoDeAfectacion: PropTypes.number,
  departamentos: PropTypes.arrayOf(PropTypes.number),
  configuracionesDePadrones: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  variables: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getTiposDeCargos: PropTypes.func.isRequired,
  dataSourceRequisitos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  foliadores: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setDataSourceRequisitos: PropTypes.func.isRequired,
};

PasosParaTramites.defaultProps = {
  plantilla_de_tramite: null,
  tipoDePadron: null,
  tipoDeAfectacion: null,
  departamentos: [],
};

const SortContainer = styled(Col)`
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ant-btn-icon-only>* {
    font-size: 25px !important;
  }

  .ant-btn-icon-only:disabled * {
    color: #CCCCCC !important;
  }

  .ant-btn {
    &:not(:nth-child(2)) {
      margin-top: 10px;
    }
  }
`;

export default PasosParaTramites;
