import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Modal,
  Row,
  Button,
  Col,
  message,
  Grid,
  Form,
  Input,
  Spin,
} from 'antd';
import {
  ArrowLeftOutlined,
  SendOutlined,
} from '@ant-design/icons';
import API from 'utils/api';
import { onError } from 'utils/handlers';
// import { getCiudadano } from 'api/cuentaunicasir';

const { Text } = Typography;

const Previewer = ({
  setVisible,
  visible,
  iFrameUrl,
  isImage,
  base64,
  allowSend,
  baseURL,
  id,
  encodeIFrameUrl,
  // cuentaUnicaID,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [sendToEmail, setSendToEmail] = React.useState(false);
  const [visibleConfirm, setVisibleConfirm] = React.useState(false);
  const [visibleInput, setVisibleInput] = React.useState(false);
  // const [cuentaUnica, setCuentaUnica] = React.useState();
  const screens = Grid.useBreakpoint();

  const sendTo = async () => {
    try {
      setLoading(true);
      if (sendToEmail) {
        await form.validateFields();
        let values;
        if (visibleInput) {
          values = form.getFieldsValue();
        }
        const res = await API.post(`${baseURL}${id}/enviar-correo/`, values);
        if (res?.status === 200) {
          message.info('El documento ha sido enviado revise su bandeja de recepción');
          setVisibleConfirm(false);
          setVisibleInput(false);
        }
      } else {
        const res = await API.post(`${baseURL}${id}/enviar-micuentamx/`);
        if (res?.status === 200) {
          message.info('El documento ha sido enviado revise su bandeja de recepción');
          setVisibleConfirm(false);
          setVisibleInput(false);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  // React.useEffect(() => {
  //   const fetchCuenta = async () => {
  //     if (cuentaUnicaID) {
  //       const _ciudadano = await getCiudadano(cuentaUnicaID);
  //       setCuentaUnica(_ciudadano);
  //     }
  //   };

  //   fetchCuenta();
  //   // eslint-disable-next-line
  // }, [cuentaUnicaID]);

  const onClickSendToEmail = () => {
    setVisibleConfirm(true);
    setSendToEmail(true);
  };

  // const onClickSendToMX = () => {
  //   setVisibleConfirm(true);
  //   setSendToEmail(false);
  // };

  const onCancel = () => {
    if (visibleInput) {
      setVisibleInput(false);
    } else if (visibleConfirm) {
      setVisibleConfirm(false);
    } else {
      setVisible(false);
    }
  };

  const SubmitControls = () => (
    <Row className="form-submit-controls">
      <Button
        type="link"
        onClick={onCancel}
      >
        <ArrowLeftOutlined />
        {screens.md && 'Regresar'}
      </Button>
      {(allowSend && !visibleConfirm) && (
        <>
          <Button
            type="link"
            onClick={onClickSendToEmail}
          >
            <SendOutlined />
            {screens.md && 'Enviar a Correo'}
          </Button>
          {/* <Button
            type="link"
            onClick={onClickSendToMX}
          >
            <SendOutlined />
            {screens.md && 'Enviar a MiCuentaMX'}
          </Button> */}
        </>
      )}
      {visibleConfirm && (
        <Button
          type="link"
          onClick={sendTo}
        >
          <SendOutlined />
          {screens.md && (
            sendToEmail ? 'Enviar a Correo' : 'Enviar a MiCuentaMX'
          )}
        </Button>
      )}
    </Row>
  );

  const rules = {
    email: [
      { required: true },
      { type: 'email' },
    ],
  };

  const googleViewerURI = encodeIFrameUrl
    ? `https://docs.google.com/viewer?url=${encodeURIComponent(iFrameUrl)}&embedded=true`
    : iFrameUrl;

  return (
    <>
      <Modal
        title={(
          <Row justify="space-between">
            <Text>
              Preview
            </Text>
            <SubmitControls />
          </Row>
        )}
        className={`modal-preview ${visibleConfirm ? 'padding' : ''}`}
        open={visible}
        footer={null}
        closable={false}
        onCancel={onCancel}
        width={visibleConfirm && screens?.md ? '50%' : '90%'}
        maskClosable={false}
        centered
      >
        {!visibleConfirm ? (
          <>
            {isImage ? (
              <img src={iFrameUrl} alt="img_preview" />
            ) : (
              <iframe
                src={base64 || googleViewerURI}
                title="preview"
              />
            )}
          </>
        ) : (
          <Row align="center" justify="middle">
            {visibleInput ? (
              <>
                <Spin
                  tip="Cargando..."
                  spinning={loading}
                  style={{ width: '100%', height: '100%' }}
                >
                  <Form
                    layout="vertical"
                    form={form}
                    onFinish={sendTo}
                    scrollToFirstError
                  >
                    <Row gutter={10}>
                      <Col span={24}>
                        <Form.Item
                          name="email"
                          rules={rules.email}
                          label="Correo electrónico"
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Col>
                      <Form.Item hidden>
                        <Button htmlType="submit" />
                      </Form.Item>
                    </Row>
                  </Form>
                </Spin>
              </>
            ) : (
              <>
                <Text style={{ fontSize: 16, textAlign: 'center' }}>
                  ¿Desea enviar el archivo a
                  {' '}
                  {/* {`${cuentaUnica?.clave_ciudadana} - ${cuentaUnica?.email}`} */}
                  ?
                  {sendToEmail && (
                    <>
                      <br />
                      o
                      <br />
                      <Button type="link" onClick={() => setVisibleInput(true)}>
                        Enviar a otro correo
                      </Button>
                    </>
                  )}
                </Text>
                <br />
                <br />
                <Text style={{ textAlign: 'center' }}>
                  Si la información no es correcta actualicela en el módulo de ciudadanos.
                </Text>
              </>
            )}
          </Row>
        )}
      </Modal>
    </>
  );
};

Previewer.propTypes = {
  setVisible: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  isImage: PropTypes.bool,
  encodeIFrameUrl: PropTypes.bool,
  iFrameUrl: PropTypes.string,
  base64: PropTypes.string,
  allowSend: PropTypes.bool,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  baseURL: PropTypes.string,
  // cuentaUnicaID: PropTypes.number,
};
Previewer.defaultProps = {
  iFrameUrl: null,
  isImage: false,
  base64: null,
  allowSend: false,
  id: null,
  baseURL: null,
  encodeIFrameUrl: false,
  // cuentaUnicaID: null,
};
export default Previewer;
