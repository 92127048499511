/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  CalendarOutlined,
} from '@ant-design/icons';

import Tramite from 'views/Retys/Tramite/index';
import NuevaSolicitud from 'views/Solicitudes/Nueva';
import NuevaQueja from 'views/Solicitudes/Quejas';
// import blank from './blank';

export default {
  title: 'Captura',
  icon: () => <CalendarOutlined />,
  showInSidebar: true,
  type: 'private',
  children: [
    {
      title: 'Ficha del Trámite',
      icon: null,
      component: Tramite,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Solicitudes',
      icon: null,
      component: NuevaSolicitud,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Quejas',
      icon: null,
      component: NuevaQueja,
      type: 'private',
      showInSidebar: true,
    },
  ],
};
