/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Card,
  message,
  Steps,
  Modal,
  Grid,
  DatePicker,
  Typography,
} from 'antd';
import {
  InfoCircleOutlined,
  PictureOutlined,
  SnippetsOutlined,
  UserOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import API from 'utils/api';
import Select from 'components/Select';
import Dragger from 'components/Dragger';
import { onError } from 'utils/handlers';
// import { findItemNested } from 'utils/filters';
import getFormatedValues from 'utils/formatValues';
import fetchSome from 'utils/fetchSome';
import { SET_GENEROS } from 'store/reducers/catalogs';
import { getCatalogoDePaises } from 'api/catalogos';
import moment from 'moment';
import Table from 'components/Table';
// import FormItem from 'antd/es/form/FormItem';
import FormSubmitControls from 'components/FormSubmitControls';
import { InputSearchPro } from 'components/InputSearch';
import { getCiudadanoCaja } from 'api/cuentaunicasir';
// import ModalDelete from 'components/ModalDelete';

const baseURI = '/solicitudes/quejas/';
const { Step } = Steps;
const { Text } = Typography;

const onClickTitle = (e) => {
  const element = e.target;
  if (element) {
    let parent = element.parentElement;
    parent = parent ? parent.parentElement : null;
    if (parent) {
      const clickableSpan = parent.previousSibling;
      if (clickableSpan) {
        clickableSpan.click();
      }
    }
  }
};

const renderTitle = (title, bold) => (
  // eslint-disable-next-line
  <span onClick={onClickTitle} className={bold ? "bold" : ""}>
    {title}
  </span>
);

export const normalizeMotivos = (_data = []) => {
  const output = _data.map((item) => {
    const clone = { ...item };
    const children = normalizeMotivos(clone.children);
    const label = clone.descripcion;
    const prefix = '';
    /*
    if (clone.categoria_id) {
      prefix = 'subcategoria_id__';
    } else if (!clone.subcategoria_id) {
      prefix = 'categoria_id__';
    }
    */
    const value = prefix ? `${prefix}${clone.id}` : clone.id;
    const title = renderTitle(label, !prefix);
    if (!children.length) {
      delete clone.children;
      return {
        ...clone,
        title,
        value,
        selectable: !prefix,
      };
    }
    return {
      ...clone,
      title,
      value,
      children,
      selectable: false,
    };
  });
  return output;
};

const NuevaQueja = () => {
  const screens = Grid.useBreakpoint();
  const entidad = useSelector(({ auth }) => auth.entidad);
  const user = useSelector(({ auth }) => auth.user);
  const history = useHistory();
  const [form] = Form.useForm();
  const [formTestigos] = Form.useForm();
  const [loading, setLoading] = useState(false);
  // const [filesRequired, setFilesRequired] = useState(false);
  // const [locationRequired, setLocationRequired] = useState(false);
  // const setMotivos = useState([]);
  const [tipoDeSolicitante, setTipoDeSolicitante] = useState([]);
  const [tipoDeAtencion, setTipoDeAtencion] = useState([]);
  // const quejas = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [solicitudPendiente, setSolicitudPendiente] = useState();
  const [tipoDePersona, setTipoDePersona] = useState(0);
  const tienePruebas = Form.useWatch('tiene_pruebas', form);
  const generos = useSelector(({ catalogs }) => catalogs.generos);
  const [paisesTemp, setPaisesTemp] = useState({ results: [] });
  const paises = useRef({ results: [] });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [testigos, setTestigos] = useState([]);
  const [ciudadanoFound, setCiudadanoFound] = useState([]);

  const fetchAll = async () => {
    try {
      setLoading(true);
      // const res = await API.get('/solicitudes/motivos-de-las-solicitudes/', {
      //   params: { entidad: entidad.id },
      // });
      const resTipos = await API.get(
        '/solicitudes/tipos-de-usuario-de-solicitud/',
      );
      const resTipoDeSolicitud = await API.get(
        '/solicitudes/tipos-de-atencion-de-solicitud/',
      );
      setTipoDeAtencion(resTipoDeSolicitud.data || []);
      setTipoDeSolicitante(resTipos.data);
      if (!generos.length) {
        fetchSome('usuarios/generos/', SET_GENEROS);
      }
      const resPaises = await getCatalogoDePaises();
      setPaisesTemp(resPaises);
      // setMotivos(res.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    if (!user?.id) {
      Modal.warning({
        title: 'Alerta de seguimiento',
        content: (
          <>
            Los usuarios invitados no podrán realizar el seguimiento a sus
            solicitudes, le recomendamos
            {' '}
            <Button type="link">Iniciar sesión</Button>
            {' '}
            con su cuenta o
            {' '}
            <Button type="link">Registrarse</Button>
          </>
        ),
        width: 600,
        okText: 'Continuar como invitado',
      });
    }
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setCurrentStep(0);
    form.resetFields();
  };

  const searchCiudadano = async () => {
    try {
      setLoading(true);
      await form.validateFields(['q']);
      const { q } = form.getFieldsValue(['q']);
      if (q) {
        const _ciudadanos = await getCiudadanoCaja({ q });
        if (_ciudadanos.results.length) {
          const [selected] = _ciudadanos.results;
          form.setFieldsValue({
            ciudadano: selected.id,
          });
          setCiudadanoFound([selected]);
        } else if (!_ciudadanos.results.length) {
          message.info('No se encontró ciudadano');
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const postFiles = async (id) => {
    try {
      const archivos = form.getFieldValue('archivos');
      const files = archivos?.fileList.map((file) => {
        const item = {};
        item.extension_del_documento = file.name.split('.').pop().toLowerCase();
        item.seguimiento = id;
        item.archivo = file.originFileObj;
        return item;
      });
      if (files?.length) {
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
        const requests = files.map((file) => {
          const formData = new FormData();
          const valuesKeys = Object.keys(file);
          valuesKeys.forEach((key) => {
            formData.append(`${key}`, file[key]);
          });
          return API.post(
            'solicitudes/archivos-de-quejas/',
            formData,
            config,
          );
        });
        await Promise.all(requests);
      }
      return true;
    } catch {
      message.warn(
        'Ha ocurrido un error al enviar la evidencia, por favor intentelo de nuevo.',
      );
      return false;
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      let postedFiles = true;
      let response;

      if (!solicitudPendiente) {
        await form.validateFields();
        const values = form.getFieldsValue();
        delete values.archivos;
        values.estado_de_la_solicitud = 1;
        values.entidad = entidad.id;
        values.categoria_de_solicitud = 1;
        const formattedValues = getFormatedValues(values);
        formattedValues.hora_de_los_hechos = moment(values.hora_de_los_hechos).format('h:mm a');

        response = await API.post(baseURI, formattedValues);
        if (response?.status === 201) {
          setSolicitudPendiente(response.data.seguimientos[0].id);
          postedFiles = await postFiles(response.data.seguimientos[0].id);
        }
      } else {
        postedFiles = await postFiles(solicitudPendiente);
      }

      if (postedFiles) {
        // onSuccess(null, 'Tu Solicitud fue registrada correctamente');
        setTimeout(() => {
          Modal.success({
            title: 'Tu Solicitud fue registrada con éxito',
            content: (
              <Row>
                {`Numero de folio para seguimiento: ${response.data.folio}`}
              </Row>

            ),
          });

          onCancel();
          history.push(
            `/mi-buzon/mis-incidentes/seguimiento?id=${response.data.id}`,
          );
        });
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rules = {
    required: [
      {
        // required: tipoDePersona !== 3,
        required: true,
        message: 'El campo es requerido',
      },
    ],
    conditionalRequired: [
      {
        required: tipoDePersona !== 1,
        message: 'El campo es requerido',
      },
    ],
    email: [
      {
        required: tipoDePersona !== 1,
        message: 'El campo es requerido',
      },
      {
        type: 'email',
        message: 'Introduzca un correo valido.',
      },
    ],
    numero_de_celular: [
      {
        pattern: /^[0-9]{0,10}$/,
        message: 'Ingrese un número teléfonico válido',
      },
    ],
  };
  // const fetchCiudadano = async (id) => {
  //   setLoading(true);
  //   if (id) {
  //     const _ciudadano = await getCiudadano(id);
  //     if (_ciudadano) {
  //       setCiudadanoFound([_ciudadano]);
  //       setTimeout(() => {
  //         form.setFieldsValue({
  //           ciudadano: _ciudadano.id,
  //         });
  //       });
  //     } else {
  //       message.info('No se encontró ciudadano');
  //       setCiudadanoFound([]);
  //     }
  //   }
  //   setLoading(false);
  // };

  const resetAndValidate = () => {
    setCiudadanoFound([]);
    form.resetFields(['q']);
    form.validateFields(['q']);
  };

  const TipoDeRegistro = () => (
    <>
      <Row gutter={10}>
        <Col span={24}>
          <Form.Item
            name="tipo_de_usuario_solicitante"
            rules={rules.required}
            label="Tipo de Usuario Solicitante"
          >
            <Select dataSource={tipoDeSolicitante} onChange={setTipoDePersona} />
          </Form.Item>
        </Col>
        {tipoDePersona === 2 && (
          <InputSearchPro
            label="Ciudadano"
            name="q"
            // onClickAdvanced={() => setVisibleBusquedaAvanzada(true)}
            breakPoints={{
              xs: 24,
              sm: 24,
              md: 8,
            }}
            inputProps={{
              onPressEnter: () => searchCiudadano(),
              onChange: ({ target: { value } }) => {
                if (!value) {
                  resetAndValidate();
                }
              },
            }}
            resultProps={{
              name: 'ciudadano',
              label: 'ciudadano',
              dataSource: ciudadanoFound,
              labelProp: 'nombre_completo',
              onClear: resetAndValidate,
              breakPoints: {
                xs: 24,
                sm: 24,
                md: 8,
              },
            }}
          />
        )}
        <Col xs={24} sm={24} md={8}>
          <Form.Item
            initialValues={{ anonima: false }}
            name="nombre"
            rules={rules.required}
            label="Nombre"
            hasFeedback
          >
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Form.Item
            name="apellido paterno"
            rules={rules.required}
            label="Apellido Paterno"
            hasFeedback
          >
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Form.Item
            name="apellido materno"
            rules={rules.required}
            label="Apellido Materno"
            hasFeedback
          >
            <Input allowClear />
          </Form.Item>
        </Col>
        {tipoDePersona === 3 && ( // 1 = anonimo
        <>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              name="correo electronico"
              rules={rules.email}
              label="Correo Electrónico"
              hasFeedback
            >
              <Input allowClear />
            </Form.Item>
          </Col>
        </>
        )}
        <Col xs={24} sm={24} md={8}>
          <Form.Item
            name="telefono"
            rules={rules.numero_de_celular}
            label="Teléfono"
            hasFeedback
          >
            <Input allowClear maxLength={10} />
          </Form.Item>
        </Col>
        {tipoDePersona !== 1 && (
          <>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="nacionalidad"
                rules={rules.required}
                label="Nacionalidad"
                hasFeedback
              >
                <Select
                  pagination={{
                    getter: getCatalogoDePaises,
                    config: { auto: true },
                    setter: (dataSource) => {
                      paises.current = dataSource;
                    },
                  }}
                  dataSource={paisesTemp.results.length
                    ? paisesTemp : paises.current}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="genero_solicitante_de_queja"
                rules={rules.required}
                label="Genero"
                hasFeedback
              >
                <Select dataSource={generos} allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="direccion"
                rules={rules.required}
                label="Dirección"
                hasFeedback
              >
                <Input allowClear />
              </Form.Item>
            </Col>
          </>
        )}
        <Col xs={24} sm={24} md={8}>
          <Form.Item
            name="tipo_de_atencion_de_solicitud"
            rules={rules.required}
            label="Tipo de Atención"
          >
            <Select dataSource={tipoDeAtencion} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );

  const InformacionDeLaQueja = () => (
    <>
      <Row gutter={10}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="fecha_de_los_hechos"
            rules={rules.required}
            label="Fecha de los hechos"
            hasFeedback
          >
            <DatePicker
              placeholder=""
              format="DD/MM/YYYY"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="hora_de_los_hechos"
            rules={rules.required}
            label="Hora aproximada de los hechos"
            hasFeedback
          >
            <DatePicker
              placeholder=""
              suffixIcon=""
              mode="time"
              picker="time"
              format="h:mm a"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="oficina_de_queja"
            rules={rules.required}
            label="Nombre de la Oficina en donde sucedieron los hechos"
            hasFeedback
          >
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="institucion_de_queja"
            rules={rules.required}
            label="De qué Institución es el Tramite/Servicio o el personal con quién trato?"
            hasFeedback
          >
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="tramite_de_queja"
            rules={rules.required}
            label="Ingrese el nombre del trámite o servicio que queria realizar"
            hasFeedback
          >
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="detalle_de_hechos"
            rules={rules.required}
            label="Narre los actos u omisiones del servidor publico que motivan su queja,indicando,el modo y circunstancias en que ocurrieron"
            hasFeedback
          >
            <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} maxLength={250} allowClear />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
  const Archivos = ({ required }) => (
    <Dragger
      form={form}
      label="Evidencias"
      limitMB={30}
      rules={required ? rules.required : null}
      name="archivos"
      allowedExtensions={['png', 'jpeg', 'jpg', 'pdf', 'docx']}
      multiple
    />
  );

  const onClickAdd = () => {
    setVisible(true);
  };

  const onClickEdit = () => {
    setVisible(true);
  };
  const onClickDelete = () => {

  };

  const onFinishTestigo = async () => {
    try {
      await formTestigos.validateFields();
      const values = formTestigos.getFieldsValue();
      const clone = [...testigos];
      const [key] = selectedRowKeys;
      if (!key) {
        clone.push({
          key: testigos.length + 1,
          ...values,
        });
        setTestigos(clone);
      } else {
        const result = clone.map((e) => (e.key === key ? { ...values, key } : e));
        setTestigos(result);
      }
      setVisible(false);
      formTestigos.resetFields();
    } catch (err) {
      onError(err);
    }
  };

  const InformacionServidorPublico = () => (
    <>
      <Row gutter={10}>
        <Col span={24}>
          <Text>
            Si desea quejarse o denunciar a un servidor publico, especifique:
          </Text>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="nombre_servidor"
            rules={rules.required}
            label="Nombre"
            hasFeedback
          >
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="descripcion_fisica"
            rules={rules.required}
            label="Descripción Fisica"
            hasFeedback
          >
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Text>
            Indicar nombre de los testigos presenciales, en caso de presentar.
          </Text>
        </Col>
        <Col span={24}>
          <Table
            cols={[
              {
                titleText: 'Descripción',
                dataIndex: 'descripcion',
                key: 'descripcion',
              },
            ]}
            rowSelection={{
              selectedRowKeys,
              type: 'radio',
            }}
            handleOnRowClick={(record) => {
              setSelectedRowKeys([record.key]);
            }}
            rowKey="key"
            controls={{
              onClickAdd,
              onClickDelete,
              onClickEdit,
            }}
            allowSearch={false}
            allowSort={false}
            data={testigos}
            mobileColIndex={0}
          />
        </Col>
      </Row>
    </>
  );

  const steps = [
    {
      key: 0,
      title: 'Tipo De Registro',
      icon: <InfoCircleOutlined />,
    },
    {
      key: 1,
      title: 'Información de la Queja',
      icon: <SnippetsOutlined />,
    },
    {
      key: 2,
      title: 'Archivos',
      icon: <PictureOutlined />,
    },
    {
      key: 3,
      title: 'Información servidor público',
      icon: <UserOutlined />,
    },
  ];

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const next = async () => {
    try {
      const fieldsToValidate = {
        1: ['correo electronico', 'telefono', 'tipo_de_atencion_de_solicitud'], // Anonimo
        2: ['correo electronico', 'telefono', 'nacionalidad', 'genero_solicitante_de_queja', 'direccion', 'tipo_de_atencion_de_solicitud'], // cuenta unica
        3: ['tipo_de_usuario_solicitante', 'nombre', 'apellido paterno', 'apellido materno', 'correo electronico', 'telefono', 'nacionalidad', 'genero_solicitante_de_queja', 'direccion', 'tipo_de_atencion_de_solicitud'], // sin registro
      };
      // await form.validateFields(['tipo_de_atencion_de_solicitud']);
      await form.validateFields(fieldsToValidate[tipoDePersona]);
      switch (currentStep) {
        case 1:
          await form.validateFields(['fecha_de_los_hechos',
            'hora_de_los_hechos', 'oficina_de_queja',
            'institucion_de_queja', 'tramite_de_queja',
            'nombre_de_servidor_publico']);
          break;
        case 2:
          await form.validateFields(['archivos']);
          break;
        case 3:
          await form.validateFields(['quejarse',
            'nombre_servidor',
            'descripcion_fisica',
            'nombre']);
          onFinish();
          break;
        default:
          break;
      }
      if (currentStep !== 3) {
        setCurrentStep(currentStep + 1);
      }
    } catch (err) {
      if (err.message !== 'seleccione_ubicacion') {
        onError(err);
      }
    }
  };

  const styles = {
    show: {
      right: 0,
      opacity: 1,
      zIndex: 3,
      // height: `calc(100vh - ${screens.md ? '350' : '300'}px)`,
    },
    hide: {
      right: '100%',
      opacity: 0,
      zIndex: 0,
    },
    base: {
      position: 'absolute',
      top: 0,
      left: 0,
    },
  };

  return (
    <>
      <Row align="center" justify="center" className="container">
        <Col xs={24} sm={24} md={24}>
          <Spin
            tip="Cargando..."
            spinning={loading}
            style={{ width: '100%', height: '100%' }}
          >
            <Card
              className="form-container no-padding"
              title="Agregar Solicitud"
              bordered={false}
              bodyStyle={{ overflow: 'hidden' }}
            >
              <Col>
                <Steps current={currentStep}>
                  {steps.map((step) => (
                    <Step title={step.title} icon={step.icon} key={step.key} />
                  ))}
                </Steps>
              </Col>
              <Col
                span={24}
                style={{
                  height: `calc(100vh - ${screens.md ? '300' : '250'}px)`,
                  position: 'relative',
                }}
              >
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                  scrollToFirstError
                >
                  <Row gutter={10} style={{ display: 'flex', flex: 1 }}>
                    <Col
                      span={24}
                      style={{
                        ...styles.base,
                        ...styles[currentStep === 0 ? 'show' : 'hide'],
                      }}
                    >
                      <TipoDeRegistro />
                    </Col>
                    <Col
                      span={24}
                      style={{
                        ...styles.base,
                        ...styles[currentStep === 1 ? 'show' : 'hide'],
                      }}
                    >
                      <InformacionDeLaQueja />
                    </Col>
                    <Col
                      span={24}
                      style={{
                        ...styles.base,
                        ...styles[currentStep === 2 ? 'show' : 'hide'],
                      }}
                    >
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="tiene_pruebas"
                          label="¿Tiene evidencia de los hechos?"
                        >
                          <Select trueFalse />
                        </Form.Item>
                      </Col>
                      {tienePruebas && (
                        <Archivos />
                      )}
                    </Col>
                    <Col
                      span={24}
                      style={{
                        ...styles.base,
                        ...styles[currentStep === 3 ? 'show' : 'hide'],
                      }}
                    >
                      <InformacionServidorPublico />
                    </Col>
                    <Row
                      justify="space-between"
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        height: 30,
                      }}
                    >
                      <Button
                        type="link"
                        onClick={prev}
                        disabled={currentStep === 0}
                      >
                        <ArrowLeftOutlined />
                        Anterior
                      </Button>
                      <Button type="link" onClick={next}>
                        {currentStep === steps.length - 1 ? (
                          <>
                            <span>Finalizar </span>
                            <SendOutlined />
                          </>
                        ) : (
                          <>
                            <span>Siguiente</span>
                            <ArrowRightOutlined />
                          </>
                        )}
                      </Button>
                    </Row>
                  </Row>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </Col>
            </Card>
          </Spin>
        </Col>
      </Row>
      <Modal
        open={visible}
        footer={null}
        closable={false}
        maskClosable={false}
        centered
        title={(
          <FormSubmitControls
            label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Testigo`}
            onCancel={() => {
              formTestigos.resetFields();
              setVisible(false);
            }}
            onFinish={onFinishTestigo}
          />
        )}
      >
        <Row>
          <Col span={24}>
            <Form
              form={formTestigos}
              layout="vertical"
            >
              <Col span={24}>
                <Form.Item
                  name="descripcion"
                  rules={rules.required}
                  label="Nombre del Testigo"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Form>
          </Col>
        </Row>
      </Modal>
      {/* <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert()}
        visible={setVisible}
        content={`Categoria ${form.getFieldValue('descripcion')}`}
        loading={loading}
      /> */}
    </>
  );
};

export default NuevaQueja;
