/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  QuestionCircleOutlined,
} from '@ant-design/icons';

import PlantillaDeFlujoDeValidacion from 'views/Retys/PlantillaDeFlujoDeValidacion';
// import HistorialFlujoModificacion from 'views/Retys/HistorialDeFlujoDeModificacion';
// import HistorialFlujoDeValidaciones from 'views/Retys/HistorialDeValidaciones';
import TipoDeProcesoParaCanalDePresentacion from 'views/Retys/TipoDeProcesoParaCanalDePresentacion';
import blank from './blank';

export default {
  title: 'RETYS',
  icon: () => <QuestionCircleOutlined />,
  showInSidebar: true,
  component: blank,
  type: 'private',
  children: [

    // {
    //   title: 'Historial de Flujo de Modificacion',
    //   icon: null,
    //   component: HistorialFlujoModificacion,
    //   type: 'private',
    //   showInSidebar: true,
    // },
    // {
    //   title: 'Historial de Flujo de Validacion',
    //   icon: null,
    //   component: HistorialFlujoDeValidaciones,
    //   type: 'private',
    //   showInSidebar: true,
    // },

    {
      title: 'Tipo de Proceso para Canal de Presentación',
      icon: null,
      component: TipoDeProcesoParaCanalDePresentacion,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Plantilla de Flujo de Validación',
      icon: null,
      component: PlantillaDeFlujoDeValidacion,
      type: 'private',
      showInSidebar: true,
    },
  ],
};
