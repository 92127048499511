import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Spin,
  Form,
  Card,
  Input,
  DatePicker,
  Button,
} from 'antd';

import {
  SET_ESTADOS_CIVILES,
  SET_GENEROS,
  SET_ESTADOS_DE_LOS_FUNCIONARIOS,
} from 'store/reducers/catalogs/index';

import fetchSome from 'utils/fetchSome';
import getFormattedValues, { hasFiles, formatReceived } from 'utils/formatValues';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import ModalDelete from 'components/ModalDelete';
import Table from 'components/Table';
import AvatarUploader from 'components/AvatarUploader';
import Select from 'components/Select';
import Uploader from 'components/Uploader';
import { onError, onSuccess } from 'utils/handlers';
import {
  RFC_PATTERN,
  CURP_PATTERN,
  digitoVerificador,
} from 'utils/patterns';
import LadaNumero from 'components/LadaNumero';

const baseURI = '/usuarios/funcionario/';
const { TextArea } = Input;

const Funcionarios = () => {
  const [form] = Form.useForm();
  const estadosCiviles = useSelector(({ catalogs }) => catalogs.estadosCiviles);
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const estadosFuncionarios = useSelector(({ catalogs }) => catalogs.estadosDeLosFuncionarios);
  const generos = useSelector(({ catalogs }) => catalogs.generos);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [puestos, setPuestos] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(formatReceived(res.data));
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resPuestos = await API.get('/configuracion/puestos/');
      setPuestos(resPuestos.data || []);

      if (!estadosFuncionarios.length) {
        fetchSome('usuarios/estados-del-funcionario/', SET_ESTADOS_DE_LOS_FUNCIONARIOS);
      }

      if (!estadosCiviles.length) {
        fetchSome('usuarios/estados-civiles/', SET_ESTADOS_CIVILES);
      }

      if (!generos.length) {
        fetchSome('usuarios/generos/', SET_GENEROS);
      }

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
}, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const files = ['foto', 'firma_electronica'];
      const config = hasFiles(values, files) ? {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      } : {};
      const formattedValues = getFormattedValues(values, {
        files,
        formData: config.headers,
        clean: false,
      });
      if (!selectedRowKeys.length) {
        const res = await API.post(baseURI, formattedValues, config);
        if (res?.status === 201) {
          onSuccess(res, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const res = await API.put(`${baseURI}${key}`, formattedValues, config);
        if (res?.status === 200) {
          onSuccess(res, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const response = await API.delete(`${baseURI}${key}/`);
      if (response?.status === 204) {
        onSuccess(response, 'Eliminado correctamente');
        onCancel();
        setVisibleAlert(false);
        await fetchData();
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    const format = (values) => {
      const clone = {};
      const keys = Object.keys(values);
      keys.forEach((prop) => {
        clone[prop] = values[prop]?.id || values[prop];
      });
      return clone;
    };
    form.setFieldsValue({
      ...format(record),
    });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 300,
    },
    {
      titleText: 'Apellido Paterno',
      dataIndex: 'apellido_paterno',
      key: 'apellido_paterno',
      width: 250,
    },
    {
      titleText: 'Apellido Materno',
      dataIndex: 'apellido_materno',
      key: 'apellido_materno',
      width: 250,
    },
  ];

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
    RFC: [
      {
        validator: async (rule, value) => {
          if (value) {
            if (!RFC_PATTERN.test(value)) {
              throw new Error('Ingrese un RFC válido');
            }
          }
        },
      },
    ],
    CURP: [
      {
        validator: async (rule, value) => {
          if (value) {
            const validado = value.toUpperCase().match(CURP_PATTERN);
            if (!validado || validado[2] !== digitoVerificador(validado[1])) {
              throw new Error('Ingrese una CURP válida');
            }
          }
        },
      },
    ],
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickDelete,
              onClickEdit,
            }}
            rowKey="id"
          />
        ) : (
          <Card
            className="form-container"
            title={`${!selectedRowKeys.length ? 'Editar' : 'Agregar'} Funcionario`}
            extra={(
              <FormSubmitControls onFinish={onFinish} onCancel={onCancel} />
                )}
            bordered={false}
          >
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              scrollToFirstError
              initialValues={{ estados_globales: 1 }}
            >

              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="nombre"
                    rules={rules.required}
                    label="Nombre"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="apellido_paterno"
                    rules={rules.required}
                    label="Apellido paterno"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="apellido_materno"
                    label="Apellido materno"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <LadaNumero
                  form={form}
                  fullSize
                  names={{
                    lada: 'lada',
                    numero_de_telefono: 'numero_de_celular',
                  }}
                  celular
                  breakPoints={{
                    md: 24,
                    lg: 12,
                    xl: 8,
                    xxl: 8,
                  }}
                />
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="CURP"
                    rules={rules.CURP}
                    label="CURP"
                    hasFeedback
                    normalize={(val) => val.toUpperCase()}
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="RFC"
                    rules={rules.RFC}
                    label="RFC"
                    hasFeedback
                    normalize={(val) => val.toUpperCase()}
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={16}>
                  <Form.Item
                    name="observaciones"
                    label="Observaciones"
                    hasFeedback
                  >
                    <TextArea />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="puesto"
                    label="Puesto"
                    hasFeedback
                    rules={rules.required}
                  >
                    <Select dataSource={puestos} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <AvatarUploader
                    form={form}
                    imageURL={form.getFieldValue('foto')}
                  />
                </Col>
              </Row>
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="genero"
                    label="Genero"
                    hasFeedback
                    rules={rules.required}
                  >
                    <Select dataSource={generos} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="estado_del_funcionario"
                    label="Estado del funcionario"
                    hasFeedback
                    rules={rules.required}
                  >
                    <Select dataSource={estadosFuncionarios} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="estados_globales"
                    label="Estado"
                    hasFeedback
                    rules={rules.required}
                  >
                    <Select
                      disabled={!selectedRowKeys.length}
                      dataSource={estadosGlobales}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="estado_civil"
                    label="Estado civil"
                    hasFeedback
                    rules={rules.required}
                  >
                    <Select dataSource={estadosCiviles} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="fecha_de_nacimiento"
                    label="Fecha de nacimiento"
                    hasFeedback
                    rules={rules.required}
                  >
                    <DatePicker
                      placeholder=""
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>
                </Col>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
                <Uploader
                  title="Firma Electrónica"
                  limitMB={50}
                  file={form.getFieldValue('firma_electronica')}
                  formItemName="firma_electronica"
                  allowedExtensions={['cer']}
                  onError={onError}
                  allowPreview={false}
                />
              </Row>
            </Form>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content={`Funcionario ${form.getFieldValue('nombre')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
};

export default Funcionarios;
