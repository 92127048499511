import API from 'utils/api';
import { onSuccess, onError } from 'utils/handlers';

export const baseURI = 'configuracion/caar/';

/**
 * GET CAARs
 * @returns {Promise<Array<object>>}
 */
export const getCAARs = async (params = null) => {
  try {
    const response = await API.get(baseURI, { params });
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET CAAR
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getCAAR = async (id) => {
  try {
    const response = await API.get(`${baseURI}${id}`);
    return response.data;
  } catch (err) {
    onError(err);
    return null;
  }
};

/**
 * GET Horarios CAAR
 * @param {number} id
 * @param {object} values
 * @returns {Promise<Array<object>>}
 */
export const getHorariosCAAR = async (id, values) => {
  try {
    const response = await API.get(`${baseURI}${id}/horarios/`, values);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * POST CAAR
  * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const postCAAR = async (values) => {
  try {
    const response = await API.post(baseURI, values);
    onSuccess(response, 'Agregado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * PATCH CAAR
 * @param {number} id
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const patchCAAR = async (id, values) => {
  try {
    const response = await API.patch(`${baseURI}${id}`, values);
    onSuccess(response, 'Editado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * DELETE CAAR
 * @param {number} id
 * @returns {Promise<object|boolean>}
 */
export const deleteCAAR = async (id) => {
  try {
    const response = await API.delete(`${baseURI}${id}`);
    onSuccess(response, 'Eliminado correctamente');
    return true;
  } catch (err) {
    onError(err);
    return false;
  }
};
