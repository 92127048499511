/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Button,
  Grid,
} from 'antd';
import {
  ArrowLeftOutlined,
  SaveOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  EditOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { onError, onSuccess } from 'utils/handlers';
import API from 'utils/api';
import ModalChangeStatus from 'components/ModalChangeStatus';
import Moment from 'moment';
import { DATE_FORMAT } from 'utils/formatValues';
import { isAdmin } from 'utils/permission';
import ModalUnauthorize from 'components/ModalUnauthorize';

const FormSubmitControls = ({
  onFinish,
  onFinishText,
  onCancel,
  onCancelText,
  baseURI,
  allowAuthorize,
  allowCancel,
  mutable,
  selectedRowKeys,
  callback,
  allowSaveAndContinue,
  loading,
  requireObservation,
  observationPropName,
  label,
  validateAuthorizable,
  customAction,
  allowCustomAction,
  customActionText,
  requireCancelDate,
  cancelDatePropName,
  allowUnauthorize,
  authorized,
}) => {
  const screens = Grid.useBreakpoint();
  const [visibleAuthorize, setVisibleAuthorize] = useState(false);
  const [visibleCancel, setVisibleCancel] = useState(false);
  const [visibleDesautorizar, setVisibleDesautorizar] = useState(false);

  // normalize/validate params
  allowAuthorize = allowAuthorize && (mutable) && !!selectedRowKeys.length;
  allowCancel = allowCancel && (mutable) && !!selectedRowKeys.length;
  allowSaveAndContinue = onFinish && allowSaveAndContinue && mutable;
  allowCustomAction = allowCustomAction && customAction && customActionText;

  const onCancelStatus = async (values) => {
    try {
      const [key] = selectedRowKeys;
      if (requireCancelDate) {
        values[cancelDatePropName] = new Moment().format(DATE_FORMAT);
      }
      const URI = `${baseURI}/${key}/`.replace(/\/\//, '/');
      const res = await API.patch(URI, {
        ...values,
        estados_globales: 5,
      });
      if (res?.status === 200) {
        setVisibleCancel(false);
        callback(5, values);
      }
    } catch (err) {
      onError(err);
    }
  };

  const onAuthorizeStatus = async () => {
    try {
      const data = validateAuthorizable ? await onFinish(true) : true;
      const validated = !validateAuthorizable || validateAuthorizable(data);
      if (validated) {
        const [key] = selectedRowKeys;
        const URI = `${baseURI}/${key}/`.replace(/\/\//, '/');
        const response = await API.patch(URI, { estados_globales: 4 });
        if (response?.status === 200) {
          onSuccess(response, 'Autorizado Exitosamente');
          setVisibleAuthorize(false);
          callback(4, response.data);
        }
      }
    } catch (err) {
      onError(err);
    }
  };

  const onUnauthorize = async () => {
    try {
      const [key] = selectedRowKeys;
      const URI = `${baseURI}/${key}/`.replace(/\/\//, '/');
      const response = await API.patch(URI, { estados_globales: 1 });
      if (response?.status === 200) {
        onSuccess(response, 'Desautorizado Exitosamente');
        setVisibleDesautorizar(false);
        callback(1, response.data);
      }
    } catch (err) {
      onError(err);
    }
  };

  const Controls = () => (
    <Row justify="end" style={{ marginLeft: 'auto' }}>
      {onCancel && (
        <Button
          type="link"
          onClick={() => onCancel()}
          disabled={loading}
        >
          <ArrowLeftOutlined />
          {screens.md && onCancelText}
        </Button>
      )}
      {allowCancel && (
        <Button
          type="link"
          onClick={() => setVisibleCancel(true)}
          disabled={loading}
          className="no-color danger"
        >
          <CloseCircleOutlined />
          {screens.md && 'Cancelar'}
        </Button>
      )}
      {allowAuthorize && (
        <Button
          type="link"
          onClick={() => setVisibleAuthorize(true)}
          disabled={loading}
          className="no-color success"
        >
          <CheckCircleOutlined />
          {screens.md && 'Autorizar'}
        </Button>
      )}
      {allowSaveAndContinue && (
        <Button
          type="link"
          onClick={() => onFinish(true)}
          disabled={loading}
        >
          <EditOutlined />
          {screens.md && `${onFinishText} y continuar`}
        </Button>
      )}
      {allowCustomAction && (
        <Button
          type="link"
          onClick={() => customAction()}
          disabled={loading}
        >
          <SyncOutlined />
          {screens.md && customActionText}
        </Button>
      )}
      {(onFinish && mutable) && (
        <Button
          type="link"
          onClick={() => onFinish()}
          disabled={loading}
        >
          <SaveOutlined />
          {screens.md && onFinishText}
        </Button>
      )}
      {(allowUnauthorize
       && !mutable
       && isAdmin()
       && selectedRowKeys?.length
       && authorized) && (
       <Button
         type="link"
         onClick={() => setVisibleDesautorizar(true)}
         disabled={loading}
         className="no-color success"
       >
         <CloseCircleOutlined />
         {screens.md && 'Desautorizar'}
       </Button>
      )}
    </Row>
  );

  if (label) {
    return (
      <>
        {label && (
          <Row justify="space-between" align="middle">
            {label}
            <Controls />
          </Row>
        )}
        {((allowAuthorize || allowCancel) || isAdmin()) && (
          <>
            <ModalChangeStatus
              onSubmit={() => (visibleAuthorize ? onAuthorizeStatus() : onCancelStatus())}
              onCancel={() => {
                setVisibleAuthorize(false);
                setVisibleCancel(false);
              }}
              visible={visibleAuthorize || visibleCancel}
              onCancelText={onCancelText}
              authorize={visibleAuthorize}
              requireObservation={requireObservation}
              observationPropName={observationPropName}
            />
            <ModalUnauthorize
              visible={visibleDesautorizar}
              setVisible={setVisibleDesautorizar}
              onFinish={onUnauthorize}
            />
          </>
        )}
      </>
    );
  }

  return (
    <>
      <Controls />
      {((allowAuthorize || allowCancel) || isAdmin()) && (
        <>
          <ModalChangeStatus
            onSubmit={() => (visibleAuthorize ? onAuthorizeStatus() : onCancelStatus())}
            onCancel={() => {
              setVisibleAuthorize(false);
              setVisibleCancel(false);
            }}
            visible={visibleAuthorize || visibleCancel}
            onCancelText={onCancelText}
            authorize={visibleAuthorize}
            requireObservation={requireObservation}
            observationPropName={observationPropName}
          />
          <ModalUnauthorize
            visible={visibleDesautorizar}
            setVisible={setVisibleDesautorizar}
            onFinish={onUnauthorize}
          />
        </>
      )}
    </>
  );
};

FormSubmitControls.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onFinish: PropTypes.func,
  onFinishText: PropTypes.string,
  onCancelText: PropTypes.string,
  baseURI: PropTypes.string,
  allowAuthorize: PropTypes.bool,
  allowCancel: PropTypes.bool,
  mutable: PropTypes.bool,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.number),
  callback: PropTypes.func,
  allowSaveAndContinue: PropTypes.bool,
  loading: PropTypes.bool,
  requireObservation: PropTypes.bool,
  observationPropName: PropTypes.string,
  label: PropTypes.string,
  validateAuthorizable: PropTypes.func,
  customAction: PropTypes.func,
  allowCustomAction: PropTypes.bool,
  customActionText: PropTypes.string,
  requireCancelDate: PropTypes.bool,
  cancelDatePropName: PropTypes.string,
  allowUnauthorize: PropTypes.bool,
  authorized: PropTypes.bool,
};

FormSubmitControls.defaultProps = {
  onFinish: null,
  onFinishText: 'Guardar',
  onCancelText: 'Regresar',
  baseURI: null,
  allowAuthorize: false,
  allowCancel: false,
  mutable: true,
  selectedRowKeys: [],
  callback: null,
  allowSaveAndContinue: false,
  loading: false,
  requireObservation: false,
  observationPropName: 'observaciones',
  label: null,
  validateAuthorizable: null,
  customAction: () => { },
  allowCustomAction: false,
  customActionText: null,
  requireCancelDate: false,
  cancelDatePropName: 'fecha_cancelacion',
  allowUnauthorize: false,
  authorized: false,
};

export default FormSubmitControls;
