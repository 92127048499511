import React, {
  useRef,
  useId,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Transfer,
  Col,
  Select,
  Form,
  Pagination,
} from 'antd';

export const createFetcher = (fetchData) => async (
  params,
  setLoading,
  targetKeys = [],
  prevDataSource,
  setDataSource,
) => {
  if (setLoading) {
    setLoading(true);
  }
  const data = await fetchData(params);
  const url = data.next && (new URL(data.next));
  if (url) {
    Object.keys(params).forEach((param) => {
      data[param] = url.searchParams.get(param);
    });
  }
  data.page = params.page;
  if (prevDataSource) {
    const keysDiff = targetKeys.filter((k) => !data.results.map((e) => e.id).includes(k));
    data.results.push(...keysDiff.map((k) => prevDataSource.results.find((item) => item.id === k))
      .filter((e) => e));
  }
  if (setDataSource) {
    setDataSource(data);
  }
  if (setLoading) {
    setLoading(false);
  }
  return data;
};

const PlainTransfer = ({
  dataSource,
  setDataSource,
  formItemName,
  label,
  rules,
  form,
  filterProp,
  filterPropLabel,
  fetchData,
  setLoading,
  loading,
  onChange,
  ...restProps
}) => {
  const refSearch = useRef();
  const componentKey = useId();
  const targetKeys = Form.useWatch(formItemName, form) || [];
  const [_loading, _setLoading] = useState(false);

  const localeFilter = (input, opt) => opt[filterProp]?.toLowerCase().trim()
    .indexOf(input?.toLowerCase().trim()) >= 0;

  const remoteSearch = (direction, value, ms = 1000) => {
    if (direction === 'left') {
      clearTimeout(refSearch.current);
      refSearch.current = setTimeout(() => fetchData(
        { page: 1, [filterProp]: value },
        setLoading || _setLoading,
        targetKeys,
        dataSource,
        setDataSource,
      ), ms);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    if (fetchData && !dataSource.results.length) {
      remoteSearch('left', undefined, 0);
    }
  }, []);

  return (
    <div style={{ overflowX: 'scroll' }} key={componentKey}>
      <Col xs={0} sm={0} md={24}>
        <Col span={24} className="ant-form-item-label">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className={rules.some((r) => r.required) ? 'ant-form-item-required' : ''} title={label}>
            {label}
          </label>
        </Col>
        <Transfer
          titles={['Disponibles', 'Seleccionados']}
          locale={{ searchPlaceholder: filterPropLabel ? `Buscar por ${filterPropLabel}` : null }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...restProps}
          disabled={restProps.disabled || loading || _loading}
          listStyle={{
            height: 300,
            ...restProps.listStyle,
          }}
          targetKeys={targetKeys}
          dataSource={dataSource?.results || dataSource}
          className="plain-transfer"
          render={(record) => `${record?.clave ? `${record?.clave} - ` : ''}${record?.[filterProp]}`}
          rowKey={(record) => record.id}
          showSelectAll={false}
          filterOption={fetchData ? () => true : localeFilter}
          onSearch={fetchData ? (dir, val) => remoteSearch(dir, val) : null}
          onChange={(keys) => {
            form.setFieldsValue({ [formItemName]: keys });
            if (onChange) {
              onChange(keys);
            }
          }}
          pagination={!fetchData}
          showSearch
          oneWay
        />
        {!!fetchData && (
          <Pagination
            current={dataSource?.page || 1}
            total={dataSource?.count}
            pageSize={10}
            onChange={(page) => fetchData(
              { page, [filterProp]: dataSource[filterProp] },
              setLoading || _setLoading,
              targetKeys,
              dataSource,
              setDataSource,
            )}
            simple
          />
        )}
        <br />
      </Col>
      <Col xs={24} sm={24} md={0}>
        <Form.Item
          name={formItemName}
          label={label}
          rules={rules}
          hasFeedback
        >
          <Select
            showArrow
            showSearch
            mode="multiple"
            style={{ width: '100%' }}
            optionFilterProp="children"
            filterOption={(input, opt) => opt.children.toLowerCase()
              .indexOf(input.toLowerCase()) >= 0}
            options={(dataSource?.results || dataSource).map((o) => ({
              key: o.id,
              value: o.id,
              label: `${o?.clave ? `${o?.clave} - ` : ''}${o?.[filterProp]}`,
            }))}
          />
        </Form.Item>
      </Col>
    </div>
  );
};

PlainTransfer.propTypes = {
  dataSource: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))]),
  rules: PropTypes.arrayOf(PropTypes.shape({})),
  formItemName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  form: PropTypes.shape({
    getFieldValue: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
  }).isRequired,
  filterProp: PropTypes.string,
  filterPropLabel: PropTypes.string,
  allowTooltip: PropTypes.bool,
  fetchData: PropTypes.func,
  setLoading: PropTypes.func,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  paginated: PropTypes.bool,
  externalDataSource: PropTypes.arrayOf(PropTypes.shape({})),
  setDataSource: PropTypes.func,
  internalFetch: PropTypes.bool,
};

PlainTransfer.defaultProps = {
  dataSource: [],
  rules: [],
  filterProp: null,
  filterPropLabel: null,
  allowTooltip: false,
  fetchData: null,
  setLoading: null,
  loading: false,
  externalDataSource: [],
  paginated: false,
  onChange: null,
  setDataSource: null,
  internalFetch: true,
};

export default PlainTransfer;
