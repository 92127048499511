import { store } from 'store';
import { onError } from 'utils/handlers';
import {
  SET_PADRONES,
  SET_CONTENT_TYPES_PADRONES,
} from 'store/reducers/catalogs';
import API from 'utils/api';

const baseURI = 'catalogos/';

export const getTipoDePadronPorContenType = (contentTypeId) => {
  const contentType = store.getState()
    .catalogs.contentTypesPadrones.find((p) => p.id === contentTypeId);
  return store
    .getState().catalogs.padrones.find((p) => p.id === contentType?.nivel)?.id;
};

export const getContenTypePorTipoDePadron = (tipoDePadronId) => store
  .getState().catalogs.contentTypesPadrones.find((p) => p.nivel === tipoDePadronId)?.id;

/**
* GET Content Types Padrones
* @param {object} params
* @returns {Promise<object[]>}
*/
export const getContentTypesPadrones = async (params = null) => {
  try {
    const response = await API.get(`${baseURI}content-types-padrones`, { params });
    store.dispatch({
      type: SET_CONTENT_TYPES_PADRONES,
      payload: response.data,
    });
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Padrones
 * @param {Object} params
 * @returns {Promise<object[]>}
 */
export const getCatalogoDePadrones = async (params = null, config) => {
  try {
    const contentTypes = await getContentTypesPadrones();
    const response = await API.get(`${baseURI}padrones/`, { params, ...config });
    const payload = response.data.map((e) => {
      if (e.id === 4) {
        e.descripcion = 'Vehiculo';
      }
      if (e.id === 11) {
        e.descripcion = 'Juegos de Azar';
      }
      if (e.id === 13) {
        e.descripcion = 'Casa de Empeño';
      }
      if (e.id === 18) {
        e.descripcion = 'Policia Especial';
      }
      if (e.id === 19) {
        e.descripcion = 'Infracciones';
      }
      if (e.id === 20) {
        e.descripcion = 'Comercio Informal';
      }
      if (e.id === 21) {
        e.descripcion = 'Mercado';
      }
      if (e.id === 22) {
        e.descripcion = 'Licencia de Funcionamiento';
      }
      if (e.id === 23) {
        e.descripcion = 'Zonas Exclusivas';
      }
      if (e.id === 24) {
        e.descripcion = 'Anuncios';
      }
      e.content_type = contentTypes.find((ct) => ct.nivel === e.id)?.id;
      return e;
    });
    store.dispatch({
      type: SET_PADRONES,
      payload,
    });
    return payload;
  } catch (err) {
    onError(err);
    return [];
  }
};
