import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Modal,
} from 'antd';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import { SET_PADRONES } from 'store/reducers/catalogs';
import ModalDelete from 'components/ModalDelete';
import { onError, onSuccess } from 'utils/handlers';
import Select from 'components/Select';
import fetchSome from 'utils/fetchSome';

const baseURI = '/tramites/tipos-de-beneficiarios/';
const BenefiaciosDelTramite = (
) => {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const padrones = useSelector(({ catalogs }) => catalogs.padrones);
  const textInput = useRef(null);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI);
      if (response?.status === 200) {
        setData(response.data);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };
  const fetchAll = async () => {
    try {
      setLoading(true);
      if (!padrones.length) {
        await fetchSome('catalogos/padrones/', SET_PADRONES);
      }
      await fetchData();
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };
  useEffect(() => {
    fetchAll();
    return () => API.source.cancel;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTimeout(() => textInput.current.focus());
  }, [<Modal />]);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        await form.validateFields();
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          onCancel();
          await fetchData();
          setVisibleAlert(false);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickAdd = () => {
    setVisible(true);
    setSelectedRowKeys([]);
    form.resetFields();
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const columns = [
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 60,
    },
    {
      titleText: 'Padròn',
      dataIndex: 'padron',
      key: 'padron',
      width: 60,
      render: (val) => padrones.find((e) => e.id === val)?.descripcion,
    },
  ];

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es Requerido',
      },
    ],
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          rowKey="id"
          mobileColIndex={0}
          handleOnRowClick={handleOnRowClick}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
        />
        <Modal
          open={visible}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Beneficiario del Trámite`}
              onFinish={onFinish}
              onCancel={onCancel}
              loading={loading}
            />
              )}
          keyboard={!loading}
          maskClosable={!loading}
          footer={null}
          closable={false}
          forceRender
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            scrollToFirstError
          >
            <Col span={24}>
              <Form.Item
                name="descripcion"
                rules={rules.required}
                label="Descripción"
                hasFeedback

              >
                <Input ref={textInput} allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="padron"
                rules={rules.required}
                label="Padrón"
                hasFeedback
              >
                <Select dataSource={padrones} allowClear />
              </Form.Item>

            </Col>
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Form>
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content="el Beneficiario del Tramite seleccionado"
          loading={loading}
        />
      </Spin>
    </Row>
  );
};
export default BenefiaciosDelTramite;
