import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Typography, Row } from 'antd';
import { ArrowLeftOutlined, CheckCircleOutlined } from '@ant-design/icons';
import FormSubmitControls from './FormSubmitCustomControls';

const { Text } = Typography;

const ModalUnauthorize = ({
  visible,
  setVisible,
  onFinish,
}) => (
  <Modal
    title={(
      <FormSubmitControls
        label="Desautorizar"
        controls={[
          {
            text: 'Cancelar',
            icon: <ArrowLeftOutlined />,
            onClick: () => setVisible(false),
          },
          {
            text: 'Desautorizar',
            icon: <CheckCircleOutlined />,
            onClick: onFinish,
          },
        ]}
      />
    )}
    open={visible}
    onCancel={() => setVisible(false)}
    closable={false}
    footer={null}
  >
    <Row justify="center">
      <Text style={{ fontSize: 16, textAlign: 'center' }}>
        ¿Está seguro que desea cambiar el estado del registro a activo?
      </Text>
    </Row>
  </Modal>
);

ModalUnauthorize.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default ModalUnauthorize;
