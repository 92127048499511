import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Typography,
} from 'antd';
import API from 'utils/api';
import getQueryParams from 'utils/getQueryParams';
import Card from './components/Card';
import './index.scss';

const { Text } = Typography;

const ActivarCuenta = () => {
  const history = useHistory();
  const codigo = getQueryParams('codigo', window.location.href);
  const [respuesta, setRespuesta] = useState([]);
  if (!codigo) {
    history.push('/inicio-de-sesion');
  }

  const verify = async () => {
    try {
      const response = await API.get(`usuarios/verificar-email?codigo=${codigo}`);
      if (response?.status === 200) {
        setRespuesta('Tu cuenta a sido activada con éxito presiona Continuar para iniciar en SAC.');
      } else {
        setRespuesta('Lo sentimos. Ha ocurrido un error inesperado, vuelva a intentarlo.');
      }
    } catch {
      setRespuesta('Lo sentimos. Ha ocurrido un error inesperado, inténtelo de nuevo más tarde.');
    }
  };

  useEffect(() => {
    verify();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  return (
    <Card>
      <Text style={{ textAlign: 'center' }}>
        {respuesta}
      </Text>
      <br />
      <Button
        className="auth-submit-button"
        style={{
          width: '150px',
        }}
        onClick={() => history.push('/inicio-de-sesion')}
      >
        Continuar
      </Button>
    </Card>
  );
};

export default ActivarCuenta;
