import React, { useState, useEffect, useRef } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Card,
  DatePicker,

} from 'antd';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import ModalDelete from 'components/ModalDelete';
import { onError, onSuccess } from 'utils/handlers';
import Uploader from 'components/Uploader';
import Select from 'components/Select';
import getFormatedValues, { DATE_FRONTEND_FORMAT, formatReceived, hasFiles } from 'utils/formatValues';

const baseURI = '/catalogos/nombre-de-ordenamiento/';

const NombresDeOrdenamiento = (
) => {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [tipoDeOrdenamiento, setTipoDeOrdenamiento] = useState([]);
  const [ambitoDeOrdenamiento, setAmbitoDeOrdenamiento] = useState([]);
  const textInput = useRef(null);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI);
      if (response?.status === 200) {
        setData(formatReceived(response.data));
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      const res = await API.get('/catalogos/tipo-de-ordenamiento/');
      setTipoDeOrdenamiento(res.data || []);
      const resNombre = await API.get('/catalogos/ambito-de-ordenamiento/');
      setAmbitoDeOrdenamiento(resNombre.data || []);
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();

    return () => API.source.cancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const files = ['archivo'];
      const config = hasFiles(values, files) ? {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      } : {};
      const formattedvalues = getFormatedValues(values, {
        files,
        formData: config.headers,
        clean: true,
      });
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, formattedvalues, config);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        await form.validateFields();
        const response = await API.put(`${baseURI}${key}/`, formattedvalues, config);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          onCancel();
          await fetchData();
          setVisibleAlert(false);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickAdd = () => {
    setVisible(true);
    setSelectedRowKeys([]);
    form.resetFields();
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue({
      ...record,
      ambito_de_ordenamiento: record?.ambito_de_ordenamiento.id,
      tipo_de_ordenamiento: record?.tipo_de_ordenamiento.id,
    });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const columns = [
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 300,
    },
    {
      titleText: 'Fecha de Publicación',
      dataIndex: 'fecha_de_publicacion',
      key: 'fecha_de_publicacion',
      width: 300,
      render: (val) => (val?.format(DATE_FRONTEND_FORMAT)),

    },
    {
      titleText: 'Fecha de Vigor',
      dataIndex: 'fecha_de_vigor',
      key: 'fecha_de_vigor',
      width: 300,
      render: (val) => (val?.format(DATE_FRONTEND_FORMAT)),

    },
  ];

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
    url: [
      {
        type: 'url',
        message: 'Ingrese un URL válido',
      },
    ],
  };

  const allowedExtensions = [
    'pdf',
  ];

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            rowKey="id"
            mobileColIndex={0}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
          />
        ) : (
          <Card
            bordered={false}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
              />
            )}
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Regulacíon`}
          >
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              scrollToFirstError
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="descripcion"
                    rules={rules.required}
                    label="Descripción"
                    hasFeedback
                  >
                    <Input ref={textInput} allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="fecha_de_publicacion"
                    label="Fecha de Publicación"
                    hasFeedback
                  >
                    <DatePicker
                      placeholder=""
                      format={DATE_FRONTEND_FORMAT}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="fecha_de_vigor"
                    label="Fecha de Vigor"
                    hasFeedback
                  >
                    <DatePicker
                      placeholder=""
                      format={DATE_FRONTEND_FORMAT}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="hipervinculo"
                    rules={rules.url}
                    label="Hipervínculo"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="ambito_de_ordenamiento"
                    label="Ambito de Ordenamiento"
                  >
                    <Select dataSource={ambitoDeOrdenamiento} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="tipo_de_ordenamiento"
                    label="Tipo de Ordenamiento"
                  >
                    <Select dataSource={tipoDeOrdenamiento} />
                  </Form.Item>
                </Col>
                <Uploader
                  title="Archivo"
                  limitMB={50}
                  file={form.getFieldValue('archivo')}
                  formItemName="archivo"
                  allowedExtensions={allowedExtensions}
                  onError={onError}
                  tooltip={{
                    title: `Extensiones Permitidas para el Documento: ${allowedExtensions}`,
                  }}
                />
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="fecha_de_vigencia"
                    label="Fecha de Vigencia"
                    hasFeedback
                  >
                    <DatePicker
                      placeholder=""
                      format={DATE_FRONTEND_FORMAT}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="fecha_de_actualizacion"
                    label="Fecha de Actualización"
                    hasFeedback
                  >
                    <DatePicker
                      placeholder=""
                      format={DATE_FRONTEND_FORMAT}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="autoridad_emite"
                    label="Autoridad(es) que la emiten"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="autoridad_aplica"
                    label="Autoridad(es) que la aplican"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="sector"
                    label="Sectores"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="materiales"
                    label="Materiales"
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="sujetos_regulados"
                    label="Sujetos Regulados"
                    hasFeedback
                  >
                    <Input.TextArea allowClear autoSize={{ minRows: 3, maxRows: 5 }} />

                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="indice_regulacion"
                    label="Indice de la regulación"
                    hasFeedback
                  >
                    <Input.TextArea allowClear autoSize={{ minRows: 3, maxRows: 5 }} />

                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="objeto_regulacion"
                    label="Objeto de la regulación"
                    hasFeedback
                  >
                    <Input.TextArea allowClear autoSize={{ minRows: 3, maxRows: 5 }} />

                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="otras_regulaciones_vinculadas"
                    label="Otras regulaciones"
                    hasFeedback
                  >
                    <Input.TextArea allowClear autoSize={{ minRows: 3, maxRows: 5 }} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="nombre_tramites_relacionados"
                    label="Nombre de trámites relacionados"
                    hasFeedback
                    tooltip="Nombre de trámites y servicios relacionados con la regulación"
                  >
                    <Input.TextArea allowClear autoSize={{ minRows: 3, maxRows: 5 }} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    name="identificacion_fundamentos"
                    label="Identificación de fundamentos juridicos"
                    hasFeedback
                  >
                    <Input.TextArea allowClear autoSize={{ minRows: 3, maxRows: 5 }} />
                  </Form.Item>
                </Col>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          </Card>
        )}

        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`Nombre de Ordenamiento ${form.getFieldValue('descripcion')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
};
export default NombresDeOrdenamiento;
