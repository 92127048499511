import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Card,
  Tabs,
  message,
} from 'antd';
import Table from 'components/Table';
import API from 'utils/api';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import LadaNumero from 'components/LadaNumero';
import Select from 'components/Select';
import fetchSome from 'utils/fetchSome';
import Direccion from 'components/Direccion';
import formattedValues, { discartNullValues, formatReceived } from 'utils/formatValues';
import { isAuthorized, isMutable } from 'utils/estadosGlobales';
import { InputSearchPro } from 'components/InputSearch';
import { onError, onSuccess } from 'utils/handlers';
import keyGenerator from 'utils/keyGenerator';
import NumericInput from 'components/NumericInput';
import HorariosUR from 'components/HorariosUR';
import BusquedaAvanzadaUsers from 'components/BusquedaAvanzadaUsers';
import { toInteger } from 'utils/normalizers';
import Ventanillas from './Ventanillas';
// import { isAdmin } from 'utils/permission';

const { TabPane } = Tabs;

const UnidadesResponsables = () => {
  const [form] = Form.useForm();
  const [formCitas] = Form.useForm();
  const atiende_cita = Form.useWatch('atiende_cita', formCitas);
  const departamento = Form.useWatch('unidad_responsable', form);
  const tiposPresupuestos = useSelector(({ catalogs }) => catalogs.tiposPresupuestos);
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const collapsedSideBar = useSelector(({ app }) => app.collapsedSidebar);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [visibleAdvanced, setvisibleAdvanced] = useState(false);
  const [data, setData] = useState([]);
  const [currentTabKey, setCurrentTabKey] = useState('general');

  const [unidadesOperativas, setUnidadesOperativas] = useState([]);
  const [unidadesResponsables, setUnidadesResponsables] = useState([]);
  const [jefeFound, setJefeFound] = useState([]);
  const [tipoOficina, setTipoOficina] = useState([]);
  const [unidadesDePlazo, setUnidadesDePlazo] = useState([]);

  const [mutable, setMutable] = useState(true);
  const [formAddress, setFormAddress] = useState();
  const [folio, setFolio] = useState([]);
  const [current, setCurrent] = useState({ estados_globales: 1 });

  const currentURI = window.location.href.split('/').pop();
  const completeURI = currentURI === 'oficina-de-atencion' ? '/configuracion/caar' : `/configuracion/${currentURI}`;

  const basicKeys = ['general', 'citas', 'horarios'];
  const complexForms = [{ key: 'direccion', formInstance: formAddress }];

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    formCitas.resetFields();
    setSelectedRowKeys([]);
    setMutable(true);
    setCurrent(null);
    setCurrentTabKey('general');
  };

  const resetAndValidateJefe = () => {
    setJefeFound([]);
    if (currentURI === 'dependencias') {
      form.resetFields(['director_de_la_dependencia']);
      form.validateFields(['director_de_la_dependencia']);
    }
  };

  const fetchData = async () => {
    try {
      const authorizedFilter = (e) => e.estados_globales === 4;
      const resDependencias = await API.get('/configuracion/dependencias/');
      if (resDependencias?.status === 200) {
        setUnidadesOperativas(resDependencias.data.filter(authorizedFilter));
        if (currentURI === 'dependencias') {
          setData(resDependencias.data);
        }
      }
      if (currentURI !== 'dependencias') {
        const resResponsables = await API.get('/configuracion/departamentos/');
        if (resResponsables?.status === 200) {
          setUnidadesResponsables(resResponsables.data.filter(authorizedFilter));
          if (currentURI === 'departamentos') {
            setData(resResponsables.data);
          }
        }
      }
      if (currentURI === 'oficina-de-atencion') {
        const resCaars = await API.get('/configuracion/caar/');
        if (resCaars?.status === 200) {
          setData(resCaars.data);
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resTipoOficina = await API.get('/tramites/tipos-de-oficinas-de-atencion/');
      setTipoOficina(resTipoOficina.data || []);
      if (!tiposPresupuestos?.length) {
        await fetchSome('configuracion/tipos-de-presupuestos/', 'SET_TIPOS_DE_PRESUPUESTOS');
      }
      const resFolio = await API.get('/configuracion/configuraciones-de-segmentos-de-folios/');
      setFolio(resFolio.data || []);
      const resUnidadesDePlazo = await API.get('/catalogos/unidades-de-plazo-cita/');
      setUnidadesDePlazo(resUnidadesDePlazo.data || []);
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onClickEdit = async (responseData = null) => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const match = responseData || data.find((i) => i.id === key);
      match.dependencia = match.dependencia?.id || match.dependencia;
      match.departamento = match.departamento?.id || match.departamento;
      setMutable(isMutable(match.estados_globales));
      if (currentURI === 'departamentos') {
        if (match?.director_del_departamento) {
          const res = await API.get(`usuarios/usuarios/${match.director_del_departamento}`);
          const userFound = {
            ...res.data,
            nombre_completo: `${res.data?.first_name} ${res.data?.last_name
              || ''} ${res.data?.second_last_name || ''}`,
          };
          form.setFieldsValue({
            director_del_departamento: res.data.id,
          });
          setJefeFound([userFound]);
        } else {
          setJefeFound([]);
        }
      }
      if (currentURI === 'oficina-de-atencion') {
        if (match?.jefe_de_recaudadora) {
          const res = await API.get(`usuarios/usuarios/${match.jefe_de_recaudadora}`);
          const userFound = {
            ...res.data,
            nombre_completo: `${res.data?.first_name} ${res.data?.last_name
              || ''} ${res.data?.second_last_name || ''}`,
          };
          form.setFieldsValue({ jefe_de_recaudadora: res.data.id });
          setJefeFound([userFound]);
        } else {
          setJefeFound([]);
        }
      }
      if (currentURI === 'dependencias') {
        if (match?.director_de_la_dependencia) {
          const res = await API.get(`usuarios/usuarios/${match.director_de_la_dependencia}`);
          const userFound = {
            ...res.data,
            nombre_completo: `${res.data?.first_name} ${res.data?.last_name
              || ''} ${res.data?.second_last_name || ''}`,
          };
          form.setFieldsValue({
            director_de_la_dependencia: res.data.id,
          });
          setJefeFound([userFound]);
        } else {
          setJefeFound([]);
        }
      }
      const values = discartNullValues(formatReceived(match));
      if (currentURI === 'departamentos') {
        values.unidad_operativa = values.unidad_operativa.id;
      }
      if (currentURI === 'oficina-de-atencion') {
        values.unidad_responsable = values.unidad_responsable.id;
      }
      form.setFieldsValue(values);
      formCitas.setFieldValue(values);
      setVisible(true);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onFinish = async (_values, _continue = false) => {
    try {
      setLoading(true);
      await form.validateFields();
      await formCitas.validateFields();
      const values = {
        ...form.getFieldsValue(),
        ...formCitas.getFieldsValue(),
      };
      if (!selectedRowKeys.length) {
        const response = await API.post(completeURI, formattedValues(values));
        if (response?.status === 201) {
          if (_continue) {
            onSuccess(response, 'Agregado correctamente');
            await fetchData();
            setSelectedRowKeys([response.data.id]);
            onClickEdit(response.data);
          } else {
            onSuccess(response, 'Agregado correctamente');
            onCancel();
            await fetchData();
          }
          setLoading(false);
          return response.data;
        }
      } else {
        delete values.clave;
        const [rowKey] = selectedRowKeys;
        const response = await API.patch(`${completeURI}/${rowKey}/`, formattedValues(values));
        if (response?.status === 200) {
          if (_continue) {
            await fetchData();
            onClickEdit(response.data);
          } else {
            onSuccess(response, 'Actualizado correctamente');
            onCancel();
            await fetchData();
          }
          setLoading(false);
          return response.data;
        }
      }
      setLoading(false);
      return null;
    } catch (err) {
      onError(err, setLoading);
    }
    return null;
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      if (key) {
        const response = await API.delete(`${completeURI}/${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          onCancel();
          setVisibleAlert(false);
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
    clave: [
      requiredRule,
      {
        validator: async (_, val) => {
          const value = val?.toString();
          const { unidad_operativa, unidad_responsable } = form.getFieldsValue();
          if (value && !selectedRowKeys.length) {
            if (unidad_operativa) {
              let { clave } = unidadesOperativas.find((e) => e.id === unidad_operativa) || {};
              clave = clave?.toString();
              if (value.length <= clave?.length) {
                throw new Error('La clave debe tener una longitud mayor a la clave de la Dependencia');
              } else if (value.substr(0, clave.length) !== clave) {
                throw new Error('La clave debe comenzar con la clave de la Dependencia');
              }
            } else if (unidad_responsable) {
              let { clave } = unidadesResponsables.find((e) => e.id === unidad_responsable) || {};
              clave = clave?.toString();
              if (value.length <= clave?.length) {
                throw new Error('La clave debe tener una longitud mayor a la clave del Departamento');
              } else if (value.substr(0, clave.length) !== clave) {
                throw new Error('La clave debe comenzar con la clave del Departamento');
              }
            }
          }
        },
      },
      {
        validator: async (rule, val) => {
          const clone = [...data];
          const match = clone.find((e) => e.clave === val);
          if (match && !selectedRowKeys.length) {
            throw new Error('La clave ya se ha dado de alta');
          }
        },
      },
    ],
    correo_electronico: [
      {
        type: 'email',
        message: 'Ingrese un correo electrónico válido',
      },
    ],
    portal_web: [
      {
        type: 'url',
        message: 'Ingrese una URL válida',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 100,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 250,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 300,
      render: (val) => (estadosGlobales.find((item) => item.id === val)?.descripcion),
    },
  ];

  const handleOnRowClick = (record) => {
    setCurrent(record);
    setSelectedRowKeys([record.id]);
    const formatted = formatReceived(record);
    if (currentURI === 'departamentos') {
      form.setFieldsValue({
        ...formatted,
        unidad_operativa: formatted.unidad_operativa.id,
      });
    }
    if (currentURI === 'oficina-de-atencion') {
      form.setFieldsValue({
        ...formatted,
        unidad_responsable: formatted.unidad_responsable.id,
      });
      formCitas.setFieldsValue(formatted);
    }
    if (currentURI === 'dependencias') {
      form.setFieldsValue(formatted);
    }
  };

  const onClickAdd = () => {
    onCancel();
    if (currentURI === 'dependencias') {
      keyGenerator(data, form);
    }
    setVisible(true);
  };

  const onClickDelete = () => {
    const [key] = selectedRowKeys;
    const match = data.find((i) => i.id === key);
    if (match.estados_globales < 4) {
      setVisibleAlert(true);
    } else {
      const status = match.estados_globales === 4 ? 'autorizados' : 'cancelados';
      message.warn(`No se pueden eliminar registros ${status}`);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const getTitle = (pluralize = false) => {
    let output = '';
    switch (currentURI) {
      case 'dependencias':
        output = pluralize ? 'Dependencias' : 'Dependencia';
        break;
      case 'departamentos':
        output = pluralize ? 'Departamentos' : 'Departamento';
        break;
      default:
        output = pluralize ? 'Oficinas de Atención' : 'Oficina de Atención';
        break;
    }

    return output;
  };

  const showMsg = (key) => {
    message.info({
      content: (
        <>
          <br />
          <Row style={{ width: '100%' }}>
            Los cambios que hayan sido realizados serán descartados.
          </Row>
          <Row align="middle" style={{ width: '100%' }}>
            ¿Desea continuar?
            <Button
              type="link"
              onClick={() => {
                setCurrentTabKey(key);
                message.destroy();
                onClickEdit();
              }}
            >
              Si
            </Button>
            <Button
              type="link"
              onClick={() => message.destroy()}
            >
              No
            </Button>
          </Row>
        </>
      ),
      duration: 0,
    });
  };

  const onChangeTabKey = (key) => {
    const fromBasic = basicKeys.includes(currentTabKey);
    const toBasic = basicKeys.includes(key);
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (!match) {
      if (fromBasic && toBasic) {
        setCurrentTabKey(key);
      } else if (fromBasic) {
        showMsg(key);
      } else {
        setCurrentTabKey(key);
      }
    } else {
      match.formInstance.onFinishHandler(key, true);
    }
  };

  const busquedaJefe = async () => {
    try {
      setLoading(true);
      await form.validateFields(['q_jefe']);
      const { q_jefe: email } = form.getFieldsValue();
      if (email) {
        const _usuarios = await API.get('usuarios/usuarios', {
          params: {
            email,
          },
        });
        if (_usuarios?.status === 200 && _usuarios.data.length === 1) {
          if (currentURI === 'dependencias') {
            form.setFieldsValue({
              director_de_la_dependencia: _usuarios.data[0].id,
            });
            const results = _usuarios.data.map((e) => ({
              ...e,
              nombre_completo: `${e?.first_name} ${e?.last_name || ''} ${e?.second_last_name || ''}`,
            }));
            setJefeFound([results[0]]);
          }
          if (currentURI === 'departamentos') {
            form.setFieldsValue({
              director_del_departamento: _usuarios.data[0].id,
            });
            const results = _usuarios.data.map((e) => ({
              ...e,
              nombre_completo: `${e?.first_name} ${e?.last_name || ''} ${e?.second_last_name || ''}`,
            }));
            setJefeFound([results[0]]);
          }
          if (currentURI === 'oficina-de-atencion') {
            form.setFieldsValue({
              jefe_de_recaudadora: _usuarios.data[0].id,
            });
            const results = _usuarios.data.map((e) => ({
              ...e,
              nombre_completo: `${e?.first_name} ${e?.last_name || ''} ${e?.second_last_name || ''}`,
            }));
            setJefeFound([results[0]]);
          }
        } else if (!_usuarios.data.length) {
          message.info('No se encontraron coincidencias');
          setJefeFound([]);
        } else {
          message
            .info('Se encontraron demasiadas coincidencias, sea mas especifico con su busqueda');
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnFinish = (vals, _continue = false) => {
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (match) {
      match.formInstance.onFinishHandler(null, _continue);
    }
    return onFinish(null, _continue);
  };

  const onChange = (id) => {
    if (id) {
      const parent = (currentURI === 'departamentos' ? unidadesOperativas : unidadesResponsables)
        .find((e) => e.id === id);
      keyGenerator(data, form, { filter: { parent } });
    } else {
      form.resetFields(['id']);
    }
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit: () => onClickEdit(null),
              onClickDelete,
            }}
            rowKey="id"
          />
        ) : (
          <Card
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} ${getTitle()}`}
            extra={(
              <FormSubmitControls
                onFinish={basicKeys
                  .concat(complexForms.map((e) => e.key))
                  .includes(currentTabKey)
                  ? handleOnFinish : null}
                onCancel={onCancel}
                allowSaveAndContinue
                allowAuthorize
                allowCancel
                mutable={mutable}
                baseURI={completeURI}
                selectedRowKeys={selectedRowKeys}
                callback={(estados_globales) => {
                  const [key] = selectedRowKeys;
                  const normalized = [...data]
                    .map((e) => (e.id === key ? { ...e, estados_globales } : e));
                  setMutable(false);
                  setData(normalized);
                  const match = normalized.find((e) => e.id === key);
                  onClickEdit(match);
                }}
                allowUnauthorize
                authorized={isAuthorized(current)}
              />
            )}
            bordered={false}
            bodyStyle={{ padding: 5, overflow: 'hidden' }}
          >
            <Tabs
              onChange={mutable ? onChangeTabKey : setCurrentTabKey}
              activeKey={currentTabKey}
              type="card"
            >
              <TabPane tab="General" key="general" forceRender>
                <Form
                  layout="vertical"
                  form={form}
                  name="general"
                  onFinish={mutable ? onFinish : null}
                  scrollToFirstError
                  initialValues={{ estados_globales: 1 }}
                >
                  <Row gutter={10}>
                    {currentURI === 'departamentos' && (
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="unidad_operativa"
                          rules={rules.required}
                          label="Dependencia"
                        >
                          <Select
                            keyLabelRender
                            dataSource={unidadesOperativas}
                            disabled={!mutable || !!selectedRowKeys.length}
                            onChange={onChange}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {currentURI === 'oficina-de-atencion' && (
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="unidad_responsable"
                          rules={rules.required}
                          label="Departamento"
                        >
                          <Select
                            keyLabelRender
                            dataSource={unidadesResponsables}
                            disabled={!mutable || !!selectedRowKeys.length}
                            onChange={onChange}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="clave"
                        rules={rules.clave}
                        label="Clave"
                        normalize={(val) => val?.toUpperCase()}
                        tooltip={currentURI !== 'dependencias' ? 'Puede que en algunas ocasiones no se genere automáticamente la clave, de ser así se deberá capturar manualmente empezando por la clave de su dependencia o departamento padre' : null}
                      >
                        <Input
                          className="upper"
                          disabled={!mutable || !!selectedRowKeys.length}
                          allowClear
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="descripcion"
                        rules={rules.required}
                        label="Descripción"
                      >
                        <Input allowClear disabled={!mutable} />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="correo_electronico"
                        rules={rules.correo_electronico}
                        label="Correo Electrónico"
                      >
                        <Input allowClear disabled={!mutable} />
                      </Form.Item>
                    </Col>
                    {currentURI === 'dependencias' && (
                      <InputSearchPro
                        label={collapsedSideBar ? 'Director de la Dependencia' : 'Director Dep.'}
                        tooltip={collapsedSideBar ? 'Búsqueda por Email'
                          : 'Búsqueda de Director de la Dependencia por Email'}
                        name="q_jefe"
                        rules={rules.email}
                        onPressEnter={() => busquedaJefe()}
                        onClickAdvanced={() => setvisibleAdvanced(true)}
                        disabled={!mutable}
                        breakPoints={{
                          xs: 24,
                          sm: 24,
                          md: 24,
                          lg: 16,
                        }}
                        inputProps={{
                          onPressEnter: () => busquedaJefe(),
                          onChange: ({ target: { value } }) => {
                            if (!value) {
                              resetAndValidateJefe();
                            }
                          },
                        }}
                        resultProps={{
                          name: 'director_de_la_dependencia',
                          label: 'Director de la Dependencia',
                          dataSource: jefeFound,
                          labelProp: 'nombre_completo',
                          onClear: resetAndValidateJefe,
                          breakPoints: {
                            md: 16,
                          },
                        }}
                      />
                    )}
                    {currentURI === 'departamentos' && (
                      <InputSearchPro
                        label={collapsedSideBar ? 'Director del Departamento' : 'Director'}
                        tooltip={collapsedSideBar ? 'Búsqueda por Email'
                          : 'Búsqueda de Director del Departamento por Email'}
                        name="q_jefe"
                        rules={rules.email}
                        onPressEnter={() => busquedaJefe()}
                        onClickAdvanced={() => setvisibleAdvanced(true)}
                        disabled={!mutable}
                        breakPoints={{
                          xs: 24,
                          sm: 24,
                          md: 24,
                          lg: 16,
                        }}
                        inputProps={{
                          onPressEnter: () => busquedaJefe(),
                          onChange: ({ target: { value } }) => {
                            if (!value) {
                              resetAndValidateJefe();
                            }
                          },
                        }}
                        resultProps={{
                          name: 'director_del_departamento',
                          label: 'Director del Departamento',
                          dataSource: jefeFound,
                          labelProp: 'nombre_completo',
                          onClear: resetAndValidateJefe,
                          breakPoints: {
                            md: 16,
                          },
                        }}
                      />
                    )}
                    {currentURI === 'oficina-de-atencion' && (
                      <InputSearchPro
                        label={collapsedSideBar ? 'Jefe de Oficina' : 'Jefe Oficina'}
                        tooltip={collapsedSideBar ? 'Busqueda por Email'
                          : 'Búsqueda del Jefe de Oficina por Email'}
                        name="q_jefe"
                        rules={rules.email}
                        onPressEnter={() => busquedaJefe()}
                        onClickAdvanced={() => setvisibleAdvanced(true)}
                        disabled={!mutable}
                        breakPoints={{
                          xs: 24,
                          sm: 24,
                          md: 24,
                          lg: 12,
                        }}
                        inputProps={{
                          onPressEnter: () => busquedaJefe(),
                          onChange: ({ target: { value } }) => {
                            if (!value) {
                              resetAndValidateJefe();
                            }
                          },
                        }}
                        resultProps={{
                          name: 'jefe_de_recaudadora',
                          label: 'Jefe de Oficina',
                          dataSource: jefeFound,
                          labelProp: 'nombre_completo',
                          onClear: resetAndValidateJefe,
                          breakPoints: {
                            xs: 24,
                            sm: 24,
                            md: 24,
                            lg: 12,
                          },
                        }}
                      />

                    )}
                    <LadaNumero
                      form={form}
                      disabled={!mutable}
                      breakPoints={{
                        xs: 24,
                        sm: 24,
                        md: 8,
                      }}
                    />
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="portal_web"
                        rules={rules.portal_web}
                        label="Portal Web"
                      >
                        <Input allowClear disabled={!mutable} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="tiene_equipo_de_computo"
                        label="¿Tiene Equipo de Cómputo?"
                      >
                        <Select trueFalse disabled={!mutable} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="tiene_conexion_a_internet"
                        label="¿Tiene Conexión a Internet?"
                      >
                        <Select trueFalse disabled={!mutable} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="tiene_area_de_espera"
                        label="¿Tiene Área De Espera?"
                      >
                        <Select trueFalse disabled={!mutable} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <NumericInput
                        name="extension"
                        label="Extensión"
                        maxLength={5}
                        disabled={!mutable}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="tipo_de_oficina"
                        label="Tipo de Oficina"
                      >
                        <Select dataSource={tipoOficina} disabled={!mutable} />
                      </Form.Item>
                    </Col>
                    {currentURI !== 'oficina-de-atencion' && (
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name={currentURI === 'departamentos' ? 'foliador_de_tramites' : 'foliador'}
                          rules={rules.required}
                          label="Foliador"
                        >
                          <Select dataSource={folio} disabled={!mutable} />
                        </Form.Item>
                      </Col>
                    )}
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="estados_globales"
                        label="Estado"
                        rules={rules.required}
                      >
                        <Select
                          dataSource={estadosGlobales}
                          disabled={(!selectedRowKeys.length || !mutable)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </TabPane>
              <TabPane
                tab="Dirección"
                key="direccion"
                forceRender
                disabled={!selectedRowKeys.length}
              >
                <Direccion
                  currentURI={completeURI}
                  parentID={selectedRowKeys[0]}
                  setLoading={setLoading}
                  allowMap
                  setFormAddress={setFormAddress}
                  setCurrentTabKey={setCurrentTabKey}
                  addressPropName="direccion"
                  callback={(values, _continue) => {
                    if (!_continue) {
                      onCancel();
                    } else {
                      const [key] = selectedRowKeys;
                      const clone = [...data];
                      const mappedData = clone.map((e) => (e.id === key
                        ? ({ ...e, direccion: values?.id }) : e));
                      setData([...mappedData]);
                    }
                  }}
                  mutable={mutable}
                />
              </TabPane>
              <TabPane
                tab="Horarios"
                key="horarios"
                disabled={!selectedRowKeys.length}
                forceRender
              >
                <HorariosUR
                  completeURI={completeURI}
                  selectedRowKey={selectedRowKeys}
                  mutable={mutable}
                />
              </TabPane>
              {currentURI === 'oficina-de-atencion' && (
                <TabPane
                  tab="Citas"
                  key="citas"
                  forceRender
                >
                  <Form
                    layout="vertical"
                    form={formCitas}
                    name="citas_general"
                    onFinish={mutable ? onFinish : null}
                    scrollToFirstError
                    initialValues={{ atiende_cita: false }}
                  >
                    <Row gutter={10}>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="atiende_cita"
                          rules={rules.required}
                          label="¿Atiende cita?"
                        >
                          <Select trueFalse disabled={!mutable} />
                        </Form.Item>
                      </Col>
                      {atiende_cita && (
                        <>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              name="horario_cantidad"
                              rules={rules.required}
                              label="Rango de tiempo de cita"
                              normalize={(val) => toInteger(val)}
                            >
                              <Input allowClear disabled={!mutable} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              name="horario_unidad_de_plazo"
                              rules={rules.required}
                              label="Tipo"
                            >
                              <Select dataSource={unidadesDePlazo} />
                            </Form.Item>
                          </Col>
                        </>
                      )}
                    </Row>
                    <Form.Item hidden>
                      <Button htmlType="submit" />
                    </Form.Item>
                  </Form>
                </TabPane>
              )}
              {currentURI === 'oficina-de-atencion' && (
                <TabPane
                  tab="Ventanillas"
                  key="ventanillas"
                  disabled={!selectedRowKeys.length}
                  forceRender
                >
                  <Ventanillas
                    oficina={selectedRowKeys[0]}
                    mutable={mutable}
                    departamento={departamento}
                  />
                </TabPane>
              )}
            </Tabs>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`${getTitle()} ${form.getFieldValue('descripcion')}`}
          loading={loading}
        />
        <BusquedaAvanzadaUsers
          visible={visibleAdvanced}
          setVisible={setvisibleAdvanced}
          callback={(selected) => {
            const _selected = {
              ...selected,
              nombre_completo: `${selected?.first_name} ${selected?.last_name
                || ''} ${selected?.second_last_name || ''}`,
            };
            setJefeFound([_selected]);
            if (currentURI === 'dependencias') {
              form.setFieldsValue({
                director_de_la_dependencia: selected.id,
              });
            }
            if (currentURI === 'departamentos') {
              form.setFieldsValue({
                director_del_departamento: selected.id,
              });
            }
            if (currentURI === 'oficina-de-atencion') {
              form.setFieldsValue({
                jefe_de_recaudadora: selected.id,
              });
            }
          }}
          dataExterno={[]}
          setDataExterno={() => { }}
        />
      </Spin>
    </Row>
  );
};

export default UnidadesResponsables;
