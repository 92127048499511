import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Card,
} from 'antd';

import { useSelector } from 'react-redux';
import Table from 'components/Table';
import API from 'utils/api';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import PlainTransfer from 'components/PlainTransfer';
import { SET_PERMISOS } from 'store/reducers/catalogs/index';
import fetchSome from 'utils/fetchSome';
import { onError, onSuccess } from 'utils/handlers';

const baseURI = '/catalogos/grupos/';

const Roles = () => {
  const permisos = useSelector(({ catalogs }) => catalogs.permisos);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      if (res?.status === 200) {
        const formated = res.data.map((item) => ({
          ...item,
          key: item.id,
          name: item.name.split('-').shift().trim(),
        }));
        setData(formated);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    const fetchAll = async () => {
      setLoading(true);
      if (!permisos?.length) {
        await fetchSome('usuarios/permisos/', SET_PERMISOS);
      }
      await fetchData();
    };

    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [rowKey] = selectedRowKeys;
        const response = await API.delete(`/catalogos/grupos/${rowKey}`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          onCancel();
          setVisibleAlert(false);
          await fetchData();
        }
      }
      setLoading(true);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Rol',
      dataIndex: 'name',
      key: 'name',
      width: '100%',
    },
  ];

  return (
    <Row align="center" justify="center" className="container">
      <Spin
        tip="Cargando..."
        spinning={loading}
      >
        {!visible
          ? (
            <Table
              cols={columns}
              mobileCols={columns}
              data={data}
              rowSelection={rowSelection}
              handleOnRowClick={handleOnRowClick}
              controls={{
                onClickAdd,
                onClickEdit,
                onClickDelete,
              }}
            />
          )
          : (
            <Card
              className="form-container"
              title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Rol`}
              extra={(
                <FormSubmitControls onFinish={onFinish} onCancel={onCancel} />
                  )}
              bordered={false}
            >
              <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                scrollToFirstError
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="name"
                      rules={rules.required}
                      label="Nombre"
                      hasFeedback
                    >
                      <Input allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <PlainTransfer
                      dataSource={permisos}
                      label="Permisos"
                      formItemName="permissions"
                      form={form}
                      filterProp="name"
                    />
                  </Col>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Row>
              </Form>
            </Card>
          )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`Rol ${form.getFieldValue('name')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
};

export default Roles;
