import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Form,
  Button,
  Row,
  Col,
  message,
  Input,
} from 'antd';

import API from 'utils/api';
import { onError } from 'utils/handlers';
import Select from 'components/Select';
import LadaNumero from '../../LadaNumero';

const currentURI = '/catalogos/entidades/configuracion/';

const Configurations = ({
  setLoading,
  callback,
  setCurrentTabKey,
  setForm,
  disabled,
}) => {
  const [form] = Form.useForm();
  const [monedas, setMonedas] = React.useState([]);
  const config = useSelector(({ auth }) => auth.entidad);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (config) {
        const {
          lada,
          moneda,
          utilizar_redondeo,
        } = config;
        const data = {
          lada,
          moneda: moneda.id,
          utilizar_redondeo,
        };
        form.setFieldsValue({ ...data });
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onFinish = async (_values, _continue = true) => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const res = await API.post(currentURI, values);
      if (res?.status === 200) {
        message.info('Información actualizada correctamente');
        setTimeout(async () => {
          callback(res.data, _continue);
          if (_continue) {
            await fetchData();
          }
        });
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const showAddressMsg = (tabKey) => {
    message.info({
      content: (
        <>
          <br />
          <Row style={{ width: '100%' }}>
            Los cambios que hayan sido realizados serán descartados.
          </Row>
          <Row align="middle" style={{ width: '100%' }}>
            ¿Desea continuar?
            <Button
              type="link"
              onClick={() => {
                setCurrentTabKey(tabKey);
                message.destroy();
                fetchData();
              }}
            >
              Si
            </Button>
            <Button
              type="link"
              onClick={() => message.destroy()}
            >
              No
            </Button>
          </Row>
        </>
      ),
    });
  };

  const onFinishHandler = async (tabKey = null, _continue = true) => {
    try {
      if (tabKey) {
        showAddressMsg(tabKey);
      } else {
        await onFinish(null, _continue);
      }
    } catch (err) {
      onError(err);
    }
  };

  useEffect(() => {
    const fetchAll = async () => {
      try {
        const resMonedas = await API.get('/configuracion/monedas/');
        setMonedas(resMonedas.data.filter((e) => !e.calculable));

        await fetchData();
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();
    setTimeout(() => {
      form.onFinishHandler = onFinishHandler;
      setForm(form);
    });
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
    lada: [requiredRule],
  };

  return (
    <>
      <Col span={24}>
        <Form
          form={form}
          name="configurations"
          layout="vertical"
          onFinish={() => onFinish()}
        >
          <Row gutter={10}>
            <Form.Item name="id" hidden>
              <Input allowClear />
            </Form.Item>
            <LadaNumero
              rules={rules}
              disabled={disabled}
              ladaOnly
              form={form}
            />
            <Col
              xs={24}
              sm={24}
              md={8}
            >
              <Form.Item
                rules={rules.required}
                name="moneda"
                label="Moneda"
                hasFeedback
              >
                <Select
                  disabled={disabled}
                  dataSource={monedas}
                  keyLabelRender
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="utilizar_redondeo"
                hasFeedback
                rules={rules.required}
                label="¿Usar redondeo?"
              >
                <Select disabled={disabled} trueFalse />
              </Form.Item>
            </Col>
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Row>
        </Form>
      </Col>
    </>
  );
};

Configurations.propTypes = {
  callback: PropTypes.func,
  setLoading: PropTypes.func.isRequired,
  setForm: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  setCurrentTabKey: PropTypes.func.isRequired,
};

Configurations.defaultProps = {
  callback: () => { },
  disabled: false,
};

export default Configurations;
