// Constants
export const SET_LADAS = 'CATALOGS__SET_LADAS';
export const SET_ESTADOS_GLOBALES = 'CATALOGS__SET_ESTADOS_GLOBALES';
export const SET_GENEROS = 'CATALOGS__SET_GENEROS';
export const SET_ESTADOS_CIVILES = 'CATALOGS__SET_ESTADOS_CIVILES';
export const SET_METODOS_DE_PAGO = 'CATALOGS__SET_METODOS_DE_PAGO';
export const SET_TIPOS_VIALIDADES = 'CATALOGS__SET_TIPOS_VIALIDADES';
export const SET_CUENTAS_CONTABLES = 'CATALOGS__SET_CUENTAS_CONTABLES';
export const SET_CONFIG_MASCARA_CONTABLE = 'CATALOGS__SET_CONFIG_MASCARA_CONTABLE';
export const SET_TIPOS_PODER = 'CATALOGS__SET_TIPOS_PODER';
export const SET_PERIODOS = 'CATALOGS__SET_PERIODOS';
export const SET_TIPOS_PRESUPUESTOS = 'CATALOGS__SET_TIPOS_PRESUPUESTOS';
export const SET_COG = 'CATALOGS__SET_COG';
export const SET_SERVICIOS_DEL_VEHICULO = 'CATALOGS__SET_SERVICIOS_DEL_VEHICULO';
export const SET_ESTADOS_DEL_VEHICULO = 'CATALOGS__SET_ESTADOS_DEL_VEHICULO';
export const SET_MARCAS_DE_VEHICULOS = 'CATALOGS__SET_MARCAS_DE_VEHICULOS';
export const SET_LINEAS_VEHICULARES = 'CATALOGS__SET_LINEAS_VEHICULARES';
export const SET_TIPOS_DE_VEHICULOS = 'CATALOGS__SET_TIPOS_DE_VEHICULOS';
export const SET_CLASES_DE_VEHICULOS = 'CATALOGS__SET_CLASES_DE_VEHICULOS';
export const SET_TIPOS_DE_ESTABLECIMIENTO = 'CATALOGS__SET_TIPOS_DE_ESTABLECIMIENTO';
export const SET_TIPOS_DE_DATO = 'CATALOGS__SET_TIPOS_DE_DATO';
export const SET_TIPOS_DE_PRESUPUESTOS = 'CATALOGS__SET_TIPOS_DE_PRESUPUESTOS';
export const SET_ESTADOS_DE_LOS_FUNCIONARIOS = 'CATALOGS__SET_ESTADOS_DE_LOS_FUNCIONARIOS';
export const SET_FUENTES_DE_FINANCIAMIENTO = 'CATALOGS__SET_FUENTES_DE_FINANCIAMIENTO';
export const SET_FORMAS_DE_PAGO = 'CATALOGS__SET_FORMAS_DE_PAGO';
export const SET_CATEGORIAS_SOLICITUDES = 'CATALOGS__SET_CATEGORIAS_SOLICITUDES';
export const SET_MOTIVOS_SOLICITUD = 'CATALOGS__SET_MOTIVOS_SOLICITUD';
export const SET_SUBCATEGORIAS_SOLICITUDES = 'CATALOGS__SET_SUBCATEGORIAS_SOLICITUDES';
export const SET_ESTADOS_SOLICITUD = 'CATALOGS__SET_ESTADOS_SOLICITUD';
export const SET_SUBCATEGORIAS = 'CATALOGS__SET_SUBCATEGORIAS';
export const SET_PERMISOS = 'CATALOGS__SET_PERMISOS';
export const SET_DIAS = 'CATALOGS__SET_DIAS';
export const SET_BANCOS = 'CATALOGS__SET_BANCOS';
export const SET_CATEGORIAS_DE_REQUISITOS = 'CATALOGS__SET_CATEGORIAS_DE_REQUISITOS';
export const SET_CLASIFICACIONES_DE_COMERCIO = 'CATALOGS__SET_CLASIFICACIONES_DE_COMERCIO';
export const SET_SECTORES_EMPRESARIALES = 'CATALOGS__SET_SECTORES_EMPRESARIALES';
export const SET_ESTADOS_DEL_NEGOCIO = 'CATALOGS__SET_ESTADOS_DEL_NEGOCIO';
export const SET_PADRONES = 'CATALOGS__SET_PADRONES';
export const SET_CONTENT_TYPES_PADRONES = 'CATALOGS__SET_CONTENT_TYPES_PADRONES';
export const CLEAR = 'CATALOGS__CLEAR';

const initialState = {
  ladas: [],
  estadosGlobales: [],
  tiposVialidades: [],
  cuentasContables: [],
  tiposDePoder: [],
  configMascaraContable: null,
  metodosDePago: [],
  generos: [],
  cog: [],
  tiposDeDato: [],
  sectoresEmpresariales: [],
  clasificacionesDeComercio: [],
  estadosDelNegocio: [],
  estadosDeLosFuncionarios: [],
  fuentesDeFinanciamiento: [],
  formasDePago: [],
  categoriasSolicitudes: [],
  motivosSolicitudes: [],
  subcategoriasSolicitudes: [],
  estadosSolicitud: [],
  subcategorias: [],
  tiposPresupuestos: [],
  estadosCiviles: [],
  dias: [],
  padrones: [],
  categoriasDeRequisitos: [],
  serviciosDelVehiculo: [],
  estadosDelVehiculo: [],
  marcasDeVehiculos: [],
  lineasVehiculares: [],
  tiposDeVehiculos: [],
  clasesDeVehiculos: [],
  tiposDeEstablecimiento: [],
  contentTypesPadrones: [],
};
const formatedPayload = (payload) => (Array.isArray(payload) ? payload : []);

// Action handlers
const setPermisos = (state, { payload }) => ({ ...state, permisos: formatedPayload(payload) });
const setLadas = (state, { payload }) => ({ ...state, ladas: payload });
const setEstadosGlobales = (state, { payload }) => ({ ...state, estadosGlobales: payload });
const setTiposVialidades = (state, { payload }) => ({ ...state, tiposVialidades: payload });
const setCuentasContables = (state, { payload }) => ({ ...state, cuentasContables: payload });
const setTiposDePoder = (state, { payload }) => ({ ...state, tiposDePoder: payload });
const setPeriodos = (state, { payload }) => ({ ...state, periodos: payload });
const setGeneros = (state, { payload }) => ({ ...state, generos: payload });
const setPadrones = (state, { payload }) => ({ ...state, padrones: formatedPayload(payload) });

const setMetodosDePago = (state, { payload }) => ({ ...state, metodosDePago: payload });
const setCOG = (state, { payload }) => ({ ...state, cog: payload });
const setFF = (state, { payload }) => ({ ...state, fuentesDeFinanciamiento: payload });
const setFormasDePago = (state, { payload }) => ({ ...state, formasDePago: payload });
const setSubcategorias = (state, { payload }) => ({ ...state, subcategorias: payload });
const setCategoriasDeRequisitos = (state, { payload }) => ({
  ...state,
  categoriasDeRequisitos: formatedPayload(payload),
});
const setConfigMascaraContable = (state, { payload }) => {
  const config = payload.find((c) => c.tipo_de_mascara === 1);
  return ({ ...state, configMascaraContable: config || null });
};
const setTiposPresupuestos = (state, { payload }) => ({
  ...state,
  tiposPresupuestos: formatedPayload(payload),
});

const setCategoriasSolicitudes = (state, { payload }) => ({
  ...state,
  categoriasSolicitudes: payload,
});
const setMotivosSolicitudes = (state, { payload }) => ({
  ...state,
  motivosSolicitudes: payload,
});
const setTiposDeDato = (state, { payload }) => ({
  ...state,
  tiposDeDato: formatedPayload(payload),
});
const setSubcategoriasSolicitudes = (state, { payload }) => ({
  ...state,
  subcategoriasSolicitudes: payload,
});
const setEstadosDeLosFuncionarios = (state, { payload }) => ({
  ...state,
  estadosDeLosFuncionarios: formatedPayload(payload),
});
const setEstadosSolicitud = (state, { payload }) => ({
  ...state,
  estadosSolicitud: payload,
});
const setEstadosCiviles = (state, { payload }) => ({
  ...state,
  estadosCiviles: formatedPayload(payload),
});
const setDias = (state, { payload }) => ({ ...state, dias: formatedPayload(payload) });

const setBancos = (state, { payload }) => ({ ...state, bancos: formatedPayload(payload) });

const setTiposDeEstablecimiento = (state, { payload }) => ({
  ...state,
  tiposDeEstablecimiento: formatedPayload(payload),
});
const setServiciosDelVehiculo = (state, { payload }) => ({
  ...state,
  serviciosDelVehiculo: formatedPayload(payload),
});
const setEstadosDelVehiculo = (state, { payload }) => ({
  ...state,
  estadosDelVehiculo: formatedPayload(payload),
});
const setMarcasDeVehiculos = (state, { payload }) => ({
  ...state,
  marcasDeVehiculos: formatedPayload(payload),
});
const setLineasVehiculares = (state, { payload }) => ({
  ...state,
  lineasVehiculares: formatedPayload(payload),
});
const setTiposDeVehiculos = (state, { payload }) => ({
  ...state,
  tiposDeVehiculos: formatedPayload(payload),
});
const setClasesDeVehiculos = (state, { payload }) => ({
  ...state,
  clasesDeVehiculos: formatedPayload(payload),
});
const setContentTypesPadrones = (state, { payload }) => ({
  ...state,
  contentTypesPadrones: formatedPayload(payload),
});
const clear = () => ({ ...initialState });

const ACTION_HANDLER = {
  [SET_LADAS]: setLadas,
  [SET_ESTADOS_GLOBALES]: setEstadosGlobales,
  [SET_TIPOS_VIALIDADES]: setTiposVialidades,
  [SET_CUENTAS_CONTABLES]: setCuentasContables,
  [SET_CONFIG_MASCARA_CONTABLE]: setConfigMascaraContable,
  [SET_TIPOS_PODER]: setTiposDePoder,
  [SET_PERIODOS]: setPeriodos,
  [SET_TIPOS_PRESUPUESTOS]: setTiposPresupuestos,
  [SET_GENEROS]: setGeneros,
  [SET_METODOS_DE_PAGO]: setMetodosDePago,
  [SET_BANCOS]: setBancos,
  [SET_COG]: setCOG,
  [SET_FUENTES_DE_FINANCIAMIENTO]: setFF,
  [SET_FORMAS_DE_PAGO]: setFormasDePago,
  [SET_CATEGORIAS_SOLICITUDES]: setCategoriasSolicitudes,
  [SET_MOTIVOS_SOLICITUD]: setMotivosSolicitudes,
  [SET_ESTADOS_DE_LOS_FUNCIONARIOS]: setEstadosDeLosFuncionarios,
  [SET_SUBCATEGORIAS_SOLICITUDES]: setSubcategoriasSolicitudes,
  [SET_ESTADOS_SOLICITUD]: setEstadosSolicitud,
  [SET_PERMISOS]: setPermisos,
  [SET_TIPOS_DE_PRESUPUESTOS]: setTiposPresupuestos,
  [SET_SUBCATEGORIAS]: setSubcategorias,
  [SET_ESTADOS_CIVILES]: setEstadosCiviles,
  [SET_TIPOS_DE_DATO]: setTiposDeDato,
  [SET_DIAS]: setDias,
  [SET_PADRONES]: setPadrones,
  [SET_CATEGORIAS_DE_REQUISITOS]: setCategoriasDeRequisitos,
  [SET_TIPOS_DE_ESTABLECIMIENTO]: setTiposDeEstablecimiento,
  [SET_SERVICIOS_DEL_VEHICULO]: setServiciosDelVehiculo,
  [SET_ESTADOS_DEL_VEHICULO]: setEstadosDelVehiculo,
  [SET_MARCAS_DE_VEHICULOS]: setMarcasDeVehiculos,
  [SET_LINEAS_VEHICULARES]: setLineasVehiculares,
  [SET_TIPOS_DE_VEHICULOS]: setTiposDeVehiculos,
  [SET_CLASES_DE_VEHICULOS]: setClasesDeVehiculos,
  [SET_CONTENT_TYPES_PADRONES]: setContentTypesPadrones,
  [CLEAR]: clear,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLER[action.type];
  return handler ? handler(state, action) : state;
};
