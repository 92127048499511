import React, { useState, useEffect } from 'react';
import {
  Row,
  Spin,
  Card,
} from 'antd';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import { onError } from 'utils/handlers';
import styled from 'styled-components';
import Vista from 'views/MiBuzon/MisFichasEnProceso/Vista';

const baseURI = '/tramites/plantillas-de-tramites-indexada/';

const Tramite = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [plantilla, setPlantilla] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [estadosDeValidacionDeFicha, setEstadosDeValidacionDeFicha] = useState([]);
  const [procesos, setProcesos] = useState([]);
  const [subProcesos, setSubProcesos] = useState([]);
  const [opciones, setOpciones] = useState([]);
  const [respuestas, setRespuestas] = useState([]);
  const [subOpciones, setSubOpciones] = useState([]);
  const [subRespuestas, setSubRespuestas] = useState([]);
  const [contenTypesTramites, setContenTypesTramites] = useState([]);

  const sourcesByLvl = {
    1: procesos,
    2: subProcesos,
    3: opciones,
    4: respuestas,
    5: subOpciones,
    6: subRespuestas,
  };

  const fetchData = async (
    page = 1,
    q = null,
    _sourcesByLvl = sourcesByLvl,
    _contenTypesTramites = contenTypesTramites,
  ) => {
    try {
      setLoading(true);
      setCurrentPage(page);
      const _data = (await API.get(baseURI, { params: { ...q, page } })).data;
      _data.results = _data.results.map((_plantilla) => {
        const valuesProceso = {};
        if (_plantilla.content_type_tramite) {
          const keysByLvl = {
            1: 'tipo_de_tramite_proceso',
            2: 'tipo_de_tramite_sub_proceso',
            3: 'tipo_de_tramite_opciones',
            4: 'tipo_de_tramite_respuesta',
            5: 'tipo_de_tramite_sub_opciones',
            6: 'tipo_de_tramite_sub_respuesta',
          };
          let lvl = _contenTypesTramites[_contenTypesTramites
            .findIndex((e) => e.id === _plantilla.content_type_tramite)].nivel;
          let value = _plantilla.object_id_tramite;
          while (lvl) {
            // eslint-disable-next-line no-loop-func
            const match = _sourcesByLvl[lvl].find((e) => e.id === value);
            valuesProceso[keysByLvl[lvl]] = match;
            if (lvl !== 1) {
              value = _sourcesByLvl[lvl - 1]
                // eslint-disable-next-line no-loop-func
                .find((e) => match[keysByLvl[lvl - 1]]?.includes?.(e.id)
                  || match[keysByLvl[lvl - 1]] === e.id).id;
            }
            lvl -= 1;
          }
        }
        return {
          ..._plantilla,
          ...valuesProceso,
          unidad_operativa: _plantilla.departamentos[0]?.unidad_operativa,
        };
      });
      setData(_data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    const fetchAll = async () => {
      try {
        setLoading(true);
        const resEstadosValidacionFicha = await API.get('/tramites/estados-de-validacion-de-fichas/');
        setEstadosDeValidacionDeFicha(resEstadosValidacionFicha.data || []);
        const [
          resProcesos,
          resSubProcesos,
          resOpciones,
          resRespuestas,
          resSubOpciones,
          resSubRespuestas,
          resContenTypesTramites,
        ] = await Promise.all([
          API.get('/tramites/procesos-de-tipo-de-tramite/'),
          API.get('/tramites/subprocesos-de-tipo-de-tramite/'),
          API.get('/tramites/opciones-de-tipo-de-tramite/'),
          API.get('/tramites/respuestas-de-tipo-de-tramite/'),
          API.get('/tramites/subopciones-de-tipo-de-tramite/'),
          API.get('/tramites/subrespuestas-de-tipo-de-tramite/'),
          API.get('/catalogos/content-types-tramites/'),
        ]);

        setProcesos(resProcesos.data);
        setSubProcesos(resSubProcesos.data);
        setOpciones(resOpciones.data);
        setRespuestas(resRespuestas.data);
        setSubOpciones(resSubOpciones.data);
        setSubRespuestas(resSubRespuestas.data);
        setContenTypesTramites(resContenTypesTramites.data);
        const _sourcesByLevel = {
          1: resProcesos.data,
          2: resSubProcesos.data,
          3: resOpciones.data,
          4: resRespuestas.data,
          5: resSubOpciones.data,
          6: resSubRespuestas.data,
        };
        await fetchData(undefined, undefined, _sourcesByLevel, resContenTypesTramites.data);
      } catch (error) {
        onError(error, setLoading);
      }
    };
    fetchAll();
    return () => API.source.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setPlantilla(null);
    setSelectedRowKeys([]);
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const columns = [
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 300,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 300,
    },
    {
      titleText: 'Estado de Ficha',
      dataIndex: 'estado_de_ficha',
      key: 'estado_de_ficha',
      width: 200,
      render: (val, record) => {
        if (record.estados_globales !== 5) {
          return val?.descripcion;
        }
        return estadosDeValidacionDeFicha.at(-1)?.descripcion;
      },
    },
  ];

  const onClickExpand = async () => {
    const [key] = selectedRowKeys;
    const _plantilla = data.results.find((e) => e.id === key);
    setPlantilla(_plantilla);
    setLoading(false);
    setTimeout(() => setVisible(true));
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <TemaAplicado>
          {!visible ? (
            <Table
              cols={columns}
              data={data.results}
              rowSelection={rowSelection}
              mobileColIndex={0}
              rowKey="id"
              handleOnRowClick={handleOnRowClick}
              controls={{
                onClickExpand,
              }}
              pagination={false}
              paginationCallback={fetchData}
              count={data.count}
              currentPage={currentPage}
              allowExpand
            />
          ) : (
            <Card
              bordered={false}
              extra={(<FormSubmitControls onCancel={onCancel} />)}
              className="form-container"
              title="Vista de Trámite"
            >
              <Vista plantilla={plantilla} />
            </Card>
          )}
        </TemaAplicado>
      </Spin>
    </Row>
  );
};

const TemaAplicado = styled.div`
.ant-tabs-left {
  overflow: hidden;
}

.ant-tabs-ink-bar {
  background: ${(props) => (props.theme.primaryText)};
  pointer-events: none;
}


.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: ${(props) => (props.theme.primaryText)};

  text-shadow: 0 0 0.25px currentColor;
}
`;

export default Tramite;
