import { store } from 'store';
import API from './api';

export default async (URI, type) => {
  const res = await API.get(URI);
  if (res?.status === 200) {
    const payload = res.data || [];
    store.dispatch({
      type,
      payload,
    });
  }
};
