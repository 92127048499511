/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  ExceptionOutlined,
} from '@ant-design/icons';
import MotivosDeSolicitudes from 'views/Solicitudes/MotivosDeSolicitudes';
import CategoriasDeLasSolicitudes from 'views/Solicitudes/CategoriasDeLasSolicitudes';
import EstadoDeLasSolicitudes from 'views/Solicitudes/ProgramasParaSolicitud';
import GruposDeAtencion from 'views/Solicitudes/GruposDeAtencion';
import SubCategoriaDeLasSolicitudes from 'views/Solicitudes/SubCategoriaDeLasSolicitudes';
import RazonesDeRechazoSolicitudes from 'views/Solicitudes/RazonesRechazoSolicitudes';
import TipoDeSeguimientoSolicitudes from 'views/Solicitudes/TipoDeSeguimientoSolicitudes';

export default {
  title: 'Catálogo de Solicitudes',
  icon: () => <ExceptionOutlined />,
  type: 'private',
  showInSidebar: true,
  children: [
    {
      title: 'Categorías',
      icon: null,
      component: CategoriasDeLasSolicitudes,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Estados de Solicitudes',
      icon: null,
      component: EstadoDeLasSolicitudes,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Motivos',
      icon: null,
      component: MotivosDeSolicitudes,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Grupo de Atención',
      icon: null,
      component: GruposDeAtencion,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Estado de las solicitudes',
      icon: null,
      component: EstadoDeLasSolicitudes,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Sub Categoría de Solicitud ',
      icon: null,
      component: SubCategoriaDeLasSolicitudes,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Razon de Rechazo',
      icon: null,
      component: RazonesDeRechazoSolicitudes,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Tipo de Seguimiento',
      icon: null,
      component: TipoDeSeguimientoSolicitudes,
      type: 'private',
      showInSidebar: true,
    },
  ],
};
