import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Row,
  Col,
  Input,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import PlainTransfer from 'components/PlainTransfer';
import NumericInput from 'components/NumericInput';
import Select from 'components/Select';

const FormCargos = ({
  getTiposDeCargos,
  monedas,
  etapasDePago,
  transferToBottom,
  setDataSourceCargos,
  dataSourceCargos,
  onChangeTiposDeCargos,
}) => {
  const formCargos = Form.useFormInstance();
  const es_tramite_gratuito = Form.useWatch('es_tramite_gratuito', formCargos);
  const linea_capturada = Form.useWatch('linea_capturada', formCargos);
  const es_cuota_fija = Form.useWatch('es_cuota_fija', formCargos);

  return (
    <Row gutter={10}>
      <Col xs={24} sm={24} md={8}>
        <Form.Item
          name="es_tramite_gratuito"
          label="¿Es trámite gratuito?"
          rules={[{ required: true }]}
        >
          <Select trueFalse />
        </Form.Item>
      </Col>
      {es_tramite_gratuito ? (
        <Col xs={24} sm={24} md={16}>
          <Form.Item
            name="descripcion_de_gratuito"
            label="Descripción de Gratuito"
            tooltip={{
              title: 'Motivo por el que no se realizará cobro alguno',
              icon: <QuestionCircleOutlined />,
            }}
          >
            <Input allowClear />
          </Form.Item>
        </Col>
      ) : (
        <>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              name="clave_de_concepto"
              label="Clave de Concepto"
            >
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              name="es_concepto_interno"
              label="¿Es Concepto Interno?"
            >
              <Select trueFalse />
            </Form.Item>
          </Col>
          {!transferToBottom && (
            <Col span={24}>
              <PlainTransfer
                fetchData={getTiposDeCargos}
                label="Cargos"
                formItemName="tipos_de_cargos"
                form={formCargos}
                filterProp="descripcion"
                filterPropLabel="descripción"
                setDataSource={setDataSourceCargos}
                dataSource={dataSourceCargos}
                onChange={onChangeTiposDeCargos}
              />
            </Col>
          )}
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              name="linea_capturada"
              label="¿Es Línea Capturada?"
              hasFeedback
            >
              <Select trueFalse />
            </Form.Item>
          </Col>
          {linea_capturada && (
            <>
              <Col xs={24} sm={24} md={8}>
                <NumericInput
                  name="vigencia_de_linea_de_captura"
                  label="Vigencia"
                  tooltip={{
                    title: 'Vigencia en días de la línea de captura',
                    icon: <QuestionCircleOutlined />,
                  }}
                />
              </Col>
            </>
          )}
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              name="es_cuota_fija"
              label="¿Es Cuota Fija?"
              hasFeedback
            >
              <Select trueFalse />
            </Form.Item>
          </Col>
          {es_cuota_fija ? (
            <Col xs={24} sm={24} md={8}>
              <NumericInput
                name="cuota_fija"
                label="Cuota Fija"
                decimal
              />
            </Col>
          ) : (
            <Col xs={24} sm={24} md={8}>
              <NumericInput
                name="cuota_variable"
                label="Cuota Variable"
                decimal
              />
            </Col>
          )}
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              name="metodologia_de_pago_para_calculo"
              label="Metodología para el cálculo"
              tooltip="Metodología utilizada para el cálculo del monto"
            >
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              name="clave_de_computo"
              label="Clave de Cómputo"
            >
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              name="periodicidad"
              label="Periodicidad"
            >
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label="¿Pago Continuo?"
              name="pago_continuo"
            >
              <Select trueFalse />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label="¿Pago Moral?"
              name="pago_moral"
              tooltip="Indica si será un pago realizado por una persona moral"
            >
              <Select trueFalse />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label="Tipo de Moneda"
              name="moneda"
            >
              <Select dataSource={monedas} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label="¿Aplica IVA?"
              name="aplica_iva"
            >
              <Select trueFalse />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label="¿Saldo a Favor?"
              name="saldo_a_favor"
            >
              <Select trueFalse />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label="¿Parte Actualizada?"
              name="parte_actualizada"
            >
              <Select trueFalse />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label="¿Aplica Recargos?"
              name="recargos"
            >
              <Select trueFalse />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label="¿Multa por Corrección?"
              name="multa_por_correccion"
            >
              <Select trueFalse />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label="¿Seguimiento en Internet?"
              name="seguimiento_por_internet"
            >
              <Select trueFalse />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label="Pago de Múltiples Trámites"
              name="pago_de_multiples_tramites"
            >
              <Select trueFalse />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              name="etapa_de_pago"
              label="Etapa de Pago"
            >
              <Select dataSource={etapasDePago} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              name="descripcion_de_pago"
              label="Descripción del Pago"
            >
              <Input.TextArea
                allowClear
                autoSize={{ minRows: 2, maxRows: 4 }}
              />
            </Form.Item>
          </Col>
          {transferToBottom && (
            <Col span={24}>
              <PlainTransfer
                fetchData={getTiposDeCargos}
                label="Cargos"
                formItemName="tipos_de_cargos"
                form={formCargos}
                filterProp="descripcion"
                filterPropLabel="descripción"
                setDataSource={setDataSourceCargos}
                dataSource={dataSourceCargos}
                onChange={onChangeTiposDeCargos}
              />
            </Col>
          )}
        </>
      )}
    </Row>
  );
};

FormCargos.propTypes = {
  getTiposDeCargos: PropTypes.func.isRequired,
  monedas: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  etapasDePago: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  transferToBottom: PropTypes.bool,
  setDataSourceCargos: PropTypes.func.isRequired,
  dataSourceCargos: PropTypes.shape({}).isRequired,
  onChangeTiposDeCargos: PropTypes.func.isRequired,
};

FormCargos.defaultProps = {
  transferToBottom: false,
};

export default FormCargos;
