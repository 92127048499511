/* eslint-disable no-tabs */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Upload,
  Form,
  message,
} from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const ImageUploader = ({
  form,
  disabled,
  formItemName,
  formItemLabel,
  imageURL: _imageURL,
  limitMB,
  icon,
  height,
  required,
  controlsPosition,
  allowClear,
}) => {
  const [fileList, setFileList] = useState([]);
  const [imageURL, setImageURL] = useState();

  React.useEffect(() => {
    setImageURL(_imageURL);
    // eslint-disable-next-line
  }, [_imageURL]);

  const rules = [
    {
      validator: async (rule, value) => {
        if (required && !(value || imageURL)) {
          throw new Error('El campo es requerido');
        }
      },
    },
  ];

  return (
    <FormItem
      label={formItemLabel}
      style={{ height: height || '100%' }}
      name={formItemName}
      height={height}
      rules={rules}
      disabled={disabled}
    >
      <Upload
        name="image"
        accept="image/png, image/jpeg, image/jpg"
        onRemove={() => {
          setImageURL();
          form?.resetFields?.([formItemName]);
          setFileList([]);
        }}
        onChange={(info) => {
          const { file } = info;
          const ext = file.name.split('.').pop();
          const isValidExt = ext === 'jpg' || ext === 'jpeg' || ext === 'png';
          if (!isValidExt) {
            message.warn('Solo se admiten archivos en formato JPG, JPEG o PNG');
          }
          const isLt2M = file.size / 1024 / 1024 < limitMB;
          if (!isLt2M) {
            message.warn(`El archivo no debe sobrepasar los ${limitMB}MB`);
          }
          if (isValidExt && isLt2M) {
            getBase64(file, setImageURL);
            setFileList([file]);
          } else {
            setImageURL();
            setFileList([]);
          }
        }}
        listType="picture-card"
        beforeUpload={() => false}
        showUploadList={false}
        disabled={disabled}
        fileList={fileList}
        style={{
          height: '100%',
        }}
      >
        {imageURL
          ? (
            <div className="preview">
              <img src={imageURL} alt={formItemName} style={{ width: '100%' }} />
              <div
                className="controls"
                style={{
                  top: controlsPosition.top,
                  left: controlsPosition.left,
                }}
              >
                {allowClear && (
                  // eslint-disable-next-line
                  <div
                    className="remove"
                    onClick={(e) => {
                      e?.stopPropagation();
                      form?.resetFields?.([formItemName]);
                      setImageURL();
                    }}
                  >
                    <DeleteOutlined />
                  </div>
                )}
                <div className="replace">
                  <EditOutlined />
                </div>
              </div>
            </div>
          )
          : (
            <div className="picker">
              {icon || <PlusOutlined />}
            </div>
          )}
      </Upload>
    </FormItem>
  );
};

ImageUploader.propTypes = {
  disabled: PropTypes.bool,
  formItemName: PropTypes.string.isRequired,
  formItemLabel: PropTypes.string,
  imageURL: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.shape().isRequired,
  limitMB: PropTypes.number,
  icon: PropTypes.node,
  height: PropTypes.number,
  required: PropTypes.bool,
  controlsPosition: PropTypes.shape({
    top: PropTypes.string,
    left: PropTypes.string,
  }),
  allowClear: PropTypes.bool,
};

ImageUploader.defaultProps = {
  disabled: false,
  formItemLabel: null,
  imageURL: null,
  limitMB: 2,
  icon: null,
  height: null,
  required: false,
  controlsPosition: {
    top: '50%',
    left: '50%',
  },
  allowClear: true,
};

const FormItem = styled(Form.Item)`
  margin-bottom: 0;
  width: 100%;
  flex-direction: unset !important;
  .ant-form-item-control {
    width: inherit !important;
    height: calc(${(props) => (props.height ? `${props.height}px` : '100%')} - ${(props) => (props.label ? 35 : 0)}px);
  }
  .ant-form-item-control-input,
  .ant-form-item-control-input-content,
  .ant-upload-picture-card-wrapper {
    width: 100% !important;
    height: 100% !important;
  }
  .ant-upload {
    width: 100%;
    height: 100%;
    .picker .anticon {
      font-size: 40px;
    }
		.preview {
      width: inherit !important;
      height: inherit !important;
			img {
				width: 100% !important;
				height: 100% !important;
				object-fit: cover;
			}
			.anticon {
				font-size: 25px;
			}
      .controls {
        display: none;
        position: absolute;
        transform: translateY(-50%) translateX(-50%);
        background-color: #FFFFFF;
        padding: 20px;
        border-radius: 20px;
        .anticon {
          margin: 0 10px;
        }
      }
			.replace {
				.anticon {
					color: #005eff;
				}
			}
			.remove {
				.anticon {
					color: #ff4d4f;
				}
			}
		}
	}
  .preview:hover {
    .controls {
      display: ${(props) => (props.disabled ? 'none' : 'flex')} !important;
    }
  }
	.ant-form-item-control-input-content {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
  .anticon {
    font-size: 25px;
  }
`;

export default ImageUploader;
