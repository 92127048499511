/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  DashboardOutlined,
  GlobalOutlined,
} from '@ant-design/icons';
import LandingEntidad from 'views/LandingEntidad';
import Blank from './blank';
import misActividades from './misActividades';
import administracion from './administracion';
import ciudadanos from './ciudadanos';
import citas from './citas';
// import tramites from './tramites';
import retys from './retys';
import mapas from './mapas';
import procedimientos from './procedimientos';
import captura from './captura';
import catalogosTramites from './catalogosTramites';
import solicitudes from './solicitudes';

export const initialRoutes = [
  {
    title: 'Dashboard',
    icon: () => <DashboardOutlined />,
    showInSidebar: true,
    component: Blank,
    type: 'private',
  },
  misActividades,
  captura,
  ciudadanos,
  catalogosTramites,
  retys,
  citas,
  solicitudes,
  procedimientos,
  mapas,
  administracion,
  {
    title: 'Página promocional',
    icon: () => <GlobalOutlined />,
    showInSidebar: true,
    component: LandingEntidad,
    type: 'private',
  },
];

const addPaths = (_routes) => _routes.map((r) => {
  const path = (`/${r.title}`).normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    .replace(/ /g, '-').toLowerCase();
  if (r.children) {
    return {
      ...r,
      path,
      children: [...addPaths(r.children)],
    };
  }
  return {
    ...r,
    path,
  };
});

const _flatRoutes = (_routes, parentPath = '') => _routes.map((r) => {
  if (r.children) {
    return _flatRoutes(r.children, parentPath + r.path);
  }
  return {
    ...r,
    path: parentPath + r.path,
  };
});

export const flatRoutes = (_routes = []) => {
  let _flattedRoutes = _flatRoutes(_routes);
  while (_flattedRoutes.find((r) => Array.isArray(r))) {
    _flattedRoutes = _flattedRoutes.flat();
  }
  return _flattedRoutes;
};

export const routes = addPaths(initialRoutes);
export const flattedRoutes = flatRoutes(routes);
