import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { useSelector } from 'react-redux';
import { createDecimalRegExp } from 'utils/patterns';

const DEFAULT_MAX_LENGHT = 31;

const NumericInput = ({
  name,
  label,
  rules,
  onChange,
  required,
  maxLength,
  minLength,
  disabled,
  hasFeedback,
  decimal,
  allowZero,
  tooltip,
  ...props
}) => {
  const moneda = useSelector(({ auth }) => auth.entidad.moneda);
  const [decimalRegExp] = createDecimalRegExp(moneda);
  // eslint-disable-next-line no-param-reassign
  minLength = minLength < 0 ? 0 : minLength;
  // eslint-disable-next-line no-param-reassign
  maxLength = maxLength > DEFAULT_MAX_LENGHT ? DEFAULT_MAX_LENGHT : maxLength;
  // eslint-disable-next-line no-param-reassign
  required = required || !!minLength;
  const integerRegExp = new RegExp(`^\\d{${minLength},${maxLength}}$`);
  const defaultRule = [
    {
      required,
      message: 'El campo es requerido',
    },
    {
      validator: async (rule, value = '') => {
        const equals = minLength === maxLength;
        const invalidDecimal = decimal && !decimalRegExp.test(value);
        const invalidInteger = !decimal && !integerRegExp.test(value);
        const parsedValue = decimal ? parseFloat(value) : parseInt(value, 10);
        if (value && required && !allowZero
          && ((!invalidInteger || !invalidDecimal) && !parsedValue && parsedValue !== 0)) {
          throw new Error(`Ingrese un número ${decimal ? 'decimal' : 'entero'} válido`);
        } else if (value) {
          if (value.length < minLength) {
            throw new Error(`El campo debe tener ${!equals ? 'mínimo' : ''} ${minLength} dígitos`);
          } else if (value.length > maxLength) {
            throw new Error(`El campo debe tener ${!equals ? 'máximo' : ''} ${maxLength} dígitos`);
          } else if ((invalidDecimal || invalidInteger) && parsedValue !== 0) {
            throw new Error(`Ingrese un número ${decimal ? 'decimal' : 'entero'} válido`);
          } else if (parsedValue && allowZero) {
            throw new Error('El campo es requerido');
          }
        }
      },
    },
  ];
  return (
    <Form.Item
      name={name}
      label={label}
      rules={rules || defaultRule}
      hasFeedback={hasFeedback}
      tooltip={tooltip}
    >
      <Input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        allowClear={hasFeedback || !disabled}
        maxLength={maxLength < DEFAULT_MAX_LENGHT ? maxLength : DEFAULT_MAX_LENGHT}
        onChange={onChange}
        disabled={disabled}
      />
    </Form.Item>
  );
};

NumericInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  rules: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  hasFeedback: PropTypes.bool,
  minLength: PropTypes.number,
  decimal: PropTypes.bool,
  allowZero: PropTypes.bool,
  tooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
      ]),
    }),
  ]),
};

NumericInput.defaultProps = {
  rules: null,
  onChange: null,
  required: false,
  maxLength: DEFAULT_MAX_LENGHT,
  disabled: false,
  hasFeedback: true,
  label: '',
  minLength: 0,
  decimal: false,
  allowZero: false,
  tooltip: null,
};

export default NumericInput;
