/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Row,
  Col,
  Spin,
  Card,
  Input,
  Tabs,
  Button,
  message,
  Grid,
} from 'antd';
import Table from 'components/Table';
import makeMessager from 'utils/complexFormsMessages';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Direccion from 'components/Direccion';
import Select from 'components/Select';
import { isMutable } from 'utils/estadosGlobales';
import { onError } from 'utils/handlers';
import { makeGetOnFinish, makeOnChangeTabKey } from 'utils/tabsManagement';
import { InputSearchPro } from 'components/InputSearch';
import {
  getUnidadesDeRecaudacion,
  patchUnidadDeRecaudacion,
  postUnidadDeRecaudacion,
  getUnidadDeRecaudacion,
  deleteUnidadDeRecaudacion,
  baseURI,
} from 'api/configuracion/unidadesDeRecaudacion';
import { getDependencia, getDependencias } from 'api/configuracion/dependencias';
import { getDepartamento, getDepartamentos } from 'api/configuracion/departamentos';
import { getCAAR, getCAARs } from 'api/configuracion/caar';
import { getUsuario, getUsuarios } from 'api/usuarios/usuarios';
import { getContentTypesURs } from 'api/catalogos/index';
import { getConceptosDeIngresos } from 'api/catalogos/conceptosDeIngresos';
import { getConfiguracionesDeSegmentosDeFolios } from 'api/configuracion/configuracionesDeFolios';
import BusquedaAvanzadaCajeros from 'components/BusquedaAvanzadaCajeros';
import checkToken from 'api/utils/checkToken';
import Configuration from './Configuration';

const { TabPane } = Tabs;

const levelSorter = (a, b) => {
  if (a.nivel < b.nivel) {
    return -1;
  }
  if (a.nivel > b.nivel) {
    return 1;
  }
  return 0;
};

const urGettersByLevel = {
  1: getDependencia,
  2: getDepartamento,
  3: getCAAR,
};

const urFiltersByLevel = {
  1: getDependencias,
  2: getDepartamentos,
  3: getCAARs,
};

const UnidadesRecaudadoras = () => {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const collapsedSideBar = useSelector(({ app }) => app.collapsedSidebar);

  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);

  const [conceptoIngresoTemp, setConceptoIngresoTemp] = useState({ results: [] });
  const conceptoIngreso = useRef({ results: [] });
  const [urs, setUrs] = useState([]);
  const [urContentType, setUrContentType] = useState();
  const [jefeFound, setJefeFound] = useState([]);
  const [ursFound, setUrsFound] = useState([]);

  const [currentTabKey, setCurrentTabKey] = useState('general');
  const [formAddress, setFormAddress] = useState();
  const [formConfigurations, setFormConfigurations] = useState();
  const [mutable, setMutable] = useState(true);
  const [folios, setFolios] = useState([]);
  const [visibleBusquedaCajeros, setVisibleBusquedaCajeros] = useState(false);

  const screens = Grid.useBreakpoint();

  const basicKeys = ['general'];
  const complexForms = [
    { key: 'direccion', formInstance: formAddress },
    { key: 'configurations', formInstance: formConfigurations },
  ];

  const fetchData = async () => {
    setLoading(true);
    const _data = await getUnidadesDeRecaudacion();
    setData(_data);
    setLoading(false);
  };

  const showMsg = makeMessager(setCurrentTabKey, fetchData);

  useEffect(() => {
    let mounted = true;

    const fetchAll = async () => {
      checkToken();
      setLoading(true);
      const [
        _contentTypesUr,
        _folios,
        _data,
      ] = await Promise.all([
        getContentTypesURs(),
        getConfiguracionesDeSegmentosDeFolios(),
        getUnidadesDeRecaudacion(),
      ]);
      if (mounted) {
        setFolios(_folios);
        setUrs(_contentTypesUr.sort(levelSorter));
        setData(_data);
        setLoading(false);
      }
    };

    fetchAll();
    return () => { mounted = false; };
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setCurrentTabKey('general');
    setUrsFound([]);
    setJefeFound([]);
    setMutable(true);
  };

  const onClickEdit = async () => {
    setLoading(true);
    const [key] = selectedRowKeys;
    const unidad = await getUnidadDeRecaudacion(key);
    setMutable(isMutable(unidad.estados_globales));
    setUrContentType(unidad.content_type_urs);
    const nivel = urs.find((e) => e.id === unidad.content_type_urs)?.nivel;
    const restData = {};
    if (nivel) {
      const ur = await urGettersByLevel[nivel](unidad.object_id_urs);
      setUrsFound([ur]);
      restData.object_id_urs = ur.id;
    }
    if (unidad.jefe_de_cajeros) {
      const resJefe = await getUsuario(unidad?.jefe_de_cajeros);
      setJefeFound([{
        ...resJefe,
        nombre_completo: `${resJefe?.first_name} ${resJefe?.last_name || ''} ${resJefe?.second_last_name || ''}`,
      }]);
      restData.jefe_de_cajeros = resJefe.id;
    }
    form.setFieldsValue({
      ...unidad,
      ...restData,
    });
    setVisible(true);
    setLoading(false);
  };

  const onFinish = async (_continue = false) => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      values.content_type_urs = urContentType;
      const [key] = selectedRowKeys;
      const _data = await (key
        ? patchUnidadDeRecaudacion(key, values)
        : postUnidadDeRecaudacion(values));
      if (_data) {
        await fetchData();
        if (_continue) {
          if (!key) {
            setSelectedRowKeys([_data.id]);
          }
        } else {
          onCancel();
        }
        return _data;
      }
      setLoading(false);
      return true;
    } catch (err) {
      onError(err, setLoading, [form], setCurrentTabKey);
      return false;
    }
  };

  const deleteItem = async () => {
    setLoading(true);
    const [key] = selectedRowKeys;
    const deleted = await deleteUnidadDeRecaudacion(key);
    if (deleted) {
      onCancel();
      setVisibleAlert(false);
      await fetchData();
    }
    setLoading(false);
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 300,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 100,
      render: (val) => (estadosGlobales.find((item) => item.id === val)?.descripcion),
    },
  ];

  const rules = {
    required: [{ required: true }],
    email: [{
      required: true,
      type: 'email',
    }],
    q_ur: [
      {
        validator: async (rule, val) => {
          const { content_type_urs } = form.getFieldsValue();
          if (content_type_urs && !val) {
            throw new Error('El campo es requerido');
          }
        },
      },
    ],
    content_type: [
      {
        validator: async (rule, val) => {
          const { q_ur } = form.getFieldsValue();
          if (q_ur && !val) {
            throw new Error('El campo es requerido');
          }
        },
      },
    ],
  };

  const busquedaUR = async () => {
    try {
      setLoading(true);
      await form.validateFields(['content_type_urs', 'q_ur']);
      const { content_type_urs, q_ur: q } = form.getFieldsValue();
      if (q) {
        setUrContentType(content_type_urs);
        const { nivel } = urs.find((e) => e.id === content_type_urs);
        const _urs = await urFiltersByLevel[nivel]({ q, estados_globales: 4 });
        if (_urs.length === 1) {
          form.setFieldsValue({
            object_id_urs: _urs[0].id,
          });
          setUrsFound([_urs[0]]);
        } else if (!_urs.length) {
          message.info('No se encontraron coincidencias');
          setUrsFound([]);
        } else {
          message
            .info('Se encontraron demasiadas coincidencias, sea mas especifico con su busqueda');
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const setFields = (usuario) => {
    form.setFieldsValue({
      jefe_de_cajeros: usuario.id,
    });
    const results = {
      ...usuario,
      nombre_completo: `${usuario?.first_name
        || ''} ${usuario?.last_name
        || ''} ${usuario?.second_last_name
        || ''}`,
    };
    setJefeFound([results]);
  };

  const busquedaJefe = async () => {
    try {
      setLoading(true);
      await form.validateFields(['q_jefe']);
      const { q_jefe: email } = form.getFieldsValue();
      if (email) {
        const _usuarios = await getUsuarios({ email });
        if (_usuarios.length === 1) {
          setFields(_usuarios[0]);
        } else {
          message.info('No se encontraron coincidencias');
          setJefeFound([]);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onChangeTabKey = makeOnChangeTabKey(
    basicKeys, complexForms, currentTabKey, setCurrentTabKey, showMsg,
  );

  const getOnFinish = makeGetOnFinish(basicKeys, complexForms, currentTabKey, onFinish);

  const resetAndValidateURs = () => {
    setUrsFound([]);
    form.resetFields(['object_id_urs']);
    form.validateFields(['object_id_urs', 'q_ur']);
  };

  const resetAndValidateJefe = () => {
    setJefeFound([]);
    form.resetFields(['jefe_de_cajeros']);
    form.validateFields(['jefe_de_cajeros']);
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit: () => onClickEdit(),
              onClickDelete,
            }}
            mobileColIndex={0}
            rowKey="id"
          />
        ) : (
          <Card
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Unidad Recaudadora`}
            extra={(
              <FormSubmitControls
                onFinish={getOnFinish()}
                onCancel={onCancel}
                mutable={mutable}
                allowSaveAndContinue
              />
            )}
            bordered={false}
          >
            <Tabs
              onChange={mutable ? onChangeTabKey : setCurrentTabKey}
              activeKey={currentTabKey}
              type="card"
            >
              <TabPane tab="General" key="general">
                <Form
                  layout="vertical"
                  name="general"
                  form={form}
                  onFinish={(_continue) => onFinish(_continue)}
                  scrollToFirstError
                  initialValues={{ estados_globales: 1, puede_recaudar: false }}
                >
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        name="conceptos_de_ingreso"
                        label={collapsedSideBar ? 'Conceptos de Ingreso' : 'Concepto'}
                        hasFeedback
                      >

                        <Select
                          mode="multiple"
                          dataSource={
                            conceptoIngresoTemp.results?.length
                              ? conceptoIngresoTemp : conceptoIngreso.current
                          }
                          usingExternalDataSource={Boolean(conceptoIngresoTemp.results?.length)}
                          labelProp="descripcion"
                          onClear={() => {
                            form.resetFields(['conceptos_de_ingreso']);
                            setConceptoIngresoTemp({ results: [] });
                          }}
                          pagination={{
                            getter: getConceptosDeIngresos,
                            setter: (dataSource) => {
                              conceptoIngreso.current = dataSource;
                            },
                            config: { auto: true, qName: 'descripcion' },
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        name="clave"
                        rules={rules.required}
                        label="Clave"
                        hasFeedback
                      >
                        <Input allowClear disabled={!mutable} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8}>
                      <Form.Item
                        name="descripcion"
                        rules={rules.required}
                        label="Descripción"
                        hasFeedback
                      >
                        <Input allowClear disabled={!mutable} />
                      </Form.Item>
                    </Col>
                    <InputSearchPro
                      label={collapsedSideBar ? 'Jefe de Cajeros' : 'Jefe'}
                      tooltip={collapsedSideBar
                        ? 'Busqueda por Email' : 'Busqueda de Jefe de Cajeros por Email'}
                      name="q_jefe"
                      onClickAdvanced={() => setVisibleBusquedaCajeros(true)}
                      rules={rules.email}
                      onPressEnter={() => busquedaJefe()}
                      disabled={!mutable}
                      breakPoints={{
                        xs: 24,
                        sm: 24,
                        md: 24,
                        lg: 12,
                      }}
                      inputProps={{
                        onPressEnter: () => busquedaJefe(),
                        onChange: ({ target: { value } }) => {
                          if (!value) {
                            resetAndValidateJefe();
                          }
                        },
                      }}
                      resultProps={{
                        name: 'jefe_de_cajeros',
                        label: 'Jefe de Cajeros',
                        dataSource: jefeFound,
                        labelProp: 'nombre_completo',
                        onClear: resetAndValidateJefe,
                        rules: rules.required,
                        breakPoints: {
                          md: 12,
                        },
                      }}
                    />

                    <InputSearchPro
                      label={`${(collapsedSideBar) ? 'Unidad Responsable' : 'UR'}`}
                      tooltip={`${(!screens.md && !collapsedSideBar) ? 'Búsqueda por clave o descripción' : 'Búsqueda de Unidad Responsable por clave o descripción'}`}
                      name="q_ur"
                      rules={rules.q_ur}
                      onPressEnter={() => busquedaUR()}
                      disabled={!mutable}
                      prefix={{
                        style: { width: '100%' },
                        rules: rules.content_type,
                        name: 'content_type_urs',
                        label: 'Nivel',
                        render: (e) => e.model,
                        dataSource: urs,
                        onChange: resetAndValidateURs,
                      }}
                      breakPoints={{
                        xs: 24,
                        sm: 24,
                        md: 24,
                        lg: 12,
                      }}
                      inputProps={{
                        onChange: ({ target: { value } }) => {
                          if (!value) {
                            resetAndValidateURs();
                          }
                        },
                      }}
                      resultProps={{
                        name: 'object_id_urs',
                        label: 'Unidad responsable',
                        dataSource: ursFound,
                        keyLabelRender: true,
                        onClear: resetAndValidateURs,
                        breakPoints: {
                          md: 12,
                        },
                      }}
                    />
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <Form.Item
                        label="Foliador"
                        name="foliador"
                      >
                        <Select
                          dataSource={folios}
                          render={(item) => `${item.descripcion} ( ${item.separador} )`}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="puede_recaudar"
                        label="¿Puede recaudar?"
                        hasFeedback
                      >
                        <Select
                          disabled={!selectedRowKeys.length}
                          dataSource={estadosGlobales}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="estados_globales"
                        label="Estado"
                        hasFeedback
                        rules={rules.required}
                      >
                        <Select
                          disabled={!selectedRowKeys.length}
                          dataSource={estadosGlobales}
                        />
                      </Form.Item>
                    </Col>
                    <Form.Item name="id" hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item hidden>
                      <Button htmlType="submit" />
                    </Form.Item>
                  </Row>
                </Form>
              </TabPane>
              <TabPane
                tab="Dirección"
                key="direccion"
                forceRender
                disabled={!selectedRowKeys.length}
              >
                <Direccion
                  currentURI={baseURI}
                  parentID={selectedRowKeys[0]}
                  setLoading={setLoading}
                  setFormAddress={setFormAddress}
                  setCurrentTabKey={setCurrentTabKey}
                  callback={(values, _continue) => {
                    if (!_continue) {
                      onCancel();
                    } else {
                      const [key] = selectedRowKeys;
                      const clone = [...data];
                      const mappedData = clone.map((e) => (e.id === key
                        ? ({ ...e, direccion: values?.id }) : e));
                      setData(mappedData);
                    }
                  }}
                />
              </TabPane>
              <TabPane
                tab="Configuraciones"
                key="configurations"
                forceRender
                disabled={!selectedRowKeys.length}
              >
                <Configuration
                  setLoading={setLoading}
                  callback={async (_, _continue) => {
                    if (!_continue) {
                      onCancel();
                      await fetchData();
                    }
                  }}
                  setCurrentTabKey={setCurrentTabKey}
                  setForm={setFormConfigurations}
                  selectedRowKey={selectedRowKeys[0]}
                />
              </TabPane>
            </Tabs>
          </Card>
        )}
      </Spin>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        content={`Unidad Recaudadora ${form.getFieldValue('descripcion')}`}
        loading={loading}
      />
      <BusquedaAvanzadaCajeros
        visible={visibleBusquedaCajeros}
        setVisible={setVisibleBusquedaCajeros}
        callback={setFields}
      />
    </Row>
  );
};

export default UnidadesRecaudadoras;
