/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Typography,
  Collapse,
  Space,
  Card,
  Grid,
  message,
} from 'antd';
import {
  EyeOutlined,
  FileDoneOutlined,
  ImportOutlined,
  InfoCircleOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import { getFullName, listFormatter } from 'utils/formatValues';
import NumericInput from 'components/NumericInput';
import Select from 'components/Select';
import Previewer from 'components/Previewer';
import Table from 'components/Table';
import address from 'utils/address';

const fundamentoToString = (f) => `Denominación: ${f?.denominacion
  || ''} -  Artículo: ${f?.articulo
  || ''} - Fracción: ${f?.fraccion
  || ''} - Inciso: ${f?.inciso
  || ''} - Párrafo: ${f?.parrafo
  || ''} `;

const { Text } = Typography;
const optionsClasificacion = [
  {
    descripcion: 'Trámite',
    id: true,
  },
  {
    descripcion: 'Servicio',
    id: false,
  },
];

const Vista = ({ plantilla }) => {
  const screens = Grid.useBreakpoint();
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visiblePreviewer, setVisiblePreviewer] = useState(false);
  const [iFrameUrl, setIFrameUrl] = useState(null);

  useEffect(() => {
    const indexedKeys = Object.keys(plantilla)
      .filter((k) => plantilla[k]?.id);
    const indexedAsFks = Object
      .fromEntries(indexedKeys.map((k) => [k, plantilla[k].id]));
    const tecnologia_del_sistema = plantilla.tecnologia_del_sistema[0]?.id;
    setTimeout(() => {
      form.setFieldsValue({
        ...plantilla,
        ...indexedAsFks,
        tipos_de_personas: plantilla.tipos_de_personas.map((e) => e.id),
        calendario_de_presentacion: plantilla
          .calendario_de_presentacion?.map((e) => e.id),
        meses_de_recurrencia: plantilla
          .meses_de_recurrencia?.map((e) => e.id),
        catalogo_de_canales_de_pago: plantilla
          .catalogo_de_canales_de_pago?.map((e) => e.id),
        usuario_responsable_de_la_resolucion: plantilla
          .usuario_responsable_de_la_resolucion?.id,
        usuario_responsable_de_queja: plantilla.usuario_responsable_de_queja?.id,
        usuario_supervisor_de_ROM: plantilla.usuario_supervisor_de_ROM?.id,
        tecnologia_del_sistema,
      });
    });
  }, []);

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
  };

  return (
    <>
      <Form
        name="plantilla_de_tramite_info"
        layout="vertical"
        form={form}
        preserve
      >
        <Collapse accordion>
          <Collapse.Panel
            forceRender
            header={(
              <Space>
                <InfoCircleOutlined />
                <b>Información Básica I</b>
              </Space>
            )}
            key="basica_1"
          >
            <Collapse accordion>
              <Collapse.Panel
                forceRender
                header={(
                  <Space>
                    <ImportOutlined />
                    <b>Datos Generales</b>
                  </Space>
                )}
                key="basica_1_general"
              >
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item label="Nombre Oficial del Trámite" name="nombre">
                      <Input.TextArea autoSize={{ minRows: 2 }} disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item label="Nombre Interno del Trámite" name="nombre_interno_del_tramite">
                      <Input.TextArea autoSize={{ minRows: 2 }} disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item label="Descripcion" name="descripcion">
                      <Input.TextArea autoSize={{ minRows: 2 }} disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item label="Clasificación" name="clasificacion">
                      <Select
                        dataSource={optionsClasificacion}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item label="Estado de la Ficha" name="estado_de_ficha">
                      <Select
                        dataSource={[plantilla.estado_de_ficha]}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item label="Homoclave" name="homoclave">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item label="Ámbito del Trámite" name="ambito_de_ordenamiento">
                      <Select
                        dataSource={[plantilla.ambito_de_ordenamiento]}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item label="Programa Relacionado" name="programa_relacionado_a_tramite">
                      <Select
                        dataSource={[plantilla
                          .programa_relacionado_a_tramite]}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Typography.Title level={4}>
                      Fundamentos Legales
                    </Typography.Title>
                    <Table
                      cols={[
                        {
                          titleText: 'Denominación',
                          dataIndex: 'denominacion',
                          key: 'denominacion',
                          width: 200,
                        },
                        {
                          titleText: 'Artículo',
                          dataIndex: 'articulo',
                          key: 'articulo',
                          width: 200,
                        },
                        {
                          titleText: 'Fracción',
                          dataIndex: 'fraccion',
                          key: 'fraccion',
                          width: 200,
                        },
                        {
                          titleText: 'Inciso',
                          dataIndex: 'inciso',
                          key: 'inciso',
                          width: 200,
                        },
                        {
                          titleText: 'Párrafo',
                          dataIndex: 'parrafo',
                          key: 'parrafo',
                          width: 200,
                        },
                      ]}
                      data={plantilla?.fundamento_legal}
                      mobileColIndex={0}
                      rowKey="id"
                      allowSearch={false}
                      allowSort={false}
                      noControls
                    />
                  </Col>
                </Row>
              </Collapse.Panel>
              <Collapse.Panel
                forceRender
                header={(
                  <Space>
                    <ImportOutlined />
                    <b>Organizaciones</b>
                  </Space>
                )}
                key="basica_1_organizaciones"
              >
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item label="Dependencia" name="unidad_operativa">
                      <Select dataSource={[plantilla.unidad_operativa]} disabled />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Typography.Title level={4}>
                      Departamentos
                    </Typography.Title>
                    <Table
                      cols={[
                        {
                          titleText: 'Clave',
                          dataIndex: 'clave',
                          key: 'clave',
                          width: 100,
                        },
                        {
                          titleText: 'Descripción',
                          dataIndex: 'descripcion',
                          key: 'descripcion',
                          width: 200,
                        },
                      ]}
                      data={plantilla?.departamentos || []}
                      mobileColIndex={0}
                      rowKey="id"
                      allowSearch={false}
                      allowSort={false}
                      noControls
                    />
                  </Col>
                </Row>
              </Collapse.Panel>
              <Collapse.Panel
                forceRender
                header={(
                  <Space>
                    <InfoCircleOutlined />
                    <b>Descripción del Trámite</b>
                  </Space>
                )}
                key="basica_1_descripcion"
              >
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item label="Tipo de Trámite" name="tipo_de_tramite">
                      <Select
                        dataSource={[plantilla.tipo_de_tramite]}
                        keyLabelRender
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      label="Resolución Vinculada"
                      name="resolucion_vinculada"
                      tooltip={{
                        title: 'La resolución de este trámite está vinculada con la presentación de otros trámites',
                      }}
                    >
                      <Select trueFalse disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="estado_de_la_empresa"
                      label="Estado de la Empresa"
                      tooltip={{
                        title: 'El trámite o servicio se realiza cuando una empresa se encuentre en',
                      }}
                      hasFeedback
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="casos_de_presentacion"
                      label="¿En qué casos se debe presentar el trámite?"
                      hasFeedback
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="resolucion_es_requisito"
                      label="¿La Resolución es Requisito?"
                      hasFeedback
                      tooltip="La resolución es requisito de otro trámite"
                    >
                      <Select trueFalse disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="tipos_de_personas"
                      label="¿Quien puede Solicitar El Tramite?"
                    >
                      <Select
                        dataSource={plantilla.tipos_de_personas}
                        disabled
                        mode="multiple"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="nombre_de_la_modalidad"
                      label="Nombre de la Modalidad"
                      hasFeedback
                      tooltip={{
                        title: 'Si existe modalidad agregarla aquí, de lo contrario puede ir vacío',
                      }}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="conservar_informacion"
                      label="¿Conservar Información?"
                      tooltip={{
                        title: '¿Este trámite o servicio requiere conservar la información para fines de acreditación, inspección y verificación con motivo del trámite o servicio?',
                      }}
                    >
                      <Select trueFalse disabled />
                    </Form.Item>
                  </Col>
                  {plantilla.conservar_informacion && (
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="detalle_conservar_informacion"
                        label="Detalle de Conservar Información"
                        hasFeedback
                      >
                        <Input.TextArea disabled autoSize={{ minRows: 2, maxRows: 4 }} />
                      </Form.Item>
                    </Col>
                  )}
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="informacion_adicional"
                      label="Información Adicional"
                      hasFeedback
                      tooltip={{
                        title: 'Información que sea útil para que el interesado realice el trámite',
                      }}
                    >
                      <Input.TextArea disabled autoSize={{ minRows: 2, maxRows: 4 }} />
                    </Form.Item>
                  </Col>
                </Row>
              </Collapse.Panel>
              <Collapse.Panel
                forceRender
                header={(
                  <Space>
                    <InfoCircleOutlined />
                    <b>Sinónimos</b>
                  </Space>
                )}
                key="basica_1_sinonimos"
              >
                <Col span={24}>
                  <Table
                    cols={[
                      {
                        titleText: 'Descripción',
                        dataIndex: 'descripcion',
                        key: 'descripcion',
                        width: '100%',
                      },
                    ]}
                    data={plantilla?.sinonimo || []}
                    mobileColIndex={0}
                    rowKey="id"
                    allowSearch={false}
                    allowSort={false}
                    noControls
                  />
                </Col>
              </Collapse.Panel>
              <Collapse.Panel
                forceRender
                header={(
                  <Space>
                    <InfoCircleOutlined />
                    <b>Personas Relacionadas</b>
                  </Space>
                )}
                key="basica_1_personas_relacionadas"
              >
                <Row gutter={10}>
                  <Col order={1} xs={24} sm={24} md={8}>
                    <Form.Item
                      label="Responsable de Queja"
                      name="usuario_responsable_de_queja"
                    >
                      <Select
                        dataSource={[plantilla.usuario_responsable_de_queja]}
                        render={(e) => getFullName(e, null, 'correo_electronico')}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col order={screens.md ? 2 : 3} xs={24} sm={24} md={8}>
                    <Form.Item
                      label="Responsable de la Resolución"
                      name="usuario_responsable_de_la_resolucion"
                    >
                      <Select
                        dataSource={[plantilla.usuario_responsable_de_la_resolucion]}
                        render={(e) => getFullName(e, null, 'correo_electronico')}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col order={screens.md ? 3 : 5} xs={24} sm={24} md={8}>
                    <Form.Item
                      label="Supervisor de ROM"
                      name="usuario_supervisor_de_ROM"
                    >
                      <Select
                        dataSource={[plantilla.usuario_supervisor_de_ROM]}
                        render={(e) => getFullName(e, null, 'correo_electronico')}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  {[
                    plantilla.usuario_responsable_de_queja,
                    plantilla.usuario_responsable_de_la_resolucion,
                    plantilla.usuario_supervisor_de_ROM,
                  ].map((usuario, idx) => (usuario ? (
                    <Col
                      order={screens.md ? idx + 4 : (idx + 1) * 2}
                      xs={24}
                      sm={24}
                      md={8}
                      key={usuario.id}
                    >
                      <Card
                        bordered={false}
                        style={{ height: '100px' }}
                      >
                        <Col>
                          <Text strong>{getFullName(usuario)}</Text>
                        </Col>
                        {usuario.numero_de_empleado && (
                          <Col>
                            <Text>
                              {`Número  de Empleado: ${usuario?.numero_de_empleado}`}
                            </Text>
                          </Col>
                        )}
                        {usuario?.funcionario?.puesto && (
                          <Col>
                            <Text>
                              {`Puesto: ${usuario?.funcionario?.puesto?.descripcion || ''}`}
                            </Text>
                          </Col>
                        )}
                        {usuario.email && (
                          <Text>
                            {`Correo Electrónico: ${usuario.email || ''}`}
                          </Text>
                        )}
                        {usuario.numero_de_celular && (
                          <Text>
                            {`Teléfono: ${usuario.numero_de_celular || ''}`}
                          </Text>
                        )}
                      </Card>
                    </Col>
                  ) : <Col span={8} key={usuario?.id || idx} />))}
                </Row>
              </Collapse.Panel>
            </Collapse>
          </Collapse.Panel>
          <Collapse.Panel
            forceRender
            header={(
              <Space>
                <InfoCircleOutlined />
                <b>Información Básica II</b>
              </Space>
            )}
            key="basica_2"
          >
            <Collapse accordion>
              <Collapse.Panel
                forceRender
                header={(
                  <Space>
                    <InfoCircleOutlined />
                    <b>Canal de Presentación</b>
                  </Space>
                )}
                key="basica_2_canal_de_presentacion"
              >
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="tramite_de_internet"
                      label="¿Trámite de Internet?"
                      tooltip={{
                        title: 'Es posible iniciar el Tramite por Internet',
                      }}
                    >
                      <Select trueFalse disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="anexo_de_documentos"
                      label="¿Permite Anexo de Documentos?"
                      tooltip=" Indica si es posible cargar o subir documentos en línea"
                    >
                      <Select trueFalse disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="recepcion_electronica"
                      label="¿Recepción Electrónica?"
                      tooltip="Indica si se puede recibir de forma electrónica"
                    >
                      <Select trueFalse disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="resolucion_inmediata"
                      label="¿Resolución Inmediata?"
                      hasFeedback
                      tooltip="Indica si el trámite tiene una resolución inmediata"
                    >
                      <Select trueFalse disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="firma_electronica"
                      label="¿Utiliza Firma Electrónica?"
                      tooltip="Indica si utiliza firma electrónica"
                    >
                      <Select trueFalse disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="agenda_cita"
                      label="¿Se Puede Agendar Cita?"
                      hasFeedback
                      tooltip="¿Se puede agendar una cita para realizar el trámite?"
                    >
                      <Select trueFalse disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="notificacion_de_estatus"
                      label="Notificación de Estatus"
                      hasFeedback
                      tooltip="Se notifica al interesado sobre el estatus del tramite"
                    >
                      <Select trueFalse disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="notificacion_de_informacion"
                      label="Notificación de Información"
                      hasFeedback
                      tooltip="Se notifica al interesado en línea, sobre la información
                              faltante del trámite"
                    >
                      <Select trueFalse disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="notificacion_de_plazo"
                      label="Notificación de Plazo"
                      hasFeedback
                      tooltip="Se notifica al interesado en línea, sobre el plazo de prevención del trámite"
                    >
                      <Select trueFalse disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="notificacion_de_vencimiento"
                      label="Notificación de Vencimiento"
                      hasFeedback
                      tooltip="Se notifica al interesado en línea, sobre el vencimiento del plazo de respuesta del trámite"
                    >
                      <Select trueFalse disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="resolucion_por_internet"
                      label="¿Resolución por Internet?"
                      tooltip="indica si la resolucion o respuesta es por internet"
                    >
                      <Select trueFalse disabled />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Table
                      cols={[
                        {
                          titleText: 'Canal de presentación',
                          dataIndex: 'canal_de_presentacion',
                          key: 'canal_de_presentacion',
                          width: 300,
                          render: (val) => val?.descripcion || val,
                        },
                        {
                          titleText: 'Pasos Presenciales',
                          dataIndex: 'pasos_presenciales',
                          key: 'pasos_presenciales',
                          width: 300,
                        },
                        {
                          titleText: 'Ubicación',
                          dataIndex: 'ubicacion',
                          key: 'ubicacion',
                          width: 300,
                        },
                        {
                          titleText: 'Pasos por otro Método',
                          dataIndex: 'pasos_por_otro_metodo',
                          key: 'pasos_por_otro_metodo',
                          width: 300,
                        },
                      ]}
                      data={plantilla?.canal_de_presentacion_plantilla || []}
                      mobileColIndex={0}
                      rowKey="id"
                      allowSearch={false}
                      allowSort={false}
                      noControls
                    />
                  </Col>
                </Row>
              </Collapse.Panel>
              <Collapse.Panel
                forceRender
                header={(
                  <Space>
                    <InfoCircleOutlined />
                    <b>Plazo Máximo</b>
                  </Space>
                )}
                key="basica_2_plazo_maximo"
              >
                <Row gutter={10}>
                  <Col span={24}>
                    <Table
                      cols={[
                        {
                          titleText: 'Plazo Máximo',
                          dataIndex: 'unidad_de_plazo_maximo',
                          key: 'unidad_de_plazo_maximo',
                          width: 150,
                          render: (val) => val?.descripcion,
                        },
                        {
                          titleText: 'Plazo Máximo',
                          dataIndex: 'plazo_maximo',
                          key: 'plazo_maximo',
                          width: 150,
                        },
                        {
                          titleText: 'Tipo de Ficta',
                          dataIndex: 'tipo_de_ficta',
                          key: 'tipo_de_ficta',
                          width: 150,
                          render: (val) => val?.descripcion,
                        },
                        {
                          titleText: 'Observaciones',
                          dataIndex: 'observaciones',
                          key: 'observaciones',
                          width: 300,
                        },
                      ]}
                      data={plantilla?.plazo_maximo || []}
                      mobileColIndex={0}
                      rowKey="id"
                      allowSearch={false}
                      allowSort={false}
                      noControls
                    />
                  </Col>
                </Row>
              </Collapse.Panel>
              <Collapse.Panel
                forceRender
                header={(
                  <Space>
                    <InfoCircleOutlined />
                    <b>Plazo de Prevención</b>
                  </Space>
                )}
                key="basica_2_plazo_de_prevencion"
              >
                <Row gutter={10}>
                  <Col span={24}>
                    <Table
                      cols={[
                        {
                          titleText: 'Unidad de Plazo Real',
                          dataIndex: 'unidad_de_plazo_real',
                          key: 'unidad_de_plazo_real',
                          width: 300,
                          render: (val) => val?.descripcion,
                        },
                        {
                          titleText: 'Plazo de Prevención',
                          dataIndex: 'plazo_prevencion',
                          key: 'plazo_prevencion',
                          width: 300,
                        },
                        {
                          titleText: 'Observaciones',
                          dataIndex: 'observaciones',
                          key: 'observaciones',
                          width: 300,
                        },
                      ]}
                      data={plantilla?.plazo_prevencion || []}
                      mobileColIndex={0}
                      rowKey="id"
                      allowSearch={false}
                      allowSort={false}
                      noControls
                    />
                  </Col>
                </Row>
              </Collapse.Panel>
              <Collapse.Panel
                forceRender
                header={(
                  <Space>
                    <InfoCircleOutlined />
                    <b>Plazo Real</b>
                  </Space>
                )}
                key="basica_2_plazo_real"
              >
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="unidad_de_plazo_real"
                      label="Unidad de Plazo Real"
                    >
                      <Select
                        dataSource={[plantilla.unidad_de_plazo_real]}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <NumericInput
                      name="plazo_real"
                      label="Plazo Real"
                      disabled
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="calendario_de_presentacion"
                      label="Calendario de Presentación"
                    >
                      <Select
                        dataSource={plantilla.calendario_de_presentacion || []}
                        disabled
                        mode="multiple"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="meses_de_recurrencia"
                      label="Meses de Recurrencia"
                    >
                      <Select
                        dataSource={plantilla.meses_de_recurrencia}
                        disabled
                        mode="multiple"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <NumericInput
                      name="volumen_anual"
                      label="Volumen Anual"
                      disabled
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <NumericInput
                      name="numero_de_resoluciones_favorables"
                      label="No. de Resoluciones Favorables"
                      disabled
                    />
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
          </Collapse.Panel>
          <Collapse.Panel
            forceRender
            header={(
              <Space>
                <InfoCircleOutlined />
                <b>Información Básica III</b>
              </Space>
            )}
            key="basica_3"
          >
            <Collapse accordion>
              <Collapse.Panel
                forceRender
                header={(
                  <Space>
                    <InfoCircleOutlined />
                    <b>Tipo de Resolución</b>
                  </Space>
                )}
                key="basica_3_tipo_de_resolucion"
              >
                <Table
                  cols={[
                    {
                      titleText: 'Tipo De Resolución',
                      dataIndex: 'tipo_de_resolucion',
                      key: 'tipo_de_resolucion',
                      width: '100%',
                      render: (val) => val?.descripcion,
                    },
                  ]}
                  data={plantilla?.tipo_de_resolucion_plantilla || []}
                  mobileColIndex={0}
                  rowKey="id"
                  allowSearch={false}
                  allowSort={false}
                  noControls
                />
              </Collapse.Panel>
              <Collapse.Panel
                forceRender
                header={(
                  <Space>
                    <InfoCircleOutlined />
                    <b>Criterio de Resolución</b>
                  </Space>
                )}
                key="basica_3_criterio_de_resolucion"
              >
                <Table
                  cols={[
                    {
                      titleText: 'Criterio',
                      dataIndex: 'criterio',
                      key: 'criterio',
                      width: '100%',
                    },
                  ]}
                  data={plantilla?.citerio_de_resolucion_de_tramite || []}
                  mobileColIndex={0}
                  rowKey="id"
                  allowSearch={false}
                  allowSort={false}
                  noControls
                />
              </Collapse.Panel>
              <Collapse.Panel
                forceRender
                header={(
                  <Space>
                    <InfoCircleOutlined />
                    <b>Monto de los Derechos</b>
                  </Space>
                )}
                key="basica_3_monto_de_los_derechos"
              >
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="es_tramite_gratuito"
                      label="¿Es trámite Gratuito?"
                    >
                      <Select trueFalse disabled />
                    </Form.Item>
                  </Col>
                  {plantilla?.es_tramite_gratuito ? (
                    <>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="clave_de_concepto"
                          label="Clave de Concepto"
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="es_concepto_interno"
                          label="¿Es Concepto Interno?"
                        >
                          <Select trueFalse disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="descripcion_de_gratuito"
                          label="Descripción de Gratuito"
                          tooltip="Motivo por el que no se realizará cobro alguno"
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          label="Tipo de Cargo"
                          name="tipo_de_cargo"
                        >
                          <Select
                            dataSource={[plantilla.tipo_de_cargo]}
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="linea_capturada"
                          label="¿Es Línea Capturada?"
                        >
                          <Select disabled trueFalse />
                        </Form.Item>
                      </Col>
                      {plantilla?.linea_capturada && (
                        <Col xs={24} sm={24} md={8}>
                          <NumericInput
                            name="vigencia_de_linea_de_captura"
                            label="Vigencia"
                            tooltip="Vigencia en días de la línea de captura"
                            disabled
                          />
                        </Col>
                      )}
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="es_cuota_fija"
                          label="¿Es Cuota Fija?"
                        >
                          <Select trueFalse disabled />
                        </Form.Item>
                      </Col>
                      {plantilla?.es_cuota_fija && (
                        <Col xs={24} sm={24} md={8}>
                          <NumericInput
                            name="cuota_fija"
                            label="Cuota Fija"
                          />
                        </Col>
                      )}
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="metodologia_de_pago_para_calculo"
                          label="Metodología para el cálculo"
                          tooltip="Métodología utilizada para el cálculo del monto"
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="clave_de_computo"
                          label="Clave de Cómputo"
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="periodicidad"
                          label="Periodicidad"
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          label="¿Pago Continuo?"
                          name="pago_continuo"
                        >
                          <Select trueFalse disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          label="¿Pago Moral?"
                          name="pago_moral"
                          tooltip="Indica si será un pago realizado por una persona moral"
                        >
                          <Select trueFalse disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          label="Tipo de Moneda"
                          name="moneda"
                        >
                          <Select dataSource={[plantilla.moneda]} disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          label="¿Aplica IVA?"
                          name="aplica_iva"
                        >
                          <Select trueFalse disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          label="¿Saldo a Favor?"
                          name="saldo_a_favor"
                        >
                          <Select trueFalse disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          label="¿Parte Actualizada?"
                          name="parte_actualizada"
                        >
                          <Select trueFalse disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          label="¿Aplica Recargos?"
                          name="recargos"
                        >
                          <Select trueFalse disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          label="¿Multa por Correción?"
                          name="multa_por_correccion"
                        >
                          <Select trueFalse disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          label="¿Seguimiento en Internet?"
                          name="seguimiento_por_internet"
                        >
                          <Select trueFalse disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          label="Pago de Multiples Trámites"
                          name="pago_de_multiples_tramites"
                        >
                          <Select trueFalse disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="catalogo_de_canales_de_pago"
                          label="Lugar de Pago"
                        >
                          <Select
                            dataSource={plantilla.catalogo_de_canales_de_pago}
                            mode="multiple"
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="etapa_de_pago"
                          label="Etapa de Pago"
                        >
                          <Select
                            dataSource={[plantilla
                              .etapa_de_pago]}
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="descripcion_de_pago"
                          label="Descripción del Pago"
                        >
                          <Input.TextArea
                            disabled
                            autoSize={{ minRows: 2, maxRows: 4 }}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )}

                  <Col span={24}>
                    <Typography.Title level={4}>
                      Fundamentos Legales
                    </Typography.Title>
                    <Table
                      cols={[
                        {
                          titleText: 'Denominación',
                          dataIndex: 'denominacion',
                          key: 'denominacion',
                          width: 200,
                        },
                        {
                          titleText: 'Artículo',
                          dataIndex: 'articulo',
                          key: 'articulo',
                          width: 200,
                        },
                        {
                          titleText: 'Fracción',
                          dataIndex: 'fraccion',
                          key: 'fraccion',
                          width: 200,
                        },
                        {
                          titleText: 'Inciso',
                          dataIndex: 'inciso',
                          key: 'inciso',
                          width: 200,
                        },
                        {
                          titleText: 'Párrafo',
                          dataIndex: 'parrafo',
                          key: 'parrafo',
                          width: 200,
                        },
                      ]}
                      data={plantilla?.fundamento_legal_monto}
                      mobileColIndex={0}
                      rowKey="id"
                      allowSearch={false}
                      allowSort={false}
                      noControls
                    />
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>

          </Collapse.Panel>
          <Collapse.Panel
            forceRender
            header={(
              <Space>
                <InfoCircleOutlined />
                <b>Información Complementaria I</b>
              </Space>
            )}
            key="complementaria_1"
          >
            <Collapse accordion>
              <Collapse.Panel
                forceRender
                header={(
                  <Space>
                    <InfoCircleOutlined />
                    <b>Comentario General</b>
                  </Space>
                )}
                key="complementaria_1_comentario_general"
              >
                <Col span={24}>
                  <Form.Item
                    label="Volúmen de Molestias o Quejas Anuales"
                    name="volumen_de_consultas"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Table
                  cols={[
                    {
                      titleText: 'Tipo de Comentario',
                      dataIndex: 'tipo_de_comentario',
                      key: 'tipo_de_comentario',
                      width: 300,
                      render: (val) => val?.descripcion,
                    },
                    {
                      titleText: 'Comentarios',
                      dataIndex: 'comentario',
                      key: 'comentario',
                      width: 300,
                    },
                  ]}
                  data={plantilla?.comentario_general || []}
                  mobileColIndex={0}
                  rowKey="id"
                  allowSearch={false}
                  allowSort={false}
                  noControls
                />
              </Collapse.Panel>
              <Collapse.Panel
                forceRender
                header={(
                  <Space>
                    <InfoCircleOutlined />
                    <b>Subsector Económico</b>
                  </Space>
                )}
                key="complementaria_1_subsecor_economico"
              >
                <Table
                  cols={[
                    {
                      titleText: 'Proceso Económico',
                      dataIndex: 'proceso_economico',
                      key: 'proceso_economico',
                      width: 100,
                      render: (val) => val?.descripcion,
                    },
                    {
                      titleText: 'Subsector Económico',
                      dataIndex: 'sub_sector_economico',
                      key: 'sub_sector_economico',
                      width: 100,
                      render: (val) => listFormatter(val.map((e) => e?.descripcion)),
                    },
                  ]}
                  data={plantilla?.subsector_economico_de_plantilla || []}
                  mobileColIndex={0}
                  rowKey="id"
                  allowSearch={false}
                  allowSort={false}
                  noControls
                />
              </Collapse.Panel>
              <Collapse.Panel
                forceRender
                header={(
                  <Space>
                    <InfoCircleOutlined />
                    <b>TICS</b>
                  </Space>
                )}
                key="complementaria_1_tics"
              >
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="cuenta_con_sistema_de_gestion"
                      label="¿Cuenta con Sistema de Gestión?"
                    >
                      <Select disabled trueFalse />
                    </Form.Item>
                  </Col>
                  {plantilla?.cuenta_con_sistema_de_gestion ? (
                    <>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="nombre_del_sistema"
                          label="Nombre del Sistema"
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="tecnologia_del_sistema"
                          label="Tecnología del Sistema"
                        >
                          <Select
                            dataSource={plantilla.tecnologia_del_sistema}
                            disabled
                            defaultValue={plantilla?.tecnologia_del_sistema?.[0]?.id}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="otra_tecnologia_del_sistema"
                          label="Otra Tecnología del Sistema"
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="adaptacion"
                          label="Adaptación"
                          tooltip="Grado de adaptacion que el sistema ofrece para envio o recepcion de solicitudes electronicas"
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="complejidad"
                          label="Complejidad"
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <NumericInput
                          name="peticiones"
                          label="Peticiones"
                          disabled
                        />
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="criticidad_del_sistema"
                          label="Criticidad del Sistema"
                        >
                          <Select disabled trueFalse />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="disponibilidad_WS"
                          label="Disponibilidad WS"
                          tooltip="¿Disponibilidad de servicios mediante webservice?"
                        >
                          <Select disabled trueFalse />
                        </Form.Item>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="necesita_sistema_back_office"
                          label="¿Necesita Sistema de Back Office?"
                        >
                          <Select disabled trueFalse />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="seguimiento_por_internet"
                          label="¿Seguimiento por Internet?"
                        >
                          <Select disabled trueFalse />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="pago_electronico"
                          label="¿Pago Electrónico?"
                          tooltip="¿Utiliza pago electrónico (e5Cinco) de Derechos, Productos y Aprovechamientos (DPA´s)?"
                        >
                          <Select disabled trueFalse />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="clave_rupa"
                          label="¿Clave Rupa?"
                        >
                          <Select trueFalse disabled />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
              </Collapse.Panel>
            </Collapse>
          </Collapse.Panel>
          <Collapse.Panel
            forceRender
            header={(
              <Space>
                <InfoCircleOutlined />
                <b>Información Complementaria II</b>
              </Space>
            )}
            key="complementaria_2"
          >
            <Collapse accordion>
              <Collapse.Panel
                forceRender
                header={(
                  <Space>
                    <InfoCircleOutlined />
                    <b>Hecho Vital</b>
                  </Space>
                )}
                key="complementaria_2_hecho_vital"
              >
                <Table
                  cols={[
                    {
                      titleText: 'Beneficiario del trámite',
                      dataIndex: 'beneficiario',
                      key: 'beneficiario',
                      width: 300,
                      render: (val) => val?.descripcion,
                    },
                    {
                      titleText: 'Beneficiarios del Trámite',
                      dataIndex: 'beneficiarios_del_tramite',
                      key: 'beneficiarios_del_tramite',
                      width: 300,
                      render: (val) => val?.descripcion,
                    },
                    {
                      titleText: 'Categoria del Trámite',
                      dataIndex: 'categoria_del_tramite',
                      key: 'categoria_del_tramite',
                      width: 300,
                      render: (val) => val?.descripcion,
                    },
                  ]}
                  data={plantilla?.hecho_vital || []}
                  mobileColIndex={0}
                  rowKey="id"
                  allowSearch={false}
                  allowSort={false}
                  noControls
                />
              </Collapse.Panel>
              <Collapse.Panel
                forceRender
                header={(
                  <Space>
                    <InfoCircleOutlined />
                    <b>Familia del Trámite</b>
                  </Space>
                )}
                key="complementaria_2_familia_del_tramite"
              >
                <Table
                  cols={[
                    {
                      titleText: 'Familia del Tramite',
                      dataIndex: 'familia_de_tramite',
                      key: 'familia_de_tramite',
                      width: 100,
                      render: (val) => val?.descripcion,
                    },
                    {
                      titleText: 'Subcategoria de Familia del Trámite',
                      dataIndex: 'sub_categoria_de_familia_de_tramite',
                      key: 'sub_categoria_de_familia_de_tramite',
                      width: 100,
                      render: (val) => listFormatter(val.map((e) => e?.descripcion)),
                    },
                  ]}
                  data={plantilla?.familia_del_tramite_plantilla || []}
                  mobileColIndex={0}
                  rowKey="id"
                  allowSearch={false}
                  allowSort={false}
                  noControls
                />
              </Collapse.Panel>
              <Collapse.Panel
                forceRender
                header={(
                  <Space>
                    <InfoCircleOutlined />
                    <b>Procesos</b>
                  </Space>
                )}
                key="complementaria_2_procesos"
              >
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="tipo_de_tramite_proceso"
                      label="Proceso"
                    >
                      <Select
                        dataSource={[plantilla.tipo_de_tramite_proceso]}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  {plantilla.tipo_de_tramite_sub_proceso && (
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="tipo_de_tramite_sub_proceso"
                        label="Sub Proceso"
                      >
                        <Select
                          dataSource={[plantilla
                            .tipo_de_tramite_sub_proceso]}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {plantilla.tipo_de_tramite_opciones && (
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="tipo_de_tramite_opciones"
                        label="Opción"
                      >
                        <Select
                          dataSource={[plantilla
                            .tipo_de_tramite_opciones]}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {plantilla.tipo_de_tramite_respuesta && (
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="tipo_de_tramite_respuesta"
                        label="Respuesta"
                      >
                        <Select
                          dataSource={[plantilla
                            .tipo_de_tramite_respuesta]}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {plantilla.tipo_de_tramite_sub_opciones && (
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="tipo_de_tramite_sub_opciones"
                        label="Sub Opciones"
                      >
                        <Select
                          dataSource={[plantilla
                            .tipo_de_tramite_sub_opciones]}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {plantilla.tipo_de_tramite_sub_respuestas && (
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="tipo_de_tramite_sub_respuestas"
                        label="Sub Respuestas"
                      >
                        <Select
                          dataSource={[plantilla
                            .tipo_de_tramite_sub_respuestas]}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Collapse.Panel>
            </Collapse>
          </Collapse.Panel>
          <Collapse.Panel
            forceRender
            header={(
              <Space>
                <FileDoneOutlined />
                <b>Interoperabilidad</b>
              </Space>
            )}
            key="interoperabilidad"
          >
            <Collapse accordion>
              <Collapse.Panel
                forceRender
                header={(
                  <Space>
                    <ImportOutlined />
                    <b>Requisitos Generales</b>
                  </Space>
                )}
                key="interoperabilidad_requisios_generales"
              >
                <Typography.Title level={4}>
                  Casos de los Requisitos
                </Typography.Title>
                <Table
                  cols={[
                    {
                      titleText: 'Nombre',
                      dataIndex: 'nombre',
                      key: 'nombre',
                      width: 300,
                    },
                    {
                      titleText: 'Descripcion',
                      dataIndex: 'descripcion',
                      key: 'descripcion',
                      width: 300,
                    },
                  ]}
                  data={plantilla?.requisitos_generales || []}
                  mobileColIndex={0}
                  rowKey="id"
                  allowSearch={false}
                  allowSort={false}
                  noControls
                  expandable={{
                    expandedRowRender: (record) => (
                      <Table
                        cols={[
                          {
                            titleText: 'Tipo de Requisito',
                            dataIndex: 'tipo_de_requisito',
                            key: 'tipo_de_requisito',
                            width: 300,
                            render: (val) => val?.descripcion,
                          },
                          {
                            titleText: 'Cantidad de Originales',
                            dataIndex: 'cantidad_de_originales',
                            key: 'cantidad_de_originales',
                            width: 200,
                          },
                          {
                            titleText: 'Cantidad de Copias',
                            dataIndex: 'cantidad_de_copias',
                            key: 'cantidad_de_copias',
                            width: 200,
                          },
                          {
                            titleText: 'Fundamentos Legales',
                            dataIndex: 'fundamento_legal',
                            key: 'fundamento_legal',
                            width: 500,
                            render: (val) => listFormatter(val
                              ?.map((f) => fundamentoToString(f))),
                          },
                        ]}
                        data={record.requisitos || []}
                        rowKey="id"
                        allowSearch={false}
                        allowSort={false}
                        noControls
                      />
                    ),
                  }}
                />
              </Collapse.Panel>
              <Collapse.Panel
                forceRender
                header={(
                  <Space>
                    <ImportOutlined />
                    <b>Requisitos</b>
                  </Space>
                )}
                key="interoperabilidad_requisios"
              >
                <Typography.Title level={4}>
                  Casos de los Requisitos
                </Typography.Title>
                <Table
                  cols={[
                    {
                      titleText: 'Nombre',
                      dataIndex: 'nombre',
                      key: 'nombre',
                      width: 300,
                    },
                    {
                      titleText: 'Descripcion',
                      dataIndex: 'descripcion',
                      key: 'descripcion',
                      width: 300,
                    },
                  ]}
                  data={plantilla?.casos_de_los_requisitos || []}
                  mobileColIndex={0}
                  rowKey="id"
                  allowSearch={false}
                  allowSort={false}
                  noControls
                  expandable={{
                    expandedRowRender: (record) => (
                      <Table
                        cols={[
                          {
                            titleText: 'Tipo de Requisito',
                            dataIndex: 'tipo_de_requisito',
                            key: 'tipo_de_requisito',
                            width: 300,
                            render: (val) => val?.descripcion,
                          },
                          {
                            titleText: 'Cantidad de Originales',
                            dataIndex: 'cantidad_de_originales',
                            key: 'cantidad_de_originales',
                            width: 200,
                          },
                          {
                            titleText: 'Cantidad de Copias',
                            dataIndex: 'cantidad_de_copias',
                            key: 'cantidad_de_copias',
                            width: 200,
                          },
                          {
                            titleText: 'Fundamentos Legales',
                            dataIndex: 'fundamento_legal',
                            key: 'fundamento_legal',
                            width: 500,
                            render: (val) => listFormatter(val
                              ?.map((f) => fundamentoToString(f))),
                          },
                        ]}
                        data={record.requisitos || []}
                        rowKey="id"
                        allowSearch={false}
                        allowSort={false}
                        noControls
                      />
                    ),
                  }}
                />
              </Collapse.Panel>
              <Collapse.Panel
                forceRender
                header={(
                  <Space>
                    <InfoCircleOutlined />
                    <b>Documento de Salida</b>
                  </Space>
                )}
                key="interoperabilidad_documentos_de_salida"
              >
                <Table
                  cols={[
                    {
                      titleText: 'Descripción del Documento',
                      dataIndex: 'descripcion',
                      key: 'descripcion',
                      width: 300,
                    },
                    {
                      titleText: 'Folio',
                      dataIndex: 'folio',
                      key: 'folio',
                      width: 300,
                    },
                    {
                      titleText: 'Tipo de Documento',
                      dataIndex: 'tipo_de_documento',
                      key: 'tipo_de_documento',
                      width: 300,
                      render: (val) => val?.descripcion,
                    },
                    {
                      titleText: 'Tipo de Entrega del Documento',
                      dataIndex: 'tipo_de_entrega_del_documento_de_salida',
                      key: 'tipo_de_entrega_del_documento_de_salida',
                      width: 300,
                    },
                    {
                      titleText: 'Otro Tipo de Documento',
                      dataIndex: 'otro_tipo_de_documento',
                      key: 'otro_tipo_de_documento',
                      width: 300,
                    },
                    {
                      titleText: 'Formato de Folio',
                      dataIndex: 'formato_para_folio',
                      key: 'formato_para_folio',
                      width: 300,
                      render: (val) => val?.descripcion,
                    },
                    {
                      titleText: 'Firmante',
                      dataIndex: 'firmante',
                      key: 'firmante',
                      width: 300,
                      render: (val) => val?.email,
                    },
                  ]}
                  data={plantilla?.documento_de_salida_de_tramite || []}
                  mobileColIndex={0}
                  rowKey="id"
                  allowSearch={false}
                  allowSort={false}
                  noControls
                />
              </Collapse.Panel>
              <Collapse.Panel
                forceRender
                header={(
                  <Space>
                    <InfoCircleOutlined />
                    <b>Cadena</b>
                  </Space>
                )}
                key="interoperabilidad_cadena"
              >
                <Table
                  cols={[
                    {
                      titleText: 'Orden de Trámite',
                      dataIndex: 'orden_de_tramite',
                      key: 'orden_de_tramite',
                      width: 300,
                      render: (val) => val?.descripcion,
                    },
                    {
                      titleText: 'Secuencia',
                      dataIndex: 'secuencia',
                      key: 'secuencia',
                      width: 300,
                    },
                    {
                      titleText: 'Homoclave',
                      dataIndex: 'homoclave',
                      key: 'homoclave',
                      width: 300,
                    },
                  ]}
                  data={plantilla?.cadena || []}
                  mobileColIndex={0}
                  rowKey="id"
                  allowSearch={false}
                  allowSort={false}
                  noControls
                />
              </Collapse.Panel>
              <Collapse.Panel
                forceRender
                header={(
                  <Space>
                    <InfoCircleOutlined />
                    <b>Interacciones</b>
                  </Space>
                )}
                key="interoperabilidad_interacciones"
              >
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={8}>
                    <NumericInput
                      disabled
                      name="cantidad_de_pasos_total"
                      label="Cantidad de Pasos Totales"
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <NumericInput
                      disabled
                      name="cantidad_de_pasos_fuera"
                      label="Cantidad de Pasos Fuera"
                      tooltip="Cantidad de pasos fuera de la dependencia."
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <NumericInput
                      disabled
                      name="cantidad_de_documentos"
                      label="Cantidad de Documentos"
                      tooltip="Cantidad de documentos requeridos"
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="razones_salir"
                      label="Razones Salir"
                      tooltip="Razones por las cuales el ciudadano tiene que salir de la dependencia o entidad."
                    >
                      <Input.TextArea
                        disabled
                        autoSize={{ minRows: 2, maxRows: 4 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
          </Collapse.Panel>
          <Collapse.Panel
            forceRender
            header={(
              <Space>
                <SolutionOutlined />
                <b>Costeo Estándar</b>
              </Space>
            )}
            key="costeo_estandar"
          >
            <Collapse accordion>
              <Collapse.Panel
                forceRender
                header={(
                  <Space>
                    <InfoCircleOutlined />
                    <b>Costeo Estándar</b>
                  </Space>
                )}
                key="costeo_estandar_costeo_estandar"
              >
                <Table
                  cols={[
                    {
                      titleText: 'Nombre del Requisito',
                      dataIndex: 'nombre_del_requisito',
                      key: 'nombre_del_requisito',
                      width: 300,
                    },
                    {
                      titleText: 'Cantidad de Personas Externas',
                      dataIndex: 'cantidad_de_personas',
                      key: 'cantidad_de_personas',
                      width: 200,
                    },
                    {
                      titleText: '¿Necesita Traslado?',
                      dataIndex: 'necesita_traslado',
                      key: 'necesita_traslado',
                      width: 200,
                      render: (val) => (val ? 'Si' : 'No'),
                    },
                    {
                      titleText: 'Horas de Espera para Pagar',
                      dataIndex: 'horas_de_espera_para_pagar',
                      key: 'horas_de_espera_para_pagar',
                      width: 200,
                    },
                    {
                      titleText: 'Minutos de Espera para Pagar',
                      dataIndex: 'minutos_de_espera_para_pagar',
                      key: 'minutos_de_espera_para_pagar',
                      width: 200,
                    },
                    {
                      titleText: '¿Es Requisito Nuevo?',
                      dataIndex: 'requisito_nuevo',
                      key: 'requisito_nuevo',
                      width: 200,
                      render: (val) => (val ? 'Si' : 'No'),
                    },
                    {
                      titleText: '¿Cumple con el Requisito?',
                      dataIndex: 'cumple_con_el_requisito',
                      key: 'cumple_con_el_requisito',
                      width: 200,
                      render: (val) => (val ? 'Si' : 'No'),
                    },
                    {
                      titleText: 'Días para Cumplir con el Requisito',
                      dataIndex: 'dias_para_cumplir_con_el_requisito',
                      key: 'dias_para_cumplir_con_el_requisito',
                      width: 200,
                    },
                    {
                      titleText: 'Horas para Cumplir con el Requisito',
                      dataIndex: 'horas_para_cumplir_con_el_requisito',
                      key: 'horas_para_cumplir_con_el_requisito',
                      width: 200,
                    },
                    {
                      titleText: 'Minutos para Cumplir con el Requisito',
                      dataIndex: 'minutos_para_cumplir_con_el_requisito',
                      key: 'minutos_para_cumplir_con_el_requisito',
                      width: 200,
                    },
                    {
                      titleText: 'Tipo de Persona que Cumple con el Requisito',
                      dataIndex: 'tipos_de_personas_que_cumplen_con_los_requisitos',
                      key: 'tipos_de_personas_que_cumplen_con_los_requisitos',
                      width: 200,
                      render: (val) => (val || val?.descripcion),
                    },
                  ]}
                  data={plantilla?.costeo_estandar || []}
                  mobileColIndex={0}
                  rowKey="id"
                  allowSearch={false}
                  allowSort={false}
                  noControls
                />
              </Collapse.Panel>
            </Collapse>
          </Collapse.Panel>
          <Collapse.Panel
            forceRender
            header={(
              <Space>
                <SolutionOutlined />
                <b>Oficina de Atención</b>
              </Space>
            )}
            key="oficina_de_atencion"
          >
            <Collapse accordion>
              <Collapse.Panel
                forceRender
                header={(
                  <Space>
                    <SolutionOutlined />
                    <b>Oficina de Atención</b>
                  </Space>
                )}
                key="oficina_de_atencion_oficina_de_atencion"
              >
                <Table
                  cols={[
                    {
                      titleText: 'Descripción',
                      dataIndex: 'descripcion',
                      key: 'descripcion',
                      width: 300,
                    },
                    {
                      titleText: 'Teléfono',
                      dataIndex: 'numero_de_telefono',
                      key: 'numero_de_telefono',
                      width: 200,
                    },
                    {
                      titleText: 'Dirección',
                      dataIndex: 'direccion',
                      key: 'direccion',
                      width: 300,
                      render: (val) => address(val),
                    },
                  ]}
                  data={plantilla?.oficina_de_atencion || []}
                  mobileColIndex={0}
                  rowKey="id"
                  allowSearch={false}
                  allowSort={false}
                  noControls
                />
              </Collapse.Panel>
            </Collapse>
          </Collapse.Panel>
          <Collapse.Panel
            forceRender
            header={(
              <Space>
                <SolutionOutlined />
                <b>Formatos Requeridos</b>
              </Space>
            )}
            key="formato_requerido"
          >
            <Collapse accordion>
              <Collapse.Panel
                forceRender
                header={(
                  <Space>
                    <SolutionOutlined />
                    <b>Formatos Requeridos</b>
                  </Space>
                )}
                key="formatos_requeridos_formatos_requeridos"
              >
                <Table
                  cols={[
                    {
                      titleText: 'Identificador del Formato',
                      dataIndex: 'identificador_de_formato',
                      key: 'identificador_de_formato',
                      width: 300,
                    },
                    {
                      titleText: 'Descripción',
                      dataIndex: 'descripcion',
                      key: 'descripcion',
                      width: 300,
                    },
                  ]}
                  data={plantilla?.formato_requerido || []}
                  mobileColIndex={0}
                  rowKey="id"
                  rowSelection={rowSelection}
                  handleOnRowClick={handleOnRowClick}
                  customActions={[
                    {
                      onClick: () => {
                        const [key] = selectedRowKeys;
                        const match = plantilla?.formato_requerido
                          .find((e) => e.id === key);
                        if (match.descargar_archivo) {
                          setVisiblePreviewer(true);
                          setIFrameUrl(match.descargar_archivo);
                        } else {
                          message.info('No se cuenta con liga de documento');
                        }
                      },
                      icon: EyeOutlined,
                      text: 'Ver archivo',
                    },
                  ]}
                  allowSearch={false}
                  allowSort={false}
                />
              </Collapse.Panel>
            </Collapse>
          </Collapse.Panel>
        </Collapse>
      </Form>
      <Previewer
        visible={visiblePreviewer}
        setVisible={setVisiblePreviewer}
        iFrameUrl={iFrameUrl}
      />
    </>
  );
};

export default Vista;
