import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Card,
  Input,
} from 'antd';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import { onSuccess, onError } from 'utils/handlers';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';

const baseURI = 'tramites/comentarios-generales/';

const TableComentario = ({
  selectedRowKey,
  callback,
}) => {
  const [formTable] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);

  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tipoDeComentario, setTipoDeComentario] = useState([]);
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI, {
        params: {
          plantilla_de_tramites: selectedRowKey,
        },
      });

      if (response?.status === 200) {
        setData(response.data);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resComentario = await API.get('/catalogos/tipos-de-comentarios/');
      setTipoDeComentario(resComentario.data);
      if (selectedRowKey) {
        await fetchData();
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();

    return () => API.source.cancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    formTable.resetFields();
    setVisibleAlert(false);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await formTable.validateFields();
      const values = formTable.getFieldsValue();
      const [key] = selectedRowKeys;
      if (selectedRowKey) {
        values.plantilla_de_tramites = selectedRowKey;
        if (!key) {
          const response = await API.post(baseURI, values);
          if (response?.status === 201) {
            onSuccess(response, 'Agregado correctamente');
            onCancel();
            await fetchData();
          }
        } else {
          const response = await API.put(`${baseURI}${key}/`, values);
          if (response?.status === 200) {
            onSuccess(response, 'Actualizado correctamente');
            onCancel();
            await fetchData();
          }
        }
      } else {
        const clone = [...data];
        if (!key) {
          clone.push({
            ...values,
            key: clone.length + 1,
          });
          setData(clone);
          callback(clone);
          onCancel();
        } else {
          const res = clone.map((item) => (item.key === key ? { ...values, key } : item));
          setData(res);
          callback(res);
          onCancel();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      if (selectedRowKey) {
        if (key) {
          const response = await API.delete(`${baseURI}${key}/`);
          if (response?.status === 204) {
            onSuccess(response, 'Eliminado correctamente');
            onCancel();
            await fetchData();
          }
        }
      } else {
        const clone = data.filter((item) => item.key !== key);
        const normalized = clone.map((item, idx) => ({ ...item, key: idx }));
        setData(normalized);
        callback(normalized);
        onCancel();
        setVisibleAlert(false);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickAdd = () => {
    setVisible(true);
    setSelectedRowKeys([]);
    formTable.resetFields();
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const handleOnRowClick = (record) => {
    const _key = selectedRowKey ? record.id : record.key;
    setSelectedRowKeys([_key]);
    formTable.setFieldsValue(record);
  };
  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const columns = [
    {
      titleText: 'Tipo de Comentario',
      dataIndex: 'tipo_de_comentario',
      key: 'tipo_de_comentario',
      width: 300,
      render: (val) => tipoDeComentario.find((item) => item.id === val)?.descripcion,
    },

    {
      titleText: 'Comentarios',
      dataIndex: 'comentario',
      key: 'comentario',
      width: 300,
    },
  ];

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (

          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            mobileColIndex={0}
            rowKey={selectedRowKey ? 'id' : 'key'}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            allowImport
            baseURI={baseURI}
          />
        ) : (
          <Card
            bordered={false}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
              />
             )}
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Comentario General`}
          >
            <Form
              layout="vertical"
              form={formTable}
              onFinish={onFinish}
              scrollToFirstError
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="tipo_de_comentario"
                    label="Tipo de Comentario"
                  >
                    <Select dataSource={tipoDeComentario} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="comentario"
                    label="Comentarios"
                  >
                    <Input.TextArea
                      maxLength={250}
                      allowClear
                      autoSize={{ minRows: 2, maxRows: 4 }}
                    />
                  </Form.Item>
                </Col>

                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content="El Comentario seleccionado"
        />
      </Spin>
    </Row>
  );
};
TableComentario.propTypes = {

  selectedRowKey: PropTypes.number,
  callback: PropTypes.func.isRequired,

};
TableComentario.defaultProps = {
  selectedRowKey: null,
};

export default TableComentario;
