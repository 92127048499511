module.exports = (values, setHasAddress) => {
  if (values) {
    const { codigo_postal, tipo_de_vialidad } = values;
    const municipio = codigo_postal?.municipio;
    const descripcion = tipo_de_vialidad?.descripcion || '';
    const calle = values?.calle_principal || '';
    const numExterior = `${values?.numero_exterior !== 'S/N' ? '#' : ''}${values?.numero_exterior || ''}`;
    const colonia = codigo_postal?.d_tipo_asenta || '';
    const asentamiento = colonia?.d_asenta || '';
    const codigo = colonia?.d_codigo || '';
    const nombreMunicipio = municipio?.nom_mun || '';
    const estado = colonia?.municipio?.estado?.nombre_de_AGEE || '';
    const str = `${descripcion} ${calle} ${numExterior ? `${numExterior},` : ''} ${colonia} ${asentamiento ? `${asentamiento},` : ''} ${codigo ? `${codigo},` : ''} ${nombreMunicipio ? `${nombreMunicipio},` : ''} ${estado}`;
    return str.trim().replace(/,\s*$/, '');
  }
  if (typeof setHasAddress === 'function') {
    setHasAddress(false);
  }
  return '';
};
