import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Col,
  Form,
  Input,
  Button,
  Spin,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import Password from 'components/Password';
import API from 'utils/api';
import {
  defaultOptions,
  allRequirementsPassed,
  validatePasswordRequirements,
} from 'utils/passwordRequirements';
import { onError, onSuccess } from 'utils/handlers';

const ModalChangePassword = ({
  visible,
  setVisible,
}) => {
  const [form] = Form.useForm();
  const [options, setOptions] = useState(defaultOptions);
  const [loading, setLoading] = useState(false);

  let timeout = null;
  const onFormValuesChange = (changedValues, allValues) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      const { password } = changedValues;
      if (password) {
        const newOps = validatePasswordRequirements(password);
        setOptions(newOps);
      } else if (!allValues.password) {
        setOptions(defaultOptions);
      }
      form.validateFields(['password', 'confirmPassword']);
    }, 500);
  };

  const rules = {
    password: [
      {
        validator: async () => {
          const password = form.getFieldValue('password');
          if (!allRequirementsPassed(password)) {
            throw new Error('La contraseña no cumple con los requisitos');
          }
        },
      },
    ],
    confirmPassword: [
      {
        validator: async (rule, value) => {
          const password = form.getFieldValue('password');
          if (value && value !== password) {
            throw new Error('Las contraseñas no coinciden.');
          }
        },
      },
    ],
    currentPassword: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setOptions(defaultOptions);
  };

  const onFinishPwd = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const response = await API.put('usuarios/actualizar-contrasena', {
        contrasena: values.currentPassword,
        contrasena_nueva: values.confirmPassword,
      });
      if (response?.status === 200) {
        onSuccess(response, 'Contraseña actualizada correctamente');
        onCancel();
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  return (
    <Modal
      open={visible}
      footer={false}
      title={(
        <FormSubmitControls
          label="Actualizar Contraseña"
          onFinish={onFinishPwd}
          onCancel={onCancel}
          loading={loading}
        />
      )}
      onCancel={onCancel}
      closable={false}
      maskClosable={!loading}
      keyboard={!loading}
      width={600}
    >
      <Spin tip="Cargando..." spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          name="update_password"
          onFinish={onFinishPwd}
          onValuesChange={onFormValuesChange}
        >
          <Col span={24}>
            <Form.Item
              label="Contraseña actual"
              name="currentPassword"
              rules={rules.currentPassword}
            >
              <Input.Password allowClear />
            </Form.Item>
          </Col>
          <Password
            rules={rules}
            options={options}
          />
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

ModalChangePassword.propTypes = {
  setVisible: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

ModalChangePassword.defaultProps = {
  visible: false,
};

export default ModalChangePassword;
