import sir from './assets/sir.png';
import ac from './assets/ac.png';
import gd from './assets/gd.png';
import sac from './assets/sac.png';

const services = [
  {
    id: 1,
    imagen: sir,
    url: 'https://sir.migob.mx',
  },
  {
    id: 2,
    imagen: ac,
    url: 'https://atencionciudadana.migob.mx',
  },
  {
    id: 3,
    imagen: gd,
    url: 'https://gobiernodigital.migob.mx',
  },
  {
    id: 4,
    imagen: sac,
    url: 'https://sac.migob.mx',
  },
];

export default services;
