import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Layout,
  Col,
  Row,
  Typography,
} from 'antd';
import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  YoutubeOutlined,
  LinkedinOutlined,
  // WechatOutlined,
  MessageOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import fallback from 'assets/fallback.png';
import A from './A';

export const socialIcons = {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  YoutubeOutlined,
  LinkedinOutlined,
};

const SocialMediaItem = ({ item }) => {
  const Icon = socialIcons[item.icono];
  if (!Icon) {
    return null;
  }
  return (
    <A style={{ padding: 5, fontSize: 32 }} href={item.url} external>
      <Icon />
    </A>
  );
};

SocialMediaItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    url: PropTypes.string,
    icono: PropTypes.string,
  }).isRequired,
};

const Footer = ({
  editing,
  entidad: {
    coordenadas,
    contacto,
    redes_sociales,
    email,
    ...entidad
  },
}) => (
  <AntFooter>
    <Row gutter={[10, 20]} style={{ justifyContent: 'center' }}>
      <Col xs={0} sm={0} md={!editing ? 4 : 0} lg={!editing ? 3 : 4} xxl={2}>
        <Img src={entidad.logo_vertical || fallback} />
      </Col>
      <Col xs={24} sm={24} md={!editing ? 12 : 14} lg={!editing ? 14 : 12} xl={11} xxl={8}>
        <Title level={4} style={{ fontWeigth: 'bold' }}>
          {`${new Date().getFullYear()} © ${entidad.nombre}`}
        </Title>
        <RowFlex>
          <MessageOutlined />
          {email ? (
            <A href={`mailto:${email}`} useAnchor>
              <Text>
                {email}
              </Text>
            </A>
          ) : (
            <Text>Correo electrónico</Text>
          )}
        </RowFlex>
        <RowFlex>
          <PhoneOutlined />
          {contacto?.numero_de_telefono ? (
            <A href={`tel:${contacto.lada?.lada}${contacto.numero_de_telefono}`} useAnchor>
              <Text>
                {`${contacto.lada?.lada} ${contacto.numero_de_telefono}`}
              </Text>
            </A>
          ) : (
            <Text>Número de teléfono</Text>
          )}
        </RowFlex>
        <RowFlex>
          <EnvironmentOutlined />
          {(coordenadas && contacto?.direccion) ? (
            <A
              href={`https://www.openstreetmap.org/?mlat=${coordenadas.latitud}&mlon=${coordenadas.longitud}#map=17/${coordenadas.latitud}/${coordenadas.longitud}&layers=T`}
              external
            >
              <Text>
                {contacto.direccion}
              </Text>
            </A>
          ) : (
            <Text>Dirección</Text>
          )}
        </RowFlex>
        <RowFlex>
          <ClockCircleOutlined />
          {contacto?.horario ? (
            <Text>
              {contacto.horario}
            </Text>
          ) : (
            <Text>Horario</Text>
          )}
        </RowFlex>
      </Col>
      <Col xs={24} sm={24} md={!editing ? 8 : 10} lg={7} xl={5} xxl={4}>
        <Title level={4}>
          Siguenos en:
        </Title>
        {redes_sociales?.some((e) => socialIcons[e.icono]) ? (
          <SocialMedia>
            {redes_sociales.map((item) => (
              <SocialMediaItem key={item.id} item={item} />
            ))}
          </SocialMedia>
        ) : (
          <>
            <Text>Links a Redes Sociales</Text>
            <br />
            <br />
            <Text>
              <b>Nota:</b>
              {' '}
              algunos datos son editables solo en la sección de entidad.
            </Text>
          </>
        )}
      </Col>
    </Row>
  </AntFooter>
);

Footer.propTypes = {
  entidad: PropTypes.shape({
    logo: PropTypes.string,
    logo_vertical: PropTypes.string,
    nombre: PropTypes.string,
    tema: PropTypes.shape({
      color_de_fondo_primario: PropTypes.string,
      color_de_fondo_secundario: PropTypes.string,
      color_de_texto_primario: PropTypes.string,
      color_de_texto_secundario: PropTypes.string,
    }),
    email: PropTypes.string,
    contacto: PropTypes.shape({
      lada: PropTypes.shape({
        lada: PropTypes.string,
      }),
      numero_de_telefono: PropTypes.number,
      direccion: PropTypes.string,
      horario: PropTypes.string,
    }),
    redes_sociales: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string,
      icon: PropTypes.string,
    })),
    coordenadas: PropTypes.shape({
      latitud: PropTypes.number,
      longitud: PropTypes.number,
    }),
    external_link: PropTypes.string,
  }).isRequired,
  editing: PropTypes.bool,
};

Footer.defaultProps = {
  editing: false,
};

const AntFooter = styled(Layout.Footer)`
  width: 100%;
  @media only screen and (max-width: 992px) {
    padding: 10px 25px;
  }
`;

const Title = styled(Typography.Title)`
  color: #FFFFFF !important;
  text-decoration: underline;
  text-transform: uppercase;
`;

const Text = styled(Typography.Text)`
  font-size: 16px;
  color: #FFFFFF !important;
`;

const Img = styled.img.attrs({ draggable: false })`
  max-height: 120px;
  margin: auto;
  object-fit: cover;
  user-select: none;
  width: inherit;
  height: inherit;
`;

const SocialMedia = styled.div`
  display: flex;
  flex-direction: row;
`;

const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  span.ant-typography {
    padding-left: 5px;
    font-size: 16px;
  }
`;

export default Footer;
