import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Card,
  Checkbox,
} from 'antd';
import Table from 'components/Table';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import formatValues from 'utils/formatValues';
import ProcedimientoRetys, { baseURI as baseURIProcedimiento } from './ProcedimientoRetys';

const baseURI = '/tramites/configuraciones-de-flujos-retys/';

const ConfiguracionFlujoRetys = () => {
  const [form] = Form.useForm();
  const folioPorDependencia = Form.useWatch('es_folio_por_dependencia', form);
  const proceso = Form.useWatch('proceso_de_retys', form);
  const requiereValidar = Form.useWatch('requiere_validar', form);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [foliadores, setFoliadores] = useState([]);
  const [procesos, setProcesos] = useState([]);
  const [procedimientos, setProcedimientos] = useState([]);
  const [roles, setRoles] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      if (res?.status === 200) {
        setData(res.data);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setVisibleAlert(false);
    setProcedimientos([]);
  };

  const options = [
    {
      label: 'Folio Único',
      value: false,
    },
    {
      label: 'Folio Por Dependencia',
      value: true,
    },
  ];

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resFolio = await API.get('/configuracion/configuraciones-de-segmentos-de-folios/');
      setFoliadores(resFolio.data || []);

      const resProceso = await API.get('/tramites/procesos-de-retys/');
      setProcesos(resProceso.data || []);

      const resRoles = await API.get('/catalogos/grupos/');
      setRoles(resRoles.data.map((e) => ({ ...e, name: e.name.split(' - ')[0] })) || []);

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const postProcedimientos = async (configuracion_de_flujo_de_retys) => {
    const promises = [];
    procedimientos.forEach((segmento) => {
      const promise = API.post(baseURIProcedimiento, formatValues({
        ...segmento,
        configuracion_de_flujo_de_retys,
      }, true));
      promises.push(promise);
    });
    if (promises.length && configuracion_de_flujo_de_retys) {
      await Promise.all(promises);
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await postProcedimientos(response.data.id);
          await fetchData();
        }
      } else {
        const [rowKey] = selectedRowKeys;
        const response = await API.put(`${baseURI}${rowKey}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      if (selectedRowKeys.length) {
        const [rowKey] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${rowKey}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          onCancel();
          await fetchData();
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Proceso',
      dataIndex: 'proceso_de_retys',
      key: 'proceso_de_retys',
      width: 150,
      render: (val) => procesos.find((e) => e.id === val)?.descripcion,
    },
    {
      titleText: 'Foliador',
      dataIndex: 'folio_de_retys',
      key: 'folio_de_retys',
      width: 150,
      render: (val) => foliadores.find((e) => e.id === val)?.descripcion,
    },
    {
      titleText: 'Requiere validar',
      dataIndex: 'requiere_validar',
      key: 'requiere_validar',
      width: 150,
      render: (val) => (val ? 'Si' : 'No'),
    },
    {
      titleText: 'Folio único o por dependencia',
      dataIndex: 'es_folio_por_dependencia',
      key: 'es_folio_por_dependencia',
      width: 250,
      render: (val) => (val ? 'Por Dependencia' : 'Folio Único'),
    },
  ];

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin
        tip="Cargando..."
        spinning={loading}
      >
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            loading={loading}
            mobileColIndex={0}
            rowKey="id"
          />
        ) : (
          <Card
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Flujo`}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
                loading={loading}
              />
            )}
            bordered={false}
          >
            <Col span={24}>
              <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                scrollToFirstError
                initialValues={{
                  estados_globales: 1,
                  es_folio_por_dependencia: false,
                  requiere_validar: false,
                }}
              >
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="proceso_de_retys"
                      rules={rules.required}
                      label="Proceso"
                      hasFeedback
                    >
                      <Select dataSource={procesos} />
                    </Form.Item>
                  </Col>
                  {proceso === 1 && (
                    <>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="es_folio_por_dependencia"
                          label="Folio Único o por dependencia"
                          rules={rules.required}
                        >
                          <Select
                            labelProp="label"
                            valueProp="value"
                            dataSource={options}
                          />
                        </Form.Item>
                      </Col>
                      {!folioPorDependencia && (
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="folio_de_retys"
                            rules={rules.required}
                            label="Folio"
                            hasFeedback
                          >
                            <Select dataSource={foliadores} />
                          </Form.Item>
                        </Col>
                      )}
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="requiere_validar"
                          valuePropName="checked"
                          style={{ marginTop: 21 }}
                        >
                          <Checkbox>
                            Se requiere validar Fichas
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                  {requiereValidar && (
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="roles"
                        rules={rules.required}
                        label="Rol que valida"
                        hasFeedback
                      >
                        <Select dataSource={roles} labelProp="name" />
                      </Form.Item>
                    </Col>
                  )}
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="seguimiento_de_propietario"
                      valuePropName="checked"
                      style={{ marginTop: 21 }}
                    >
                      <Checkbox>
                        Seguimiento solo de propietarios
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Row>
                <ProcedimientoRetys
                  selectedRowKey={selectedRowKeys[0]}
                  onChangeData={(_data) => setProcedimientos(_data)}
                  proceso={proceso}
                />
              </Form>
            </Col>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`Configuración Flujo Retys ${form.getFieldValue('descripcion')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
};

export default ConfiguracionFlujoRetys;
