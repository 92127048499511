/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  message,
  Tooltip,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import API from 'utils/api';
import fetchSome from 'utils/fetchSome';
import makeMessager from 'utils/complexFormsMessages';
import { onError } from 'utils/handlers';
import { SET_TIPOS_VIALIDADES } from 'store/reducers/catalogs';
import Select from 'components/Select';
import { getCatalogoDePaises, getCodigosPostales } from 'api/catalogos';
import Mapa from 'components/Mapa';
import { toInteger } from 'utils/normalizers';
import usePaginatedFetcher from 'hooks/usePaginatedFetcher';
import ModalSearch from './ModalSearch';
// import DireccionVerificada from './Verificada';

const rules = {
  required: [{ required: true }],
  entre_calle: [
    ({ getFieldValue }) => ({
      validator: async (_, value) => {
        if (!value && getFieldValue('tipo_de_vialidad_entre')) {
          throw new Error('El campo es requerido');
        }
      },
    }),
  ],
  tipo_de_vialidad_entre: [
    ({ getFieldValue }) => ({
      validator: async (_, value) => {
        if (!value && getFieldValue('entre_calle')) {
          throw new Error('El campo es requerido');
        }
      },
    }),
  ],
  y_calle: [
    ({ getFieldValue }) => ({
      validator: async (_, value) => {
        if (!value && getFieldValue('tipo_de_vialidad_y_calle')) {
          throw new Error('El campo es requerido');
        }
      },
    }),
  ],
  tipo_de_vialidad_y_calle: [
    ({ getFieldValue }) => ({
      validator: async (_, value) => {
        if (!value && getFieldValue('y_calle')) {
          throw new Error('El campo es requerido');
        }
      },
    }),
  ],
};

const FormItemsDireccion = forwardRef((props, ref) => {
  const {
    setVisibleNoSeCodigo,
    paises,
    collpasedSidebar,
    tiposVialidades,
    // allowVerify,
    // permission,
    setLoading,
    visibleNoSeCodigo,
    addressPropName,
    triggerClone,
    // mutable: _mutable,
    mutable,
    entidad,
    allowMap,
  } = props;
  const formInstance = Form.useFormInstance();
  const direccionId = Form.useWatch('id', formInstance);
  const qColonias = useRef();
  // const mutable = _mutable && ((!direccionId && permission?.add)
  //   || (direccionId && permission?.change));

  const [
    colonias,
    fetchColonias,
    loadingFetch = false,
  ] = usePaginatedFetcher(getCodigosPostales, { onlyOnePage: false });

  const fillValuesFromColonies = (_colonias) => {
    try {
      setLoading(true);
      const _pais = formInstance.getFieldValue('pais');
      setTimeout(async () => {
        const { results } = _colonias;
        const resultsFromCountry = results.filter((e) => e.municipio.pais === _pais);
        if (resultsFromCountry.length) {
          const [firstItem] = resultsFromCountry;
          if (resultsFromCountry.length === 1) {
            formInstance.setFieldsValue({
              colonia: firstItem.id,
              codigo_postal: firstItem.d_codigo,
              estado: firstItem.municipio.estado.nombre_de_AGEE,
              municipio: firstItem.municipio.nom_mun,
              pais: firstItem?.municipio?.estado?.pais || firstItem.municipio.estado.pais.nombre,
            });
          } else {
            formInstance.setFieldsValue({
              codigo_postal: firstItem.d_codigo,
              estado: firstItem.municipio.estado.nombre_de_AGEE,
              municipio: firstItem.municipio.nom_mun,
              colonia: undefined,
            });
          }
        } else {
          message.info(`No se encontro información relacionada al código postal brindado ${results.length ? 'en el país seleccionado' : ''}`);
          formInstance.setFieldsValue({
            codigo_postal: undefined,
            estado: undefined,
            municipio: undefined,
            colonia: undefined,
          });
        }
      }, 300);
      setLoading(false);
      ref.current?.focus();
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onFinishSearch = async (values) => {
    const match = Array.isArray(values) ? values[0] : values;
    if (match) {
      formInstance.setFieldsValue({
        estado: match.municipio.estado.nombre_de_AGEE,
        municipio: match.municipio.nom_mun,
        colonia: match.id,
        codigo_postal: match.d_codigo,
      });
      await fetchColonias(1, null, { d_codigo: match.d_codigo });
      setVisibleNoSeCodigo(false);
    } else {
      message.warn('Seleccione un elemento');
    }
  };

  const onSearchCodigoPostal = async (d_codigo, fill = true) => {
    if (d_codigo?.toString()?.length === 5) {
      const _colonias = await fetchColonias(1, null, { d_codigo });
      if (fill) {
        fillValuesFromColonies(_colonias);
      }
    }
  };

  useEffect(() => {
    if (direccionId) {
      const d_codigo = formInstance.getFieldValue('codigo_postal');
      onSearchCodigoPostal(d_codigo, false);
    } else {
      setTimeout(() => formInstance
        .setFieldsValue({ pais: entidad.pais, tipo_de_vialidad: 5 }), 1000);
    }
  }, [direccionId]);

  useEffect(() => setLoading(loadingFetch), [loadingFetch]);

  useEffect(() => {
    const d_codigo = formInstance.getFieldValue('codigo_postal');
    if (d_codigo) {
      onSearchCodigoPostal(d_codigo);
    }
  }, []);

  useEffect(() => {
    if (triggerClone) {
      const d_codigo = formInstance.getFieldValue('codigo_postal');
      if (d_codigo) {
        onSearchCodigoPostal(d_codigo);
      }
    }
  }, [triggerClone]);

  return (
    <>
      <Col span={24}>
        <Row gutter={10} align="middle">
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label={(
                <>
                  <span style={{ marginRight: 5 }}>
                    Código Postal
                  </span>
                  {mutable && (
                    <Tooltip
                      title="Ingresar este campo llenara automáticamente estado, municipio y las colonias disponibles"
                    >
                      <QuestionCircleOutlined className="primary-on-hover" />
                    </Tooltip>
                  )}
                </>
              )}
              name="codigo_postal"
              normalize={toInteger}
            >
              <Input.Search
                onPressEnter={(e) => {
                  e.preventDefault();
                  onSearchCodigoPostal(formInstance.getFieldValue('codigo_postal'));
                }}
                maxLength={6}
                onSearch={onSearchCodigoPostal}
                disabled={!mutable}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item>
              <Button
                type="link"
                onClick={() => setVisibleNoSeCodigo(true)}
                className="primary-on-hover"
                style={{ marginTop: 22 }}
                disabled={!mutable}
              >
                No sé mi código
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col xs={24} sm={24} md={6}>
            <Form.Item
              name="pais"
              label="País"
              rules={rules.required}
            >
              <Select
                dataSource={paises}
                labelProp="descripcion"
                disabled={!mutable}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <Form.Item
              name="estado"
              label="Estado"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <Form.Item
              name="municipio"
              label="Municipio"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <Form.Item
              label="Colonia"
              name="colonia"
              rules={rules.required}
            >
              <Select
                dataSource={colonias}
                labelProp="d_asenta"
                onPopupScroll={(e) => {
                  if (colonias.next) {
                    const { scrollHeight, scrollTop, clientHeight } = e.target;
                    if ((scrollHeight - Math.ceil(scrollTop) === clientHeight && scrollTop !== 0)) {
                      setTimeout(() => fetchColonias(colonias.page + 1, qColonias.current));
                    }
                  }
                }}
                onInputKeyDown={(e) => {
                  if (e.key === 'Enter' && qColonias.current !== e.target.value) {
                    qColonias.current = e.target.value;
                    e.preventDefault();
                    e.stopPropagation();
                    fetchColonias(1, qColonias.current);
                  }
                }}
                disabled={!mutable}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={16} />
          <Col xs={24} sm={24} md={12} lg={collpasedSidebar ? 8 : 12}>
            <Form.Item noStyle>
              <Input.Group style={{ display: 'flex' }} className="select-input" compact>
                <Form.Item
                  name="tipo_de_vialidad"
                  rules={rules.required}
                  label="Tipo"
                  style={{ width: '40%' }}
                >
                  <Select dataSource={tiposVialidades} disabled={!mutable} />
                </Form.Item>
                <Form.Item
                  name="calle_principal"
                  label="Vialidad Principal"
                  rules={rules.required}
                  style={{ width: '60%' }}
                >
                  <Input maxLength={170} disabled={!mutable} allowClear />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <Form.Item
              name="numero_exterior"
              label="Número Exterior"
              rules={rules.required}
            >
              <Input maxLength={20} ref={ref} disabled={!mutable} allowClear />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <Form.Item
              name="numero_interior"
              label="Número Interior"
            >
              <Input maxLength={20} disabled={!mutable} allowClear />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={collpasedSidebar ? 8 : 12}>
            <Form.Item noStyle>
              <Input.Group style={{ display: 'flex' }} className="select-input" compact>
                <Form.Item
                  name="tipo_de_vialidad_entre"
                  label="Tipo"
                  rules={rules.tipo_de_vialidad_entre}
                  style={{ width: '40%' }}
                >
                  <Select dataSource={tiposVialidades} disabled={!mutable} />
                </Form.Item>
                <Form.Item
                  name="entre_calle"
                  rules={rules.entre_calle}
                  label="Entre Vialidad"
                  className="affix-wrapper-without-padding"
                  style={{ width: '60%' }}
                >
                  <Input maxLength={170} disabled={!mutable} allowClear />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={collpasedSidebar ? 8 : 12}>
            <Form.Item noStyle>
              <Input.Group style={{ display: 'flex' }} className="select-input" compact>
                <Form.Item
                  name="tipo_de_vialidad_y_calle"
                  label="Tipo"
                  rules={rules.tipo_de_vialidad_y_calle}
                  style={{ width: '40%' }}
                >
                  <Select dataSource={tiposVialidades} disabled={!mutable} />
                </Form.Item>
                <Form.Item
                  name="y_calle"
                  rules={rules.y_calle}
                  label="Y Vialidad"
                  style={{ width: '60%' }}
                >
                  <Input maxLength={170} disabled={!mutable} allowClear />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="descripcion_de_la_ubicacion"
              label="Descripción de la Ubicación"
            >
              <Input.TextArea
                autoSize={{ minRows: 2, maxRows: 3 }}
                maxLength={250}
                disabled={!mutable}
                allowClear
              />
            </Form.Item>
          </Col>
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Row>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        {allowMap && (
          <Col span={24}>
            <Mapa
              showAddress
              interactive={mutable}
              form={formInstance}
            />
          </Col>
        )}
        {/* {allowVerify && direccionId && (
          <Col span={24}>
            <DireccionVerificada
              direccionId={direccionId}
              permission={permission?.direccionverificadadecontribuyente}
            />
          </Col>
        )} */}
      </Col>
      <ModalSearch
        setLoading={setLoading}
        callback={onFinishSearch}
        setVisible={setVisibleNoSeCodigo}
        visible={visibleNoSeCodigo}
        addressPropName={addressPropName}
      />
    </>
  );
});

const Direccion = ({
  useParentID,
  parentID,
  setLoading,
  callback,
  currentURI: _currentURI,
  setFormAddress,
  addressPropName,
  setCurrentTabKey,
  mutable,
  // mutable: _mutable,
  permission,
  allowMap,
  allowVerify,
  triggerClone,
  valuesCallback,
}) => {
  const currentURI = _currentURI.at(-1) !== '/' ? `${_currentURI}/` : _currentURI;
  const [formInstance] = Form.useForm();
  // const direccionId = Form.useWatch('id', formInstance);
  const tiposVialidades = useSelector(({ catalogs }) => catalogs.tiposVialidades);
  const collpasedSidebar = useSelector(({ app }) => app.collpasedSidebar);
  const entidad = useSelector(({ auth }) => auth.entidad);
  const [visibleNoSeCodigo, setVisibleNoSeCodigo] = useState(false);
  const [paises, setPaises] = useState({ results: [] });
  const ref = useRef();
  // const mutable = _mutable && ((!direccionId && permission?.add)
  //    || (direccionId && permission?.change));

  const formatValues = (values) => {
    const keys = Object.keys(values);
    const clone = {};
    keys.forEach((prop) => {
      const value = values[prop];
      if (value && value !== 'N/A') {
        clone[prop] = value;
      }
    });
    return clone;
  };

  const getCodigoPostal = async (codigoPostal) => {
    if (!codigoPostal?.d_codigo) {
      const resCP = await API.get(`/catalogos/codigos-postales/${codigoPostal}/`);
      return resCP.data;
    }
    return codigoPostal;
  };

  const getDireccion = async () => {
    try {
      setLoading(true);
      formInstance.resetFields();
      if (!useParentID || parentID) {
        const { data } = await API.get(`${currentURI}${useParentID ? parentID : ''}/`);
        const addresData = data[`${addressPropName}`];
        if (addresData?.id) {
          const { codigo_postal } = addresData;
          const codigoPostal = await getCodigoPostal(codigo_postal?.id || codigo_postal);
          const formattedValues = formatValues({
            ...addresData,
            pais: codigoPostal.municipio.estado.pais.nombre || undefined,
            estado: codigoPostal.municipio.estado.nombre_de_AGEE || undefined,
            municipio: codigoPostal.municipio.nom_mun || undefined,
            codigo_postal: codigoPostal.d_codigo,
            colonia: codigoPostal.id,
          });
          if (valuesCallback) {
            valuesCallback(formattedValues);
          }
          formInstance.setFieldsValue(formattedValues);
        }
      }
      setTimeout(() => {
        if (setFormAddress) {
          // eslint-disable-next-line no-use-before-define
          formInstance.onFinishHandler = onFinishHandler;
          setFormAddress(formInstance);
        }
        ref.current?.focus();
        setLoading(false);
      });
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const showMsg = makeMessager(setCurrentTabKey, getDireccion);

  const onFinish = async (_continue = true) => {
    try {
      setLoading(true);
      await formInstance.validateFields();
      const values = formInstance.getFieldsValue();

      const resPatch = await API.patch(`${currentURI}${useParentID ? parentID : ''}`, {
        [`${addressPropName}`]: {
          ...values,
          codigo_postal: values.colonia,
        },
      });
      if (resPatch?.status === 200) {
        message.info('Dirección agregada correctamente');
        setTimeout(async () => {
          callback(resPatch.data.address, _continue);
          if (_continue) {
            await getDireccion();
          }
        });
      }
      setTimeout(() => {
        setLoading(false);
      });
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onFinishHandler = async (tabKey = null, _continue = true) => {
    try {
      if (tabKey) {
        showMsg(tabKey);
      } else {
        await onFinish(_continue);
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    let isMounted = true;
    const fetch = async () => {
      try {
        if (!tiposVialidades?.length) {
          await fetchSome('/usuarios/tipo-vialidad/', SET_TIPOS_VIALIDADES);
        }
        const pais = await getCatalogoDePaises({ id: 152 });
        if (isMounted) {
          setPaises(pais);
        }
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetch();
    return () => { isMounted = false; };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getDireccion();
    // eslint-disable-next-line
  }, [parentID]);

  if (setFormAddress) {
    return (
      <Form
        name={addressPropName}
        form={formInstance}
        layout="vertical"
        onFinish={() => onFinish()}
      >
        <FormItemsDireccion
          mutable={mutable}
          setVisibleNoSeCodigo={setVisibleNoSeCodigo}
          paises={paises}
          collpasedSidebar={collpasedSidebar}
          tiposVialidades={tiposVialidades}
          allowVerify={allowVerify}
          ref={ref}
          permission={permission}
          setLoading={setLoading}
          visibleNoSeCodigo={visibleNoSeCodigo}
          addressPropName={addressPropName}
          triggerClone={triggerClone}
          entidad={entidad}
          allowMap={allowMap}
        />
      </Form>
    );
  }

  return (
    <FormItemsDireccion
      mutable={mutable}
      // mutable={_mutable}
      setVisibleNoSeCodigo={setVisibleNoSeCodigo}
      paises={paises}
      collpasedSidebar={collpasedSidebar}
      tiposVialidades={tiposVialidades}
      allowVerify={allowVerify}
      ref={ref}
      permission={permission}
      setLoading={setLoading}
      visibleNoSeCodigo={visibleNoSeCodigo}
      addressPropName={addressPropName}
      triggerClone={triggerClone}
      entidad={entidad}
      allowMap={allowMap}
    />
  );
};

Direccion.propTypes = {
  parentID: PropTypes.number,
  callback: PropTypes.func,
  valuesCallback: PropTypes.func,
  currentURI: PropTypes.string.isRequired,
  addressPropName: PropTypes.string,
  setLoading: PropTypes.func.isRequired,
  setFormAddress: PropTypes.func.isRequired,
  setCurrentTabKey: PropTypes.func.isRequired,
  mutable: PropTypes.bool,
  useParentID: PropTypes.bool,
  allowMap: PropTypes.bool,
  allowVerify: PropTypes.bool,
  permission: PropTypes.shape({
    add: PropTypes.bool.isRequired,
    authorize: PropTypes.bool.isRequired,
    cancel: PropTypes.bool.isRequired,
    change: PropTypes.bool.isRequired,
    delete: PropTypes.bool.isRequired,
    export: PropTypes.bool.isRequired,
    import: PropTypes.bool.isRequired,
    sync: PropTypes.bool.isRequired,
    view: PropTypes.bool.isRequired,
    direccionverificadadecontribuyente: PropTypes.shape({}),
  }).isRequired,
  triggerClone: PropTypes.bool,
};

Direccion.defaultProps = {
  parentID: null,
  callback: () => { },
  addressPropName: 'direccion',
  mutable: true,
  useParentID: true,
  allowMap: true,
  allowVerify: false,
  triggerClone: false,
  valuesCallback: null,
};

export default Direccion;
